import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import StarRating from '@rubenvara/react-star-rating'
import DeleteModal from './ReviewModal/DeleteModal'

const ReviewComponent = ({
  reviews,
  handlereplyClick,
  handleReviewDelete,
  timeZone,
  userRestriction,
  isUploading
}) => {
  const convertStarRatingToNumber = starRatingText => {
    // console.log(starRatingText, 'starRatingText')
    const textToNumber = {
      ONE: 1.0,
      TWO: 2.0,
      THREE: 3.0,
      FOUR: 4.0,
      FIVE: 5.0
    }
    const uppercaseText = (starRatingText || '').toUpperCase()
    return textToNumber[uppercaseText] || 0
  }
  const config = {
    fullColor: '#FEA500',
    size: 17,
    showText: false
  }

  const [comments, setComments] = useState([])

  const [isReadMore, setIsReadMore] = useState(true)

  const handleReadMore = (comments, currentIndex) => {
    // console.log(comments, 'comments')
    if (comments > limitedContent) {
      // console.log(isReadMore, 'isReadMore')

      setIsReadMore(prevState => ({
        ...prevState,
        [currentIndex]: !prevState[currentIndex]
      }))
    }
  }

  // console.log(isReadMore, 'isReadMore', typeof isReadMore[1])

  const limitedContent = 200
  let fullContent

  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [reviewId, setReviewId] = useState()
  const [locationId, setLocationId] = useState()

  const handleDeleteModal = (reviewId, locationId) => {
    setLocationId(locationId)
    setReviewId(reviewId)
    setOpenModalDelete(!openModalDelete)
  }

  return (
    <div>
      {reviews &&
        reviews?.map((item, index) => (
          <div
            key={index}
            className={`shadow-Review ${
              index % 2 === 0 ? 'bg-reviewTable1' : 'bg-reviewTable2'
            } border-0 card rounded-lg mt-2 mb-4  `}
          >
            <div className='card-body'>
              <div className='card-title '>
                <div className='row'>
                  <div className='col-9 flex'>
                    <div>
                      <div className='flex items-center gap-3'>
                        <img
                          src={item?.details?.review?.reviewer?.profilePhotoUrl}
                          width={50}
                          height={50}
                          alt='no img'
                        />
                        <div className='flex flex-col'>
                          <div className='flex gap-3'>
                            <p className='text-lg font-medium'>
                              {item?.details?.review?.reviewer?.displayName}
                            </p>
                            <div className='flex gap-2 items-center'>
                              <span className='fw-semibold text-amber-500 fs-5 '>
                                {' '}
                                {convertStarRatingToNumber(
                                  item?.details?.review?.starRating
                                )}{' '}
                              </span>
                              <StarRating
                                rating={convertStarRatingToNumber(
                                  item?.details?.review?.starRating
                                )}
                                style={{ marginBottom: '0px' }}
                                config={config}
                              />
                            </div>

                            <p
                              className={`mt-1 ms-2 text-md font-medium ${
                                item?.details?.review?.starRating === 'FIVE' ||
                                item?.details?.review?.starRating == 'FOUR'
                                  ? 'text-green-500'
                                  : item?.details?.review?.starRating ===
                                      'THREE' ||
                                    item?.details?.review?.starRating === 'TWO'
                                  ? 'text-blue-500'
                                  : 'text-red-700'
                              }`}
                            >
                              {item?.details?.review?.starRating === 'FIVE' ||
                              item?.details?.review?.starRating === 'FOUR'
                                ? 'Positive'
                                : item?.details?.review?.starRating ===
                                    'THREE' ||
                                  item?.details?.review?.starRating === 'TWO'
                                ? 'Moderate'
                                : 'Negative'}
                            </p>
                          </div>
                          <div className='text-sm flex flex-col'>
                            <p>{item?.address}</p>
                            <p>
                              {item?.state} {item?.city} {item?.postal_code}
                            </p>
                          </div>
                        </div>

                        {/* <div className='flex gap-2 items-center'>
                                                    <span className="fw-semibold text-amber-500 fs-5 ">  {convertStarRatingToNumber(item?.details?.review?.starRating)} </span>
                                                    <StarRating rating={convertStarRatingToNumber(item?.details?.review?.starRating)} style={{ marginBottom: "0px" }} config={config} />
                                                </div>

                                                <p className={`mt-1 ms-2 text-md font-medium ${item?.details?.review?.starRating === "FIVE" || item?.details?.review?.starRating == "FOUR"
                                                    ? "text-green-500"
                                                    : item?.details?.review?.starRating === "THREE" || item?.details?.review?.starRating === "TWO"
                                                        ? "text-blue-500"
                                                        : "text-red-700"}`}>
                                                    {item?.details?.review?.starRating === "FIVE" || item?.details?.review?.starRating === "FOUR"
                                                        ? "Positive"
                                                        : item?.details?.review?.starRating === "THREE" || item?.details?.review?.starRating === "TWO"
                                                            ? "Moderate"
                                                            : "Negative"}
                                                </p> */}
                      </div>

                      <p
                        className={`text-md  mt-3 ${
                          item?.details?.review?.comment?.length
                            ? 'text-businessProfile '
                            : 'text-ReviewNoContent'
                        } `}
                        id={`collapse${index}`}
                      >
                        {item?.details?.review?.comment?.length > 200
                          ? ` ${item?.details?.review?.comment.slice(
                              0,
                              isReadMore[index] === undefined
                                ? isReadMore
                                  ? limitedContent
                                  : fullContent
                                : isReadMore[index]
                                ? fullContent
                                : limitedContent
                            )}`
                          : item?.details?.review?.comment}
                        <span
                          className={`${
                            item?.details?.review?.comment?.length > 200
                              ? 'text-listing cursor-pointer'
                              : 'hidden'
                          }`}
                          onClick={() =>
                            handleReadMore(
                              item?.details?.review?.comment?.length,
                              index
                            )
                          }
                        >
                          {isReadMore[index] === undefined
                            ? isReadMore
                              ? '....Read more'
                              : 'Read Less'
                            : isReadMore[index]
                            ? 'Read Less'
                            : '....Read More'}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='col-3 text-end'>
                    <span className='text-sm'>
                      {moment
                        .utc(item?.details?.review?.updateTime)
                        .tz('Asia/Calcutta')
                        .format('DD MMM YYYY')}
                    </span>
                    <br />
                    <span className='text-sm text-businessProfile'>
                      {moment
                        .utc(item?.details?.review?.updateTime)
                        .tz('Asia/Calcutta')
                        .format('hh:mm A')}
                    </span>
                  </div>
                </div>
              </div>
              {/* <!-- Modal toggle --> */}
              {item?.reply?.replyComment ? (
                <>
                  <hr />
                  <h5 className='card-title mt-3'>
                    <div className='row'>
                      <div className='col-6 flex items-center gap-3'>
                        <img
                          src='https://pro-manage.s3.ap-south-1.amazonaws.com/ReviewReplyIcon.svg'
                          alt='ReviewReply'
                          className='img-fluid'
                        />
                        <p className='text-base font-medium m-0 text-pink-500'>
                          Response from the Owner
                        </p>
                      </div>

                      <div className='col-6 text-end'>
                        <span className='text-sm '>
                          {moment
                            .utc(item?.reply?.updatedDate)
                            .tz('Asia/Calcutta')
                            .format('DD MMM YYYY')}
                        </span>
                        <br />
                        <span className='text-sm text-businessProfile'>
                          {timeZone
                            ? moment
                                .tz(
                                  moment
                                    .utc(item?.reply?.updatedDate)
                                    .tz('Asia/Calcutta')
                                    .format('YYYY-MM-DD') +
                                    ' ' +
                                    item?.reply?.updatedTime,
                                  'Asia/Calcutta'
                                )
                                .format('hh:mm A')
                            : moment
                                .utc(item?.reply?.updatedTime)
                                .tz('Asia/Calcutta')
                                .format('hh:mm A')}
                        </span>
                      </div>
                      <div className='col-12 mt-2 flex justify-between items-center'>
                        <span className='text-md text-businessProfile col-10 leading-6 font-semibold'>
                          {item?.reply?.replyComment}
                        </span>
                        {userRestriction == 1 ? (
                          ''
                        ) : (
                          <img
                            src='https://pro-manage.s3.ap-south-1.amazonaws.com/DeleteReplyIcon.svg'
                            className='img-fluid cursor-pointer  hover:opacity-70'
                            alt='Delete'
                            onClick={() =>
                              handleDeleteModal(
                                item?.details?.review?.reviewId,
                                item?.locationId
                              )
                            }
                          />
                        )}
                      </div>
                      {userRestriction == 1 ? (
                        ''
                      ) : (
                        <img
                          src='https://pro-manage.s3.ap-south-1.amazonaws.com/ReviewEditButton.svg'
                          alt='edit reply'
                          className='img-fluid mt-2 cursor-pointer w-14 '
                          onClick={() => handlereplyClick(item)}
                        />
                      )}
                    </div>
                  </h5>
                </>
              ) : (
                <>
                  {userRestriction == 1 ? (
                    ''
                  ) : (
                    <button
                      className='text-listing font-medium text-md mt-2 ms-2 ps-2 cursor-pointer  w-16 rounded-lg p-1 border-1 border-listing '
                      // onClick={() => setOpenModalReply(true)}

                      onClick={() => handlereplyClick(item)}
                    >
                      Reply
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        ))}

      {/* <p className="text-center mt-5 fw-semibold"> {
                !reviews && "No reviews found"
            }</p> */}
      <DeleteModal
        openModalDelete={openModalDelete}
        setOpenModalDelete={setOpenModalDelete}
        handleDelete={handleReviewDelete}
        reviewId={reviewId}
        locationId={locationId}
        isUploading={isUploading}
      />
    </div>
  )
}

export default ReviewComponent
