const url = window.location.pathname
// console.log(url, 'sideba123r')
export const notification = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 4.5C6.75 4.5 6 9 6 12.75C6 16.5 3.75 18.75 3.75 18.75H20.25C20.25 18.75 18 16.5 18 12.75C18 9 17.25 4.5 12 4.5ZM12 4.5V2.25M15 18.75C15 18.75 15 21.75 12 21.75C9 21.75 9 18.75 9 18.75'
      // stroke={url?.includes('notification') ? '#817AF3' : '#323B4B'}
      stroke='#323B4B'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
)
