import React, { useContext, useEffect, useRef, useState } from 'react'
import CalenderForInsights from '../Components/InsightCalender'
import Counts from '../Components/Counts'
import AddQuestionModal from '../Components/QuestionModal/AddQuestionModal'
import AnswerModel from '../Components/QuestionModal/AnswerModal'
import DeleteModal from '../Components/QuestionModal/DeleteModal'
import moment from 'moment'
import httpservice from '../Service/httpservice'
import QuestionEditModal from '../Components/QuestionModal/QuestionEditModal'
import { API_URL } from '../env/env'
import { toast } from 'react-toastify'
import { AuthContext } from '../context/AuthContext'
import { LocationDetails } from '../Store/LocationInfo'
import RangeSlider from '../Components/RangeSlider'
import { UserInfo } from '../Store/UserInfo'
import { CalenderQuestionInfo } from '../Store/CalenderInfo'
import Loader from '../loader/loader'
import QuestionCalender from '../Components/QuestionCalender'

const Questions = () => {
  const date = CalenderQuestionInfo(state => state.CalenderQuestionInfo)
  const boolean = localStorage.getItem('calender')
  const {
    userInfo,
    groupId,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleQuestionCountNotification,
    handleReviewNotification,
    handleLocationNotification,
    tokenInfo
  } = useContext(AuthContext)

  useEffect(() => {
    if (userInfo?.info?.roleDetails?.roleId === 1) {
      handleLocationCountNotification(true)
      handleReviewCountNotification(true)
      handleQuestionCountNotification(true)
      // handleReviewNotification(true)
      // handleLocationNotification(true)
    }
  }, [groupId.groupId, userInfo])

  useEffect(() => {
    tokenInfo()
  }, [])

  useEffect(() => {
    // console.log(boolean === 'true', 'date', typeof boolean)

    if (boolean === 'true') {
      // console.log('1', 'count')
      const formattedStartDate = moment
        .utc(date?.startDate)
        .tz('Asia/Calcutta')
        .format('YYYY-MM-DD')
      const formattedEndDate = moment
        .utc(date?.endDate)
        .tz('Asia/Calcutta')
        .format('YYYY-MM-DD')
      // startDate = formattedStartDate;
      // endDate = formattedEndDate
      // setStartDate(formattedStartDate);
      // setEndDate(formattedEndDate);

      handelGetAllResponse(formattedStartDate, formattedEndDate, 1)
    }
  }, [date, boolean, groupId])

  useEffect(() => {
    setStartDate(
      moment.utc(date?.startDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
    )
    setEndDate(
      moment.utc(date?.endDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
    )
  }, [date])

  const [load, setload] = useState(true)

  const [sort, setSort] = useState(0)

  const [startDate, setStartDate] = useState(
    moment.utc(date?.startDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
  )
  const [endDate, setEndDate] = useState(
    moment.utc(date?.endDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
  )

  const [totalPage, setTotalPage] = useState(2)
  const [hasMore, setHasMore] = useState(true)
  const elementRef = useRef(null)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [qaType, setQaType] = useState(0)
  const [allResponse, setAllResponse] = useState([])
  const [questionCount, setQuestionCount] = useState('')

  let groupValue = localStorage.getItem('GroupChange')

  useEffect(() => {
    if (groupValue === 'true') {
      // console.log('group', groupValue)
      setAllResponse([])
      setPage(1)
      localStorage.setItem('GroupChange', 'false')
    }
  }, [groupValue, page])

  useEffect(() => {
    handelVerifiedLocations()
  }, [groupId.groupId])

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const firstEntry = entries[0]
        // console.log('hasMore1', totalPage >= page, page, totalPage)
        if (totalPage >= page) {
          if (firstEntry?.isIntersecting && hasMore) {
            handelGetAllResponse()
          }
        }
      },
      {
        rootMargin: '100px'
      }
    )

    if (observer && elementRef.current) {
      observer.observe(elementRef.current)
    }

    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [
    elementRef,
    hasMore,
    pageSize,
    page,
    sort,
    totalPage,
    groupId.groupId,
    startDate,
    endDate,
    qaType
  ])

  // useEffect(() => {
  //   handelGetAllResponse()
  // }, [])

  // const  = LocationDetails(state => state.LocationDetails)
  const [listOfLocations, setListOfLocations] = useState([])
  const [selectedLocation, setSelectedLocation] = useState('')

  const handelVerifiedLocations = async () => {
    try {
      let body = {
        userId: userInfo.info?.userId,
        groupId: groupId.groupId
      }

      let url = `${API_URL}/location/verified`

      const response = await httpservice.post(url, body)
      if (response.status === 200) {
        // console.log(response, 'locationResponse')
        setListOfLocations(response?.data?.data)
      } else {
        setListOfLocations([])
      }
    } catch (error) {
      setListOfLocations([])
    }
  }

  const [openAddQuestionModal, setOpenAddQuestionModal] = useState(false)
  const [addAnswer, setAddAnswer] = useState('')

  const [openReplyQuestionModal, setOpenReplyQuestionModal] = useState(false)
  const [replyAnswer, setReplyAnswer] = useState('')
  const [questionReplyJson, setQuestionReplyJson] = useState('')

  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const [openQuestionEditModal, setOpenQuestionEditModal] = useState(false)
  const [editQuestionJson, setEditQuestionJson] = useState('')
  const [editQuestion, setEditQuestion] = useState('')
  const [editAnswer, setEditAnswer] = useState('')
  const [type, setType] = useState('')
  const [quesName, setquesName] = useState('')
  const [editAnswerType, seteditAnswerType] = useState('')
  const [filter, setFilter] = useState(0)

  const handleReplyAnswer = async () => {
    try {
      const body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        locationId: selectedLocation,
        text: replyAnswer,
        quesName: quesName
      }
      const url =
        editAnswerType === 'editAnswer'
          ? `${API_URL}/qa/answer/update`
          : `${API_URL}/qa/answer`
      const allResponse = await httpservice.post(url, body)
      if (allResponse.status === 200) {
        toast.success(
          editAnswerType === 'editAnswer'
            ? 'Answer has been edited'
            : 'Answer has been added'
        )
        setReplyAnswer('')
        setQuestionReplyJson('')
        setOpenReplyQuestionModal(false)
        setSelectedLocation('')
        setPage(1)
        setAllResponse([])

        // handelGetAllResponse()
      } else {
        setReplyAnswer('')
        setQuestionReplyJson('')
        setOpenReplyQuestionModal(false)
        setSelectedLocation('')

        toast.error(allResponse.message)
      }
    } catch (error) {
      setReplyAnswer('')
      setQuestionReplyJson('')
      setOpenReplyQuestionModal(false)
      setSelectedLocation('')
      toast.error(error.message)
    }
  }

  const handleAddQuestionPost = async () => {
    try {
      if (!userInfo || !userInfo.info || !userInfo.info.userId) {
        return toast.error('User information is missing.')
      }
      if (!groupId || !groupId.groupId) {
        return toast.error('Group information is missing.')
      }
      if (!selectedLocation) {
        return toast.error('Please select a location.')
      }
      if (addAnswer === '') {
        return toast.error('Please add a question.')
      }

      const body = {
        userId: userInfo.info.userId,
        groupId: groupId.groupId,
        locationId: selectedLocation,
        text: addAnswer
      }

      const url = `${API_URL}/qa/ques/create`
      const allResponse = await httpservice.post(url, body)

      if (allResponse?.status === 200) {
        toast.success('Question has been added successfully.')
        setAddAnswer('')
        setSelectedLocation('')
        setOpenAddQuestionModal(false)
        setPage(1)
        setAllResponse([])
        if (userInfo?.info?.roleDetails?.roleId === 1) {
          handleQuestionCountNotification(true)
        }
      } else {
        toast.error(allResponse?.message || 'Failed to add question.')
        setAddAnswer('')
        setSelectedLocation('')
        setOpenAddQuestionModal(false)
      }
    } catch (err) {
      toast.error(err.message || 'An error occurred while adding the question.')
      setAddAnswer('')
      setSelectedLocation('')
      setOpenAddQuestionModal(false)
    }
  }

  const handleDelete = async type => {
    try {
      const body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        locationId: selectedLocation,
        quesName: quesName
      }
      // console.log(type, 'type')
      const url =
        type === 'Question'
          ? `${API_URL}/qa/ques/delete`
          : `${API_URL}/qa/answer/delete`
      const response = await httpservice.post(url, body)
      if (response?.status === 200) {
        toast.success('Question has been deleted')
        setOpenDeleteModal(false)
        setSelectedLocation('')
        setPage(1)
        setAllResponse([])
        if (userInfo?.info?.roleDetails?.roleId === 1) {
          handleQuestionCountNotification(true)
        }
      } else {
        toast.error(response.message)
        setOpenDeleteModal(false)
        setSelectedLocation('')
      }
    } catch (err) {
      toast.error(err.message)
      setOpenDeleteModal(false)
      setSelectedLocation('')
    }
  }
  const handleEditQuestion = async () => {
    try {
      const body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        locationId: selectedLocation,
        text: editQuestion,
        quesName: quesName
      }

      const url = `${API_URL}/qa/ques/update`
      const response = await httpservice.post(url, body)
      if (response?.status === 200) {
        toast.success('Question has been updated')
        setEditQuestion('')
        setSelectedLocation('')
        setOpenQuestionEditModal(false)
        setPage(1)
        if (userInfo?.info?.roleDetails?.roleId === 1) {
          handleQuestionCountNotification(true)
        }
        setAllResponse([])
      } else {
        setEditQuestion('')
        setSelectedLocation('')
        setOpenQuestionEditModal(false)
        toast.error(response.message)
      }
    } catch (error) {
      toast.error(error.message)
      setEditQuestion('')
      setSelectedLocation('')
      setOpenQuestionEditModal(false)
    }
  }

  const handleAddQuestionModal = () => {
    // setReplyAnswer(item?.details?.topAnswers.map((item)=>item))
    setOpenAddQuestionModal(!openAddQuestionModal)
  }

  const handleAnswerModal = (item, type, answer) => {
    if (type === 'newAnswer') {
      setQuestionReplyJson(item)
      seteditAnswerType(type)
      setOpenReplyQuestionModal(!openReplyQuestionModal)
    } else {
      seteditAnswerType(type)
      setQuestionReplyJson(item)
      // console.log(answer, 'answer')
      setReplyAnswer(answer)
      setOpenReplyQuestionModal(!openReplyQuestionModal)
    }
  }

  const handleDeleteModal = type => {
    // console.log(type, 'type')
    setType(type)
    setOpenDeleteModal(!openDeleteModal)
  }

  const handelEditQuestion = item => {
    setEditQuestionJson(item)
    setEditQuestion(item?.details?.text)
    setOpenQuestionEditModal(!openQuestionEditModal)
  }

  const handelGetAllResponse = async () => {
    try {
      let body = {}

      if (boolean === 'true' || boolean === true) {
        body = {
          userId: userInfo?.info?.userId,
          groupId: groupId?.groupId,
          page: 1,
          pageSize: Number(pageSize),
          qaType: Number(qaType),
          sortBy: Number(sort),
          startDate: startDate,
          endDate: endDate
        }
      } else {
        body = {
          userId: userInfo?.info?.userId,
          groupId: groupId?.groupId,
          page: Number(page),
          pageSize: Number(pageSize),
          qaType: Number(qaType),
          sortBy: Number(sort),
          startDate: startDate,
          endDate: endDate
        }
      }

      const url = `${API_URL}/qa/all`
      const response = await httpservice.post(url, body)

      if (response?.status === 200) {
        setQuestionCount(response?.data)
        // Handle successful response
        // console.log(response?.data.totalPages >= page)
        // console.log('no more data', response?.data.totalPages)

        setTotalPage(response?.data.totalPages)
        // console.log(response?.data?.data, 'review')
        setload(false)
        if (boolean === 'true') {
          setPage(1)
          setAllResponse([])
        }

        // console.log(boolean, 'boolean135')
        if (boolean === 'false') {
          if (totalPage >= page) {
            setAllResponse(prevProducts => {
              const newData = response?.data?.data || []
              return Array.isArray(prevProducts)
                ? [...prevProducts, ...newData]
                : newData
            })
            if (totalPage >= page && boolean === 'false') {
              setPage(prevPage => prevPage + 1)
            }
          } else {
            setHasMore(false)
          }
        }
        localStorage.setItem('calender', false)
      } else {
        // Handle non-200 status
        console.error('Failed to fetch data:', response?.status)
        setAllResponse([])
        setQuestionCount('')
        setload(false)
      }
    } catch (err) {
      // Handle network errors or other exceptions
      console?.error('An error occurred while fetching data:', err)
      setAllResponse([])
      setQuestionCount('')
      setload(false)
    }
  }

  const rangeRef1 = useRef()
  const rangeRef2 = useRef()
  const rangeRef3 = useRef()

  // // console.log(allResponse.details.topanswer.find(item=>item.author.type===), 'allResponse')
  return (
    <div>
      {load && <Loader />}

      <div className='bg-InsightBackGround'>
        <div className='container  p-8 pt-3  '>
          <div className=' flex items-center justify-between text-3xl font-semibold'>
            <h2 className='capitalize'>Question & Answer</h2>
            <QuestionCalender />
          </div>

          <div className='container md:px-4 py-2'>
            <div className=' mt-2 mb-2 '>
              <div className='flex justify-around items-center shadow-filter1 rounded-2xl py-4  gap-2 bg-white'>
                <RangeSlider
                  title='Total'
                  imageUrl='https://pro-manage.s3.ap-south-1.amazonaws.com/count.svg'
                  count={questionCount?.totalQa || 0}
                  colorClass='bg-TotalColor'
                  dialClass='dial'
                  rangeRef={rangeRef1}
                  percentage={questionCount.totalQa ? 100 : 0}
                  // updateRangeSlider={updateRangeSlider}
                />
                <RangeSlider
                  title='Answered'
                  imageUrl='https://pro-manage.s3.ap-south-1.amazonaws.com/answer.svg'
                  count={questionCount?.answeredCount || 0}
                  colorClass='bg-listing'
                  dialClass='dial1'
                  rangeRef={rangeRef2}
                  percentage={
                    questionCount.answeredCount
                      ? (questionCount.answeredCount / questionCount.totalQa) *
                        100
                      : 0
                  }
                />
                <RangeSlider
                  title='Not Answered'
                  imageUrl='https://pro-manage.s3.ap-south-1.amazonaws.com/Group+1321316036.svg'
                  count={questionCount?.notAnsweredCount || 0}
                  colorClass='bg-reviewNotReplied'
                  dialClass='dial2'
                  rangeRef={rangeRef3}
                  percentage={
                    questionCount.notAnsweredCount
                      ? (questionCount.notAnsweredCount /
                          questionCount.totalQa) *
                        100
                      : 0
                  }
                />
              </div>
            </div>
          </div>

          <div className=' flex justify-between mb-4 mt-2'>
            <div className='flex gap-3 '>
              {userInfo?.info?.accessTypeDetails?.accessTypeId === 1 ? (
                ''
              ) : (
                <div
                  className='border-listing cursor-pointer border-1 text-md font-medium  bg-white text-md text-listing  p-2.5  rounded-lg flex gap-2'
                  onClick={() => handleAddQuestionModal()}
                >
                  + Add Questions
                </div>
              )}
              {/* <div className='flex items-center  gap-2 justify-center   bg-white border border-gray-300 text-black text-md  p-2.5 w-28 rounded-lg cursor-pointer hover:bg-pink-200'>
                <img
                  src='https://pro-manage.s3.ap-south-1.amazonaws.com/filter.svg'
                  className='img-fluid'
                  alt='filter'
                /> */}
              <div>
                <select
                  className='bg-white   text-black text-sm  rounded-lg block w-full p-2.5  dark:placeholder-gray-400 dark:text-white hover:bg-pink-200 cursor-pointer '
                  onChange={e => {
                    setQaType(e?.target?.value)
                    setPage(1)
                    setAllResponse('')
                  }}
                >
                  <option selected value='0'>
                    All Questions
                  </option>
                  <option value='2'>Answered </option>
                  <option value='1'>Unanswered</option>
                </select>
              </div>
            </div>
            <div className='flex gap-2'>
              <div className='  col-6'>
                <select
                  id='countries'
                  className='bg-white  border border-gray-300 text-black text-sm  rounded-lg block w-full p-2.5  dark:placeholder-gray-400 dark:text-white hover:bg-pink-200 cursor-pointer '
                  onChange={e => {
                    setSort(e?.target?.value)
                    setPage(1)
                    setAllResponse('')
                  }}
                >
                  <option selected value='0'>
                    Newest
                  </option>
                  <option value='1'>Oldest</option>
                </select>
              </div>
              <div className='col-6'>
                <select
                  id='pageSize'
                  className='bg-white  border border-gray-300 text-black text-sm  rounded-lg block w-full p-2.5 cursor-pointer  dark:placeholder-gray-400 dark:text-white hover:bg-pink-200'
                  onChange={e => {
                    setPageSize(e?.target?.value)
                    setPage(1)
                    setAllResponse('')
                  }}
                >
                  <option selected value='10'>
                    Show 10 Entries
                  </option>
                  <option value='25'>Show 25 Entries</option>
                  <option value='50'>Show 50 Entries</option>
                  <option value='100'>Show 100 Entries</option>
                </select>
              </div>
            </div>
          </div>

          {allResponse?.length > 0 &&
            allResponse?.map((item, index) => (
              <div
                className={`border-0 card rounded-lg mt-2 mb-4 shadow-Review ${
                  index % 2 === 0 ? 'bg-reviewTable1' : 'bg-reviewTable2'
                } bg-overView  p-4`}
              >
                <div className='flex justify-between'>
                  <div className='flex flex-col'>
                    <p className='  text-lg mb-2 font-medium text-ViewActivity'>
                      Business : {item?.title}
                    </p>
                    <p className='text-sm text-businessProfile'>
                      {item.address}
                    </p>
                  </div>
                  <div className='flex flex-col'>
                    <p className='text-sm font-normal text-lightBlack'>
                      {moment
                        .utc(item?.details?.createTime)
                        .tz('Asia/Calcutta')
                        .format('DD MMM YYYY')}
                    </p>
                    <p className='text-sm font-normal text-lightBlack'>
                      {moment
                        .utc(item?.details?.createTime)
                        .tz('Asia/Calcutta')
                        .format('hh:mm A')}
                    </p>
                  </div>
                </div>
                <div className='flex items-center gap-3 pt-2'>
                  <img
                    src={
                      item?.details?.author?.type === 'MERCHANT'
                        ? 'https://pro-manage.s3.ap-south-1.amazonaws.com/DummyProfileManager.svg'
                        : (() => {
                            const profilePhotoUri =
                              item?.details?.author?.profilePhotoUri
                            if (
                              profilePhotoUri &&
                              !profilePhotoUri.startsWith('https://') &&
                              !profilePhotoUri.startsWith('http://')
                            ) {
                              return `https:${profilePhotoUri}`
                            }
                            return profilePhotoUri
                          })()
                    }
                    width={45}
                    height={45}
                    alt='no img'
                  />

                  <p
                    className={`text-lg font-medium ${
                      item?.details?.author?.type === 'MERCHANT'
                        ? 'text-listing'
                        : ''
                    }`}
                  >
                    {item?.details?.author?.type === 'MERCHANT'
                      ? 'MERCHANT'
                      : item?.details?.author?.displayName}
                  </p>
                </div>
                <div className='flex justify-between'>
                  <h4 className='text-md font-medium pt-2 col-9'>
                    Question:
                    <span className='text-businessProfile ps-2 leading-6'>
                      {item?.details?.text}
                    </span>
                  </h4>
                  {item?.details?.author?.type === 'MERCHANT' &&
                    item?.details?.author?.type !== 'REGULAR_USER' && (
                      <div
                        className={`flex gap-3 justify-between items-center ${
                          userInfo?.info?.accessTypeDetails?.accessTypeId === 1
                            ? 'hidden'
                            : ''
                        }`}
                      >
                        <img
                          src='https://pro-manage.s3.ap-south-1.amazonaws.com/PostMedia.svg'
                          className='cursor-pointer w-8 hover:opacity-80'
                          alt='Profilepost'
                          onClick={() => {
                            handleDeleteModal('Question')
                            setquesName(item?.details?.name)
                            setSelectedLocation(item?.locationId)
                          }}
                        />
                        <img
                          src='https://pro-manage.s3.ap-south-1.amazonaws.com/editMedia.svg'
                          className='cursor-pointer w-8 hover:opacity-80'
                          alt='Profilepost'
                          onClick={() => {
                            handelEditQuestion(item)
                            setquesName(item?.details?.name)
                            setSelectedLocation(item?.locationId)
                          }}
                        />
                      </div>
                    )}
                </div>

                {item?.details?.topAnswers ? (
                  item?.details?.topAnswers?.map(item1 => (
                    <div className='pt-3'>
                      <hr className='pt-2 pb-2' />
                      <div className='flex justify-between items-center pb-2'>
                        <div className='flex gap-3 items-center'>
                          <img
                            src={
                              item1.author?.type === 'MERCHANT'
                                ? 'https://pro-manage.s3.ap-south-1.amazonaws.com/DummyProfileManager.svg'
                                : (() => {
                                    const profilePhotoUrl =
                                      item1?.author.profilePhotoUri
                                    if (
                                      profilePhotoUrl &&
                                      !profilePhotoUrl.startsWith('https://') &&
                                      !profilePhotoUrl.startsWith('http://')
                                    ) {
                                      return `https:${profilePhotoUrl}`
                                    }
                                    return profilePhotoUrl
                                  })()
                            }
                            alt='profileImg'
                            width={45}
                            height={45}
                          />
                          <p className='text-lg font-medium text-black'>
                            {item1.author?.type === 'MERCHANT'
                              ? 'MERCHANT'
                              : item1.author.displayName}
                          </p>
                        </div>
                        <div className='flex flex-col'>
                          <p className='text-sm font-normal text-lightBlack'>
                            {moment
                              .utc(item1?.createTime)
                              .tz('Asia/Calcutta')
                              .format('DD MMM YYYY')}
                          </p>
                          <p className='text-sm font-normal text-lightBlack'>
                            {moment
                              .utc(item1?.createTime)
                              .tz('Asia/Calcutta')
                              .format('hh:mm A')}
                          </p>
                        </div>
                      </div>
                      <div className='flex justify-between items-center'>
                        <p className='text-md font-medium text-black col-9'>
                          Answer :{' '}
                          <span className='text-businessProfile'>
                            {item1.text}
                          </span>
                        </p>

                        {item1?.author?.type === 'MERCHANT' &&
                          item1?.author?.type !== 'REGULAR_USER' && (
                            <div
                              className={`flex gap-3 justify-between items-center ${
                                userInfo?.info?.accessTypeDetails
                                  ?.accessTypeId === 1
                                  ? 'hidden'
                                  : ''
                              } `}
                            >
                              <img
                                src='https://pro-manage.s3.ap-south-1.amazonaws.com/PostMedia.svg'
                                className='cursor-pointer w-8 hover:opacity-80'
                                alt='Profilepost'
                                onClick={() => {
                                  handleDeleteModal('Answer')
                                  setquesName(item?.details?.name)
                                  setSelectedLocation(item?.locationId)
                                }}
                              />
                              <img
                                src='https://pro-manage.s3.ap-south-1.amazonaws.com/editMedia.svg'
                                className='cursor-pointer w-8 hover:opacity-80'
                                alt='Profilepost'
                                onClick={() => {
                                  handleAnswerModal(
                                    item,
                                    'editAnswer',
                                    item1.text
                                  )
                                  setquesName(item?.details?.name)
                                  setSelectedLocation(item?.locationId)
                                }}
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    className={`text-md font-medium   text-lg text-listing  pt-2.5 cursor-pointer  rounded-lg w-28 ${
                      userInfo?.info?.accessTypeDetails?.accessTypeId === 1
                        ? 'hidden'
                        : ''
                    } `}
                    onClick={() => {
                      handleAnswerModal(item, 'newAnswer')
                      setquesName(item?.details?.name)
                      setSelectedLocation(item?.locationId)
                    }}
                  >
                    <p className=''>Answer</p>
                  </div>
                )}

                {item?.details?.topAnswers ? (
                  item?.details?.topAnswers?.find(
                    item1 => item1?.author?.type === 'MERCHANT'
                  ) ? (
                    ''
                  ) : (
                    <div
                      className={`text-md font-medium   text-lg text-listing  pt-2.5 cursor-pointer  rounded-lg w-28 ${
                        userInfo?.info?.accessTypeDetails?.accessTypeId === 1
                          ? 'hidden'
                          : ''
                      } `}
                      onClick={() => {
                        handleAnswerModal(item, 'newAnswer')
                        setquesName(item?.details?.name)
                        setSelectedLocation(item?.locationId)
                      }}
                    >
                      <p className=''>Answer {item?.author?.type}</p>
                    </div>
                  )
                ) : (
                  ''
                )}
              </div>
            ))}
        </div>
      </div>

      <AddQuestionModal
        openAddQuestionModal={openAddQuestionModal}
        setOpenAddQuestionModal={setOpenAddQuestionModal}
        setAddAnswer={setAddAnswer}
        addAnswer={addAnswer}
        handleAddQuestionPost={handleAddQuestionPost}
        setSelectedLocation={setSelectedLocation}
        listOfLocations={listOfLocations}
      />

      <AnswerModel
        openReplyQuestionModal={openReplyQuestionModal}
        setOpenReplyQuestionModal={setOpenReplyQuestionModal}
        setReplyAnswer={setReplyAnswer}
        replyAnswer={replyAnswer}
        questionReplyJson={questionReplyJson}
        handleReplyAnswer={handleReplyAnswer}
        setSelectedLocation={setSelectedLocation}
      />

      <DeleteModal
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDelete={handleDelete}
        type={type}
        setSelectedLocation={setSelectedLocation}
      />

      <QuestionEditModal
        editQuestionJson={editQuestionJson}
        openQuestionEditModal={openQuestionEditModal}
        setOpenQuestionEditModal={setOpenQuestionEditModal}
        editQuestion={editQuestion}
        setEditQuestion={setEditQuestion}
        handleEditQuestion={handleEditQuestion}
        // selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
      />

      <div className=''>
        <h2
          className='mb-4 fw-semibold'
          ref={elementRef}
          style={{ textAlign: 'center' }}
        >
          {totalPage >= page && hasMore && allResponse?.length
            ? 'Loading Items'
            : ''}
          {allResponse?.length === 0 && (
            <div className='flex justify-center items-center'>
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/ReviewNotFound.svg'
                alt=''
              />
            </div>
          )}
        </h2>
      </div>
    </div>
  )
}

export default Questions
