import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const initialValue = {
  LocationDetails: [],
};

export const LocationDetails = create(
  persist(() => initialValue, {
    name: "LocationDetails",
    storage: createJSONStorage(() => localStorage),
    partialize: (state) => ({ LocationDetails: state.LocationDetails }),
  })
);


export const setLocationData = (item)=>
LocationDetails.setState((state)=>{
  return{ LocationDetails: item }
})