import React from 'react'
import moment from 'moment'
import { set } from 'date-fns'

const SixthForm = ({
  onPrevious,
  everyPrevious,
  handleConfirm,
  days,
  values,
  setIsUploading,
  isUploading
}) => {
  // console.log(values, 'values')
  return (
    <div>
      <div className='flex items-center justify-center row'>
        <div className='col-4'>
          <img
            src='https://pro-manage.s3.ap-south-1.amazonaws.com/step6.svg'
            className='img-fluid'
            alt='vector'
          />
        </div>
        <div className=' bg-white rounded-2xl col-6 p-0'>
          <form className=''>
            <div className='ms-3 me-3 ps-3 pe-3 pt-2 pb-2 mt-2 mb-0 flex gap-1 relative bg-white rounded-xl flex-col'>
              <div className='flex justify-between items-center col-12'>
                <h4 className='text-lightBlack font-medium text-xl '>
                  Business Name{' '}
                </h4>
                <img
                  src='https://pro-manage.s3.ap-south-1.amazonaws.com/editMedia.svg'
                  className='cursor-pointer w-8'
                  alt='Profilepost'
                  onClick={() => everyPrevious(5)}
                />
              </div>

              <p className='text-overviewDetails text-base'>{values?.title}</p>
              {values?.storeCode && (
                <>
                  <h4 className='text-lightBlack font-medium text-xl'>
                    Store Code
                  </h4>
                  <p className='text-businessProfile text-base'>
                    {values?.storeCode}
                  </p>
                </>
              )}
              <h4 className='text-lightBlack font-medium text-xl'>Category</h4>
              <p className='text-businessProfile text-base'>Primary Category</p>
              <p className='text-listing text-base'>
                {values?.categories?.primaryCategory?.displayName}
              </p>
              {values?.categories?.additionalCategories?.length > 0 && (
                <div>
                  <p className='text-businessProfile text-base'>
                    Additional Categories
                  </p>
                  {values.categories.additionalCategories.map((item, index) => (
                    <p key={index} className='text-listing text-base'>
                      {item.displayName}
                    </p>
                  ))}
                </div>
              )}
            </div>

            <hr className='text-businessProfile' />

            <div className='ms-3  me-3 ps-3 pe-3 pt-2 pb-2 mt-3 mb-0 flex gap-1 relative bg-white rounded-xl flex-col'>
              <div className='flex justify-between items-center col-12'>
                <h4 className='text-lightBlack font-medium text-xl '>
                  Address{' '}
                </h4>
                <img
                  src='https://pro-manage.s3.ap-south-1.amazonaws.com/editMedia.svg'
                  className='cursor-pointer w-8'
                  alt='Profilepost'
                  onClick={() => everyPrevious(4)}
                />
              </div>
              <p className='text-businessProfile text-base col-8'>
                {values?.storefrontAddress?.addressLines[0]}{' '}
                {values?.storefrontAddress?.locality}{' '}
                {values?.storefrontAddress?.administrativeArea}{' '}
                {values?.storefrontAddress?.postalCode}
              </p>
              <div className='flex justify-start gap-3 items-center'>
                <div className=''>
                  <h4 className='text-RecommentationText font-medium text-base'>
                    Longtitude
                  </h4>
                  <p className='text-listing text-base'>
                    {values?.latlng?.longitude}
                  </p>
                </div>
                <div>
                  <h4 className='text-RecommentationText font-medium text-base'>
                    Latitude
                  </h4>
                  <p className='text-listing text-base'>
                    {values?.latlng?.latitude}
                  </p>
                </div>
              </div>
            </div>

            <hr className='text-businessProfile' />

            <div className='ms-3  me-3 ps-3 pe-3 pt-2 pb-2 mt-3 mb-0 flex gap-1 relative bg-white rounded-xl flex-col'>
              <div className='flex justify-between items-center col-12'>
                <h4 className='text-lightBlack font-medium text-xl '>
                  Contact Number{' '}
                </h4>
                <img
                  src='https://pro-manage.s3.ap-south-1.amazonaws.com/editMedia.svg'
                  className='cursor-pointer w-8'
                  alt='Profilepost'
                  onClick={() => everyPrevious(3)}
                />
              </div>
              <p className='text-businessProfile text-base'>Primary Number</p>
              <p className='text-listing text-base'>
                {values?.phoneNumbers?.primaryPhone}
              </p>
              {values?.phoneNumbers?.additionalPhones && (
                <>
                  <p className='text-businessProfile text-base'>
                    Alternate Number
                  </p>
                  {values?.phoneNumbers?.additionalPhones.map((item, index) => (
                    <p className='text-listing text-base'>{item}</p>
                  ))}
                </>
              )}

              {values.websiteUrl && (
                <>
                  <h4 className='text-lightBlack font-medium text-xl '>
                    Your Website Url
                  </h4>
                  <p className='text-listing text-base'>{values.websiteUrl}</p>
                </>
              )}
            </div>

            <hr className='text-businessProfile' />
            {}
            <div className='ms-3  me-3 ps-3 pe-3 pt-2 pb-2 mt-3 mb-0 flex gap-1 relative bg-white rounded-xl flex-col'>
              <div className='flex justify-between items-center col-12'>
                <h4 className='text-lightBlack font-medium text-xl '>
                  Working Hours{' '}
                </h4>
                <img
                  src='https://pro-manage.s3.ap-south-1.amazonaws.com/editMedia.svg'
                  className='cursor-pointer w-8'
                  alt='Profilepost'
                  onClick={() => everyPrevious(2)}
                />
              </div>
              {days.map((day, index) =>
                values?.regularHours?.periods?.map((item, i) => {
                  if (item?.closeDay === day) {
                    return (
                      <div key={i} className='text-justify flex'>
                        <p
                          className={`${
                            item?.openDay === 'SUNDAY'
                              ? 'text-listing'
                              : 'text-businessProfile'
                          } text-base w-11 pb-2`}
                        >
                          <span
                            className={`${
                              item?.openDay === null
                                ? ' text-white ps-4 ms-2'
                                : ''
                            }`}
                          >
                            {item?.openDay?.substring(0, 3)}{' '}
                          </span>
                        </p>
                        <p
                          className={`${
                            item?.openDay === 'SUNDAY'
                              ? 'text-listing'
                              : 'text-businessProfile'
                          } text-base pb-2`}
                        >
                          {moment({
                            hour: item?.openTime?.hours,
                            minute: item?.openTime?.minutes
                          }).format('hh:mm A')}{' '}
                          -{' '}
                          {moment({
                            hour: item?.closeTime?.hours,
                            minute: item?.closeTime?.minutes
                          }).format('hh:mm A')}
                        </p>
                      </div>
                    )
                  }
                  return null
                })
              )}
            </div>

            <hr className='text-businessProfile' />

            <div className='ms-3  me-3 ps-3 pe-3 pt-2 pb-2 mt-3 mb-0 flex gap-1 relative bg-white rounded-xl flex-col'>
              <div className='flex justify-between items-center col-12'>
                <h4 className='text-lightBlack font-medium text-xl '>
                  Description{' '}
                </h4>
                <img
                  src='https://pro-manage.s3.ap-south-1.amazonaws.com/editMedia.svg'
                  className='cursor-pointer w-8'
                  alt='Profilepost'
                  onClick={() => everyPrevious(1)}
                />
              </div>
              <p className='text-businessProfile text-base'>
                {values?.profile?.description}
              </p>
            </div>

            <div className='bg-formBottom flex justify-around pb-4 p-2  rounded-b-2xl'>
              {/* <button type="button" onClick={onPrevious} className='bg-formBottom border-1 border-listing text-listing text-xl mt-3 rounded-xl w-44 pt-2 pb-2'>
                            Previous
                        </button> */}
              <button
                type='button'
                onClick={handleConfirm}
                className='bg-listing text-light text-xl mt-3 rounded-xl w-44 pt-2 pb-2'
                disabled={isUploading}
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SixthForm
