import React, { useContext, useEffect, useRef, useState } from 'react'
import httpservice from '../Service/httpservice'
import { Button, Modal } from 'flowbite-react'
import { API_URL } from '../env/env'
import { AccountDetails } from '../Store/AccountInfo'
import { LocationDetails } from '../Store/LocationInfo'
import moment from 'moment'
import Loader from '../loader/loader'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import CreateModal from '../Components/PostModal/CreateModal'
import EditModal from '../Components/PostModal/EditModal'
import DeleteModal from '../Components/PostModal/DeleteModal'
import PostDetails from '../Components/PostModal/PostDetails'
import { useQuery } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'
import { AuthContext } from '../context/AuthContext'
import { ThreeDots } from 'react-loader-spinner'

const BusinessPost = () => {
  const Locationid = LocationDetails(state => state.LocationDetails)

  const {
    tokenInfo,
    userInfo,
    groupId,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleQuestionCountNotification,
    handleReviewNotification,
    handleLocationNotification
  } = useContext(AuthContext)

  useEffect(() => {
    tokenInfo()
  }, [])

  useEffect(() => {
    if (userInfo?.info?.roleDetails?.roleId === 1) {
      handleLocationCountNotification(true)
      handleReviewCountNotification(true)
      handleQuestionCountNotification(true)
      // handleReviewNotification(true)
      // handleLocationNotification(true)
    }
  }, [groupId, userInfo])

  // const [postData, setPostData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [popUpId, setPopUpId] = useState(false)
  const [isDeleteModal, setDeleteModal] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState({ summary: '' })
  const [postImage, setPostImage] = useState('No media available')
  const [summary, setSummary] = useState('')
  const [actionType, setActionType] = useState('LEARN_MORE')
  const [websiteurl, setWebsiteurl] = useState('')
  const [popUpComment, setpopUpComment] = useState('')
  const [postComment, setPostComment] = useState('')
  const [editsummary, setEditsummary] = useState(false)
  const [selectedFileName, setSelectedFileName] = useState('')
  const [selectedFileSize, setSelectedFileSize] = useState('')
  const [deletePostName, setDeletePostName] = useState('')
  const [load, setload] = useState(true)

  const [postStatus, setPostStatus] = useState()

  const [isUploading, setIsUploading] = useState(false)

  const queryClient = useQueryClient()

  const invalidateAndRefetch = () => {
    queryClient.invalidateQueries(['listPhoto'])
  }
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const elementRef = useRef(null)
  const [pageToken, setPageToken] = useState('')
  const [totalPage, setTotalPage] = useState('')
  // const { data: postData, isPending } = useQuery({
  //   queryFn: () => listPost(),
  //   queryKey: ['listPhoto', { Locationid }],
  //   staleTime: 1000 * 3600,
  //   cacheTime: 3600
  // })

  // useEffect(() => {
  //   listPost()
  // }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const firstEntry = entries[0]
        if (firstEntry.isIntersecting && hasMore === true) {
          if (pageToken?.length) {
            listPost()
          }
        }
      },
      {
        rootMargin: '120px'
      }
    )

    if (observer && elementRef.current) {
      observer.observe(elementRef.current)
    }

    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [elementRef, hasMore, pageToken])

  const [post, setpost] = useState([])

  const listPost = async () => {
    try {
      let body = {
        userId: userInfo.info?.userId,
        groupId: groupId?.groupId,
        locationId: Locationid
      }

      const url = `${API_URL}/post/all?pageToken=${pageToken}`
      const response = await httpservice.post(url, body)
      if (response?.status === 200) {
        setPageToken(
          response?.data?.data?.nextPageToken
            ? response?.data?.data?.nextPageToken
            : ''
        )
        setHasMore(true)

        // console.log('List response ==> ', response)
        // setPostData(response?.data?.data?.localPosts);
        setload(false)
      } else {
        setPageToken('')
        setHasMore(false)
        // console.log('List response ==> ', response)
        // setPostData(response?.data?.data?.localPosts);
        setload(false)
        setPostStatus(true)
      }
      setpost(prevProducts => {
        const newData = response?.data?.data?.localPosts || []
        return Array.isArray(prevProducts)
          ? [...prevProducts, ...(newData || [])]
          : newData
      })
      setPostStatus(true)
    } catch (error) {
      setPostStatus(true)
      setload(false)
      // console.log('error ==> ', error)
    }
  }

  useEffect(() => {
    listPost()
  }, [])

  const deletePost = async name => {
    try {
      setIsUploading(true)
      const postsplit = name.split('/')
      const postid = postsplit[postsplit.length - 1]
      // console.log('postid ==> ', postid)
      const body = {
        userId: userInfo.info?.userId,
        groupId: groupId?.groupId,
        locationId: Locationid
      }
      const url = `${API_URL}/post/delete/${postid}`

      const response = await httpservice.post(url, body)
      if (response?.status === 200) {
        invalidateAndRefetch()
        setDeleteModal(false)
        setpost([])
        setIsUploading(false)
        setPageToken('')
        listPost()
        toast.success('Photo deleted successfully')
      }
      if (response.data.status === false) {
        setDeleteModal(false)
        setIsUploading(false)
        toast.error(response.data.message)
      }
    } catch (error) {
      setIsUploading(false)
      setDeleteModal(false)
      toast.error(error?.response?.data?.message)
      // console.log('error ==> ', error)
    }
  }

  const UpdatePost = async e => {
    e.preventDefault()
    setIsUploading(true)
    // console.log('Update Post !!!!!!!! ', isEditModalOpen)
    try {
      const postsplit = isEditModalOpen?.name.split('/')
      const postid = postsplit[postsplit.length - 1]
      // console.log('postImage entry!!!!!! ', selectedFile)

      if (selectedFile) {
        // console.log('postImage file !!!!!! ', selectedFile)
        const response = await EditFileUpload(postid)
        // console.log('response file !!!!!! ', response)
      }
      if (!selectedFile && editsummary) {
        // console.log('postImage summary !!!!!! ', postid)
        const query = {
          userId: userInfo.info?.userId,
          groupId: groupId?.groupId,
          locationId: Locationid,
          updateMask: 'summary,callToAction'
        }
        const queryParams = new URLSearchParams(query).toString()
        const body = {
          languageCode: 'en',
          summary: isEditModalOpen?.summary,
          topicType: isEditModalOpen?.topicType
        }
        const url = `${API_URL}/post/${postid}?${queryParams}`
        const response = await httpservice.patch(url, body)
        // console.log('response !!!!!! ', response)

        // console.log('response Status !!!!!! ', response?.status)
        if (response?.status === 200) invalidateAndRefetch()
        setPopUpId(false)
        setSelectedFile(null)
        setpost([])
        setPageToken('')
        listPost()
        setIsUploading(false)
        toast.success('Post Updated successfully')
        if (response.data.status === false) {
          setPopUpId(false)
          setIsUploading(false)
          toast.error(response.data.message)
        }
      }
    } catch (error) {
      setPopUpId(false)
      setIsUploading(false)
      toast.error(error?.response?.data?.message)
      // console.log('error ==> ', error)
    }
  }

  const EditFileUpload = async postid => {
    setIsUploading(true)
    try {
      const formData = new FormData()
      const data = {
        userId: userInfo.info?.userId,
        groupId: groupId?.groupId,
        locationId: Locationid,
        summary: isEditModalOpen?.summary,
        actionType: isEditModalOpen?.callToAction?.actionType || 'NONE',
        url: isEditModalOpen?.callToAction?.url || '',
        the_file: selectedFile || '',
        updateMask: 'summary,media'
      }
      // console.log('Edit formData data : ', data)

      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value)
      }
      // console.log('formData Edit : ', formData)

      const url = `${API_URL}/post/update/${postid}`
      const response = await httpservice.patch(url, formData)
      if (response.status === 200) {
        toast.success('Post edited successfully will reflect in 10 min')
        setPopUpId(false)
        setSelectedFile(null)
        invalidateAndRefetch()
        setpost([])
        setPageToken('')
        setIsUploading(false)
        listPost()
      } else {
        setPopUpId(false)
        setIsUploading(false)
        toast.error(response.data.message)
      }
      // console.log('Create Post Response:', response)
    } catch (error) {
      setPopUpId(false)
      setIsUploading(false)
      toast.error(error?.response?.data?.message)
      // console.log('Error creating post:', error)
    }
  }

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   // console.log('Selected File:', file);

  //   setSelectedFile(file);
  // };

  const handleFileUpload = e => {
    setIsUploading(true)
    e.preventDefault()

    // console.log('Create Post !!!!!!!! ')

    const formData = new FormData()
    const data = {
      userId: userInfo.info?.userId,
      groupId: groupId?.groupId,
      locationId: Locationid,
      summary: summary,
      actionType: actionType || 'NONE',
      url: websiteurl,
      the_file: selectedFile || ''
    }
    // console.log('formData data : ', data)

    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value)
    }
    // console.log('formData : ', formData)
    CreatePost(formData)
  }

  const CreatePost = async formData => {
    try {
      const url = `${API_URL}/post`
      const response = await httpservice.post(url, formData)
      // console.log('Create Post Response:', response)
      if (response?.status === 200) invalidateAndRefetch()

      if (response?.data?.status === false) {
        toast.error(response.data.message)
      }
      setpost([])
      setPageToken('')
      listPost()
      setSelectedFile(null)
      setIsModalOpen(false)
      setIsUploading(false)
      setSummary('')
      setActionType('LEARN_MORE')
      setWebsiteurl('')
      toast.success('Post created successfully')
    } catch (error) {
      setIsModalOpen(false)
      setSummary('')
      setActionType('LEARN_MORE')
      setWebsiteurl('')
      setIsUploading(false)
      setSelectedFile(null)
      toast.error(error?.response?.data?.message)
      // console.log('Error creating post:', error)
    }
  }

  // console.log(postComment, 'postComment')

  const handleTextAreaChange = e => {
    const { name, value } = e.target
    if (value.length <= 1500) {
      setIsEditModalOpen(prevValues => ({
        ...prevValues,
        [name]: value
      }))
      setEditsummary(true)
    }
  }

  // useEffect(() => {
  //   listPost()
  // }, [])

  const handlePostDetails = post => {
    setpopUpComment(true)
    setPostComment(post)
  }

  const handleDeletePopUp = postName => {
    setDeleteModal(true)
    setDeletePostName(postName)
  }

  // // console.log(postStatus, 'postStatus')

  // useEffect(() => {
  //   setload(isPending)
  // }, [isPending])

  return (
    <>
      {load && <Loader />}
      <div className='px-10 pb-5'>
        <div className=' justify-between pt-2 items-center flex'>
          <h1 className='text-3xl font-semibold'>Posts</h1>
          {userInfo?.info?.accessTypeDetails?.accessTypeId === 1 ? (
            ''
          ) : (
            <button
              className='px-4 py-2 flex gap-2 text-base font-medium text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none'
              onClick={() => setIsModalOpen(true)}
            >
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/AddIcon+(2).svg'
                className='img-fluid'
                alt='Profilepost'
              />
              Post Upload
            </button>
          )}
        </div>

        {post?.length > 0 ? (
          <div>
            <div className=''>
              <div className='grid grid-cols-3 gap-4 mt-3'>
                {post?.map((post, index) => (
                  <>
                    <div
                      key={index}
                      className='border-1 bg-white border-overView  rounded-2xl p-0.5'
                    >
                      {post?.media ? (
                        post.media.map((item, ind) => (
                          <div
                            key={ind}
                            className='m-auto flex justify-center bg-gray-100 rounded-lg'
                          >
                            {item.googleUrl && (
                              <img
                                src={item.googleUrl}
                                alt='Profilepost'
                                className='h-48 object-cover max-h-52 max-w-[100%] '
                              />
                            )}
                          </div>
                        ))
                      ) : (
                        <img
                          className='flex justify-center items-center p-2 h-48 w-100'
                          src='https://pro-manage.s3.ap-south-1.amazonaws.com/dummyImg.jpg'
                          alt='Profilepost'
                        />
                      )}
                      <div className='flex justify-between p-3 pt-3'>
                        <div className=''>
                          <p className='text-xl font-medium'>
                            {' '}
                            {moment
                              .utc(post?.updateTime)
                              .tz('Asia/Calcutta')
                              .format('DD MMM YYYY')}
                          </p>
                          <p className='text-base font-light'>Posted Date</p>
                          <p className='col-10 text-businessProfile text-md'>
                            {' '}
                            {post?.summary?.slice(0, 30)}....
                          </p>
                          <p
                            className='text-base font-light text-listing cursor-pointer'
                            onClick={() => handlePostDetails(post)}
                          >
                            View More
                          </p>
                        </div>

                        {userInfo?.info?.accessTypeDetails?.accessTypeId ===
                        1 ? (
                          ''
                        ) : (
                          <div className='flex gap-3'>
                            <img
                              src='https://pro-manage.s3.ap-south-1.amazonaws.com/editMedia.svg'
                              className='cursor-pointer w-10'
                              alt='Profilepost'
                              onClick={() => {
                                setPopUpId(true)
                                setIsEditModalOpen(post)
                              }}
                            />
                            <img
                              src='https://pro-manage.s3.ap-south-1.amazonaws.com/PostMedia.svg'
                              className='cursor-pointer w-10'
                              alt='Profilepost'
                              onClick={() => handleDeletePopUp(post?.name)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <h1 className='text-center   fw-semibold flex justify-center'>
            <img
              src='https://pro-manage.s3.ap-south-1.amazonaws.com/NoImgMeans.svg'
              alt='noData'
              className='img-fluid'
            />
          </h1>
        )}
      </div>

      <div>
        <CreateModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          summary={summary}
          setSummary={setSummary}
          setActionType={setActionType}
          websiteurl={websiteurl}
          setWebsiteurl={setWebsiteurl}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          selectedFileName={selectedFileName}
          actionType={actionType}
          handleFileUpload={handleFileUpload}
          setSelectedFileName={setSelectedFileName}
          isUploading={isUploading}
        />

        {/* Edit Post */}
        <EditModal
          popUpId={popUpId}
          setPopUpId={setPopUpId}
          setSelectedFile={setSelectedFile}
          isEditModalOpen={isEditModalOpen}
          selectedFile={selectedFile}
          setSelectedFileName={setSelectedFileName}
          handleTextAreaChange={handleTextAreaChange}
          selectedFileName={selectedFileName}
          UpdatePost={UpdatePost}
          isUploading={isUploading}
        />

        {/* Delete Modal */}

        <DeleteModal
          isDeleteModal={isDeleteModal}
          setDeleteModal={setDeleteModal}
          deletePost={deletePost}
          deletePostName={deletePostName}
          isUploading={isUploading}
        />

        {/* Post Details */}
        <PostDetails
          popUpComment={popUpComment}
          setpopUpComment={setpopUpComment}
          postComment={postComment}
        />
      </div>

      <div className=''>
        <h2
          className='mb-4 fw-semibold'
          ref={elementRef}
          style={{ textAlign: 'center' }}
        >
          {pageToken?.length > 0 ? (
            <div className='flex justify-center'>
              <ThreeDots
                visible={true}
                height='80'
                width='80'
                color='#817AF3'
                radius='9'
                ariaLabel='three-dots-loading'
                wrapperStyle={{}}
                wrapperClass=''
              />
            </div>
          ) : (
            ''
          )}
          {/* Loading */}
        </h2>
      </div>
    </>
  )
}

export default BusinessPost
