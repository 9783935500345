import React from 'react'
import { Button, Modal } from 'flowbite-react'
const AddQuestionModal = ({
  openAddQuestionModal,
  setOpenAddQuestionModal,
  setAddAnswer,
  addAnswer,
  handleAddQuestionPost,
  setSelectedLocation,
  listOfLocations
}) => {
  // console.log(listOfLocations, 'listOfLocations')
  return (
    <div>
      <Modal
        dismissible
        show={openAddQuestionModal}
        onClose={() => {
          setOpenAddQuestionModal(false)
          setAddAnswer('')
          setSelectedLocation('')
        }}
      >
        <Modal.Header className='p-3 bg-reviewPopUp rounded-t-2xl    '>
          Add Question
        </Modal.Header>
        <Modal.Body className=' bg-reviewPopUp'>
          <div className=''>
            <form className='mt-3'>
              <div className='mb-3'>
                <select
                  id='countries'
                  className='bg-white  border border-gray-300 text-black text-sm  rounded-lg block w-full p-2.5  dark:placeholder-gray-400 dark:text-white hover:bg-pink-200 cursor-pointer '
                  onChange={e => {
                    setSelectedLocation(e?.target?.value)
                  }}
                  required
                >
                  <option selected disable value=''>
                    Loacation
                  </option>
                  {listOfLocations.map(item => (
                    <option value={`${item?.locationId}`}>
                      {item?.title + item.address}
                    </option>
                  ))}
                </select>
              </div>
              <div className='relative'>
                <div className='form-floating relative'>
                  <textarea
                    style={{ height: '250px' }}
                    className='form-control comment_flot rounded-2xl border-0'
                    placeholder='Leave a comment here'
                    id='floatingTextarea'
                    value={addAnswer}
                    onChange={e => {
                      const inputValue = e.target.value
                      const sanitizedInput = inputValue.replace(
                        /[<>{}~`"]/g,
                        ''
                      )
                      setAddAnswer(sanitizedInput?.slice(0, 4000))
                    }}
                    required
                  ></textarea>
                  <label
                    for='floatingTextarea'
                    className='text-businessProfile'
                  >
                    Enter Your Question here ...
                  </label>
                </div>
                <div className='absolute bottom-1 right-2 text-xs text-gray-500'>
                  {addAnswer?.length}
                  /4000
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className='pt-3 justify-end '>
          <div className='flex justify-end gap-4 bg-white '>
            <button
              type='submit'
              className='px-4 py-2 text-md font-medium text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none  '
              onClick={() => handleAddQuestionPost()}
            >
              Send
            </button>
            <button
              color='gray'
              onClick={() => {
                setOpenAddQuestionModal(false)
                setAddAnswer('')
                setSelectedLocation('')
              }}
              className='px-4 py-2 text-md font-medium text-center border-1 text-gray  rounded-lg focus:outline-none'
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AddQuestionModal
