import React, { useEffect, useState } from 'react'
import { API_URL } from '../../env/env'
import { format } from 'date-fns'
// import { Chart } from 'react-google-charts';
import Chart from 'react-apexcharts'
import httpservice from '../../Service/httpservice'
import { Popover } from 'antd'
import { useQuery } from '@tanstack/react-query'

const TotalViewsAndActivity = ({ startDate, endDate, userId, groupId }) => {
  const [viewsAndActivityData, setViewsAndActivityData] = useState([])
  const [month, setMonth] = useState(false)
  const [day, setDay] = useState(true)
  const [date1, setDate1] = useState()
  const [totalViewsAndActivityData, setTotalViewsAndActivityData] = useState([])
  const [newDate, setNewDate] = useState([])
  const content = (
    <div className=''>
      <p className='text-businessProfile text-xs p-2'>
        Drag the chart to zoom discovery in, allowing you to
        <br /> view the detailed records.
      </p>
    </div>
  )

  const { data, isPending } = useQuery({
    queryFn: () => groupId !== undefined && viewsAndActivity(),
    queryKey: ['viewsAndActivity', startDate, endDate, groupId, month],
    staleTime: 1000 * 3600,
    cacheTime: 600000
    // onSuccess: ViewsAndActivityData => {
    //   setViewsAndActivityData(ViewsAndActivityData)
    // }
  })

  useEffect(() => {
    setViewsAndActivityData(data?.data?.dateWiseInsights)
    setTotalViewsAndActivityData(data?.data?.totalInsights)
  }, [data?.data])

  // console.log(data, 'ViewsAndActivityData', viewsAndActivityData)

  //   useEffect(() => {
  //     viewsAndActivity()
  //   }, [startDate, endDate, groupId, month])

  useEffect(() => {
    if (month) {
      const generateMonthList = (startDate, endDate) => {
        const start = new Date(startDate)
        const end = new Date(endDate)
        const monthList = []

        while (start <= end) {
          monthList.push(
            new Date(start).toLocaleString('default', {
              month: 'short',
              year: 'numeric'
            })
          )
          start.setMonth(start.getMonth() + 1)
        }

        return monthList
      }

      const months = generateMonthList(startDate, endDate)
      setDate1(months)
    }
    // else{
    //   const generateMonthList = (startDate, endDate) => {
    //     const start = new Date(startDate);
    //     const end = new Date(endDate);
    //     const monthList = [];

    //     while (start <= end) {
    //       monthList.push(new Date(start).toLocaleString('default', { date: 'short' }),);
    //       start.setDate(start.getDate() + 1);
    //     }

    //     return monthList;
    //   };

    //   const months = generateMonthList(startDate, endDate);
    //   setDate1(months)
    // }
  }, [startDate, endDate, month])

  const SuffixAdd = data => {
    if (data > 1000 && data < 100000) {
      return (data / 1000).toFixed(2) + 'K'
    } else if (data > 99999) {
      return (data / 100000).toFixed(3) + 'L'
    } else {
      return data
    }
  }

  const viewsAndActivity = async () => {
    try {
      let body = {
        userId: userId,
        groupId: groupId,
        startDate: startDate,
        endDate: endDate
      }
      let url
      if (month) {
        url = `${API_URL}/other/getViewsAndActivities/monthly`
      } else {
        url = `${API_URL}/other/getViewsAndActivities`
      }
      const response = await httpservice.post(url, body)
      if (response?.status === 200) {
        // console.log(response?.data?.data?.dateWiseInsights, 'discovery')
        if (month) {
          //   setViewsAndActivityData(
          response?.data?.data?.dateWiseInsights?.map(item => {
            const date = new Date(item?.insightDate)
            return {
              data: {
                ...item,
                insightDate: date.toLocaleString('default', {
                  month: 'short',
                  year: 'numeric'
                })
              }
            }
          })
          //   )

          const formattedDates = response?.data?.data?.dateWiseInsights?.map(
            item => {
              const date = new Date(item.insightDate)
              return date.toLocaleString('default', {
                month: 'short',
                year: 'numeric'
              })
            }
          )

          setNewDate(formattedDates)
        } else {
          //   setViewsAndActivityData(response?.data?.data?.dateWiseInsights)
          return {
            data: {
              dateWiseInsights: response?.data?.data?.dateWiseInsights,
              totalInsights: response?.data?.data
            }
          }
        }
        // setTotalViewsAndActivityData(response?.data?.data)
      }
    } catch (error) {}
  }

  const formattedDateNew = []
  if (newDate?.length !== date1?.length) {
    date1?.map((item, index) => {
      const existingIndex = newDate?.includes(item)
      // console.log(existingIndex, 'existingIndex', typeof existingIndex)
      if (existingIndex === false) {
        // console.log(date1[index], 'date1')

        const formattedDate = {
          insightDate: date1[index],
          viewCount: 0,
          activityCount: 0
        }
        // console.log(formattedDate, 'formattedDate')
        formattedDateNew.push(formattedDate)
      }
    })
  }

  let finalOut = [...(viewsAndActivityData || []), ...formattedDateNew]
  if (month) {
    finalOut = finalOut.sort(
      (a, b) => new Date(a.reviewDate) - new Date(b.reviewDate)
    )
  }

  const currentDate2 = new Date(startDate)
  const tempNewChartData2 = []

  if (!month) {
    while (currentDate2.toISOString().split('T')[0] <= endDate) {
      const formattedDate = currentDate2.toISOString().split('T')[0]

      const existingIndex = viewsAndActivityData?.findIndex(
        entry => entry.insightDate === formattedDate
      )

      if (existingIndex === -1) {
        const newEntry = {
          insightDate: formattedDate,
          viewCount: 0,
          activityCount: 0
        }
        tempNewChartData2.push(newEntry)
      }
      currentDate2.setDate(currentDate2.getDate() + 1)
    }
    if (tempNewChartData2?.length > 0) {
      setViewsAndActivityData(prevState =>
        [...prevState, ...tempNewChartData2].sort(
          (a, b) => new Date(a.insightDate) - new Date(b.insightDate)
        )
      )
    }
  }

  let ViewsAndActivity = []
  if (viewsAndActivityData?.length > 0) {
    if (month) {
      ViewsAndActivity = [
        ['Insight Date', 'viewCount', 'activityCount'],
        ...finalOut.map(item => [
          format(new Date(item?.insightDate), 'MMM dd'),
          Number(item?.viewCount),
          Number(item?.activityCount)
        ])
      ]
    } else {
      ViewsAndActivity = [
        ['Insight Date', 'viewCount', 'activityCount'],
        ...viewsAndActivityData?.map(item => [
          format(new Date(item?.insightDate), 'MMM dd'),
          Number(item?.viewCount),
          Number(item?.activityCount)
        ])
      ]
    }
  }
  if (ViewsAndActivity?.length > 0) {
    var ViewsAndActivityOptions = {
      series: [
        {
          name: 'viewCount',
          data: month
            ? finalOut?.map(item => item?.viewCount)
            : viewsAndActivityData?.map(item => item?.viewCount)
        },
        {
          name: 'activityCount',
          data: month
            ? finalOut?.map(item => item?.activityCount)
            : viewsAndActivityData?.map(item => item?.activityCount)
        }
      ],
      options: {
        chart: {
          height: 300
        },
        xaxis: {
          type: 'datetime',
          categories: month
            ? finalOut?.map(item => item.insightDate)
            : viewsAndActivityData.map(item => item.insightDate)
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 2
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy'
          }
        },
        colors: ['#6C60FF', '#CE2A96'],
        legend: {
          show: false
        },
        grid: {
          borderColor: 'transparent'
        }
      }
    }
  }

  return (
    <div className=' rounded-2xl p-4 shadow-filter1 border-[0.1px] border-cardInsights'>
      <h1 className='text-xl font-semibold flex items-center gap-2'>
        Discovery{' '}
        <span>
          <Popover placement='rightTop' content={content} className=''>
            <img
              src='https://pro-manage.s3.ap-south-1.amazonaws.com/InformationIcon.svg'
              alt='image'
            />
          </Popover>
        </span>
      </h1>
      <div className='flex justify-between items-center gap-4 pt-3 pb-3'>
        <div className='flex gap-4'>
          <div className='flex items-baseline  gap-2 '>
            <p className='w-2 h-2 bg-sky-500 rounded-full '></p>
            <div>
              <p className='text-black text-md'>Total </p>
              <p className='text-black font-semibold text-lg'>
                {SuffixAdd(
                  totalViewsAndActivityData?.totalActivityCount?.totalCount +
                    totalViewsAndActivityData?.totalViewsCount?.totalCount
                )}
              </p>
            </div>
          </div>
          <div className='flex gap-2 items-baseline'>
            <p className='w-2 h-2 bg-ActivityCount rounded-full'></p>
            <div>
              <p className='text-black  text-md'>TotalActivityCount </p>
              <p className='text-black font-semibold text-lg'>
                {SuffixAdd(
                  totalViewsAndActivityData?.totalActivityCount?.totalCount
                )}
              </p>
            </div>
          </div>
          <div className='flex gap-2 items-baseline'>
            <p className='w-2 h-2 bg-mapViews   rounded-full'></p>
            <div>
              <p className='text-black  text-md'>TotalViewsCount </p>
              <p className='text-black font-semibold text-lg'>
                {' '}
                {SuffixAdd(
                  totalViewsAndActivityData?.totalViewsCount?.totalCount
                )}
              </p>
            </div>
          </div>
        </div>
        {/* <div className='flex'>
                    <button className={`${month === true ? "bg-listing rounded-3xl text-white -me-5" : " rounded-tr-0 rounded-tl-3xl rounded-bl-3xl rounded-br-0 border-2 border-borderColor"} font-medium py-2 px-4  w-24 transition-all duration-600`} onClick={() => { setMonth(true); setDay(false) }} >Month</button>
                    <button className={`${day === true ? "bg-listing rounded-3xl  text-white  -ms-5" : " border-l-0 border-r-2 rounded-tr-3xl rounded-tl-0 rounded-bl-0 rounded-br-3xl roun border-b-2 border-t-2 border-borderColor"} font-medium py-2 px-4 w-24 transition-all duration-600`} onClick={() => { setMonth(false); setDay(true) }} >day</button>
                </div> */}
      </div>
      <div>
        {/* <Chart
                    chartType="LineChart"
                    width="100%"
                    height="400px"
                    data={ViewsAndActivity}
                    options={ViewsAndActivityOptions}
                /> */}
        <Chart
          options={ViewsAndActivityOptions?.options}
          series={ViewsAndActivityOptions?.series}
          type='line'
          width='100%'
          height='300px'
        />
      </div>
    </div>
  )
}

export default TotalViewsAndActivity
