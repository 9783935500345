// import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { AccountDetails } from '../Store/AccountInfo'
import { Link } from 'react-router-dom'
import httpservice from '../Service/httpservice'
import moment from 'moment-timezone'
import { useRef } from 'react'
import { setLocationData } from '../Store/LocationInfo'
import Calender from '../Components/ReviewCalender'
import { API_URL } from '../env/env'
// import ReactStars from 'react-rating-stars-component'
import Loader from '../loader/loader'
import useDebounce from '../hooks/useDebounce'
import { AuthContext } from '../context/AuthContext'
import StarRating from '@rubenvara/react-star-rating'
const BusinessProfile = () => {
  const [page, setPage] = useState(1)
  const [currentId, SetCurrentId] = useState(-1)
  const [businessData, SetBusinessProfile] = useState()
  const elementRef = useRef(null)
  const {
    userInfo,
    groupId,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleQuestionCountNotification,
    handleReviewNotification,
    handleLocationNotification
  } = useContext(AuthContext)
  const [hasMore, setHasMore] = useState(true)
  const [totalPage, setTotalPage] = useState(2)
  const [load, setload] = useState(true)
  const [searchParam, setSearchParams] = useState('')
  const debounceSearchValue = useDebounce(searchParam, 500)

  let groupValue = localStorage.getItem('GroupChange')
  const { tokenInfo } = useContext(AuthContext)

  useEffect(() => {
    tokenInfo()
  }, [])

  useEffect(() => {
    if (userInfo?.info?.roleDetails?.roleId === 1) {
      handleLocationCountNotification(true)
      handleReviewCountNotification(true)
      handleQuestionCountNotification(true)
      // handleReviewNotification(true)
      // handleLocationNotification(true)
    }
  }, [groupId, userInfo])

  useEffect(() => {
    if (groupValue === 'true') {
      // console.log('group', groupValue)
      SetBusinessProfile([])
      setPage(1)
      localStorage.setItem('GroupChange', 'false')
    }
  }, [groupValue, page])

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const firstEntry = entries[0]
        if (totalPage >= page) {
          // console.log(totalPage, 'totalPage', page)
          // console.log(hasMore, 'total')
          if (firstEntry.isIntersecting && hasMore) {
            // console.log(hasMore, 'total')
            getBusinessProfile()
          }
        }
      },
      {
        rootMargin: '550px'
      }
    )

    if (observer && elementRef.current) {
      observer.observe(elementRef.current)
    }

    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [
    page,
    hasMore,
    groupId.groupId,
    groupValue === 'true',
    debounceSearchValue
  ])

  // const BusinessInfo = useQuery\
  const HandleLocationId = locationId => {
    setLocationData(locationId)
  }

  const getBusinessProfile = async () => {
    // console.log(page, 'page')
    try {
      const body = {
        userId: userInfo.info?.userId,
        groupId: groupId?.groupId,
        page: page,
        searchText: searchParam,
        pageSize: 10
      }
      const url = `${API_URL}/location`
      const res = await httpservice.post(url, body)
      // console.log(res?.status, 'res')
      if (res.status === 200) {
        SetBusinessProfile(prevProducts => {
          const newData = res?.data?.data || []
          return Array.isArray(prevProducts)
            ? [...prevProducts, ...newData]
            : newData
        })
        setTotalPage(res?.data?.totalPages)
        setload(false)
        if (totalPage >= page) {
          // setload(true);
          setPage(prev => prev + 1)
        } else {
          setHasMore(false)
        }
      } else {
        setload(false)
      }
    } catch (error) {}
  }

  const handleSearch = searchText => {
    SetBusinessProfile([])
    setSearchParams(searchText.toLowerCase())
    setPage(1)
  }

  // console.log(businessData, 'businessData')

  const bgColors = [
    'bg-reviewTable1',
    'bg-custom-background5',
    'bg-custom-background5',
    'bg-reviewTable1'
  ]
  const bgColors1 = [
    'bg-violet-100',
    'bg-blue-100',
    'bg-blue-100',
    'bg-violet-100'
  ]

  const config = {
    fullColor: '#FEA500',
    size: 17,
    showText: false
  }

  return (
    <>
      <Calender Title={'Business Profile'} />
      <div className=' grid-cols-1 flex  justify-content-start md:me-4 mt-3 gap-3'></div>
      <div className='container md:px-4 py-2'>
        <div className='flex justify-between items-center'>
          <div className='col-5 position-relative pb-2 '>
            <div className='absolute inset-y-0 start-0 flex items-center ps-3 pb-2 pointer-events-none'>
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/searchIcon.svg'
                alt='searchIcon'
              />
            </div>
            <input
              className='rounded-lg   w-100  ps-10 p-2.5 bg-bgSearch serch_btn text-sm  '
              placeholder='search business & address'
              type='search'
              value={searchParam}
              onChange={e => handleSearch(e.target.value)}
            />
          </div>
          {userInfo?.info?.accessTypeDetails?.accessTypeId === 1 ? (
            ''
          ) : (
            <div className=' bg-listing p-2 rounded-lg  col-2  hover:opacity-95'>
              <Link
                to={`/businessprofile/addBusiness`}
                className='flex gap-2 items-center'
              >
                <img
                  src='https://pro-manage.s3.ap-south-1.amazonaws.com/AddIcon.svg'
                  alt='addIcon'
                />
                <p className='text-light cursor-pointer'>Add new Business</p>
              </Link>
            </div>
          )}
        </div>
        <div className='grid grid-cols-2 gap-4 '>
          {businessData?.map((item, index) => (
            <div
              key={index}
              className={` mt-2 p-3 ps-3  hover:shadow-md delay-75  hover:${
                bgColors1[index % bgColors1.length]
              } rounded-xl ${bgColors[index % bgColors.length]} `}
            >
              <Link to={`overview`}>
                <div
                  className='card-body'
                  onClick={() => HandleLocationId(item?.locationId)}
                >
                  <h5 className='card-title'>
                    <div className='row'>
                      <h2 className='text-xl font-medium'>
                        {' '}
                        {item?.details.title}
                      </h2>
                      <div className='flex gap-2 mt-1 items-center'>
                        <img
                          src='https://pro-manage.s3.ap-south-1.amazonaws.com/GoogleLogo+1.svg'
                          width={30}
                          height={30}
                          alt='logo'
                          className='w-5'
                        />
                        <h2 className='my-2 '>
                          <span className='text-amber-500 text-lg fw-semibold flex justify-center items-center gap-0'>
                            {' '}
                            <span className='mt-1 me-1'>
                              {' '}
                              {item?.rating ? item?.rating : 0}
                            </span>{' '}
                            <span>
                              <StarRating
                                rating={item?.rating ? item?.rating : 0}
                                style={{ marginBottom: '0px' }}
                                config={config}
                              />
                            </span>{' '}
                          </span>
                        </h2>
                      </div>
                      <div className='text-businessProfile text-base'>
                        {item.details.storefrontAddress?.addressLines.map(
                          (item, index) => (
                            <p key={index}>{item}</p>
                          )
                        )}
                        <p>
                          {item?.details.storefrontAddress?.locality},{' '}
                          <span>
                            {
                              item?.details.storefrontAddress
                                ?.administrativeArea
                            }
                          </span>
                          ,{' '}
                          <span>
                            {item?.details.storefrontAddress?.postalCode}
                          </span>
                        </p>
                      </div>

                      <div className='col-1'>
                        {/* <img src={item.rev} width={50} height={50} alt="" /> */}
                      </div>
                    </div>
                  </h5>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <div className=''>
        <h2
          className='mb-4 fw-semibold'
          ref={elementRef}
          style={{ textAlign: 'center' }}
        >
          {businessData?.length > 9 && totalPage > page ? 'Load More' : ''}
        </h2>
      </div>

      {businessData?.length === 0 && (
        <div className='flex justify-center items-center'>
          <img
            src='https://pro-manage.s3.ap-south-1.amazonaws.com/ReviewNotFound.svg'
            alt=''
          />
        </div>
      )}
      {load && <Loader />}
    </>
  )
}

export default BusinessProfile
