import React, { useContext, useEffect, useRef, useState } from 'react'
import { AccountDetails } from '../Store/AccountInfo'
import { CalenderInsightInfo } from '../Store/CalenderInfo'
import moment from 'moment'
import { API_URL } from '../env/env'
import httpservice from '../Service/httpservice'
import SearchChart from '../Components/CommenCharts/SearchChart'
import { format } from 'date-fns'
import ViewByDevice from '../Components/Discovery/ViewByDevice'
import Loader from './../loader/loader'
import TotalMapSearch from '../Components/Discovery/TotalMapSearch'
import TotalActivity from '../Components/Discovery/TotalActivity'
import TotalViewsAndActivity from '../Components/Discovery/TotalViewsAndActivity'
import { AuthContext } from '../context/AuthContext'

const InsightDiscovery = () => {
  const [load, setload] = useState(true)
  const {
    userInfo,
    groupId,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleQuestionCountNotification,
    handleReviewNotification,
    handleLocationNotification
  } = useContext(AuthContext)
  const date = CalenderInsightInfo(state => state.CalenderInsightInfo)
  const { tokenInfo } = useContext(AuthContext)

  useEffect(() => {
    tokenInfo()
  }, [])

  useEffect(() => {
    if (userInfo?.info?.roleDetails?.roleId === 1) {
      if (groupId) {
        handleLocationCountNotification(true)
        handleReviewCountNotification(true)
        handleQuestionCountNotification(true)
        // handleReviewNotification(true)
        // handleLocationNotification(true)
      }
    }
  }, [groupId, userInfo])

  const [startDate, setStartDate] = useState(
    moment.utc(date?.startDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
  )
  const [endDate, setEndDate] = useState(
    moment.utc(date?.endDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
  )

  useEffect(() => {
    setStartDate(
      moment.utc(date?.startDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
    )
    setEndDate(
      moment.utc(date?.endDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
    )
    // setload(false)
  }, [date])

  return (
    <div className='bg-InsightBackGround'>
      {load && <Loader />}
      <div className='container p-14 pt-0'>
        <p className='text-md mb-4 font-normal text-gray-900 dark:text-white'>
          Data Synced From{' '}
          <span className='font-medium'>
            {new Date(startDate).toLocaleDateString('en-us', {
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            })}
          </span>{' '}
          till{' '}
          <span className='font-medium'>
            {new Date(endDate).toLocaleDateString('en-us', {
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            })}
          </span>
        </p>
        <div className=' flex gap-4 mb-4'>
          <div className='shadow-filter1 rounded-3xl p-3 col-5 border-[0.1px] border-cardInsights'>
            <ViewByDevice
              startDate={startDate}
              endDate={endDate}
              userId={userInfo.info?.userId}
              groupId={groupId?.groupId}
            />
          </div>
          <div className='col-7 pe-4'>
            <div className=' col-12 shadow-filter1 rounded-2xl p-3 border-[0.1px] border-cardInsights'>
              <SearchChart
                startDate={startDate}
                endDate={endDate}
                userId={userInfo.info?.userId}
                groupId={groupId?.groupId}
              />
            </div>
          </div>
        </div>

        <div>
          <TotalMapSearch
            startDate={startDate}
            endDate={endDate}
            userId={userInfo.info?.userId}
            groupId={groupId?.groupId}
          />
        </div>

        <TotalActivity
          startDate={startDate}
          endDate={endDate}
          userId={userInfo.info?.userId}
          groupId={groupId?.groupId}
          setload={setload}
        />
      </div>
    </div>
  )
}

export default InsightDiscovery
