import React from 'react'
import { API_URL } from '../../env/env'
import httpservice from '../../Service/httpservice'
import { Button, Modal } from 'flowbite-react'

const DeleteUserModal = ({
  openDeleteUserModal,
  setOpenDeleteUserModal,
  handleDelete,
  user,
  isUploading
}) => {
  return (
    <div>
      <Modal
        show={openDeleteUserModal}
        onClose={() => {
          setOpenDeleteUserModal(false)
        }}
      >
        <Modal.Body className='overflow-x-hidden p-3'>
          <div className='p-3'>
            <div className='flex justify-center items-center'>
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/trash12.svg'
                alt=''
              />
            </div>
            <p className='text-center pt-3 text-TuskColor text-2xl font-semibold'>
              Do you want to delete this User ?
            </p>
            <div className='text-center flex justify-center items-center gap-4 mt-3'>
              <button
                className='border-1 p-2 ps-3 pe-3 rounded-md border-newCancelButton text-md font-medium text-EditUser'
                onClick={() => setOpenDeleteUserModal(false)}
              >
                Cancel
              </button>
              <button
                className='bg-deleteButton p-2 ps-3 pe-3 rounded-md text-white'
                onClick={() => {
                  setOpenDeleteUserModal(false)
                  handleDelete(user)
                }}
                disabled={isUploading}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default DeleteUserModal
