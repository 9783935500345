import axios from 'axios'
import { API_URL } from '../env/env'
import { setUserInfo } from '../Store/UserInfo'

const instance = axios.create()
// const {}

const getAuthToken = () => {
  const tokenDetails = JSON.parse(localStorage.getItem('tmbUserData1'))
  return tokenDetails?.state.UserInfo?.token || ''
}

const authToken = async authTokenValue => {
  try {
    const response = await axios.post(
      `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${authTokenValue}`
    )
    // console.log(response, 'response')

    return response?.status
  } catch (error) {
    // console.log(error, 'error')
    return error.response?.status
  }
}

const refreshToken = async () => {
  try {
    const tokenDetails = JSON.parse(localStorage.getItem('tmbUserData1'))
    // console.log('REFRESHING in try', tokenDetails)
    let reqData = {
      email: tokenDetails?.state?.UserInfo?.info?.email,
      user_id: tokenDetails?.state?.UserInfo?.info?.userId,
      sub: tokenDetails?.state?.UserInfo?.info?.sub
    }
    const newToken = await axios.post(
      `${API_URL}/auth/google/token/refresh`,
      reqData,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    // debugger
    if (newToken) {
      tokenDetails.state.UserInfo['token'] = newToken?.data?.token
      // console.log(
      //   tokenDetails?.state?.UserInfo,
      //   'tokenDetails?.state?.UserInfo',
      //   newToken?.data?.token
      // )
      // localStorage.setItem('tmbUserData1', JSON.stringify(tokenDetails))
      setUserInfo(tokenDetails?.state?.UserInfo)
    }
    return newToken.data
  } catch (error) {
    console.error(error, 'ERROR')
    return error.response
  }
}

const logout = async () => {
  window.location.href = '/login'
  localStorage.clear()
}

let isRefreshing = false
let refreshPromise = null

instance.interceptors.request.use(
  async config => {
    const authTokenValue = getAuthToken()
    // console.log(authTokenValue, 'authTokenValue')
    const response = await authToken(authTokenValue)
    // console.log('status', response)
    // debugger
    // const number = 530
    // // console.log(response, 'response', number <= 600)

    if (response === 400) {
      // debugger
      if (!isRefreshing) {
        isRefreshing = true
        refreshPromise = refreshToken()
          .then(newAccessToken => {
            isRefreshing = false
            refreshPromise = null
            // console.log('refresh', newAccessToken)
            if (newAccessToken && newAccessToken.status === true) {
              config.headers['Authorization'] = `Bearer ${newAccessToken.token}`
            } else {
              // console.log('Else')
              // debugger
              logout()
            }
          })
          .catch(error => {
            console.error('Error refreshing token:', error)
            isRefreshing = false
            refreshPromise = null
          })
      }
      return refreshPromise.then(() => {
        config.headers['Authorization'] = `Bearer ${getAuthToken()}`
        return config
      })
    } else {
      config.headers['Authorization'] = `Bearer ${authTokenValue}`
      return config
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default instance
