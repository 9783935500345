import React, { useContext, useEffect, useState } from 'react'
import { AccountDetails } from '../Store/AccountInfo'
import { API_URL } from '../env/env'
import httpservice from '../Service/httpservice'
import { CalenderInsightInfo } from '../Store/CalenderInfo'
import moment from 'moment'
import ReviewsRechartsLineChart from './CommenCharts/ReviewsRechartsLineChart'
import RatingTrends from './CommenCharts/RatingTrends'
import { Link } from 'react-router-dom'
import StarRating from '@rubenvara/react-star-rating'
import OverAllReview from './InsightOverAllReview'
import Loader from './../loader/loader'
import { useQuery } from '@tanstack/react-query'
import { AuthContext } from '../context/AuthContext'
import { toast } from 'react-toastify'

const InsightReviews = () => {
  // const id = AccountDetails(state => state.AccountDetails)
  // const tokenDetails = JSON.parse(localStorage.getItem('tmbUserData'))
  const { userInfo, groupId } = useContext(AuthContext)
  const date = CalenderInsightInfo(state => state.CalenderInsightInfo)
  // console.log(date, 'date inline 20')
  const [load, setload] = useState(true)
  useEffect(() => {
    setStartDate(
      moment.utc(date?.startDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
    )
    setEndDate(
      moment.utc(date?.endDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
    )
  }, [date])

  const [startDate, setStartDate] = useState(
    moment.utc(date?.startDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
  )
  const [endDate, setEndDate] = useState(
    moment.utc(date?.endDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
  )

  const [reviewCount, setReviewCount] = useState([])

  useEffect(() => {
    // Reviews()
    RatingTrend()
    // LatestReview()
    // TopBranchReview()
  }, [groupId, startDate, endDate])

  const { data, isPending } = useQuery({
    queryFn: () => LatestReview(),
    queryKey: ['LatestReview', startDate, endDate, groupId],
    staleTime: 1000 * 3600,
    cacheTime: 600000
  })

  const { data: BranchPerformance } = useQuery({
    queryFn: () => TopBranchReview(),
    queryKey: ['TopBranchReview', startDate, endDate, groupId],
    staleTime: 1000 * 3600,
    cacheTime: 600000
  })

  const { data: Trends } = useQuery({
    queryFn: () => RatingTrend(),
    queryKey: ['RatingTrend', startDate, endDate, groupId],
    staleTime: 1000 * 3600,
    cacheTime: 600000
  })

  //   const { data:bottomBranch } = useQuery({
  //     queryFn: () => LatestReview(),
  //     queryKey: ['LatestReview', startDate, endDate, id],
  //     staleTime: 1000 * 3600,
  //     cacheTime: 600000
  //   })

  useEffect(() => {
    setLatestReview(data?.data)
  }, [data])

  useEffect(() => {
    setTopBranch(BranchPerformance?.data?.topBusiness)
    setBottomBranch(BranchPerformance?.data?.bottomBusiness)
  }, [BranchPerformance])

  useEffect(() => {
    setRatingTrends(Trends?.data)
  }, [Trends])

  useEffect(() => {
    setload(isPending)
  }, [isPending])

  // console.log(BranchPerformance?.data, 'BranchPerformance', isPending)

  const calculatePreviousDate = (startDate, endDate) => {
    const startDateMoment = moment.utc(startDate).tz('Asia/Calcutta')
    const endDateMoment = moment.utc(endDate).tz('Asia/Calcutta')

    const monthsDiff = endDateMoment.diff(startDateMoment, 'months')
    const weekDiff = endDateMoment.diff(startDateMoment, 'weeks')
    const yearsDiff = endDateMoment.diff(startDateMoment, 'years')

    // console.log(monthsDiff, 'monthsDiff', yearsDiff, weekDiff)

    let previousDate
    let difference

    if (monthsDiff >= 11 && weekDiff <= 3) {
      previousDate = startDateMoment
        .subtract(monthsDiff, 'months')
        .format('YYYY-MM-DD')
      difference = { monthsDiff, type: 'Months' }
    } else if (yearsDiff >= 1) {
      previousDate = startDateMoment
        .subtract(yearsDiff, 'years')
        .format('YYYY-MM-DD')
      difference = { yearsDiff, type: 'Years' }
    } else {
      previousDate = startDateMoment
        .subtract(weekDiff, 'weeks')
        .format('YYYY-MM-DD')
      difference = { weekDiff, type: 'Weeks' }
    }
    return { previousDate, difference }
  }

  const previousDate = calculatePreviousDate(startDate, endDate)
  // console.log(previousDate?.difference?.yearsDiff, 'previousDate', startDate)

  const Reviews = async () => {
    try {
      let body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        startDate: startDate,
        endDate: endDate,
        previousStartDate: previousDate?.previousDate,
        previousEndDate: startDate
      }

      const url = `${API_URL}/review/get_average_star_rating`
      const res = await httpservice.post(url, body)
      if (res.status === 200) {
        setReviewCount(res?.data?.data)
      }
    } catch (error) {}
  }

  const [ratingTrends, setRatingTrends] = useState([])

  // console.log(Trends?.data, 'ratingTrends')

  const RatingTrend = async () => {
    try {
      let body = {
        userId: userInfo.info?.userId,
        groupId: groupId?.groupId,
        startDate: startDate,
        endDate: endDate
      }
      let url = `${API_URL}/review/get_review_rating_trends`

      const response = await httpservice.post(url, body)
      if (response.status === 200) {
        // console.log(response?.data?.data, 'ratingTrends')
        // setRatingTrends(response?.data?.data)
        return { data: response?.data?.data }
      } else {
        setRatingTrends([])
        setload(false)
      }
    } catch (error) {
      setRatingTrends([])
      setload(false)
    }
  }

  const [latestReview, setLatestReview] = useState([])

  const LatestReview = async () => {
    try {
      let body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId
      }
      let url = `${API_URL}/review/get_latest_reviews`
      let response = await httpservice.post(url, body)
      // console.log(response, 'response123')
      if (response.status === 200) {
        // console.log(response?.data?.data, 'response?.data?.data')
        return { data: response?.data?.data }
        // setLatestReview(response?.data?.data)
      } else {
        setLatestReview([])
      }
    } catch (error) {
      setLatestReview([])
    }
  }

  // console.log(latestReview, 'latestReview')

  const [TopBranch, setTopBranch] = useState([])
  const [BottomBranch, setBottomBranch] = useState([])

  const TopBranchReview = async () => {
    try {
      let body = {
        userId: userInfo.info?.userId,
        groupId: groupId?.groupId,
        startDate: startDate,
        endDate: endDate
      }

      const url = `${API_URL}/location/get_top_and_bottom_business`

      const response = await httpservice.post(url, body)
      if (response.status === 200) {
        // console.log(response?.data?.data, 'response')
        return {
          data: {
            topBusiness: response?.data?.data?.topBusiness,
            bottomBusiness: response?.data?.data?.bottomBusiness
          }
        }

        // setTopBranch(response?.data?.data?.topBusiness)
        // setBottomBranch(response?.data?.data?.bottomBusiness)
      } else {
        setTopBranch('')
        setBottomBranch('')
      }
    } catch (error) {
      setTopBranch('')
      setBottomBranch('')
    }
  }

  const config = {
    fullColor: '#FEA500',
    size: 12,
    showText: false
  }

  const convertStarRatingToNumber = starRatingText => {
    const textToNumber = {
      ONE: 1.0,
      TWO: 2.0,
      THREE: 3.0,
      FOUR: 4.0,
      FIVE: 5.0
    }
    const uppercaseText = (starRatingText || '').toUpperCase()
    return textToNumber[uppercaseText] || 0
  }

  return (
    <>
      {load && <Loader />}
      <p className='text-md mb-4 font-normal text-gray-900 dark:text-white'>
        Data Synced From{' '}
        <span className='font-medium'>
          {new Date(startDate).toLocaleDateString('en-us', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
          })}
        </span>{' '}
        till{' '}
        <span className='font-medium'>
          {new Date(endDate).toLocaleDateString('en-us', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
          })}
        </span>
      </p>
      <div className='row '>
        <div className='col-5 flex justify-center ps-0 pe-0'>
          <OverAllReview
            startDate={startDate}
            endDate={endDate}
            previousDate={previousDate}
            userId={userInfo.info?.userId}
            groupId={groupId?.groupId}
          />
        </div>
        <div className='col-7'>
          <RatingTrends
            ratingTrends={ratingTrends}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </div>
      <div>
        <ReviewsRechartsLineChart
          startDate={startDate}
          endDate={endDate}
          id={groupId?.groupId}
          userId={userInfo.info?.userId}
        />
      </div>

      <div className='row mt-5   '>
        <div className='col-6 pe-4'>
          <h1 className='text-xl font-semibold'>Latest Reviews </h1>
          {latestReview?.length > 0 &&
            latestReview?.map(item => (
              <>
                <div className='flex justify-between gap-3 pb-2 pt-3'>
                  <div className='pt-1 flex items-center gap-2'>
                    <img
                      src={item?.details.review?.reviewer?.profilePhotoUrl}
                      alt='ProfileImg'
                      width='50px'
                    />
                    <div>
                      <p className='text-lg text-black font-medium'>
                        {item?.details.review?.reviewer?.displayName}
                      </p>
                      <p className='col-10 text-sm text-businessProfile'>
                        {item?.address} {item?.city}
                      </p>
                    </div>
                  </div>
                  <p className='text-sm col-2'>
                    {' '}
                    {moment
                      .utc(item?.details?.review?.updateTime)
                      .tz('Asia/Calcutta')
                      .format('DD MMM YYYY')}
                  </p>
                </div>
                <div className='flex items-center gap-2'>
                  <img
                    src='https://pro-manage.s3.ap-south-1.amazonaws.com/Google__Logo+1.svg'
                    alt='google logo'
                  />
                  <p className='text-amber-500 font-semibold'>
                    {convertStarRatingToNumber(
                      item?.details?.review?.starRating
                    )}
                  </p>
                  <StarRating
                    rating={
                      item?.details?.review?.starRating
                        ? convertStarRatingToNumber(
                            item?.details?.review?.starRating
                          )
                        : 0
                    }
                    style={{ marginBottom: '0px' }}
                    config={config}
                  />
                  <p
                    className={`mt-1 ms-2 text-base font-medium ${
                      item?.details?.review?.starRating === 'FIVE' ||
                      item?.details?.review?.starRating == 'FOUR'
                        ? 'text-green-500'
                        : item?.details?.review?.starRating === 'THREE' ||
                          item?.details?.review?.starRating === 'TWO'
                        ? 'text-blue-500'
                        : 'text-red-700'
                    }`}
                  >
                    {item?.details?.review?.starRating === 'FIVE' ||
                    item?.details?.review?.starRating === 'FOUR'
                      ? 'Positive'
                      : item?.details?.review?.starRating === 'THREE' ||
                        item?.details?.review?.starRating === 'TWO'
                      ? 'Moderate'
                      : 'Negative'}
                  </p>
                </div>
                <div>
                  <p className='text-sm text-black pt-1'>
                    {item?.details?.review?.comment}
                  </p>
                </div>
                <hr className='mt-2' />
              </>
            ))}

          {latestReview?.length === 0 ? (
            <p className='text-md font-medium flex justify-center mt-3 text-listing pb-3 text-center'>
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/Layer_1.svg'
                alt='noinfo'
              />
            </p>
          ) : (
            userInfo?.userAccess?.map(
              item =>
                item?.accessId === 3 &&
                (item?.isAcccess ? (
                  <p
                    className='font-semibold text-listing text-center pt-3'
                    key={item?.accessId}
                  >
                    <Link to='/reviews'>View All Reviews</Link>
                  </p>
                ) : (
                  <p
                    className='font-semibold text-listing text-center pt-3'
                    onClick={() =>
                      toast.error("You don't have access to view reviews")
                    }
                    key={item.accessId}
                  >
                    View All Reviews
                  </p>
                ))
            )
          )}
        </div>

        <div className='col-6'>
          <div className='bg-oldDate rounded-2xl ps-6 pe-6 pt-3'>
            <p className='text-xl font-semibold mb-3'>
              Top Branches by Reviews
            </p>

            <div className='relative overflow-x-auto shadow-base rounded-lg  mt-2'>
              <table className='w-full text-xs font-normal  text-left rtl:text-right  dark:text-gray-400'>
                <thead className='uppercase bg-custom-background3 dark:bg-gray-700 dark:text-gray-400 '>
                  <tr>
                    <th scope='col' className='px-2 py-2 font-semibold'>
                      Business
                    </th>
                    <th scope='col' className='px-2 py-2 font-semibold'>
                      Rating
                    </th>
                    <th scope='col' className='px-2 py-2 font-semibold'>
                      Reviews
                    </th>
                    <th scope='col' className='px-2 py-2 font-semibold'>
                      Replied
                    </th>
                    <th scope='col' className='px-2 py-2 font-semibold'>
                      Not Replied
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {TopBranch?.length > 0 &&
                    TopBranch?.map(item => (
                      <tr className='bg-transparent border text-xs border-tableLatest dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600'>
                        <td className='px-2 py-2 font-normal  border-r border-r-tableLatest  text-gray-900 flex flex-col  dark:text-white'>
                          <p>{item?.title}</p>
                          <p>{item?.address}</p>
                        </td>
                        <td className='px-2 py-2 border-r border-r-tableLatest'>
                          <p className='flex gap-2'>
                            {item?.average_rating}{' '}
                            <StarRating
                              rating={
                                item?.average_rating ? item?.average_rating : 0
                              }
                              style={{ marginBottom: '0px' }}
                              config={config}
                            />
                          </p>
                        </td>

                        <td className='px-2 py-2 border-r border-r-tableLatest'>
                          {item?.repliedCount + item?.notRepliedCount}
                        </td>
                        <td className='px-2 py-2 border-r border-r-tableLatest'>
                          {item?.repliedCount}
                        </td>
                        <td className='px-2 py-2'>{item?.notRepliedCount}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {TopBranch?.length ? (
              <Link to='/insights/allbranchreport'>
                <p className='text-md font-medium text-listing mt-2 pb-3'>
                  View All
                </p>
              </Link>
            ) : (
              <p className='text-md font-medium flex justify-center mt-3 text-listing pb-3 text-center'>
                <img
                  src='https://pro-manage.s3.ap-south-1.amazonaws.com/Layer_1.svg'
                  alt='noinfo'
                />
              </p>
            )}
          </div>
          <div className='bg-oldDate rounded-2xl ps-6 pe-6 pt-3 mt-4'>
            <p className='text-xl font-semibold mb-3'>
              Bottom Branches by Reviews
            </p>

            <div className='relative overflow-x-auto shadow-base rounded-lg  mt-2'>
              <table className='w-full text-xs font-normal  text-left rtl:text-right  dark:text-gray-400'>
                <thead className='uppercase bg-custom-background3 dark:bg-gray-700 dark:text-gray-400 '>
                  <tr>
                    <th scope='col' className='px-2 py-2 font-semibold'>
                      Business
                    </th>
                    <th scope='col' className='px-2 py-2 font-semibold'>
                      Rating
                    </th>
                    <th scope='col' className='px-2 py-2 font-semibold'>
                      Reviews
                    </th>
                    <th scope='col' className='px-2 py-2 font-semibold'>
                      Replied
                    </th>
                    <th scope='col' className='px-2 py-2 font-semibold'>
                      Not Replied
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {BottomBranch?.length > 0 &&
                    BottomBranch?.map(item => (
                      <tr className='bg-transparent border text-xs border-tableLatest dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600'>
                        <td className='px-2 py-2 font-normal  border-r border-r-tableLatest  text-gray-900 flex flex-col  dark:text-white'>
                          <p>{item?.title}</p>
                          <p>{item?.address}</p>
                        </td>
                        <td className='px-2 py-2 border-r border-r-tableLatest'>
                          <p className='flex gap-2'>
                            {item?.average_rating}{' '}
                            <StarRating
                              rating={
                                item?.average_rating ? item?.average_rating : 0
                              }
                              style={{ marginBottom: '0px' }}
                              config={config}
                            />
                          </p>
                        </td>
                        <td className='px-2 py-2 border-r border-r-tableLatest'>
                          {item?.repliedCount + item?.notRepliedCount}
                        </td>
                        <td className='px-2 py-2 border-r border-r-tableLatest'>
                          {item?.repliedCount}
                        </td>
                        <td className='px-2 py-2'>{item?.notRepliedCount}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {BottomBranch?.length ? (
              <Link to='/insights/allbranchreport'>
                <p className='text-md font-medium text-listing mt-2 pb-3'>
                  View All
                </p>
              </Link>
            ) : (
              <p className='text-md font-medium flex justify-center mt-3 text-listing pb-3 text-center'>
                <img
                  src='https://pro-manage.s3.ap-south-1.amazonaws.com/Layer_1.svg'
                  alt='noinfo'
                />
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default InsightReviews
