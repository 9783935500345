import React, { useContext, useEffect, useState } from 'react'
import { AccountDetails } from '../../Store/AccountInfo'
import { CalenderInsightInfo } from '../../Store/CalenderInfo'
import moment from 'moment'
import { API_URL } from '../../env/env'
import httpservice from '../../Service/httpservice'
import { Link } from 'react-router-dom'
import { Button, Dropdown, Table } from 'antd'
import {
  CaretDownOutlined,
  DownOutlined,
  SmileOutlined
} from '@ant-design/icons'
import { Pagination } from 'antd'

// import { Table } from 'antd';
import { useQuery } from '@tanstack/react-query'
import { AuthContext } from '../../context/AuthContext'

const PerformaneComponent = ({ size, setload }) => {
  const { userInfo, groupId } = useContext(AuthContext)
  const date = CalenderInsightInfo(state => state.CalenderInsightInfo)
  const [startDate, setStartDate] = useState(
    moment.utc(date?.startDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
  )
  const [endDate, setEndDate] = useState(
    moment.utc(date?.endDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
  )

  const { data: performance, isPending } = useQuery({
    queryFn: () => groupId.groupId !== undefined && PerformanceData(),
    queryKey: ['performance', groupId, startDate, endDate],
    staleTime: 1000 * 3600,
    cacheTime: 600000
  })

  useEffect(() => {
    if (size === true) {
      setPerformanceData(
        performance?.data?.allResponse?.length > 3
          ? performance?.data?.allResponse
              ?.sort((a, b) => b.growthPercentage - a.growthPercentage)
              ?.slice(0, 3)
          : performance?.data?.allResponse?.sort(
              (a, b) => b.growthPercentage - a.growthPercentage
            )
      )
    } else {
      //   if (performance?.data?.length > 0) {
      setPerformanceData(
        performance?.data?.allResponse?.sort(
          (a, b) => b.growthPercentage - a.growthPercentage
        )
      )
      setPositiveGrowth(
        (performance?.data?.allResponse || [])
          ?.filter(item => item?.growthPercentage > 0)
          ?.sort((a, b) => b.growthPercentage - a.growthPercentage)
      )
      SetNegativeGrowth(
        performance?.data?.allResponse
          ?.filter(item => item?.growthPercentage < 0)
          ?.sort((a, b) => a.growthPercentage - b.growthPercentage)
      )
      //   }
    }
  }, [performance, size])

  // console.log(performance, 'performance')

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setStartDate(
      moment.utc(date?.startDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
    )
    setEndDate(
      moment.utc(date?.endDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
    )
  }, [date])

  //   useEffect(() => {
  //     PerformanceData()
  //   }, [id, startDate, endDate])

  const [filteredValue, setFilterValue] = useState('All')
  const [performanceData, setPerformanceData] = useState([])
  const [positiveGrowth, setPositiveGrowth] = useState()
  const [negativeGrowth, SetNegativeGrowth] = useState()
  const PerformanceData = async () => {
    try {
      const body = {
        userId: userInfo.info?.userId,
        groupId: groupId?.groupId,
        startDate: startDate,
        endDate: endDate
      }
      const url = `${API_URL}/location/profileViews`

      const response = await httpservice.post(url, body)
      if (response.status === 200) {
        return {
          data: {
            allResponse: response?.data?.data || []
          }
        }

        // if (size === true) {
        //   setPerformanceData(response?.data?.data.slice(0, 5))
        //   //   setload(false)
        // } else {
        //   setPerformanceData(response?.data?.data)

        //   setPositiveGrowth(
        //     response?.data?.data
        //       .filter(item => item?.growthPercentage > 0)
        //       .sort((a, b) => b.growthPercentage - a.growthPercentage)
        //   )

        //   SetNegativeGrowth(
        //     response?.data?.data
        //       .filter(item => item?.growthPercentage < 0)
        //       .sort((a, b) => a.growthPercentage - b.growthPercentage)
        //   )

        //   //   setload(false)
        // }
      } else {
        setload(false)
      }
    } catch (error) {
      setload(false)
    }
  }

  const columns = [
    {
      title: <span className='bg-custom-background3 p-0'>Sl.No.</span>,
      dataIndex: 'slNo',
      key: 'slNo',
      render: (text, record, index) => index + 1,
      className: `${size === true ? ' font-normal' : 'font-semibold'}`
    },
    {
      title: 'Locations',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <div
          className={`${
            size === true ? ' text-xs font-medium ' : 'text-md font-semibold'
          } flex flex-col text-gray-900 whitespace-wrap dark:text-white`}
        >
          <div className='text-base font-semibold '>{record.title}</div>
          <div className='col-9 text-xs font-normal '>
            {record.address} {record.city} {record.state} {record?.postal_code}
          </div>
        </div>
      )
    },
    {
      title: 'Website Click',
      dataIndex: 'WebsiteClick',
      key: 'WebsiteClick',
      className: `${size === true ? 'font-normal' : ' font-semibold '} col-1`,
      render: (text, record) => <p>{record.profileViewsWebsiteClick}</p>
    },
    {
      title: 'Direction Request',
      dataIndex: 'DirectionRequest',
      key: 'DirectionRequest',
      className: `${size === true ? 'font-normal' : ' font-semibold '} col-1 `,
      render: (text, record) => <p>{record.profileViewsDirection}</p>
    },
    {
      title: 'Search Views',
      dataIndex: 'SearchViews',
      key: 'SearchViews',
      className: `${size === true ? 'font-normal' : ' font-semibold '} col-1`,
      render: (text, record) => <p>{record.profileViewsSearch}</p>
    },
    {
      title: 'Map Views',
      dataIndex: 'MapViews',
      key: 'MapViews',
      className: `${size === true ? 'font-normal' : ' font-semibold '} col-1 `,
      render: (text, record) => <p>{record.profileViewsMap}</p>
    },
    {
      title: 'Growth',
      dataIndex: 'growthPercentage',
      key: 'growthPercentage',
      render: text => (
        <p
          className={
            text > 0
              ? 'text-green-500'
              : text < 0
              ? 'text-red-500'
              : 'text-black'
          }
        >
          {text}%
        </p>
      ),
      className: `${size === true ? 'px-2 py-2 font-normal' : ' font-semibold'}`
    }
  ]

  const [searchTerm, setsearchTerm] = useState('')
  const [filteredPerformance, setfilteredPerformance] = useState([])

  useEffect(() => {
    if (performanceData?.length > 0) {
      const filteredData = (
        (filteredValue === 'All' && performanceData) ||
        (filteredValue === 'Positive' && positiveGrowth) ||
        (filteredValue === 'Negative' && negativeGrowth)
      )?.filter(
        item =>
          item?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          item?.address?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          item?.city?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          item?.state?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          item?.postal_code?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      )

      setfilteredPerformance(filteredData || [])
    }
  }, [
    searchTerm,
    filteredValue,
    performanceData,
    positiveGrowth,
    negativeGrowth
  ])

  const data = filteredPerformance || []

  // console.log(performanceData, 'filteredValue')
  const [pageSize, setPageSize] = useState(15) // Initialize page size state

  const handleChangePageSize = (current, newPageSize) => {
    // Update function to receive both current page and new page size
    setPageSize(newPageSize) // Update page size when changed
  }

  const items = [
    {
      key: '1',
      label: (
        <p
          className='font-normal text-sm'
          onClick={() => {
            setFilterValue('All')
          }}
        >
          All
        </p>
      )
    },
    {
      key: '2',
      label: (
        <p
          className='font-normal text-sm'
          onClick={() => {
            setFilterValue('Positive')
          }}
        >
          Positive Growth
        </p>
      )
    },
    {
      key: '3',
      label: (
        <p
          className='font-normal text-sm'
          onClick={() => {
            setFilterValue('Negative')
          }}
        >
          Negative Growth
        </p>
      )
    }
  ]

  useEffect(() => {
    // console.log(isPending, 'isPending')
    if (isPending === false) {
      setTimeout(() => {
        setload(false)
      }, 1000)
      // setload(false)
    }
  }, [isPending])

  return (
    <div>
      <div
        className={`${size === true ? 'ps-3 ' : 'bg-white p-10'} rounded-4 `}
      >
        <p
          className={`text-md mb-4 font-normal text-gray-900 dark:text-white ${
            size === true && 'hidden'
          }`}
        >
          Data Synced From{' '}
          <span className='font-medium'>
            {new Date(startDate).toLocaleDateString('en-us', {
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            })}
          </span>{' '}
          till{' '}
          <span className='font-medium'>
            {new Date(endDate).toLocaleDateString('en-us', {
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            })}
          </span>
        </p>
        <p
          className={`text-xl  pb-3 font-semibold text-gray-900 dark:text-white ${
            size === true && 'hidden'
          }`}
        >
          Performance
        </p>

        {/* <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-md text-tableHeading capitalize bg-custom-background3 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className={`${size === true ? "px-2 py-2" : "px-6 py-3"} font-semibold`}>
                                    Locations
                                </th>
                                <th scope="col" className={`${size === true ? "px-2 py-2" : "px-6 py-3"} text-start whitespace-nowrap  font-semibold`}>
                                    Profile Views
                                </th>
                                <th scope="col" className={`${size === true ? "px-2 py-2" : "px-6 py-3"} text-start font-semibold`}>
                                    Growth
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                performanceData?.map((item) => (

                                    <tr className="bg-white border-b border-b-tableBorder  dark:bg-gray-800 dark:border-gray-700 ">
                                        <th className={`${size === true ? "px-2 py-2 text-xs font-medium " : "px-6 py-4 text-md font-semibold"}    border-r border-r-tableBorder flex flex-col  text-gray-900 whitespace-wrap dark:text-white`}>
                                            {item?.title}<br />
                                            {item?.address}
                                        </th>
                                        <td className={`${size === true ? "px-2 py-2 font-normal" : "px-6 py-4  font-semibold"} text-start  border-r border-r-tableBorder `}>
                                            {item?.profileViews}
                                        </td>
                                        <td className={`${size === true ? "px-2 py-2 font-normal" : "px-6 py-4 font-semibold"} text-start `}>

                                            {(item?.growthPercentage) > 0 ? <p className='text-green-500'>{item?.growthPercentage}%</p> : <p className='text-red-500'>{item?.growthPercentage}%</p>}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div> */}

        <div
          className={`flex justify-between items-center mb-4 ${
            size === true ? 'hidden' : ''
          }`}
        >
          <div className='position-relative  col-7 '>
            <div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/searchIcon.svg'
                alt='searchIcon'
              />
            </div>
            <input
              className='text-sm  col-10  serch_btn ps-12'
              placeholder='Search'
              type='search'
              value={searchTerm}
              onChange={e => {
                setsearchTerm(e.target.value)
              }}
            />
          </div>
          <div>
            <Dropdown
              menu={{
                items
              }}
              placement='bottomRight'
              arrow={{
                pointAtCenter: true
              }}
            >
              <p className='border-listing border-[0.5px] rounded-md text-base p-1 w-24 flex  justify-between'>
                {filteredValue} <CaretDownOutlined />
              </p>
            </Dropdown>
          </div>
        </div>

        {/* <Table
          columns={columns}
          dataSource={data}
          className='w-full text-sm  text-left relative overflow-x-auto shadow-md sm:rounded-lg rtl:text-right text-gray-500 dark:text-gray-400 '
          headerClassName={() =>
            'bg-custom-background3 dark:bg-gray-700 dark:text-gray-400 text-md text-tableHeading capitalize'
          }
          bordered
          pagination={{
            pageSize: pageSize, // Use dynamic page size
            onChange: handleChangePageSize // Handle page size change event
          }}
        /> */}
        <Table
          columns={columns}
          dataSource={data}
          headerClassName={() =>
            'bg-custom-background3 dark:bg-gray-700 dark:text-gray-400 text-md text-tableHeading capitalize'
          }
          bordered
          pagination={
            size
              ? false
              : {
                  pageSize: pageSize, // Use dynamic page size
                  onChange: handleChangePageSize, // Handle page size change event
                  showSizeChanger: true, // Show the page size changer dropdown
                  pageSizeOptions: ['15', '20', '30', '50']
                }
          }
        />

        {performanceData?.length >= 3 && (
          <Link to={`/insights/performance`}>
            <p
              className={`text-lg  font-medium text-listing text-start pt-2 dark:text-white ${
                size === false && 'hidden'
              }`}
            >
              View All
            </p>
          </Link>
        )}
      </div>
    </div>
  )
}

export default PerformaneComponent
