import React from 'react'
import { handleInputValidation } from '../../helper/validator'

const SecondForm = ({
  address,
  setAddress,
  longitude,
  setLongitude,
  latitude,
  setlatitude,
  pincode,
  setPincode,
  city,
  setCity,
  state,
  setState,
  handleNext,
  onPrevious
}) => {
  // console.log(address.length, 'address')
  return (
    <div>
      <div className='flex items-center'>
        <div className=''>
          <img
            src='https://pro-manage.s3.ap-south-1.amazonaws.com/step2.svg'
            className='img-fluid'
            alt='vector'
          />
        </div>
        <div className=' bg-white   rounded-2xl'>
          <div className='flex justify-between pb-2 ps-4 pt-4 pe-4'>
            <h2 className='text-2xl	font-medium'>Enter your business address</h2>
          </div>
          <p className='col-10 mb-3 ps-4 text-businessProfile'>
            Add a location where customers can visit your business in person
          </p>
          <form className=''>
            <div className='mb-3  ps-4 pe-4'>
              <div className='relative '>
                <input
                  type='text'
                  id='Region'
                  className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 'cursor-text text-gray-900   `}
                  placeholder=' '
                  value={'India'}
                  disabled={true}
                />
                <label
                  for='Region'
                  className='absolute text-sm font-medium text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  Region
                </label>
              </div>
            </div>
            <div className='ps-4 mb-2 pe-4'>
              <div className='relative '>
                <input
                  type='text'
                  id='Address'
                  className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 'cursor-text text-gray-900   `}
                  placeholder=' '
                  value={address}
                  onChange={e =>
                    setAddress(e.target.value.replace(/\s{2,}/g, ' '))
                  }
                  required
                />
                <label
                  for='Address'
                  className='absolute text-sm font-medium text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  Address
                </label>
              </div>
            </div>
            <div className='ps-4 mb-2 pe-4'>
              <div className='relative '>
                <input
                  type='tel'
                  id='latitude'
                  className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 'cursor-text text-gray-900   `}
                  placeholder=' '
                  pattern='\d*'
                  value={latitude}
                  onChange={e => {
                    handleInputValidation(e.target.value, setlatitude, 5)
                  }}
                />
                <label
                  for='latitude'
                  className='absolute text-sm font-medium text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  Latitude
                </label>
              </div>
            </div>
            <div className='ps-4 mb-2 pe-4'>
              <div className='relative '>
                <input
                  type='tel'
                  id='longitude'
                  className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 'cursor-text text-gray-900`}
                  placeholder=' '
                  value={longitude}
                  onChange={e => {
                    handleInputValidation(e.target.value, setLongitude, 5)
                  }}
                  required
                />
                <label
                  htmlFor='longitude'
                  className='absolute text-sm font-medium text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  Longitude
                </label>
              </div>
            </div>
            <div className='ps-4 mb-2 pe-4'>
              <div className='relative '>
                <input
                  type='tel'
                  id='Pincode'
                  className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 'cursor-text text-gray-900   `}
                  placeholder=' '
                  value={pincode}
                  onChange={e =>
                    handleInputValidation(
                      e.target.value.slice(0, 6),
                      setPincode,
                      3
                    )
                  }
                  required
                />
                <label
                  for='Pincode'
                  className='absolute text-sm font-medium text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  Pincode
                </label>
              </div>
            </div>
            <div className='ps-4 mb-2 pe-4'>
              <div className='relative '>
                <input
                  type='text'
                  id='City'
                  className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 'cursor-text text-gray-900   `}
                  placeholder=' '
                  value={city}
                  onChange={e =>
                    handleInputValidation(e.target.value, setCity, 0)
                  }
                  required
                />
                <label
                  for='City'
                  className='absolute text-sm font-medium text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  City
                </label>
              </div>
            </div>
            <div className='ps-4 mb-2 pe-4'>
              <div className='mb-3 '>
                <select
                  id='state'
                  className='block w-full p-3  font-medium  text-gray-900 border border-gray-300 rounded-lg  bg-white sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                  value={state}
                  onChange={e => setState(e.target.value)}
                  required
                >
                  <option value='' selected disabled={true}>
                    Select State
                  </option>
                  <option value='Andhra Pradesh'>Andhra Pradesh</option>
                  <option value='Arunachal Pradesh'>Arunachal Pradesh</option>
                  <option value='Assam'>Assam</option>
                  <option value='Bihar'>Bihar</option>
                  <option value='Chhattisgarh'>Chhattisgarh</option>
                  <option value='Goa'>Goa</option>
                  <option value='Gujarat'>Gujarat</option>
                  <option value='Haryana'>Haryana</option>
                  <option value='Himachal Pradesh'>Himachal Pradesh</option>
                  <option value='Jharkhand'>Jharkhand</option>
                  <option value='Karnataka'>Karnataka</option>
                  <option value='Kerala'>Kerala</option>
                  <option value='Madhya Pradesh'>Madhya Pradesh</option>
                  <option value='Maharashtra'>Maharashtra</option>
                  <option value='Manipur'>Manipur</option>
                  <option value='Meghalaya'>Meghalaya</option>
                  <option value='Mizoram'>Mizoram</option>
                  <option value='Nagaland'>Nagaland</option>
                  <option value='Odisha'>Odisha</option>
                  <option value='Punjab'>Punjab</option>
                  <option value='Rajasthan'>Rajasthan</option>
                  <option value='Sikkim'>Sikkim</option>
                  <option value='Tamil Nadu'>Tamil Nadu</option>
                  <option value='Telangana'>Telangana</option>
                  <option value='Tripura'>Tripura</option>
                  <option value='Uttar Pradesh'>Uttar Pradesh</option>
                  <option value='Uttarakhand'>Uttarakhand</option>
                  <option value='West Bengal'>West Bengal</option>
                </select>
              </div>
            </div>

            <div className='bg-formBottom flex justify-around pb-4 p-2 rounded-b-2xl'>
              <button
                type='button'
                onClick={onPrevious}
                className='bg-formBottom border-1 border-listing text-listing text-xl mt-3 rounded-xl w-44 pt-2 pb-2'
              >
                Previous
              </button>
              <button
                type='button'
                onClick={handleNext}
                className='bg-listing text-light text-xl mt-3 rounded-xl w-44 pt-2 pb-2'
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SecondForm
