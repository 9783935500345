import React, { useContext, useEffect, useState } from 'react'
import CreateModal from '../Components/PostModal/CreateModal'
import { AccountDetails } from '../Store/AccountInfo'

import httpservice from '../Service/httpservice'
import { API_URL } from '../env/env'
import ViewDetails from '../Components/BulkModal/ViewDetails'
import { Link } from 'react-router-dom'
import PopoverExample from '../Components/PopoverExample'
import { Empty, Popover } from 'antd'
import Loader from '../loader/loader'
import { AuthContext } from '../context/AuthContext'

const BulkUpload = () => {
  const [load, setload] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpen1, setIsModalOpen1] = useState(false)
  const { userInfo, groupId } = useContext(AuthContext)
  const [bulkPostLocationId, setbulkPostLocationId] = useState()
  const [completedData, setCompletedData] = useState()
  const [processingData, setProcessingData] = useState()
  const [websiteUrl, setWesiteUrl] = useState('')

  const {
    tokenInfo,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleQuestionCountNotification,
    handleReviewNotification,
    handleLocationNotification
  } = useContext(AuthContext)

  useEffect(() => {
    tokenInfo()
  }, [])

  useEffect(() => {
    if (userInfo?.info?.roleDetails?.roleId === 1) {
      handleLocationCountNotification(true)
      handleReviewCountNotification(true)
      handleQuestionCountNotification(true)
      // handleReviewNotification(true)
      // handleLocationNotification(true)
    }
  }, [groupId, userInfo])

  useEffect(() => {
    ListBulkUpload()
  }, [groupId])

  const [listBulkUploadList, setBulkUploadList] = useState([])
  const ListBulkUpload = async () => {
    try {
      let body = {
        userId: userInfo.info?.userId,
        groupId: groupId?.groupId
      }
      let url = `${API_URL}/post/bulk_upload/list`
      const response = await httpservice.post(url, body)
      if (response.status === 200) {
        // console.log(response?.data?.data, 'responseDat In listBulkUpload')
        setBulkUploadList(response?.data?.data)
        setProcessingData(
          response?.data?.data?.filter(
            item => item?.total_profile === 'Processing'
          )
        )
        setCompletedData(
          response?.data?.data?.filter(
            item => item?.total_profile === 'Completed'
          )
        )
        setload(false)
      } else {
        setload(false)
      }
    } catch (error) {
      setload(false)
    }
  }

  const handleFilteredData = e => {
    if (e.target.value === 'All') {
      ListBulkUpload()
      return
    }
    if (e.target.value === 'Processing') {
      setBulkUploadList(processingData)
    }
    if (e.target.value === 'Completed') {
      setBulkUploadList(completedData)
    }
  }

  const [description, setDescription] = useState('')
  const [image, setImage] = useState()

  const content = (
    <div className=' text-sm text-gray-500 dark:text-gray-400 p-2'>
      <div className='flex justify-start items-center w-[30rem]'>
        <img
          src={image}
          alt='image'
          className='img-fluid w-52 h-52 rounded-lg'
        />
        <div className='px-3 py-2'>
          <h4 className='text-black text-lg font-semibold'>Description</h4>
          <p className='text-xs text-notVerified h-[180px] overflow-x-auto'>
            {description}
          </p>
          {websiteUrl && (
            <>
              <h4 className={`text-black text-lg font-semibold`}>
                Website Url
              </h4>
              <Link to={websiteUrl} target='_blank' className='text-websiteURl'>
                {websiteUrl}
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  )

  const EmptyWithShadow = ({ condition }) => (
    <div
      className={`${condition ? '' : 'hidden'}`}
      style={{
        boxShadow: condition ? '0px 2px 5px rgba(0, 0, 0, 0.1)' : 'none',
        borderRadius: condition ? '0 0 10px 10px' : '0'
      }}
    >
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className='mt-0 pt-5' />
    </div>
  )

  // console.log(
  //   typeof userInfo?.info?.accessTypeDetails?.accessTypeId,
  //   'tokenInfo'
  // )

  return (
    <div className='container p-6'>
      {load && <Loader />}
      <p className='text-Heading text-2xl font-semibold pb-4 '>Bulk Upload</p>
      <div className='bg-custom-background4 rounded-4 p-4'>
        <div className='flex items-center justify-between '>
          <div className='col-lg-2 col-md-4 col-6'>
            <select
              id='bulkStatus'
              onChange={e => handleFilteredData(e)}
              className='bg-custom-background4 border-1 text-listing border-listing text-base rounded-lg focus:ring-listing focus:border-listing block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white'
            >
              <option selected value='All'>
                All
              </option>
              <option value='Completed'>Completed</option>
              <option value='Processing'>Processing</option>
            </select>
          </div>
          {userInfo?.info?.accessTypeDetails?.accessTypeId === 1 ? (
            ''
          ) : (
            <div className=' justify-end flex'>
              <Link to='upload'>
                <button
                  className='px-4 py-2 flex gap-2 text-base font-medium text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none'
                  // onClick={() => setIsModalOpen(true)}
                >
                  <img
                    src='https://pro-manage.s3.ap-south-1.amazonaws.com/AddIcon+(2).svg'
                    className='img-fluid'
                    alt='Profilepost'
                  />
                  Upload post
                </button>
              </Link>
            </div>
          )}
        </div>

        <div className='relative overflow-x-auto mt-3'>
          <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
            <thead className='text-base text-gray-700  capitalize bg-custom-background3 dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th
                  scope='col'
                  className='px-6 py-3 font-semibold   rounded-s-lg'
                >
                  Uploaded On
                </th>
                <th scope='col' className='px-6 font-semibold py-3'>
                  Uploaded by
                </th>
                <th scope='col' className='px-6 font-semibold text-center py-3'>
                  Photo
                </th>
                <th scope='col' className='px-6 font-semibold py-3'>
                  Status
                </th>
                <th
                  scope='col'
                  className='px-6  font-semibold   py-3 rounded-e-lg'
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {listBulkUploadList?.map((item, index) => (
                <tr
                  className={`${
                    index % 2 === 0 ? 'bg-white' : 'bg-custom-background5'
                  } dark:bg-gray-800 dark:border-gray-700 `}
                >
                  <td
                    className={`px-6 py-6 text-base font-semibold col-2  border-r border-r-tableBorder  text-gray-900 dark:text-white`}
                  >
                    <p className='text-businessProfile font-medium '>
                      {item.created_date} {item?.created_time}
                    </p>
                  </td>
                  <td
                    className={` px-6 py-6  font-semibold text-start col-2 border-r text-listing text-base border-r-tableBorder `}
                  >
                    {item?.createdBy}
                  </td>
                  <td
                    className={` px-6 py-6 font-semibold text-start border-r col-2  border-r-tableBorder `}
                  >
                    <Popover
                      placement='leftTop'
                      content={content}
                      trigger='click'
                      className=''
                    >
                      <img
                        src={item?.imageUrl}
                        alt='image'
                        className='w-20 h-20 cursor-pointer m-auto'
                        onClick={() => {
                          setDescription(item?.summary)
                          setImage(item?.imageUrl)
                          setWesiteUrl(item?.url)
                        }}
                      />
                    </Popover>
                  </td>
                  <td
                    className={` px-6 py-6 font-semibold text-start border-r col-2 border-r-tableBorder `}
                  >
                    <p
                      className={`flex justify-center items-center gap-2 col-8 p-2 rounded-lg ${
                        item?.total_profile === 'Processing'
                          ? 'bg-processing-gradient text-light font-normal rounded-lg'
                          : item?.total_profile === 'Completed'
                          ? 'bg-Compelte-gradient text-light font-normal rounded-xl'
                          : ''
                      }`}
                    >
                      {' '}
                      <img
                        src={`${
                          item?.total_profile === 'Processing'
                            ? 'https://pro-manage.s3.ap-south-1.amazonaws.com/processingImg.svg'
                            : item?.total_profile === 'Completed'
                            ? 'https://pro-manage.s3.ap-south-1.amazonaws.com/completedImg.svg'
                            : ''
                        }`}
                        alt='processing'
                      />
                      {item?.total_profile}
                    </p>
                  </td>
                  <td className={` px-6 py-6 font-semibold text-start col-2 `}>
                    <div
                      className='border-1 border-ViewActivity hover:bg-ViewActivity hover:text-white  text-base cursor-pointer font-medium col-8 text-center rounded-lg'
                      onClick={() => {
                        setIsModalOpen1(true)
                        setbulkPostLocationId(item?.bulkPostId)
                      }}
                    >
                      <p className='text-ViewActivity hover:text-white  p-2'>
                        {' '}
                        View Details
                      </p>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <EmptyWithShadow condition={listBulkUploadList?.length === 0} />
          {/* {
                        listBulkUploadList?.length === 0 && <p className='text-center font-medium text-listing'>   <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className='shadow-md p-0 pt-5  mt-0 ' /></p>
                    } */}
        </div>

        {/* <PopoverExample /> */}
      </div>

      <ViewDetails
        isModalOpen={isModalOpen1}
        setIsModalOpen1={setIsModalOpen1}
        userId={userInfo.info?.userId}
        groupId={groupId?.groupId}
        bulkPostLocationId={bulkPostLocationId}
      />
    </div>
  )
}

export default BulkUpload
