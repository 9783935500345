import React, { useEffect, useRef } from 'react'
import { Bar } from 'react-chartjs-2'

const RatingTrends = ({ ratingTrends, startDate, endDate }) => {
  const chartRef = useRef(null)

  const labels = ratingTrends?.map(entry => entry?.insightsDate)
  const dataValues = ratingTrends?.map(entry =>
    entry?.avgRating !== null ? entry?.avgRating : 0
  )

  const data = {
    labels: labels,
    datasets: [
      {
        // label: 'SearchData',
        data: dataValues,
        backgroundColor: '#8FBBFA',
        // borderColor: repeatedColors.slice(0, dataValues.length),
        borderWidth: 0,
        fill: false,
        borderRadius: 5,
        barPercentage: 0.4,
        maxBarThickness: 60
      }
    ]
  }

  const options = {
    type: 'bar',
    indexAxis: 'y',
    data: data,
    hAxis: {
      title: 'Month',
      gridlines: {
        color: 'transparent'
      }
    },
    tooltip: {
      trigger: 'both'
    },
    focusTarget: 'category',
    scales: {
      y: {
        grid: {
          display: false
        },
        ticks: {
          min: 'start',
          max: 'end'
        }
      },
      x: {
        type: 'linear',
        beginAtZero: true,
        grid: {
          display: false
        },
        ticks: {
          stepSize: 1,
          min: 1,
          max: 5
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  }

  return (
    <div className=''>
      <div className='rounded-2xl p-4 shadow-filter1 border-[0.1px] border-cardInsights'>
        <h2 className='text-xl font-semibold mb-3'>Rating Trends</h2>
        {/* <p className='text-md mb-4 font-normal text-gray-900 dark:text-white'>
          Data Synced From{' '}
          <span className='font-medium'>
            {new Date(startDate).toLocaleDateString('en-us', {
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            })}
          </span>{' '}
          till{' '}
          <span className='font-medium'>
            {new Date(endDate).toLocaleDateString('en-us', {
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            })}
          </span>
        </p> */}
        <Bar data={data} options={options} width={200} />
      </div>
    </div>
  )
}

export default RatingTrends
