import React, { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'

const TopBar = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const {
    tokenInfo,
    groupId,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleQuestionCountNotification,
    locationCountNotification,
    reviewCountNotification,
    questionCountNotification
  } = useContext(AuthContext)

  // console.log(reviewCountNotification, 'reviewCountNotification')

  useEffect(() => {
    if (groupId) {
      handleLocationCountNotification()
      handleReviewCountNotification()
      handleQuestionCountNotification()
    }
    //   // handleReviewNotification(true)
    //   // handleLocationNotification(true)
  }, [groupId])

  const tabs = [
    {
      title: 'Reviews',
      Url: 'useractivity/review',
      count: reviewCountNotification?.totalNotificationCount
    },
    {
      title: 'Business Profile',
      Url: 'useractivity/location',
      count: locationCountNotification?.totalNotificationCount
    },
    {
      title: 'Question and Answer',
      Url: 'useractivity/question',
      count: questionCountNotification?.totalNotificationCount
    }
  ]
  return (
    <div className='p-6 bg-custom-background4 rounded-t-4 rounded-b-none m-6 mb-0'>
      <div className='text-3xl font-semibold'>User Activity</div>
      <aside
        id='default-sidebar'
        className=' md:relative fixed top-0 left-0 z-40 transition-transform -translate-x-full sm:translate-x-0'
        aria-label='Sidenav'
      >
        <div className='overflow-y-auto ms-4 me-4 mt-10 bg-white p-2 pb-0'>
          <ul className='space-y-2 flex justify-start gap-4'>
            {tabs.map(item => (
              <li
                className={
                  pathname.includes(`${item.Url}`)
                    ? 'text-lg font-semibold px-5 mt-0 py-2.5 text-sideBar block capitalize  cursor-pointer'
                    : 'text-sideBar text-lg mt-0 py-2.5 capitalize font-semibold rounded-full px-5 cursor-pointer'
                }
                onClick={() => navigate(`/${item.Url}`)}
                key={item.title}
              >
                <div className='flex justify-center gap-3'>
                  {item.title}

                  <p className='text-notificationCount font-semibold rounded-full bg-notification w-11 text-center h-7 mb-0'>
                    {item?.count}
                  </p>
                </div>

                <p
                  className={
                    pathname.includes(`${item.Url}`)
                      ? 'border-2 border-listing'
                      : ''
                  }
                  style={{ borderRadius: '4.53px 4.53px 0px 0px' }}
                ></p>
              </li>
            ))}
          </ul>
        </div>
      </aside>
    </div>
  )
}

export default TopBar
