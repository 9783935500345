import React, { useContext, useState } from 'react'
import { AccountDetails } from '../../Store/AccountInfo'
import { LocationDetails } from '../../Store/LocationInfo'
import { API_URL } from '../../env/env'
import httpservice from '../../Service/httpservice'
import { Button, Modal } from 'flowbite-react'
import { toast } from 'react-toastify'
import { AuthContext } from '../../context/AuthContext'

const WebsiteModal = ({
  openModalWebsiteLink,
  setOpenModalWebsiteLink,
  websiteUrl,
  SetWebsiteUrl,
  invalidateAndRefetch,
  handleLocationCountNotification
}) => {
  // const id = AccountDetails((state) => state.AccountDetails);
  // const tokenDetails = JSON.parse(localStorage.getItem("tmbUserData"));
  const { userInfo, groupId } = useContext(AuthContext)
  const Locationid = LocationDetails(state => state.LocationDetails)
  const [isDisable, setIsDisable] = useState(true)
  const handleEditWebsite = async e => {
    e.preventDefault()
    try {
      let body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        locationId: Locationid,
        sectionName: 'Website Url',
        updateData: {
          websiteUri: websiteUrl
        }
      }
      const url = `${API_URL}/location/update?updateMask=websiteUri`
      const res = await httpservice.patch(url, body)

      if (res.status === 200) {
        setOpenModalWebsiteLink(false)
        setIsDisable(true)
        if (userInfo?.info?.roleDetails?.roleId === 1) {
          handleLocationCountNotification(true)
        }
        invalidateAndRefetch()
        toast.success('WebsiteUrl updated, reflects in 10.')
      } else {
        setOpenModalWebsiteLink(false)
        setIsDisable(true)
        toast.error(res.data.message)
      }
    } catch (error) {
      setOpenModalWebsiteLink(false)
      setIsDisable(true)
      toast.error(error?.response?.data?.message)
      // console.log(error)
    }
  }
  return (
    <div>
      <Modal
        show={openModalWebsiteLink}
        onClose={() => {
          setOpenModalWebsiteLink(false)
          setIsDisable(true)
        }}
      >
        <Modal.Header className='p-3'>
          <p className=' font-medium text-2xl'>Edit Website</p>
        </Modal.Header>
        <Modal.Body>
          <div className='space-y-6'>
            <p className='text-md font-medium text-listing dark:text-gray-400'>
              Please provide the link to your business website, including the
              'https://' prefix. The link should not redirect to any promotional
              page or social media profile.
            </p>
            <form>
              <div className='relative '>
                <input
                  className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer   ${
                    isDisable
                      ? 'cursor-not-allowed text-gray-400'
                      : 'cursor-text text-gray-900 '
                  }  `}
                  placeholder=' '
                  value={websiteUrl}
                  onChange={e => {
                    SetWebsiteUrl(e.target.value)
                  }}
                  disabled={isDisable}
                  type='text'
                  id='default-input'
                />
                <div className='absolute inset-y-0 end-2 flex items-center ps-3 cursor-pointer'>
                  <img
                    src='https://pro-manage.s3.ap-south-1.amazonaws.com/OverViewEdit.svg'
                    alt='edit'
                    className='img-fluid'
                    onClick={() => setIsDisable(false)}
                  />
                </div>

                <label
                  for='Alternate'
                  className='absolute text-sm font-semibold text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  Website URL
                </label>
              </div>
            </form>
            <div className='mt-3 bg-caution rounded-lg p-3'>
              {/* <p className=' text-cautionText text-lg   pb-0 font-medium'>Caution</p> */}
              <p className='text-red-700 flex gap-1'>
                {' '}
                <img
                  src='https://pro-manage.s3.ap-south-1.amazonaws.com/caution.svg'
                  className='w-5'
                />
                Updating the Website might take time around 10 - 15 min{' '}
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='pt-0 justify-end'>
          <div className='flex justify-end gap-3 mt-4'>
            <button
              color='gray'
              onClick={() => {
                setOpenModalWebsiteLink(false)
                setIsDisable(true)
              }}
              className='px-4 py-2 text-md font-medium text-center border-1 text-gray  rounded-lg focus:outline-none'
            >
              Cancel
            </button>
            <button
              type='submit'
              className='px-4 py-2 text-md font-medium text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none'
              onClick={e => handleEditWebsite(e)}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default WebsiteModal
