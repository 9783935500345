import React, { useContext, useEffect, useState } from "react";
import httpservice from "../../Service/httpservice";
import { Modal } from "flowbite-react";
import { API_URL } from "../../env/env";
import { toast } from "react-toastify";
import { AccountDetails } from "../../Store/AccountInfo";
import { handleInputValidation } from "../../helper/validator";
import { AuthContext } from "../../context/AuthContext";

const AddUserModal = ({
  openAddUserModal,
  setOpenAddUserModal,
  roleId,
  tokenDetails,
  getSingleLocation,
  setIsUploading,
  isUploading,
}) => {
  const [userName, SetUserName] = useState("");
  const [email, SetEmail] = useState("");
  const [PhNumber, SetPhNumber] = useState("");
  const [access_type, setaccess_type] = useState("");
  const [getAllAccessType, setAllAccessType] = useState("");
  const [allRoles, setAllRoles] = useState("");

  useEffect(() => {
    getaccess_type();
    getAllRole();
  }, []);
  // const [passWord, SetPassword] = useState('')
  const [role, setRole] = useState("");
  // const id = AccountDetails((state) => state.AccountDetails);
  const { userInfo, groupId } = useContext(AuthContext);
  const HandleAddUser = async (e) => {
    e.preventDefault();
    if (!userName) {
      toast.error("Please Enter User Name");
      return;
    }
    if (!PhNumber || PhNumber.length < 10) {
      toast.error("Please Enter Phone Number");
      return;
    }
    if (!email) {
      toast.error("Please Enter Email");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      toast.error("Please Enter a Valid Email");
      return;
    }

    if (!access_type) {
      toast.error("Please Select Access Type");
      return;
    }
    setIsUploading(true);
    try {
      let body = {
        userId: userInfo?.info?.userId,
        name: userName,
        email: email,
        phone: PhNumber,
        role: 2,
        access_type: access_type,
        // "password": passWord
      };
      const url = `${API_URL}/user/add`;
      const response = await httpservice.post(url, body);
      if (response.status === 200) {
        setOpenAddUserModal(false);
        getSingleLocation();
        SetUserName("");
        SetEmail("");
        SetPhNumber("");
        setRole("");
        setIsUploading(false);
        setaccess_type("");
        toast.success(response?.data?.message);
      } else {
        SetUserName("");
        SetEmail("");
        SetPhNumber("");
        setRole("");
        setaccess_type("");
        setIsUploading(false);
        setOpenAddUserModal(false);
        toast.error(response?.data?.message);
      }
    } catch (error) {
      SetUserName("");
      SetEmail("");
      SetPhNumber("");
      setRole("");
      setaccess_type("");
      setOpenAddUserModal(false);
      toast.error(error.response?.data?.message);
    }
  };

  const getaccess_type = async () => {
    try {
      const body = {
        userId: userInfo?.info?.userId,
      };
      const url = `${API_URL}/other/all_access_type`;
      const response = await httpservice.post(url, body);
      if (response.status === 200) {
        setAllAccessType(response?.data?.data);
      } else {
        setAllAccessType("");
        // toast.error(response?.data?.message)
      }
    } catch (error) {
      setAllAccessType("");
      // toast.error(error.response?.data?.message)
    }
  };
  const getAllRole = async () => {
    try {
      const body = {
        userId: userInfo?.info?.userId,
      };
      const url = `${API_URL}/other/all_roles`;
      const response = await httpservice.post(url, body);
      if (response.status === 200) {
        setAllRoles(response?.data?.data);
      } else {
        setAllRoles("");
        // toast.error(response?.data?.message)
      }
    } catch (error) {
      setAllRoles("");
      // toast.error(error.response?.data?.message)
    }
  };

  // console.log(getAllAccessType, 'getAllAccessType', allRoles)

  return (
    <div>
      <Modal
        show={openAddUserModal}
        onClose={() => {
          setOpenAddUserModal(false);
          SetUserName("");
          SetEmail("");
          SetPhNumber("");
          setRole("");
          setaccess_type("");
        }}
        size="xl"
      >
        <Modal.Header className="p-3 bg-reviewPopUp rounded-t-2xl">
          <p className=" font-semibold text-xl">Add User</p>
        </Modal.Header>
        <Modal.Body className="p-10 bg-reviewPopUp">
          <div className=" text-center">
            {/* <div className='flex flex-col gap-3 mx-auto my-auto relative justify-center items-center w-24 pb-3'>
                            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/9df77cf82c9eb9a6cd48541a789bd37e3b621c1ca6f518292ab26b50d43a691c?"
                                className="aspect-square object-contain object-center w-full overflow-hidden max-w-[185px]" alt='profileImg' />
                        </div> */}

            <div className="relative">
              <input
                type="text"
                id="floating_outlined"
                onChange={(e) =>
                  handleInputValidation(e?.target?.value, SetUserName, 1)
                }
                value={userName}
                className="block px-2.5 pb-2.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
              />
              <label
                for="floating_outlined"
                className="absolute text-sm text-black dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-reviewPopUp dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                Enter Name
              </label>
            </div>
            <div className="relative mt-3">
              <input
                type="tel"
                id="floating"
                onChange={(e) =>
                  handleInputValidation(e?.target?.value, SetPhNumber, 2)
                }
                value={PhNumber}
                className="block px-2.5 pb-2.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
              />
              <label
                for="floating"
                className="absolute text-sm text-black dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-reviewPopUp dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                Enter the phone number
              </label>
            </div>
            <div className="relative mt-3">
              <input
                type="email"
                id="floating_email"
                onChange={(e) =>
                  handleInputValidation(e?.target?.value, SetEmail, 4)
                }
                value={email}
                className="block px-2.5 pb-2.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
              />
              <label
                for="floating_email"
                className="absolute text-sm text-black dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-reviewPopUp dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                Enter the email id
              </label>
            </div>
            {/* <div className="relative mt-3">
                            <input type="password" id="floating_password" onChange={(e) => SetPassword(e?.target?.value)}
                                className="block px-2.5 pb-2.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                            <label for="floating_password"
                                className="absolute text-sm text-black dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-reviewPopUp dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Password</label>
                        </div> */}
            {/* <select
              className='justify-between items-center bg-reviewPopUp rounded border self-center flex w-full max-w-[669px] gap-5 mt-3 px-3 py-2.5 border-solid border-slate-300 max-md:max-w-full max-md:flex-wrap'
              onChange={e => setRole(e?.target?.value)}
            >
              <option className='text-black' selected disabled value=''>
                Role
              </option>
              {allRoles &&
                allRoles?.map((role, index) => (
                  <option className='text-black' value={`${role?.id}`}>
                    {role?.roleName}
                  </option>
                ))}
              <img
                loading='lazy'
                src='https://cdn.builder.io/api/v1/image/assets/TEMP/85f10bfc566cb3624fbb68310ab7db1faa5a9f73116c304ff0ea80f18546383d?'
                className='aspect-[3.92] object-contain object-center w-full overflow-hidden shrink-0 flex-1 max-w-[119px]'
                alt=''
              />
            </select> */}
            <select
              className="justify-between items-center bg-reviewPopUp rounded border self-center flex w-full max-w-[669px] gap-5 mt-3 px-3 py-2.5 border-solid border-slate-300 max-md:max-w-full max-md:flex-wrap"
              onChange={(e) => setaccess_type(e?.target?.value)}
            >
              <option className="text-black" selected disabled value="">
                Access Type
              </option>
              {getAllAccessType &&
                getAllAccessType?.map((accessType, index) => (
                  <option
                    className="text-black"
                    value={`${accessType?.accessTypeId}`}
                  >
                    {accessType?.accessTypeName}
                  </option>
                ))}
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/85f10bfc566cb3624fbb68310ab7db1faa5a9f73116c304ff0ea80f18546383d?"
                className="aspect-[3.92] object-contain object-center w-full overflow-hidden shrink-0 flex-1 max-w-[119px]"
                alt=""
              />
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className=" text-white bg-listing rounded-lg ps-3 pe-3 pt-2 pb-2 text-md col-6 mx-auto my-auto"
            type="submit"
            onClick={(e) => HandleAddUser(e)}
            disabled={isUploading}
          >
            Add User
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddUserModal;
