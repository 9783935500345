import React, { useEffect, useState } from 'react'
import useDebounce from '../hooks/useDebounce'

const AdditionalCategoryInput = ({
  index,
  handleAdditionalCategory,
  handleAltCat,
  dataList,
  additionalCategories,
  setAdditionalCategories,
  setAddnewCategory,
  AddnewCategory,
  addBusiness
}) => {
  const [inputValue, setInputValue] = useState('')
  const [showDropdown, setShowDropdown] = useState(false)
  const [inputVisibility, setInputVisibility] = useState(true)
  const debouncedInputValue = useDebounce(inputValue)

  const handleRemove = categoryToRemove => {
    const updatedCategories = additionalCategories?.filter(
      category => category.name !== categoryToRemove
    )
    setAdditionalCategories(updatedCategories)
    setInputValue('')
    setShowDropdown(false)
    setInputVisibility(false)
    setAddnewCategory(AddnewCategory - 1)
  }

  useEffect(() => {
    if (debouncedInputValue.length > 1) {
      handleAdditionalCategory(index, debouncedInputValue)
    }
  }, [debouncedInputValue])
  return (
    <div>
      {inputVisibility && (
        <div className='relative w-full mt-4'>
          <div className='relative '>
            <input
              type='text'
              className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer  cursor-text text-gray-900  `}
              placeholder=' '
              value={inputValue}
              onChange={e => {
                setInputValue(e.target.value)
              }}
              onClick={() => setShowDropdown(true)}
              id={`simple-search-${index}`}
            />
            <div className='absolute inset-y-0 end-2 flex items-center ps-3 cursor-pointer'>
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/Delete.svg'
                alt='edit'
                className='img-fluid'
                onClick={() => handleRemove(inputValue)}
              />
            </div>
            <label
              for='AdditionalCategory'
              className={`absolute text-sm  dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] ${
                addBusiness
                  ? 'bg-white font-medium text-gray-500'
                  : 'bg-white font-semibold text-gray-500'
              } dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
            >
              Additional Category
            </label>
          </div>

          {showDropdown &&
            inputValue?.length > 0 &&
            Array.isArray(dataList[index]) && (
              <div className='top-full mt-2 bg-white border border-gray-300 shadow-lg rounded-lg h-auto min-h-0 max-h-72 overflow-y-scroll'>
                {dataList[index].map((item, itemIndex) => (
                  <div
                    key={itemIndex}
                    className='p-2 cursor-pointer hover:bg-gray-100'
                    onClick={() => {
                      handleAltCat(item)
                      setInputValue(item.displayName)
                      setShowDropdown(false)
                    }}
                  >
                    {item.displayName}
                  </div>
                ))}
              </div>
            )}

          {/* <p onClick={() => handleRemove(inputValue)}>Cross</p> */}
        </div>
      )}
    </div>
  )
}

export default AdditionalCategoryInput
