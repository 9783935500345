import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import httpservice from '../Service/httpservice'
import { Link } from 'react-router-dom'
import { LocationDetails } from '../Store/LocationInfo'
import 'react-datepicker/dist/react-datepicker.css'
import '../Styles/overview.css'
import StarRating from '@rubenvara/react-star-rating'

import { API_URL } from '../env/env'
import Loader from '../loader/loader'
import BusinessTitleModal from '../Components/OverviewModal/BusinessTitleModal'
import AddressModal from '../Components/OverviewModal/AddressModal'
import NumberModal from '../Components/OverviewModal/NumberModal'
import DescriptionModal from '../Components/OverviewModal/DescriptionModal'
import WebsiteModal from '../Components/OverviewModal/WebsiteModal'

import CategoryModal from '../Components/OverviewModal/CategoryModal'
import RegularTimeModal from '../Components/OverviewModal/RegularTimeModal'
import { useQuery } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'
import { AuthContext } from '../context/AuthContext'
import SpecialHourModal from '../Components/OverviewModal/SpecialHourModal'

const BusinessOverView = () => {
  const { userInfo, groupId } = useContext(AuthContext)
  const Locationid = LocationDetails(state => state.LocationDetails)
  const [reviews, SetReview] = useState([])

  const {
    tokenInfo,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleQuestionCountNotification
  } = useContext(AuthContext)

  useEffect(() => {
    tokenInfo()
  }, [])

  useEffect(() => {
    if (userInfo?.info?.roleDetails?.roleId === 1) {
      if (groupId) {
        handleLocationCountNotification(true)
        handleReviewCountNotification(true)
        handleQuestionCountNotification(true)
      }
    }
    // handleReviewNotification(true)
    // handleLocationNotification(true)
  }, [groupId, userInfo])
  // Disable
  const queryClient = useQueryClient()

  const invalidateAndRefetch = () => {
    queryClient.invalidateQueries(['SingleLocation'])
  }

  // console.log(Locationid, 'Locationid')
  const { data } = useQuery({
    queryFn: () => Locationid && getSingleLocation(),
    queryKey: ['SingleLocation', { Locationid }],
    staleTime: 1000 * 3600,
    cacheTime: 3600
  })

  const [openModalAddress, setOpenModalAddress] = useState(false)
  const [openModalCategories, setOpenModalCategories] = useState(false)
  const [openModalNumber, setOpenModalNumber] = useState(false)
  const [openModalRegularHour, setOpenModalRegularHour] = useState(false)
  const [openModalWebsiteLink, setOpenModalWebsiteLink] = useState(false)
  const [openModalDescription, setOpenModalDescription] = useState(false)
  const [OpenModalSpecialHours, setOpenModalSpecialHours] = useState(false)

  // Title State
  const [openModalBusiness, setOpenModalBusiness] = useState(false)
  const [BusinessName, setBusinessName] = useState()
  const [isDisabled, setIsDisabled] = useState(true)

  // Address State
  const [Address, SetAddress] = useState()
  const [Locality, SetLocality] = useState()
  const [administrativeArea, setadministrativeArea] = useState()
  const [LanguageCode, setLanguageCode] = useState()
  const [PostalCode, SetPostalCode] = useState()
  const [Region, SetRegion] = useState()

  // Contact Number State

  // Category State
  // const [Categories, SetCategories] = useState();
  const [primaryCategory, setprimaryCategory] = useState()
  const [additionalCategories, setAdditionalCategories] = useState([])
  const [showDropdown, setShowDropdown] = useState(false)
  const [newAdditional, setNewAdditional] = useState([])
  const [ShowDropdown1, setShowDropdown1] = useState(false)
  const [staticCategories, setStaticCategories] = useState([]) // setStaticCategories]

  const [workingHr, setworkingHr] = useState()
  const [workingHr1, setworkingHr1] = useState()
  const [websiteUrl, SetWebsiteUrl] = useState()
  const [Description, SetDescription] = useState()
  const [uniqueOpening, setUniqueOpening] = useState([])

  const [primaryPhone, setPrimaryPhone] = useState('')
  const [AlternateNumber, SetAlternateNumber] = useState()

  const [load, setload] = useState(true)

  const [specialHr, setSpecialHr] = useState([])

  // console.log(specialHr, 'specialHr')

  const getSingleLocation = async () => {
    try {
      const body = {
        userId: userInfo.info?.userId,
        groupId: groupId?.groupId
      }
      const url = `${API_URL}/location/${Locationid}`
      const res = await httpservice.post(url, body)
      if (res.status === 200) {
        // setOverViewDetails(res?.data?.data);
        setload(false)
        localStorage.setItem('refreshTokenInError', false)
        return { data: res.data.data }
      } else {
        setload(false)
      }
      // console.log(res, 'res')
    } catch (error) {
      setload(false)
      localStorage.setItem('refreshTokenInError', true)
      // console.log(error, 'console.error();')
    }
  }

  const handleBusinessTitle = Title => {
    setOpenModalBusiness(true)
    // setIsDisabled(false)
    setBusinessName(Title)
  }

  const handleDescription = description => {
    SetDescription(description)
    setOpenModalDescription(true)
  }

  const handleAddress = Address => {
    SetAddress(Address?.addressLines)
    setadministrativeArea(Address?.administrativeArea)
    setLanguageCode(Address?.languageCode)
    SetLocality(Address?.locality)
    SetPostalCode(Address?.postalCode)
    SetRegion(Address?.regionCode)
    setOpenModalAddress(true)
    // SetDescription(description)
    // console.log(Address, 'Address', Address?.addressLines.length)
    // setOpenModalDescription(true)
  }

  const handleCategories = Categories => {
    // console.log(Categories?.additionalCategories, 'additionalCategoriesArray')
    const additionalCategoriesArray =
      Categories?.additionalCategories?.map(item1 => item1) || []
    // console.log(additionalCategoriesArray, 'additionalCategoriesArray')
    setAdditionalCategories(Categories?.additionalCategories)
    setStaticCategories(Categories?.additionalCategories)
    setNewAdditional(Categories?.additionalCategories)
    setprimaryCategory(Categories.primaryCategory)
    setOpenModalCategories(true)
  }

  const handlePhoneNumber = number => {
    setOpenModalNumber(true)
    // console.log(number, 'AlternateNumber')
    setPrimaryPhone(number?.primaryPhone)
    SetAlternateNumber(number)
  }

  const handleWebsite = websiteurl => {
    SetWebsiteUrl(websiteurl)
    setOpenModalWebsiteLink(true)
  }

  const today = new Date()

  const initialDateTime = {
    startDate: {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate()
    },
    openTime: {
      hours: '',
      minutes: ''
    },
    endDate: {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate()
    },
    closeTime: {
      hours: '',
      minutes: ''
    },
    showSelect: true
  }

  const [dateTimes, setDateTimes] = useState()
  // console.log(dateTimes, 'dateTimes12345', initialDateTime)
  const [showInputs, setShowInputs] = useState(false)

  const generateTimeOptions = () => {
    const options = []
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = `${(hour < 10 ? '0' : '') + hour}:${
          (minute < 10 ? '0' : '') + minute
        }`
        options.push(formattedHour)
      }
    }
    options.push('24:00')
    return options
  }

  const timeOptions = generateTimeOptions()

  // console.log(dateTimes, 'dateTimes')

  const updatedDateTimes = dateTimes?.map(dateTime => {
    if (dateTime.showSelect === false) {
      const { openTime, closeTime, showSelect, ...updatedDateTime } = dateTime
      return { ...updatedDateTime, closed: true }
    } else {
      const { showSelect, ...updatedDateTime } = dateTime
      return updatedDateTime
    }
  })

  const handleSpecialHours = overview => {
    setOpenModalSpecialHours(true)
    setSpecialHr(overview)
  }

  function formatTime (hours, minutes) {
    const meridiem = hours >= 12 ? 'PM' : 'AM'
    const hour = hours % 12 === 0 ? 12 : hours % 12
    const minute =
      minutes !== undefined ? String(minutes).padStart(2, '0') : '00'

    return `${String(hour)}:${minute} ${meridiem}`
  }

  // console.log(updatedDateTimes, 'specialHr')

  const handleworkinghrPopUp = item => {
    // console.log(
    //   days.map(day => item?.find(item => item.openDay === day)),
    //   'workingHr123456'
    // )
    // console.log(item, 'item')
    setworkingHr(days.map(day => item?.find(item => item?.openDay === day)))
    setworkingHr1(item)
    setDayStatus(days?.map(day => item?.some(item => item?.openDay === day)))
    setOpenModalRegularHour(true)
  }

  const days = [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY'
  ]
  const [dayStatus, setDayStatus] = useState([])
  const [initialSelectedTimes, setInitialSelectedTimes] = useState([])
  const newDayStatus = days.map(day =>
    data?.data?.details?.regularHours?.periods.some(
      item => item?.openDay === day
    )
  )
  // useEffect(() => {
  //   // const newDayStatus =

  //   setInitialSelectedTimes(newDayStatus);
  // }, [overViewDetails]);

  // console.log('sunday', workingHr)

  const handleCheckboxChange = index => {
    setInitialSelectedTimes(prevTimes => {
      const updatedTimes = [...prevTimes]
      updatedTimes[index] = !updatedTimes[index]
      return updatedTimes
    })
    setworkingHr(prevWorkingHr => {
      return prevWorkingHr?.filter(item => item?.openDay !== days[index])
    })
    // console.log(dayStatus[index] === false)
    const updatedDayStatus = [...dayStatus]
    updatedDayStatus[index] = false
    // console.log(updatedDayStatus, 'updatedDayStatus')
    setDayStatus(updatedDayStatus)
    handleChangeSelection1(index, initialSelectedTimes[index])
  }

  const handleChangeSelection1 = (index, boolean) => {
    // console.log(index, boolean, workingHr1, 'workingHr1')
    if (boolean) {
      setworkingHr1(prevWorkingHr => {
        return prevWorkingHr.filter(item => item.openDay !== days[index])
      })
    }
  }

  // console.log(data?.data, 'data')
  useEffect(() => {
    if (data) {
      setload(false)
    }
  }, [data])

  useEffect(() => {
    setUniqueOpening(
      data?.data?.details?.regularHours?.periods?.map((item, index, array) => {
        if (item?.openDay) {
          const currentIndex = array.findIndex(
            el => el.openDay === item.openDay
          )
          if (currentIndex !== index) {
            return { ...item, openDay: null }
          }
        }
        return item
      })
    )

    setDayStatus(
      days?.map(day =>
        data?.data?.details?.regularHours?.periods.some(
          item => item?.openDay === day
        )
      )
    )

    const newDayStatus = days.map(day =>
      data?.data?.details?.regularHours?.periods.some(
        item => item?.openDay === day
      )
    )

    setInitialSelectedTimes(newDayStatus)
  }, [data])

  // console.log(dayStatus, 'uniqueOpeningHours')

  const status = localStorage.getItem('refreshTokenInError')

  const config = {
    fullColor: '#FEA500',
    size: 17,
    showText: false
  }

  // console.log(specialHr, 'specialhr')

  return (
    <>
      {load && <Loader />}
      {status === 'false' ? (
        <div className=' md:px-4 py-2 ms-4 me-4 '>
          <div className='border-1 border-overView rounded-3xl mb-3 bg-white'>
            <div className='ps-3 pt-4 pb-4 flex justify-between'>
              <div>
                <h1 className='text-xl font-medium text-lightBlack mb-2'>
                  Business Name
                </h1>
                <h2 className='text-overviewDetails text-xl'>
                  {data?.data?.details?.title}
                </h2>
                {data?.data?.details?.storeCode && (
                  <>
                    <h1 className='text-lg font-medium text-lightBlack mt-1 mb-1'>
                      Store Code
                    </h1>
                    <h2 className='text-overviewDetails text-md'>
                      {data?.data?.details?.storeCode}
                    </h2>
                  </>
                )}
                <div className='flex gap-3 items-center mt-2 mb-2'>
                  <img
                    className='w-6'
                    src='https://pro-manage.s3.ap-south-1.amazonaws.com/GoogleLogo2.svg'
                    alt='Google'
                  />
                  <h2 className=' '>
                    <span className='text-amber-500 text-lg fw-semibold flex justify-center items-center gap-0'>
                      {' '}
                      <span className='mt-1 me-1'>
                        {' '}
                        {data?.data?.rating ? data?.data?.rating : 0}
                      </span>{' '}
                      <span>
                        <StarRating
                          rating={data?.data?.rating ? data?.data?.rating : 0}
                          style={{ marginBottom: '0px' }}
                          config={config}
                        />
                      </span>{' '}
                    </span>
                  </h2>
                </div>
                <h4 className='text-lightBlack text-lg font-medium'>
                  Reviews : <span>{data?.data?.totalRating}</span>
                  <span className='font-normal text-overviewDetails text-base'>
                    {' '}
                    {reviews?.totalReviewCount}
                  </span>
                </h4>
              </div>
              {userInfo?.info?.accessTypeDetails?.accessTypeId === 1 ? (
                ''
              ) : (
                <div className='pe-4 my-auto'>
                  <img
                    src='https://pro-manage.s3.ap-south-1.amazonaws.com/OverViewEdit.svg'
                    alt='editIcon'
                    className='img-fluid cursor-pointer hover:opacity-75'
                    onClick={() =>
                      handleBusinessTitle(data?.data?.details?.title)
                    }
                  />
                </div>
              )}
            </div>

            <hr className='text-violet-300' />

            <div className='ps-3 pt-2 mb-3 flex justify-between'>
              <div>
                <h1 className='text-xl font-medium text-lightBlack mb-2'>
                  Address Name
                </h1>
                <p className='text-businessProfile text-base'>
                  {data?.data?.details?.storefrontAddress?.addressLines?.map(
                    (item, index) => (
                      <p key={index}>{item}</p>
                    )
                  )}
                  {data?.data?.details?.storefrontAddress?.locality},{' '}
                  {data?.data?.details?.storefrontAddress?.administrativeArea},{' '}
                  {data?.data?.details?.storefrontAddress?.postalCode}
                </p>
                <p className='text-businessProfile text-base'>
                  {' '}
                  {data?.data?.details?.latlng?.latitude
                    ? `Latitude : ${data?.data?.details?.latlng?.latitude}`
                    : ''}
                </p>
                <p className='text-businessProfile text-base'>
                  {data?.data?.details?.latlng?.longitude
                    ? `Longitude : ${data?.data?.details?.latlng?.longitude}`
                    : ''}
                </p>
              </div>

              {userInfo?.info?.accessTypeDetails?.accessTypeId === 1 ? (
                ''
              ) : (
                <div className='pe-4 my-auto'>
                  <img
                    src='https://pro-manage.s3.ap-south-1.amazonaws.com/OverViewEdit.svg'
                    alt='editIcon'
                    className='img-fluid cursor-pointer hover:opacity-75'
                    onClick={() =>
                      handleAddress(data?.data.details?.storefrontAddress)
                    }
                  />
                </div>
              )}
            </div>

            <hr className='text-violet-300' />

            <div className='ps-3 pt-2 mb-3 flex justify-between '>
              <div>
                <h1 className='text-xl font-medium text-lightBlack mb-2'>
                  Contact Number
                </h1>
                <h5 className='text-businessProfile text-base mb-2'>
                  Primary Number
                </h5>
                <p className='text-base text-listing mb-3'>
                  {data?.data?.details?.phoneNumbers?.primaryPhone}
                </p>
                <div className=''>
                  {data?.data?.details?.phoneNumbers?.additionalPhones && (
                    <>
                      <p className='text-businessProfile text-base mb-2'>
                        Additional Number
                      </p>
                      {data?.data?.details?.phoneNumbers?.additionalPhones?.map(
                        item => (
                          <p className='text-base text-listing'>{item}</p>
                        )
                      )}
                    </>
                  )}
                </div>
              </div>

              {userInfo?.info?.accessTypeDetails?.accessTypeId === 1 ? (
                ''
              ) : (
                <div className='pe-4 my-auto'>
                  <img
                    src='https://pro-manage.s3.ap-south-1.amazonaws.com/OverViewEdit.svg'
                    alt='editIcon'
                    className='img-fluid cursor-pointer hover:opacity-75'
                    onClick={() =>
                      handlePhoneNumber(data?.data?.details?.phoneNumbers)
                    }
                  />
                </div>
              )}
              {/* <h6 onClick={() => handlePhoneNumber(data?.data?.details?.phoneNumbers)}>Edit</h6> */}
            </div>

            <hr className='text-violet-300' />

            <div className='ps-3 pt-2 mb-3 flex justify-between'>
              {data?.data?.details?.categories?.primaryCategory && (
                <>
                  <div>
                    <h1 className='text-xl font-medium text-lightBlack mb-2'>
                      Category
                    </h1>
                    <h2 className='text-businessProfile text-base mb-2'>
                      Primary Categories
                    </h2>
                    <p className='text-base text-listing mb-2'>
                      {
                        data?.data?.details?.categories?.primaryCategory
                          ?.displayName
                      }
                    </p>
                    {data?.data?.details?.categories?.additionalCategories && (
                      <>
                        <h2 className='text-businessProfile text-base mb-2'>
                          Additional Categories
                        </h2>
                        <p className='text-base text-listing'>
                          {data?.data?.details?.categories
                            ?.additionalCategories &&
                            data?.data?.details?.categories?.additionalCategories.map(
                              item => <p>{item.displayName}</p>
                            )}
                        </p>
                      </>
                    )}
                  </div>
                  {userInfo?.info?.accessTypeDetails?.accessTypeId === 1 ? (
                    ''
                  ) : (
                    <div className='pe-4 my-auto'>
                      <img
                        src='https://pro-manage.s3.ap-south-1.amazonaws.com/OverViewEdit.svg'
                        alt='editIcon'
                        className='img-fluid cursor-pointer hover:opacity-75'
                        onClick={() =>
                          handleCategories(data?.data?.details?.categories)
                        }
                      />
                    </div>
                  )}
                </>
              )}
            </div>

            <hr className='text-violet-300' />

            <div className='ps-3 pt-2 mb-3'>
              {
                <div className='flex justify-between '>
                  <div>
                    <h2 className='text-xl font-medium text-lightBlack mb-2'>
                      Working Hours{' '}
                    </h2>
                    <>
                      {days.map((day, index) =>
                        uniqueOpening?.map((item, i) => {
                          if (item?.closeDay === day) {
                            return (
                              <div key={i} className='text-justify flex'>
                                <p
                                  className={`${
                                    item?.openDay === 'SUNDAY'
                                      ? 'text-listing'
                                      : 'text-businessProfile'
                                  } text-base w-11 pb-2`}
                                >
                                  <span
                                    className={`${
                                      item?.openDay === null
                                        ? ' text-white ps-4 ms-2'
                                        : ''
                                    }`}
                                  >
                                    {item?.openDay?.substring(0, 3)}{' '}
                                  </span>
                                </p>
                                <p
                                  className={`${
                                    item?.openDay === 'SUNDAY'
                                      ? 'text-listing'
                                      : 'text-businessProfile'
                                  } text-base pb-2`}
                                >
                                  {moment({
                                    hour: item?.openTime?.hours,
                                    minute: item?.openTime?.minutes
                                  }).format('hh:mm A')}{' '}
                                  -{' '}
                                  {moment({
                                    hour: item?.closeTime?.hours,
                                    minute: item?.closeTime?.minutes
                                  }).format('hh:mm A')}
                                </p>
                              </div>
                            )
                          }
                          return null
                        })
                      )}
                      <p className='text-base text-businessProfile'>
                        {uniqueOpening == 'undefined' &&
                          'No working hours added'}
                      </p>
                    </>
                  </div>
                  {userInfo?.info?.accessTypeDetails?.accessTypeId === 1 ? (
                    ''
                  ) : (
                    <div className='pe-4 my-auto'>
                      <img
                        src='https://pro-manage.s3.ap-south-1.amazonaws.com/OverViewEdit.svg'
                        alt='editIcon'
                        className='img-fluid cursor-pointer hover:opacity-75'
                        onClick={() =>
                          handleworkinghrPopUp(
                            data?.data?.details?.regularHours?.periods
                          )
                        }
                      />
                    </div>
                  )}
                </div>
              }
            </div>

            <hr className='text-violet-300' />

            <div className='ps-3 pt-2 mb-3 flex justify-between'>
              <div>
                <h2 className='text-xl font-medium text-lightBlack mb-2'>
                  Your Website Url{' '}
                </h2>
                <p className='text-base text-listing'>
                  <Link to={data?.data?.details?.websiteUri} target='_blank'>
                    {data?.data?.details?.websiteUri}
                  </Link>
                </p>
              </div>
              {userInfo?.info?.accessTypeDetails?.accessTypeId === 1 ? (
                ''
              ) : (
                <div className='pe-4 my-auto'>
                  <img
                    src='https://pro-manage.s3.ap-south-1.amazonaws.com/OverViewEdit.svg'
                    alt='editIcon'
                    className='img-fluid cursor-pointer hover:opacity-75'
                    onClick={() =>
                      handleWebsite(data?.data?.details?.websiteUri)
                    }
                  />
                </div>
              )}
            </div>

            <hr className='text-violet-300' />

            <div className='ps-3 pt-2 mb-3 flex justify-between'>
              <div className='w-3/4'>
                <h3 className='text-xl font-medium text-lightBlack mb-2'>
                  {' '}
                  Description
                </h3>
                <p className='text-businessProfile text-base mb-2 '>
                  {data?.data?.details?.profile?.description
                    ? data?.data?.details?.profile?.description
                    : 'Add Description'}
                </p>
              </div>
              {userInfo?.info?.accessTypeDetails?.accessTypeId === 1 ? (
                ''
              ) : (
                <div className='pe-4 my-auto'>
                  <img
                    src='https://pro-manage.s3.ap-south-1.amazonaws.com/OverViewEdit.svg'
                    alt='editIcon'
                    className={`img-fluid cursor-pointer hover:opacity-75 `}
                    onClick={() =>
                      handleDescription(
                        data?.data?.details?.profile?.description
                      )
                    }
                  />
                </div>
              )}
            </div>

            <hr className='text-violet-300' />

            <div className='ps-3 pt-2 mb-3 flex justify-between'>
              <div>
                <h3 className='text-xl font-medium text-lightBlack mb-2'>
                  {' '}
                  Special Hours
                </h3>
                <p className='text-businessProfile text-base mb-2'>
                  {data?.data?.details?.specialHours?.specialHourPeriods
                    ? data?.data?.details?.specialHours?.specialHourPeriods.map(
                        item => (
                          <>
                            <p>
                              {item?.startDate?.day}/{item?.startDate?.month}/
                              {item?.startDate?.year}
                            </p>
                            {/* <p>{item?.endDate?.day}/{item?.endDate?.month}/{item?.endDate?.year}</p> */}
                            <p>
                              {' '}
                              {item?.openTime &&
                                formatTime(
                                  item.openTime.hours,
                                  item.openTime.minutes
                                )}
                              {item?.openTime ? ' - ' : ''}
                              {item?.closeTime &&
                                formatTime(
                                  item.closeTime.hours,
                                  item.closeTime.minutes
                                )}
                            </p>
                          </>
                        )
                      )
                    : 'Not added yet!'}
                </p>
              </div>
              {userInfo?.info?.accessTypeDetails?.accessTypeId === 1 ? (
                ''
              ) : (
                <div className='pe-4 my-auto'>
                  <img
                    src='https://pro-manage.s3.ap-south-1.amazonaws.com/OverViewEdit.svg'
                    alt='editIcon'
                    className='img-fluid cursor-pointer hover:opacity-75'
                    onClick={() =>
                      handleSpecialHours(
                        data?.data?.details?.specialHours?.specialHourPeriods
                      )
                    }
                  />
                </div>
              )}
            </div>
          </div>

          <BusinessTitleModal
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            BusinessName={BusinessName}
            setBusinessName={setBusinessName}
            openModalBusiness={openModalBusiness}
            setOpenModalBusiness={setOpenModalBusiness}
            invalidateAndRefetch={invalidateAndRefetch}
            handleLocationCountNotification={handleLocationCountNotification}
          />

          <AddressModal
            openModalAddress={openModalAddress}
            setOpenModalAddress={setOpenModalAddress}
            Address={Address}
            SetAddress={SetAddress}
            administrativeArea={administrativeArea}
            setadministrativeArea={setadministrativeArea}
            LanguageCode={LanguageCode}
            setLanguageCode={setLanguageCode}
            SetLocality={SetLocality}
            SetPostalCode={SetPostalCode}
            SetRegion={SetRegion}
            Locality={Locality}
            PostalCode={PostalCode}
            Region={Region}
            invalidateAndRefetch={invalidateAndRefetch}
            handleLocationCountNotification={handleLocationCountNotification}
          />

          <NumberModal
            AlternateNumber={AlternateNumber}
            SetAlternateNumber={SetAlternateNumber}
            primaryPhone={primaryPhone}
            setPrimaryPhone={setPrimaryPhone}
            openModalNumber={openModalNumber}
            setOpenModalNumber={setOpenModalNumber}
            invalidateAndRefetch={invalidateAndRefetch}
            handleLocationCountNotification={handleLocationCountNotification}
          />

          <DescriptionModal
            openModalDescription={openModalDescription}
            setOpenModalDescription={setOpenModalDescription}
            Description={Description}
            SetDescription={SetDescription}
            invalidateAndRefetch={invalidateAndRefetch}
            handleLocationCountNotification={handleLocationCountNotification}
          />

          <WebsiteModal
            openModalWebsiteLink={openModalWebsiteLink}
            setOpenModalWebsiteLink={setOpenModalWebsiteLink}
            websiteUrl={websiteUrl}
            SetWebsiteUrl={SetWebsiteUrl}
            invalidateAndRefetch={invalidateAndRefetch}
            handleLocationCountNotification={handleLocationCountNotification}
          />

          <CategoryModal
            setAdditionalCategories={setAdditionalCategories}
            additionalCategories={additionalCategories}
            primaryCategoryold={primaryCategory}
            setOpenModalCategories={setOpenModalCategories}
            openModalCategories={openModalCategories}
            setNewAdditional={setNewAdditional}
            newAdditional={newAdditional}
            setShowDropdown={setShowDropdown}
            showDropdown={showDropdown}
            setShowDropdown1={setShowDropdown1}
            ShowDropdown1={ShowDropdown1}
            invalidateAndRefetch={invalidateAndRefetch}
            staticCategories={staticCategories}
            handleLocationCountNotification={handleLocationCountNotification}
          />

          <RegularTimeModal
            openModalRegularHour={openModalRegularHour}
            setOpenModalRegularHour={setOpenModalRegularHour}
            newDayStatus={newDayStatus}
            setInitialSelectedTimes={setInitialSelectedTimes}
            days={days}
            dayStatus={dayStatus}
            handleCheckboxChange={handleCheckboxChange}
            initialSelectedTimes={initialSelectedTimes}
            timeOptions={timeOptions}
            workingHr={workingHr}
            workingHr1={workingHr1}
            setworkingHr1={setworkingHr1}
            invalidateAndRefetch={invalidateAndRefetch}
            handleLocationCountNotification={handleLocationCountNotification}
          />

          <SpecialHourModal
            OpenModalSpecialHours={OpenModalSpecialHours}
            setOpenModalSpecialHours={setOpenModalSpecialHours}
            setDateTimes={setDateTimes}
            setSpecialHr={setSpecialHr}
            specialHr={specialHr}
            timeOptions={timeOptions}
            showInputs={showInputs}
            dateTimes={dateTimes}
            setShowInputs={setShowInputs}
            updatedDateTimes={updatedDateTimes}
            invalidateAndRefetch={invalidateAndRefetch}
            initialDateTime={initialDateTime}
            handleLocationCountNotification={handleLocationCountNotification}
          />
        </div>
      ) : (
        <h1 className='text-center   fw-semibold flex justify-center'>
          <img
            src='https://pro-manage.s3.ap-south-1.amazonaws.com/NoImgMeans.svg'
            alt='noData'
            className='img-fluid'
          />
        </h1>
      )}
    </>
  )
}

export default BusinessOverView
