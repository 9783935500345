export const employee = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3364 23.4241L21.6802 4.08008" stroke="#323B4B" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.3363 23.4249L4.43252 23.2809C3.63031 23.2809 3 22.7529 3 22.0809V2.16094C3 1.48894 3.63031 0.960938 4.43252 0.960938H17.8982" stroke="#323B4B" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.6798 4.08177H17.8979V1.00977L21.6798 4.08177Z" stroke="#323B4B" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.06299 5.56884L5.8079 6.19284L7.81343 4.46484" stroke="#323B4B" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.87598 4.22461H16.1791" stroke="#323B4B" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.87598 5.95312H16.1791" stroke="#323B4B" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.06299 9.16845L5.8079 9.74445L7.81343 8.06445" stroke="#323B4B" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.87598 7.82422H16.1791" stroke="#323B4B" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.87598 9.50586H16.1791" stroke="#323B4B" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.06299 12.7681L5.8079 13.3441L7.81343 11.6641" stroke="#323B4B" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.87598 11.4258H16.1791" stroke="#323B4B" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.87598 13.1055H16.1791" stroke="#323B4B" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.06299 16.3208L5.8079 16.9448L7.81343 15.2168" stroke="#323B4B" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.87598 14.9766H16.1791" stroke="#323B4B" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.87598 16.7051H14.46" stroke="#323B4B" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.06299 19.9204L5.8079 20.4964L7.81343 18.8164" stroke="#323B4B" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.87598 18.5762H14.46" stroke="#323B4B" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.87598 20.2578H14.46" stroke="#323B4B" stroke-width="0.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
