import { useEffect, useRef, useState } from 'react';

function useDebounce(value, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const handler = () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
    };

    handler();

    return () => clearTimeout(timeoutRef.current);
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;
