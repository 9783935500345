import moment from 'moment'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const InsightQuestions = ({ allResponse, userInfo }) => {
  // console.log(allResponse, 'leading-4')
  const navigate = useNavigate()
  return (
    <div>
      <div className='mt-5'>
        <p
          className={`text-xl font-semibold mb-3 ${
            allResponse?.data?.data === 0 ? 'hidden' : ''
          }`}
        >
          Question And Answer
        </p>

        {allResponse?.data?.data?.length > 0 &&
          allResponse?.data?.data?.map((item, index) => (
            <>
              <div className={`rounded-2xl shadow-filter1 p-4 mb-3`}>
                <div className='flex justify-between'>
                  <div className='flex flex-col'>
                    <p className='  text-lg mb-2 font-medium text-ViewActivity'>
                      Business : {item?.title}
                    </p>
                    <p className='text-sm text-businessProfile'>
                      {item.address}
                    </p>
                  </div>
                  <div className='flex flex-col'>
                    <p className='text-sm font-normal text-lightBlack'>
                      {moment
                        .utc(item?.details?.createTime)
                        .tz('Asia/Calcutta')
                        .format('DD MMM YYYY')}
                    </p>
                    <p className='text-sm font-normal text-lightBlack'>
                      {moment
                        .utc(item?.details?.createTime)
                        .tz('Asia/Calcutta')
                        .format('hh:mm A')}
                    </p>
                  </div>
                </div>
                <div className='flex items-center gap-3 pt-2'>
                  <img
                    src={
                      item?.details?.author?.type === 'MERCHANT'
                        ? ''
                        : (() => {
                            const profilePhotoUri =
                              item?.details?.author?.profilePhotoUri
                            if (
                              profilePhotoUri &&
                              !profilePhotoUri?.startsWith('https:') &&
                              !profilePhotoUri?.startsWith('http:')
                            ) {
                              return `https:${profilePhotoUri}`
                            }
                            return profilePhotoUri
                          })()
                    }
                    width={45}
                    height={45}
                    alt='no img'
                  />
                  <div className='flex flex-col'>
                    <div className='flex gap-3'>
                      <p className='text-lg font-medium'>
                        {item?.details?.author?.displayName}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='flex justify-between'>
                  <h4 className='text-md font-medium pt-2 col-9'>
                    Question:
                    <span className='text-listing ps-2 leading-6'>
                      {item?.details?.text}
                    </span>
                  </h4>
                  {/* {item?.details?.author?.type === 'MERCHANT' &&
                item?.details?.author?.type !== 'REGULAR_USER' && (
                  <div className='flex gap-3 justify-between items-center'>
                    <img
                      src='https://pro-manage.s3.ap-south-1.amazonaws.com/PostMedia.svg'
                      className='cursor-pointer w-8 hover:opacity-80'
                      alt='Profilepost'
                      // onClick={() => {
                      //   handleDeleteModal('Question')
                      //   setquesName(item?.details?.name)
                      // }}
                    />
                    <img
                      src='https://pro-manage.s3.ap-south-1.amazonaws.com/editMedia.svg'
                      className='cursor-pointer w-8 hover:opacity-80'
                      alt='Profilepost'
                      // onClick={() => {
                      //   handelEditQuestion(item)
                      //   setquesName(item?.details?.name)
                      // }}
                    />
                  </div>
                )} */}
                </div>

                {/* {item?.details?.topAnswers &&
                item.details.topAnswers.length > 0 ? (
                  <div className='pt-3'>
                    <hr className='pt-2 pb-2' />
                    <div className='flex justify-between items-center pb-2'>
                      <div className='flex gap-3'>
                        <img
                          src={
                            item.details.topAnswers[0]?.author?.type ===
                            'MERCHANT'
                              ? ''
                              : item.details.topAnswers[0]?.author
                                  ?.profilePhotoUri
                          }
                          alt='profileImg'
                        />
                        <p className='text-lg font-medium text-black'>
                          {item.details.topAnswers[0]?.author?.displayName}
                        </p>
                      </div>
                      <div className='flex flex-col'>
                        <p className='text-sm font-normal text-lightBlack'>
                          {moment
                            .utc(item.details.topAnswers[0]?.createTime)
                            .tz('Asia/Calcutta')
                            .format('DD MMM YYYY')}
                        </p>
                        <p className='text-sm font-normal text-lightBlack'>
                          {moment
                            .utc(item.details.topAnswers[0]?.createTime)
                            .tz('Asia/Calcutta')
                            .format('hh:mm A')}
                        </p>
                      </div>
                    </div>
                    <div className='flex justify-between items-center'>
                      <p className='text-md font-medium text-black col-9'>
                        Answer :{' '}
                        <span className='text-businessProfile'>
                          {item.details.topAnswers[0]?.text}
                        </span>
                      </p>
                    </div>
                    <p
                      className='  text-md font-medium   text-lg text-listing  pt-2.5 cursor-pointer'
                      onClick={() => navigate('/questions')}
                    >
                      Read More ...
                    </p>
                  </div>
                ) : (
                  <div
                    className='  text-md font-medium   text-lg text-listing  pt-2.5 cursor-pointer  rounded-lg w-28 '
                    onClick={() => {
                      navigate('/questions')
                    }}
                  >
                    <p className=''>Answer</p>
                  </div>
                )} */}
              </div>

              {/* <hr className='mt-3 mb-2' /> */}
            </>
          ))}
        <p
          className={`font-semibold text-listing text-center pt-3 ${
            allResponse?.length === 0 ? 'hidden' : ''
          }`}
        >
          {userInfo &&
            userInfo?.userAccess?.map(
              item =>
                item?.accessId === 5 &&
                (item?.isAcccess ? (
                  <Link
                    to='/questions'
                    className='cursor-pointer'
                    key={item?.accessName}
                  >
                    View All Questions And Answer
                  </Link>
                ) : (
                  <span
                    className='cursor-pointer'
                    onClick={() =>
                      toast.error("You don't have access Contact ADMIN")
                    }
                    key={item.accessName}
                  >
                    View All Questions And Answer
                  </span>
                ))
            )}
        </p>

        {allResponse?.length === 0 && (
          <div className='bg-oldDate rounded-2xl ps-6 pe-6 pt-3 pb-3'>
            {/* <p className='text-xl font-semibold mb-3 pt-3 pb-3'>
              Question And Answer
            </p> */}
            <div className='flex flex-col justify-center items-center'>
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/Layer_1.svg'
                alt='noinfo'
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default InsightQuestions
