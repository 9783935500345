import React, { useContext, useEffect, useState } from 'react'
import { LocationDetails } from '../Store/LocationInfo'
import { AccountDetails } from '../Store/AccountInfo'
import httpservice from '../Service/httpservice'
import { API_URL } from '../env/env'
import Loader from '../loader/loader'
import { useQuery } from '@tanstack/react-query'
import { AuthContext } from '../context/AuthContext'
const BusinessService = () => {
  const { userInfo, groupId } = useContext(AuthContext)
  const Locationid = LocationDetails(state => state.LocationDetails)

  const {
    tokenInfo,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleQuestionCountNotification,
    handleReviewNotification,
    handleLocationNotification
  } = useContext(AuthContext)

  useEffect(() => {
    tokenInfo()
  }, [])

  useEffect(() => {
    if (userInfo?.info?.roleDetails?.roleId === 1) {
      handleLocationCountNotification(true)
      handleReviewCountNotification(true)
      handleQuestionCountNotification(true)
      // handleReviewNotification(true)
      // handleLocationNotification(true)
    }
  }, [groupId, userInfo])

  const [serviceState, setServiceState] = useState(false)

  const [load, setload] = useState(true)
  const { data: serviceDetails, isPending } = useQuery({
    queryFn: () => getSingleLocation(),
    queryKey: ['getSingleLocation', { Locationid, serviceState }],
    staleTime: 1000 * 3600,
    cacheTime: 3600
  })
  const getSingleLocation = async () => {
    try {
      const body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId
      }
      const url = `${API_URL}/location/${Locationid}`
      const res = await httpservice.post(url, body)
      if (res.status === 200) {
        // console.log(res, 'res')
        // setServiceDetails(res?.data?.data)
        setload(false)
        return { data: res?.data?.data }
      }
      // console.log(res, 'res')
    } catch (error) {
      setServiceState(true)
      // console.log(error)
    }
  }
  // console.log(serviceDetails, 'serviceCount')
  useEffect(() => {
    if (serviceDetails) {
      setload(false)
    }
    if (serviceState) {
      setload(false)
    }
  }, [serviceDetails, serviceState])
  // console.log(serviceDetails?.data?.details, 'serviceDetails')
  return (
    <div className='mb-6 '>
      {/* {services && services?.length}
            {services?.map((item) => (
                <div>
                    <h1>{item?.displayName}</h1>
                </div>
            ))} */}

      {load && <Loader />}
      <div className='continer-md px-12 mt-4'>
        <p className='text-xl font-medium text-lightBlack'>
          {serviceDetails?.data?.details?.categories?.primaryCategory
            ?.displayName ? (
            <>
              {
                serviceDetails?.data?.details?.categories?.primaryCategory
                  ?.displayName
              }
              <span className='font-normal text-sm'> (Primary Category)</span>
            </>
          ) : (
            <h1 className='text-center   fw-semibold flex justify-center'>
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/NoImgMeans.svg'
                alt='noData'
                className='img-fluid'
              />
            </h1>
          )}
        </p>
        <div className=' flex flex-wrap gap-3 mt-3 col-8'>
          {serviceDetails?.data?.details?.categories?.primaryCategory?.serviceTypes?.map(
            (item, index) => (
              <p className='p-2 border-1 border-listing rounded-lg  text-listing'>
                {item?.displayName}
              </p>
            )
          )}
        </div>
      </div>

      {serviceDetails?.data?.details.categories?.additionalCategories &&
        serviceDetails?.data?.details.categories?.additionalCategories.map(
          (item, index) => (
            <div className='continer-md px-12 mt-4'>
              <p className='text-xl font-medium text-lightBlack'>
                {item?.displayName}
                <span className='font-normal text-sm'>
                  {' '}
                  (Additional Category)
                </span>
              </p>
              <div className=' flex flex-wrap gap-3 mt-3 col-8'>
                {item.serviceTypes?.map((service, idx) => (
                  <p className='p-2 border-1 border-listing rounded-lg  text-listing'>
                    {service?.displayName}
                  </p>
                ))}
              </div>
            </div>
          )
        )}

      {serviceDetails?.data?.details?.categories?.additionalCategories
        ?.length === 0 &&
        serviceDetails?.data?.details?.categories?.primaryCategory?.length ===
          0 && (
          <h1 className='text-center   fw-semibold flex justify-center'>
            <img
              src='https://pro-manage.s3.ap-south-1.amazonaws.com/NoImgMeans.svg'
              alt='noData'
              className='img-fluid'
            />
          </h1>
        )}
    </div>
  )
}
export default BusinessService
