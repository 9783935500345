import React, { useContext, useEffect, useState } from 'react'
import httpservice from '../Service/httpservice'
import { AccountDetails } from '../Store/AccountInfo'
import { setLocationData } from '../Store/LocationInfo'
import { Link } from 'react-router-dom'
import Counts from '../Components/Counts'
import Calender from '../Components/ReviewCalender'
import { API_URL } from '../env/env'
import useDebounce from '../hooks/useDebounce'
import Loader from '../loader/loader'
import { useQuery } from '@tanstack/react-query'
import { AuthContext } from '../context/AuthContext'
import { Empty } from 'antd'
import { UserInfo, setUserInfo } from '../Store/UserInfo'

const Listings = () => {
  const {
    userInfo,
    groupId,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleQuestionCountNotification,
    handleReviewNotification,
    handleLocationNotification
  } = useContext(AuthContext)

  const [counterValue, setCounterValue] = useState('')
  const [Info, SetInfo] = useState([])
  const [searchParam, setSearchParams] = useState('')
  const [businessStatus, setbusinessStatus] = useState('')
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const debounceSearchValue = useDebounce(searchParam, 500)
  const [load, setload] = useState(true)

  const { tokenInfo } = useContext(AuthContext)

  useEffect(() => {
    tokenInfo()
  }, [])

  useEffect(() => {
    if (userInfo?.info?.roleDetails?.roleId === 1) {
      handleLocationCountNotification(true)
      handleReviewCountNotification(true)
      handleQuestionCountNotification(true)
    }
    // handleReviewNotification(true)
    // handleLocationNotification(true)
  }, [groupId, userInfo])

  let groupValue = localStorage.getItem('GroupChange')

  useEffect(() => {
    if (groupValue === 'true') {
      // console.log('group', groupValue)
      setPage(1)
      localStorage.setItem('GroupChange', 'false')
    }
  }, [groupValue, page])

  const { data: businessInfo, isPending } = useQuery({
    queryFn: () => listing(),
    queryKey: [
      'listing',
      debounceSearchValue,
      businessStatus,
      pageSize,
      page,
      groupId,
      groupValue === 'true'
    ],
    staleTime: 1000 * 3600,
    enabled: groupValue !== 'true',
    cacheTime: 600000
  })

  const { data: countInfo } = useQuery({
    queryFn: () => count(),
    queryKey: ['count', groupId],
    staleTime: 1000 * 3600,
    cacheTime: 3600
  })

  const count = async () => {
    const body = {
      userId: userInfo?.info?.userId,
      groupId: groupId?.groupId
    }
    const url = `${API_URL}/location/count`
    try {
      const res = await httpservice.post(url, body)
      // console.log(res?.data?.data, 'res')
      if (res.status === 200) {
        // console.log(res, 'infoPge')
        // setCounterValue(res?.data?.data)
        return { data: res?.data?.data }
      }
    } catch (error) {
      return { data: '' }
    }
  }

  const listing = async () => {
    const body = {
      userId: userInfo?.info?.userId,
      groupId: groupId?.groupId,
      page: page,
      pageSize: pageSize,
      searchText: searchParam,
      businessStatus: businessStatus
    }

    const url = `${API_URL}/location`
    try {
      const res = await httpservice.post(url, body)
      // console.log(res?.status, 'res')
      if (res?.status === 200) {
        // SetInfo(res?.data?.data)
        // setTotal(res?.data?.totalPages)
        const data = res.data.data ? res.data.data : null
        const totalCount = res.data.totalPages ? res.data.totalPages : null

        // console.log(totalCount, 'infoPge')

        const details = {
          totalCount: totalCount,
          data: data
        }
        return { data: details }
      } else {
        // SetInfo("")
        // console.log(res, 'infoPge')
        return { data: '' }
      }
    } catch (error) {
      // SetInfo("")
      // console.log(error, 'res')
      return { data: '' }
    }
  }

  const HandleLocationId = locationId => {
    setLocationData(locationId)
  }

  const handleSearch = searchText => {
    // console.log(searchText, 'searchText')
    setSearchParams(searchText.toLowerCase())
    setPage(1)
  }

  const EmptyWithShadow = ({ condition }) => (
    <div
      className={`${condition ? '' : 'hidden'}`}
      style={{
        boxShadow: condition ? '0px 2px 5px rgba(0, 0, 0, 0.1)' : 'none',
        borderRadius: condition ? '0 0 10px 10px' : '0'
      }}
    >
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className='mt-0 pt-5' />
    </div>
  )
  // console.log(isPending, 'ispending', load)
  useEffect(() => {
    if (isPending) {
      // debugger
      setload(false)
    }
  }, [isPending])
  return (
    <>
      {isPending && load && <Loader />}
      <Calender Title={'Listings'} />
      <Counts
        Count1={countInfo?.data?.verifyCount}
        Count2={countInfo?.data?.notVerifyCount}
        Count3={countInfo?.data?.duplicateCount}
        heading1={'Verified'}
        heading2={'Not Verified'}
        heading3={'Duplicate'}
        Image1={'https://pro-manage.s3.ap-south-1.amazonaws.com/Verified.svg'}
        Image2={
          'https://pro-manage.s3.ap-south-1.amazonaws.com/VerifiedRequired.svg'
        }
        Image3={'https://pro-manage.s3.ap-south-1.amazonaws.com/Duplicate.svg'}
        image4={'https://pro-manage.s3.ap-south-1.amazonaws.com/Total.svg'}
        total={true}
      />
      <div className='bg-custom-background4 m-4 rounded-2xl'>
        <div className='row  md:ms-4 md:me-5 pt-5 flex justify-between items-center'>
          <div className='col-lg-3 col-md-4 col-6'>
            <div className='relative'>
              <div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
                <svg
                  className='w-4 h-4 text-gray-500 dark:text-gray-400'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 20 20'
                >
                  <path
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
                  />
                </svg>
              </div>
              <input
                type='search'
                id='default-search'
                className='rounded-lg w-100 lowercase  p-2.5 ps-10 bg-bgSearch serch_btn text-sm  '
                placeholder='search business & address'
                value={searchParam}
                onChange={e => handleSearch(e.target.value)}
                required
              />
            </div>
          </div>
          <div className='col-lg-5 flex justify-end items-center gap-3'>
            <div className='col-lg-3 col-4'>
              <select
                id='businessStatus'
                className='bg-white border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                onChange={e => {
                  setbusinessStatus(e?.target?.value)
                  setPage(1)
                }}
              >
                {/* <option selected>Choose a country</option> */}
                <option selected value='All'>
                  All
                </option>
                <option value='VERIFIED'>Verified</option>
                <option value='NOT VERIFIED'>NotVerified</option>
                <option value='DUPLICATE'>Duplicate</option>
              </select>
            </div>
            <div className='w-40 col-md-4 col-6'>
              <select
                id='pageSize'
                className='bg-white border  border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                onChange={e => {
                  setPageSize(e?.target?.value)
                  setPage(1)
                }}
              >
                <option selected value='10'>
                  Show 10 Entries
                </option>
                <option value='25'>Show 25 Entries </option>
                <option value='50'>Show 50 Entries</option>
                <option value='100'>Show 100 Entries</option>
              </select>
            </div>
          </div>
        </div>

        <div className='container md:px-8 py-8'>
          <div className='relative overflow-x-auto   rounded-lg '>
            <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 rounded-lg '>
              <thead className='text-xs text-black capitalize bg- dark:bg-gray-700 dark:text-gray-400 bg-custom-background3 '>
                <tr className=''>
                  <th
                    scope='col'
                    className='px-2 py-2 font-medium text-lg text-left  '
                  >
                    Business ID
                  </th>
                  <th
                    scope='col'
                    className='px-2 py-2 font-medium text-lg text-left     text-wrap'
                  >
                    Business Profile
                  </th>
                  <th
                    scope='col'
                    className='px-2 py-2 font-medium text-lg text-left   '
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody
                className={`border-[0.1px] border-listingTableBorder border-t-0`}
              >
                {businessInfo?.data?.data &&
                  businessInfo?.data?.data.map((item, index) => (
                    <tr
                      className={`  ${
                        index % 2 === 0
                          ? 'bg-white  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600'
                          : 'bg-custom-background5  dark:hover:bg-gray-600 dark:bg-gray-800 dark:border-gray-700'
                      }`}
                      onClick={() => HandleLocationId(item?.locationId)}
                    >
                      <th
                        scope='row'
                        className='px-2 py-2 font-medium text-start ps-3 text-gray-600 whitespace-nowrap dark:text-white   '
                      >
                        {item?.details?.storeCode}
                      </th>
                      <td className='px-2 py-3 col-7   '>
                        <Link className=' ' to={`/businessprofile/overview`}>
                          <p className='text-listing font-medium  '>
                            {item?.details?.title}
                          </p>
                        </Link>
                        <div key={index}>
                          {item?.details?.storefrontAddress?.addressLines
                            ?.length > 2 ? (
                            <p className='col-7'>
                              {item?.details?.storefrontAddress.addressLines.join(
                                ', '
                              )}
                            </p>
                          ) : (
                            item?.details?.storefrontAddress?.addressLines?.map(
                              (address, addressIndex) => (
                                <p key={addressIndex} className='col-8'>
                                  {address}
                                </p>
                              )
                            )
                          )}
                        </div>

                        <p>
                          {item?.details?.storefrontAddress?.locality}
                          <span className='ps-1 pe-1'>
                            {item?.details?.storefrontAddress?.postalCode}
                          </span>{' '}
                          <span className='ps-1 pe-1'>
                            {
                              item?.details?.storefrontAddress
                                ?.administrativeArea
                            }{' '}
                          </span>
                        </p>
                        {item?.details?.storeCode && (
                          <p>
                            Google StoreCode :
                            <span className='font-semibold'>
                              {' '}
                              {item?.details?.storeCode}
                            </span>
                          </p>
                        )}
                      </td>
                      <td className={`px-3 py-2`}>
                        <div
                          className={`flex justify-center gap-2 capitalize mx-auto my-auto cursor-default flex-wrap ${
                            item?.googleStatus === 'VERIFIED'
                              ? 'bg-Compelte-gradient text-white rounded-lg p-2.5 text-xs text-center  col-10'
                              : item?.googleStatus === 'NOT VERIFIED'
                              ? 'bg-processing-gradient rounded-lg text-white p-2.5 text-xs text-center  col-10'
                              : 'bg-Duplicate text-white rounded-md p-2.5 text-xs text-center  col-10'
                          }`}
                        >
                          <img
                            src={`${
                              item?.googleStatus === 'NOT VERIFIED'
                                ? 'https://pro-manage.s3.ap-south-1.amazonaws.com/processingImg.svg'
                                : item?.googleStatus === 'VERIFIED'
                                ? 'https://pro-manage.s3.ap-south-1.amazonaws.com/completedImg.svg'
                                : 'https://pro-manage.s3.ap-south-1.amazonaws.com/duplicate.svg'
                            }`}
                          />
                          {
                            <p className='capitalize mb-0 flex '>
                              {item?.googleStatus}
                            </p>
                          }
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {businessInfo?.data?.data?.length > 0 ? (
              <div className='mt-4 mb-4 sm:flex items-center justify-end'>
                <div className='text-sm text-gray-500 w-28'>
                  Page{' '}
                  <span className='font-medium text-gray-700'>
                    {page} of {businessInfo?.data?.totalCount}
                  </span>
                </div>

                <div className='flex items-center gap-x-4 sm:mt-0'>
                  {page >= 2 ? (
                    <Link
                      className='flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100'
                      onClick={() => page !== 1 && setPage(prev => prev - 1)}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke-width='1.5'
                        stroke='currentColor'
                        className='w-5 h-5'
                      >
                        <path
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          d='M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18'
                        />
                      </svg>

                      <span>Previous</span>
                    </Link>
                  ) : (
                    ''
                  )}
                  {page !== businessInfo?.data?.totalCount ? (
                    <Link
                      className='flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100'
                      onClick={() => setPage(prev => prev + 1)}
                    >
                      <span>Next</span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke-width='1.5'
                        stroke='currentColor'
                        className='w-5 h-5'
                      >
                        <path
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          d='M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3'
                        />
                      </svg>
                    </Link>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
            <EmptyWithShadow condition={businessInfo?.data?.length === 0} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Listings
