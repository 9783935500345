import { Modal } from 'flowbite-react'
import React from 'react'
import Upload from '../PhotoUpload'

const AddModal = ({
  setSelectedFileName,
  isModalOpen,
  setIsModalOpen,
  selectedFile,
  setSelectedFile,
  selectFileName,
  category,
  handleFileUpload,
  isUploading
}) => {
  return (
    <div>
      <Modal
        show={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
          setSelectedFile(null)
        }}
      >
        <Modal.Header className='p-3'>
          <p className=' font-semibold text-2xl'>Upload Photo</p>
        </Modal.Header>
        <Modal.Body>
          <div className='space-y-6'>
            <form>
              <div id='PostUpload'>
                {selectedFile ? (
                  <div className='flex justify-center '>
                    <div>
                      <div className='flex flex-col justify-center items-center'>
                        <img
                          src={URL.createObjectURL(selectedFile)}
                          alt='Selected File Preview'
                          id='imageFile'
                          className='img-fluid object-cover '
                          style={{ maxWidth: '100%', maxHeight: '200px' }}
                        />
                        {/* <div className='flex items-center gap-3 pt-3'>
                                                        <p className=' font-semibold text-lg text-listing'><span className='text-black' >File Name :</span> {selectFileName}</p>
                                                        <img src="https://pro-manage.s3.ap-south-1.amazonaws.com/PostMedia.svg" alt='ProfileDelete' className='cursor-pointer' onClick={() => setSelectedFile(null)} />
                                                    </div> */}
                        <div className='flex items-center justify-between pt-3 p-2 mt-4 rounded-lg w-100 shadow-photoName'>
                          <div className='flex items-center'>
                            <img
                              src={
                                'https://pro-manage.s3.ap-south-1.amazonaws.com/showPhoto.svg'
                              }
                              alt='photo'
                              className='img-fluid'
                            />
                            <p className=' font-normal text-md text-RecommentationText'>
                              {' '}
                              {selectFileName}
                            </p>
                          </div>
                          <img
                            src='https://pro-manage.s3.ap-south-1.amazonaws.com/DeleteIconforPhoto.svg'
                            alt='ProfileDelete'
                            className='cursor-pointer'
                            onClick={() => setSelectedFile(null)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <Upload
                      setSelectedFileName={setSelectedFileName}
                      setSelectedFile={setSelectedFile}
                      category={category}
                    />
                    <div className='bg-Recommentation  rounded-lg ps-4 pb-3 pt-1 mt-3'>
                      <p className='mt-2 text-RecommentationText text-xs 	font-medium'>
                        Recommendations for Google:
                      </p>
                      {/* <p className='mt-2 text-RecommentationText text-xs 	font-medium'>
                        • Avoid posting media that is unrelated to your business
                        category.{' '}
                      </p>
                      <p className='mt-2 text-RecommentationText text-xs 	font-medium'>
                        • It is recommended to include your brand logo or a
                        textual mention in the picture.
                      </p>
                      <p className='mt-2 text-RecommentationText text-xs 	font-medium'>
                        • Avoid including phone numbers and links in the
                        description when posting on Google.
                      </p> */}
                      <p className='mt-2 text-RecommentationText text-xs 	font-medium'>
                        • Photo: Use a 4:3 ratio | JPG, PNG, orJPEG format |
                        File size between 10 KB and 5 MB.
                      </p>
                      <p className='mt-2 text-RecommentationText text-xs 	font-medium'>
                        {category === 'COVER'
                          ? '• Image dimension should be  1024px/576px w/h'
                          : '• Image dimension should be min: 250px/250px w/h'}
                      </p>
                    </div>
                  </>
                )}

                <div className='flex mt-3 cursor-pointer'>
                  <p className=' font-semibold text-lg '>Category Type : </p>
                  <p
                    className={` ms-2 rounded-lg ps-2.5 pe-2.5 pt-1 pb-1 text-center bg-Check border-1 border-listing text-listing font-medium`}
                  >
                    {' '}
                    {category}
                  </p>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>

        <Modal.Footer className='pt-0 justify-end'>
          <div className='flex justify-end gap-3 mt-4'>
            <button
              color='gray'
              onClick={() => {
                setIsModalOpen(false)
                setSelectedFile(null)
              }}
              className='px-4 py-2 text-md font-medium text-center border-1 text-gray  rounded-lg focus:outline-none'
            >
              Cancel
            </button>
            <button
              type='submit'
              className={`px-4 py-2 text-md font-medium text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none ${
                !selectedFile
                  ? 'opacity-50 cursor-not-allowed'
                  : 'cursor-pointer'
              }`}
              onClick={handleFileUpload}
              disabled={!selectedFile || isUploading}
            >
              Upload
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AddModal
