import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts' // Import ApexCharts
import { format } from 'date-fns'
import { addDays } from 'date-fns'
import { API_URL } from '../../env/env'
import httpservice from '../../Service/httpservice'
import { Popover } from 'antd'
import { useQuery } from '@tanstack/react-query'

const ReviewsGoogleAreaChart = ({ startDate, endDate, userId, id }) => {
  const [RechartCount, setRechartCount] = useState([])
  const [ActivityCount, setActivityCount] = useState([])
  const [month, setMonth] = useState(false)
  const [days, setDays] = useState(true)

  // useEffect(() => {
  //   ReviewRechartCount()
  // }, [startDate, endDate, id, month])

  const { data, isPending } = useQuery({
    queryFn: () => ReviewRechartCount(),
    queryKey: ['ReviewRechartCount', userId, id, startDate, endDate],
    staleTime: 1000 * 3600,
    cacheTime: 600000
    // onSuccess: ViewsAndActivityData => {
    //   setViewsAndActivityData(ViewsAndActivityData)
    // }
  })

  useEffect(() => {
    setActivityCount(data?.data?.ActivityCount)
    setRechartCount(data?.data?.reviewResponses)
  }, [data])

  let currentDate = new Date(startDate)

  const tempNewChartData = []

  const [date1, setDate1] = useState([])
  const [newDate, setNewDate] = useState([])

  useEffect(() => {
    if (month) {
      const generateMonthList = (startDate, endDate) => {
        const start = new Date(startDate)
        const end = new Date(endDate)
        const monthList = []

        while (start <= end) {
          monthList.push(
            new Date(start).toLocaleString('default', {
              month: 'short',
              year: 'numeric'
            })
          )
          start.setMonth(start.getMonth() + 1)
        }

        return monthList
      }

      const months = generateMonthList(startDate, endDate)
      setDate1(months)
    }
  }, [startDate, endDate, month])

  if (!month) {
    while (currentDate?.toISOString().split('T')[0] <= endDate) {
      const formattedDate = currentDate.toISOString().split('T')[0]
      const existingIndex = RechartCount?.findIndex(
        entry => entry.reviewDate === formattedDate
      )
      if (existingIndex === -1) {
        const newEntry = {
          reviewDate: formattedDate,
          repliedCount: 0,
          notRepliedCount: 0
        }
        tempNewChartData.push(newEntry)
      }
      currentDate.setDate(currentDate.getDate() + 1)
    }

    if (tempNewChartData.length > 0) {
      setRechartCount(prevState =>
        [...prevState, ...tempNewChartData].sort(
          (a, b) => new Date(a.reviewDate) - new Date(b.reviewDate)
        )
      )
    }
  }

  const ReviewRechartCount = async () => {
    try {
      let body = {
        userId: userId,
        groupId: id,
        startDate: startDate,
        endDate: endDate
      }

      const url = `${API_URL}${
        month
          ? '/review/get_review_response/monthly'
          : '/review/get_review_response'
      }`
      const response = await httpservice.post(url, body)
      if (response.status === 200) {
        // console.log(response?.data?.data, 'reviewResponses')
        if (month) {
          setRechartCount(
            response?.data?.data?.reviewResponses.map(item => {
              const date = new Date(item.reviewDate)
              return {
                ...item,
                reviewDate: date.toLocaleString('default', {
                  month: 'short',
                  year: 'numeric'
                })
              }
            })
          )

          const formattedDates = response.data.data?.reviewResponses.map(
            item => {
              const date = new Date(item.reviewDate)
              return date.toLocaleString('default', {
                month: 'short',
                year: 'numeric'
              })
            }
          )

          setNewDate(formattedDates)
        } else {
          return {
            data: {
              reviewResponses: response?.data?.data?.reviewResponses,
              ActivityCount: response?.data?.data?.reviewResponseSummary
            }
          }
          // setRechartCount(response?.data?.data?.reviewResponses)
        }
        // setActivityCount(response?.data?.data?.reviewResponseSummary)
      } else {
        setRechartCount([])
        setActivityCount([])
      }
    } catch (error) {
      setRechartCount([])
      setActivityCount([])
    }
  }

  const formattedDateNew = []
  // console.log(newDate, 'newDatenewDate', date1)
  if (newDate?.length !== date1.length) {
    date1?.map((item, index) => {
      const existingIndex = newDate?.includes(item)
      // console.log(existingIndex, 'existingIndex', typeof existingIndex)
      if (existingIndex === false) {
        // console.log(date1[index], 'date1')

        const formattedDate = {
          reviewDate: date1[index],
          repliedCount: 0,
          notRepliedCount: 0
        }
        // console.log(formattedDate, 'formattedDate')
        formattedDateNew.push(formattedDate)
      }
    })
  }

  let finalOut = [...(RechartCount || []), ...formattedDateNew]
  if (month) {
    finalOut = finalOut.sort(
      (a, b) => new Date(a.reviewDate) - new Date(b.reviewDate)
    )
  }

  if (RechartCount?.length > 0) {
    var chartOptions = {
      series: [
        {
          name: 'repliedCount',
          data: month
            ? finalOut?.map(item => item?.repliedCount)
            : RechartCount?.map(item => item?.repliedCount)
        },
        {
          name: 'notRepliedCount',
          data: month
            ? finalOut?.map(item => item?.notRepliedCount)
            : RechartCount?.map(item => item?.notRepliedCount)
        }
      ],
      options: {
        chart: {
          height: 260
        },
        xaxis: {
          type: 'datetime',
          categories: month
            ? finalOut?.map(item => item.reviewDate)
            : RechartCount.map(item => item.reviewDate)
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 2
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy'
          }
        },
        colors: ['#4285F4', '#FF5E83'],
        legend: {
          show: false
        },
        grid: {
          borderColor: 'transparent'
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.2,
            opacityTo: 0.6
          }
        }
      }
    }
  }

  const SuffixAdd = data => {
    if (data > 1000 && data < 100000) {
      return (data / 1000).toFixed(2) + 'K'
    } else if (data > 99999) {
      return (data / 100000).toFixed(3) + 'L'
    } else {
      return data
    }
  }

  const content = (
    <div className=''>
      <p className='text-businessProfile text-xs p-2'>
        Drag the chart to zoom in, allowing you to
        <br /> view the detailed records.
      </p>
    </div>
  )

  return (
    <div className='mt-4  rounded-2xl p-4 shadow-filter1 border-[0.1px] border-cardInsights'>
      <h1 className='text-xl font-semibold flex items-center gap-2'>
        Reviews{' '}
        <span>
          <Popover placement='rightTop' content={content} className=''>
            <img
              src='https://pro-manage.s3.ap-south-1.amazonaws.com/InformationIcon.svg'
              alt='image'
            />
          </Popover>
        </span>
      </h1>
      <div className='flex justify-between gap-4 pt-3 pb-3'>
        <div className='flex gap-4'>
          <div className='flex items-baseline  gap-2 '>
            <p className='w-2 h-2 bg-violet-600 rounded-full'></p>
            <div>
              <p className='text-black text-md'>Total </p>
              <p className='text-black font-semibold text-lg'>
                {SuffixAdd(ActivityCount?.totalReviews || 0)}
              </p>
            </div>
          </div>
          <div className='flex gap-2 items-baseline'>
            <p className='w-2 h-2 bg-replied rounded-full'></p>
            <div>
              <p className='text-black  text-md'>Replied </p>
              <p className='text-black font-semibold text-lg'>
                {SuffixAdd(ActivityCount?.totalRepliedCount || 0)}
              </p>
            </div>
          </div>
          <div className='flex gap-2 items-baseline'>
            <p className='w-2 h-2 bg-Notreplied rounded-full'></p>
            <div>
              <p className='text-black  text-md'>Not Replied </p>
              <p className='text-black font-semibold text-lg'>
                {' '}
                {SuffixAdd(ActivityCount?.totalNotRepliedCount || 0)}
              </p>
            </div>
          </div>
        </div>
        {/* <div className='flex'>
          <button className={`${month === true ? "bg-listing rounded-3xl text-white -me-5" : " rounded-tr-0 rounded-tl-3xl rounded-bl-3xl rounded-br-0 border-2 border-borderColor"} font-medium py-2 px-4  w-24 transition-all duration-600`} onClick={() => { setMonth(true); setDays(false) }} >Month</button>
          <button className={`${days === true ? "bg-listing rounded-3xl  text-white -ms-5" : " border-l-0 border-r-2 rounded-tr-3xl rounded-tl-0 rounded-bl-0 rounded-br-3xl roun border-b-2 border-t-2 border-borderColor"} font-medium py-2 px-4 w-24 transition-all duration-600`} onClick={() => { setMonth(false); setDays(true) }} >day</button>
        </div> */}
      </div>
      <Chart
        options={chartOptions?.options}
        series={chartOptions?.series}
        type='area'
        width='100%'
        height='300px'
      />
    </div>
  )
}

export default ReviewsGoogleAreaChart
