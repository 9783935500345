import { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import usePermission from '../hooks/usePermission'

export const ProtectedRoute = ({
  children,
  requiredPermission,
  userAccess,
  accessTypeId,
  roleBase
}) => {
  const { location } = useLocation()

  const { currentUser } = useContext(AuthContext)

  const response = usePermission(
    requiredPermission,
    userAccess,
    accessTypeId,
    roleBase
  )

  return response === false ? (
    <Navigate to='/Unauthorized' start={{ from: { location } }} replace />
  ) : currentUser ? (
    children
  ) : (
    <Navigate to='/login' />
  )
}
