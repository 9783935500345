import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import { API_URL } from '../env/env'
import { toast } from 'react-toastify'
import { setUserInfo } from '../Store/UserInfo'
import { handleInputValidation } from '../helper/validator'
const { jwtDecode } = require('jwt-decode')

const Login = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { setCurrentUser, currentUser } = useContext(AuthContext)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const queryParameters = new URLSearchParams(window.location.search)
  const token = queryParameters.get('token')
  const err = queryParameters.get('err')
  // console.log(currentUser?.info, 'currentUser')

  useEffect(() => {
    if (currentUser?.info) {
      if (location.pathname === '/' || location.pathname === '/login') {
        navigate('/insights/dashboard')
        // window.location.reload()
      }
    } else {
      navigate('/login')
    }
  }, [location.pathname, currentUser])

  if (token) {
    const decoded = jwtDecode(token)

    if (decoded?.data?.data?.info && decoded?.data?.data?.token) {
      // localStorage.setItem('tmbUserData', JSON.stringify(decoded.data))

      setCurrentUser(decoded.data.data)
      // console.log(decoded.data.data, 'setUserInfo')
      setUserInfo(decoded.data.data)
      // debugger
      navigate('/insights/dashboard')
    }
  }
  if (err) {
    toast.error('Invalid User')
  }

  const login = async e => {
    // debugger/
    e.preventDefault()
    const res = await axios.get(`${API_URL}/auth/google`, {
      withCredentials: true
    })
    // console.log(res, 'res')
    // debugger
    const redirectUrl = res.data.redirectUrl

    // Attempt redirection using window.location.href
    window.location.href = redirectUrl
    // setTimeout(() => {
    //   window.location.href = redirectUrl
    // }, 100)
  }

  return (
    <div className='bg-white md:h-auto h-screen'>
      <div className='relative  flex md:flex-row flex-col justify-center items-center  p-0 w-[100%]'>
        <div className='spreadOperator md:block hidden'></div>
        <div className='spreadOperator1 md:block hidden'></div>
        <div
          className='col-lg-7 col-12 md:h-[90vh] md:hidden block'
          // style={{
          //   backgroundImage:
          //     'url("https://pro-manage.s3.ap-south-1.amazonaws.com/LoginPage.svg")',
          //   backgroundSize: 'contain',
          //   backgroundRepeat: 'no-repeat',
          //   backgroundPosition: 'center'
          // }}
        >
          <img
            src='https://pro-manage.s3.ap-south-1.amazonaws.com/LoginPage.svg'
            alt='img'
            className=''
          />
        </div>
        <div className='p-4 relative col-lg-5 col-12'>
          {/* <div className='flex justify-end'>
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/UpVector.svg'
                className='img-fluid'
                alt='upimage'
              />
            </div>
            <img
              src='https://pro-manage.s3.ap-south-1.amazonaws.com/logo.png'
              alt=''
              className=' m-auto w-28 '
            /> */}
          {/* <h2 className='text-listing  text-2xl	font-medium text-center mb-3'>Welcome Back...</h2> */}
          <Link to={'https://tuskmelonpro.com/'} target='_blank'>
            <div className='flex justify-center items-center gap-2 mb-2'>
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/LoginLogo.svg'
                className='h-16'
                alt='logo'
              />
              <h2 className='text-tuskManager font-semibold text-center text-2xl'>
                {' '}
                Tusk Manager
              </h2>
            </div>
          </Link>
          {/* <p className='text-center m-auto text-lorem text-lg lg:w-[21em] mb-3'>
            Loreu ipsum Dola Lorem ipsum dolor sit amet consectetur.
          </p> */}
          <div className='flex flex-col col-md-10 col-12 m-auto justify-center items-center p-8  border-1 border-loginBorder rounded-3xl'>
            <button
              type='submit'
              onClick={e => login(e)}
              className='bg-listing border-1 col-md-8 col-10 justify-center flex gap-2 items-center font-medium border-listing text-white text-lg mt-2 mb-2 rounded-2xl  p-2.5'
            >
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/GoogleLogo.svg'
                alt='google image'
                className='img-fluid'
              />
              Login
            </button>
            <p className='text-base text-center col-md-8 col-12 font-light'>
              By clicking Login you are accepting our
              <Link
                to={'https://www.tuskmelonpro.com/privacypolicy'}
                className='text-termsAndCondtion  text-decoration-underline'
                target='_blank'
              >
                {' '}
                Privacy Policy
              </Link>{' '}
              &
              <Link
                to={'https://www.tuskmelonpro.com/termsandcondition'}
                className='text-termsAndCondtion text-decoration-underline ms-1'
                target='_blank'
              >
                Terms and Conditions
              </Link>
            </p>
          </div>
          {/* <img
              src='https://pro-manage.s3.ap-south-1.amazonaws.com/DownVector.svg'
              className='img-fluid  bottom-3 left-5'
              alt='upimage'
            /> */}
        </div>
        <div className='border-[0.7px] h-[50vh] border-loginBorder md:block hidden '></div>
        <div
          className='col-lg-7 col-12 md:h-[90vh] md:block hidden'
          // style={{
          //   backgroundImage:
          //     'url("https://pro-manage.s3.ap-south-1.amazonaws.com/LoginPage.svg")',
          //   backgroundSize: 'contain',
          //   backgroundRepeat: 'no-repeat',
          //   backgroundPosition: 'center'
          // }}
        >
          <img
            src='https://pro-manage.s3.ap-south-1.amazonaws.com/LoginPage.svg'
            alt='img'
            className=''
          />
        </div>
      </div>
      <p className='text-center pb-3 pt-3 z-20 relative'>
        © 2024 Copyright {' '}
        <span class='font-Poppins pe-2 text-base font-medium bg-gradient-to-r from-purple-700 via-blue-500 to-green-500 bg-clip-text text-transparent'>
          <Link to={'https://www.tuskmelon.com/'} target='_blank'>
            Tuskmelon
          </Link>
        </span>{' '}
        All Rights Reserved.
      </p>
    </div>
  )
}

export default Login
