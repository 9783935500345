import { Modal } from 'flowbite-react'
import React from 'react'
import Upload from '../PhotoUpload'
import moment from 'moment-timezone'

const EditModal = ({
  popUpId,
  setPopUpId,
  setSelectedFile,
  isEditModalOpen,
  selectedFile,
  setSelectedFileName,
  handleTextAreaChange,
  selectedFileName,
  UpdatePost,
  isUploading
}) => {
  return (
    <div>
      <Modal
        show={popUpId}
        onClose={() => {
          setPopUpId(false)
          setSelectedFile(null)
        }}
        size='3xl'
      >
        <Modal.Header className='p-3'>
          <p className=' font-semibold text-2xl'>Edit Post</p>
        </Modal.Header>
        <Modal.Body className='overflow-x-hidden'>
          <div>
            <form>
              <div className='grid grid-cols-2 border-1 border-dotted border-black rounded-lg gap-3 p-2'>
                <div>
                  {/* <label className='font-semibold text-xl' for="default-input">Old Media : </label> */}
                  <p className='text-center text-lg  text-black'>Old Media</p>
                  {isEditModalOpen?.media ? (
                    isEditModalOpen.media.map((item, ind) => (
                      <div key={ind} className='m-auto flex justify-center'>
                        {item.googleUrl && (
                          <img
                            src={item.googleUrl}
                            alt='Profilepost'
                            className='object-cover '
                            style={{ maxWidth: '100%', maxHeight: '200px' }}
                          />
                        )}
                      </div>
                    ))
                  ) : (
                    <img
                      className='flex justify-center items-center p-2 mx-auto my-auto '
                      src='https://pro-manage.s3.ap-south-1.amazonaws.com/dummyImg.jpg'
                      alt='Profilepost'
                    />
                  )}
                </div>
                <div className='flex items-center '>
                  <img
                    src='https://pro-manage.s3.ap-south-1.amazonaws.com/Dotted.svg'
                    alt='dottedImg'
                    className='img-fluid h-52'
                  />
                  {/* <label className='font-semibold text-xl pt-2' for="default-input">New Media : </label> */}
                  <div>
                    <p className='text-center text-lg  text-black'>New Media</p>
                    {selectedFile ? (
                      <div className='flex justify-center '>
                        <div>
                          <div className='flex flex-col justify-center items-center p-2'>
                            <img
                              src={URL.createObjectURL(selectedFile)}
                              alt='Selected File Preview'
                              id='imageFile'
                              className='img-fluid '
                            />
                            <div className='flex items-center justify-between pt-3 p-2 mt-3 rounded-lg w-72 shadow-photoName'>
                              <div className='flex items-center'>
                                <img
                                  src={
                                    'https://pro-manage.s3.ap-south-1.amazonaws.com/showPhoto.svg'
                                  }
                                  alt='photo'
                                  className='img-fluid'
                                />
                                <p className=' font-normal text-md text-RecommentationText'>
                                  {' '}
                                  {selectedFileName}
                                </p>
                              </div>
                              <img
                                src='https://pro-manage.s3.ap-south-1.amazonaws.com/DeleteIconforPhoto.svg'
                                alt='ProfileDelete'
                                className='cursor-pointer'
                                onClick={() => setSelectedFile(null)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <Upload
                          setSelectedFileName={setSelectedFileName}
                          setSelectedFile={setSelectedFile}
                          FromPost={'true'}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className='bg-Recommentation  rounded-lg ps-4 pb-3 pt-1 mt-3'>
                <p className='mt-2 text-RecommentationText text-sm 	font-semibold '>
                  Recommendations for Google:
                </p>
                <p className='mt-2 text-RecommentationText text-xs 	font-medium'>
                  • Avoid posting media that is unrelated to your business
                  category.{' '}
                </p>
                <p className='mt-2 text-RecommentationText text-xs 	font-medium'>
                  • It is recommended to include your brand logo or a textual
                  mention in the picture.
                </p>
                <p className='mt-2 text-RecommentationText text-xs 	font-medium'>
                  • Avoid including phone numbers and links in the description
                  when posting on Google.
                </p>
                <p className='mt-2 text-RecommentationText text-xs 	font-medium'>
                  • Photo: Use a 4:3 ratio | JPG, PNG, orJPEG format | File size
                  between 10 KB and 5 MB.
                </p>
              </div>
              <div>
                <div className='relative'>
                  <label
                    className='font-semibold text-xl pt-2 pb-2'
                    for='default-input'
                  >
                    Summary :{' '}
                  </label>
                  <textarea
                    className='Rectangle93'
                    style={{
                      width: '100%',
                      height: 300,
                      background: 'white',
                      boxShadow:
                        '9px 6px 61.900001525878906px rgba(227.74, 225.37, 225.37, 0.25)',
                      borderRadius: 16,
                      border: '1px #F3EDFF solid'
                    }}
                    name='summary'
                    value={isEditModalOpen?.summary}
                    onChange={handleTextAreaChange}
                  />
                  <div className='absolute bottom-3 right-2 text-xs text-gray-500'>
                    {isEditModalOpen?.summary?.length}/1500
                  </div>
                </div>
                {/* <textarea id="message" rows="10" className="block text-md p-2.5 overflow-hidden w-full text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder=""  value={isEditModalOpen?.summary} onChange={handleTextAreaChange}></textarea> */}
                <div className='pt-2 flex items-center gap-2'>
                  <p className='font-semibold text-xl pt-2 pb-2'>Date : </p>
                  <p className='text-lg font-semibold'>
                    {' '}
                    {moment
                      .utc(isEditModalOpen?.updateTime)
                      .tz('Asia/Calcutta')
                      .format('DD MMM YYYY ')}
                  </p>
                  <p className='text-base font-medium text-notificationCount'>
                    {moment
                      .utc(isEditModalOpen?.updateTime)
                      .tz('Asia/Calcutta')
                      .format(' hh:mm:ss A')}
                  </p>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className='pt-0 justify-end'>
          <div className='flex justify-end gap-3 mt-4'>
            <button
              color='gray'
              onClick={() => {
                setPopUpId(false)
                setSelectedFile(null)
              }}
              className='px-4 py-2 text-md font-medium text-center border-1 text-gray  rounded-lg focus:outline-none'
            >
              Cancel
            </button>
            <button
              type='submit'
              className='px-4 py-2 text-md font-medium text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none'
              onClick={e => UpdatePost(e)}
              disabled={isUploading}
            >
              Upload
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default EditModal
