import React from 'react'
import { Link, useParams } from 'react-router-dom'

const InsightsTopBar = () => {
  const tabs = [
    {
      name: 'Dashboard',
      url: '/insights/dashboard',
      imgOut: 'https://pro-manage.s3.ap-south-1.amazonaws.com/Insightsout.svg',
      imgIn: 'https://pro-manage.s3.ap-south-1.amazonaws.com/InsightsIn.svg',
      bgNormal: 'bg-white',
      bgActive: 'border-b-TotalColor'
    },
    {
      name: 'Discovery',
      url: '/insights/discovery',
      imgOut: 'https://pro-manage.s3.ap-south-1.amazonaws.com/DiscoveryOut.svg',
      imgIn: 'https://pro-manage.s3.ap-south-1.amazonaws.com/DiscoveryIn.svg',
      bgNormal: 'bg-white',
      bgActive: 'border-b-DiscoveryActiveColor'
    },
    {
      name: 'Performance ',
      url: '/insights/performance',
      imgOut:
        'https://pro-manage.s3.ap-south-1.amazonaws.com/PerformanceOut.svg',
      imgIn: 'https://pro-manage.s3.ap-south-1.amazonaws.com/Performancein.svg',
      bgNormal: 'bg-white',
      bgActive: 'border-b-PerformanceActiveColor'
    },
    {
      name: 'Reviews',
      url: '/insights/review',
      imgOut: 'https://pro-manage.s3.ap-south-1.amazonaws.com/ReviewsOut.svg',
      imgIn: 'https://pro-manage.s3.ap-south-1.amazonaws.com/ReviewsIn.svg',
      bgNormal: 'bg-white',
      bgActive: 'border-b-ReviewActiveColor'
    }
  ]

  let url = window.location.pathname
  url = url.split('/')
  let Newurl = url[2]
  // console.log(Newurl === undefined, "newurl")
  // // console.log(url.length, "length", url[2])
  // let newurl
  // if (url[2] === undefined) {
  //     newurl = "/insights"
  // }

  return (
    <>
      <div className='mt-4'>
        <div className='flex justify-between items-center  rounded-2xl '>
          {tabs.map((item, index) => (
            <Link to={`${item.url}`}>
              <div
                className={`w-[250px]   rounded-3xl ${
                  item.url.includes(Newurl) &&
                  `${item.bgActive}  border-b-4 shadow-dashBoardMenuShadow `
                }  border-l-white border-r-white  border-l-1 border-r-1  px-4 bg-white   py-[30px]  text-center flex justify-center items-center gap-2`}
              >
                <img className='' src={item.imgOut} alt='InsightsIcon' />
                <p
                  className={`text-md font-medium ${
                    item.url.includes(Newurl)
                      ? 'text-black font-semibold'
                      : 'text-insightsTopHeading'
                  }`}
                >
                  {item.name}
                </p>
              </div>
            </Link>
          ))}
        </div>
        {/* <div className={`flex items-center justify-center gap-3 col-3 p-4  ${index !== 3 && "border-e-2"}`}>
                                <img className='' src={item.url.includes(Newurl) ? item.imgIn : item.imgOut} alt='InsightsIcon' />
                                <Link to={`${item.url}`}>
                                    <p className={`text-md font-medium ${item.url.includes(Newurl) ? "text-listing font-semibold" : ""}`}>{item.name}</p>
                                </Link>
                            </div> */}
      </div>
    </>
  )
}

export default InsightsTopBar
