import React, { useEffect } from 'react'
import Sidebar from '../Components/Sidebar'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import TopNavbar from '../Components/Header'
import TopBar from '../Components/BusinessTopBar'
import NotificationTopBar from '../Components/NotificationTopBar/TopBar'
import CalenderForInsights from '../Components/InsightCalender'
import InsightsTopBar from '../Components/InsightsTopBar'
import WalkThrough from '../Components/WalkThrough'

const Home = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname === '/insights') {
      navigate('/insights/dashboard')
      // window.location.reload()
    }
  }, [location.pathname])
  // console.log(location.pathname, 'location')
  return (
    <>
      <div className='flex h-screen '>
        <Sidebar />
        <div className='flex flex-col flex-1 overflow-hidden'>
          <TopNavbar />
          <main className='flex-1 overflow-x-hidden overflow-y-auto'>
            {[
              '/businessprofile/overview',
              '/businessprofile/media',
              '/businessprofile/post',
              '/businessprofile/service',
              '/businessprofile/review'
            ].includes(location.pathname) && <TopBar />}
            {[
              '/insights/performance',
              '/insights/discovery',
              '/insights/review',
              '/insights/dashboard'
            ].includes(location.pathname) && (
              <div className='bg-InsightBackGround'>
                <div className='container  p-10  '>
                  {/* {['/insights/dashboard']?.includes(location.pathname) && (
                    <div className='bg-gradient-to-r from-purple-600 to-pink-500 flex items-center justify-center rounded-3xl'>
                      <div className='flex flex-col col-8'>
                        <h3 className='text-italic font-medium font-2xl pb-2 text-white'>
                          Welcome to{' '}
                        </h3>
                        <h2 className='text-3xl font-semibold pb-2 text-white'>
                          Tusk manager
                        </h2>
                        <p className='text-lg font-normal text-white col-9'>
                          Lorem ipsum dolor sit amet consectetur. Elit ante
                          libero ultrices risus semper etiam. Ac ultrices nunc
                          lorem congue vitae a faucibus.
                        </p>
                      </div>
                      <div>
                        <img
                          className='img-fluid'
                          src='https://pro-manage.s3.ap-south-1.amazonaws.com/Insights.svg'
                          alt='Insights'
                        />
                      </div>
                    </div>
                  )} */}
                  <div className=' flex items-center justify-between text-3xl font-semibold'>
                    <h2 className='capitalize'>
                      Insights-
                      {location.pathname.split('/')[2] === 'review'
                        ? 'Reviews'
                        : location.pathname.split('/')[2]}
                    </h2>
                    <CalenderForInsights />
                  </div>
                  <InsightsTopBar />
                </div>
              </div>
            )}

            {[
              '/useractivity/review',
              '/useractivity/location',
              '/useractivity/question'
            ].includes(location.pathname) && <NotificationTopBar />}

            <div className=' mx-auto'>
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default Home
