import React, { useEffect, useState } from 'react'
import { API_URL } from '../../env/env'
import { format } from 'date-fns'
import Chart from 'react-apexcharts' // Import ApexCharts
import httpservice from '../../Service/httpservice'
import { Popover } from 'antd'
import { useQuery } from '@tanstack/react-query'

const TotalMapSearch = ({ startDate, endDate, userId, groupId }) => {
  const [month, setMonth] = useState(true)
  const [day, setDay] = useState()
  const [date1, setDate1] = useState()
  const [newDate, setNewDate] = useState([])

  const { data, isPending } = useQuery({
    queryFn: () => handleviewsMetrics(),
    queryKey: ['handleviewsMetrics', startDate, endDate, groupId, month],
    staleTime: 1000 * 3600,
    cacheTime: 600000
    // onSuccess: ViewsAndActivityData => {
    //   setViewsAndActivityData(ViewsAndActivityData)
    // }
  })

  // console.log(data?.data?.mapAndSearchViews, 'data')

  useEffect(() => {
    // setNewDate(data?.data?.dateWiseInsights)
    SetMetricsData(data?.data?.mapAndSearchViews)

    // const formattedDates = data?.data?.mapAndSearchViews?.map(item => item)

    setNewDate(data?.data?.mapAndSearchViews)
    SetMatricsTotal(data?.data?.totalMapSearchView)
  }, [data])

  useEffect(() => {
    if (month) {
      const generateMonthList = (startDate, endDate) => {
        const start = new Date(startDate)
        const end = new Date(endDate)
        const monthList = []

        while (start <= end) {
          monthList.push(new Date(start))
          start.setMonth(start.getMonth() + 1)
        }

        return monthList
      }

      const months = generateMonthList(startDate, endDate)
      setDate1(months)
    }
  }, [startDate, endDate, month])

  const SuffixAdd = data => {
    if (data > 1000 && data < 100000) {
      return (data / 1000).toFixed(2) + 'K'
    } else if (data > 99999) {
      return (data / 100000).toFixed(3) + 'L'
    } else {
      return data
    }
  }

  const [MetricsData, SetMetricsData] = useState([])
  const [MatricsTotal, SetMatricsTotal] = useState([])

  //   useEffect(() => {
  //     handleviewsMetrics()
  //   }, [startDate, endDate, groupId, month])

  const handleviewsMetrics = async () => {
    try {
      let body = {
        userId: userId,
        groupId: groupId,
        startDate: startDate,
        endDate: endDate
      }
      let url
      if (month) {
        url = `${API_URL}/other/viewsMetrics/monthly`
      } else {
        url = `${API_URL}/other/viewsMetrics`
      }
      // let url = `${API_URL}/other/viewsMetrics`

      const response = await httpservice.post(url, body)
      if (response?.status === 200) {
        // console.log(
        //   response?.data?.data,
        //   'responseData From Discovery->>>>>>>>>>>>>>>>>>'
        // )
        if (month) {
          //   SetMetricsData(
          //     response?.data?.data?.mapAndSearchViews?.map(item => {
          //       const date = new Date(item?.insightDate)
          //       return {
          //         ...item,
          //         insightDate: date.toLocaleString('default', {
          //           month: 'short',
          //           year: 'numeric'
          //         })
          //       }
          //     })
          //   )

          //   const formattedDates = response?.data?.data?.mapAndSearchViews?.map(
          //     item => {
          //       const date = new Date(item.insightDate)
          //       return date.toLocaleString('default', {
          //         month: 'short',
          //         year: 'numeric'
          //       })
          //     }
          //   )

          //   setNewDate(formattedDates)
          return {
            data: {
              mapAndSearchViews: response?.data?.data?.mapAndSearchViews,
              totalMapSearchView: response?.data?.data
            }
          }
        } else {
          return {
            data: {
              mapAndSearchViews: response?.data?.data?.mapAndSearchViews,
              totalMapSearchView: response?.data?.data
            }
          }
          //   SetMetricsData(response?.data?.data?.mapAndSearchViews)
        }
        // SetMetricsData(response?.data?.data?.mapAndSearchViews)
        // SetMatricsTotal(response?.data?.data)
      }
    } catch (error) {}
  }

  const formattedDateNew = []
  if (newDate?.length !== date1?.length) {
    date1?.map((item, index) => {
      const existingIndex = newDate?.includes(item)
      // console.log(existingIndex, 'existingIndex', typeof existingIndex)
      if (existingIndex === false) {
        // console.log(date1[index], 'date1')

        const formattedDate = {
          insightDate: date1[index],
          searchViews: 0,
          mapsViews: 0
        }
        // console.log(formattedDate, 'formattedDate')
        formattedDateNew.push(formattedDate)
      }
    })
  }

  let finalOut = [...(MetricsData || []), ...formattedDateNew]

  // console.log([...(MetricsData || []), ...formattedDateNew], 'finalOut', date1)
  // if (month) {
  //   finalOut = finalOut.sort(
  //     (a, b) => new Date(a.reviewDate) - new Date(b.reviewDate)
  //   )
  // }

  const currentDate = new Date(startDate)
  const tempNewChartData = []

  if (!month) {
    while (currentDate.toISOString().split('T')[0] <= endDate) {
      const formattedDate = currentDate.toISOString().split('T')[0]
      const existingIndex = MetricsData?.findIndex(
        entry => entry.insightDate === formattedDate
      )

      if (existingIndex === -1) {
        const newEntry = {
          insightDate: formattedDate,
          searchViews: 0,
          mapsViews: 0
        }
        tempNewChartData.push(newEntry)
      }

      currentDate.setDate(currentDate.getDate() + 1)
    }

    if (tempNewChartData.length > 0) {
      SetMetricsData(prevState =>
        [...prevState, ...tempNewChartData].sort(
          (a, b) => new Date(a.insightDate) - new Date(b.insightDate)
        )
      )
    }
  }

  // let AreaData =

  // console.log(
  //   'MetricsData',
  //   MetricsData?.map(item => item?.searchViews)
  // )
  //

  // const parseAndFormatDate = dateString => {
  //   const date = new Date(dateString)

  //   return date.toISOString().split('T')[0]
  // }

  const AreaData = {
    series: [
      {
        name: 'searchViews',
        data: month
          ? finalOut?.map(item => item?.searchViews)
          : MetricsData?.map(item => item?.searchViews),
        fill: {
          opacity: 0.2
        }
      },
      {
        name: 'mapsViews',
        data: month
          ? finalOut?.map(item => item?.mapsViews)
          : MetricsData?.map(item => item?.mapsViews),
        fill: {
          opacity: 0.2
        }
      }
    ],
    options: {
      chart: {
        // type: 'line',
        height: 300
      },
      xaxis: {
        type: 'date',
        categories: month
          ? finalOut?.map(item => item?.insightDate)
          : MetricsData?.map(item => item?.insightDate)
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      tooltip: {
        x: {
          format: 'dd/MM/yyyy'
        }
      },
      colors: ['#7549FF', '#FD2254'],
      legend: {
        show: false
      },
      grid: {
        borderColor: 'transparent'
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.2,
          opacityTo: 0.4
        }
      }
    }
  }

  const content = (
    <div className=''>
      <p className='text-businessProfile text-xs p-2'>
        Drag the chart to zoom in, allowing you to
        <br /> view the detailed records.
      </p>
    </div>
  )

  // console.log(
  //   finalOut?.map(item => item?.insightDate),
  //   'finalOut'
  // )
  return (
    <div className='mt-3   rounded-2xl p-4 shadow-filter1 border-[0.1px] border-cardInsights'>
      <h1 className='text-xl font-semibold flex items-center gap-2'>
        View Analytics{' '}
        <span>
          <Popover placement='rightTop' content={content} className=''>
            <img
              src='https://pro-manage.s3.ap-south-1.amazonaws.com/InformationIcon.svg'
              alt='image'
            />
          </Popover>
        </span>
      </h1>
      <div className='flex justify-between items-center gap-4 pt-3 pb-3'>
        <div className='flex gap-4'>
          <div className='flex items-baseline  gap-2 '>
            <p className='w-2 h-2 bg-sky-500 rounded-full '></p>
            <div>
              <p className='text-black text-md'>TotalViews </p>
              <p className='text-black font-semibold text-lg'>
                {SuffixAdd(
                  MatricsTotal?.totalMapViews + MatricsTotal?.totalSearchViews
                )}
              </p>
            </div>
          </div>
          <div className='flex gap-2 items-baseline'>
            <p className='w-2 h-2 bg-mapViews rounded-full'></p>
            <div>
              <p className='text-black  text-md'>TotalMapViews </p>
              <p className='text-black font-semibold text-lg'>
                {SuffixAdd(MatricsTotal?.totalMapViews)}
              </p>
            </div>
          </div>
          <div className='flex gap-2 items-baseline'>
            <p className='w-2 h-2 bg-SearchViews2 rounded-full'></p>
            <div>
              <p className='text-black  text-md'>TotalSearchViews </p>
              <p className='text-black font-semibold   text-lg'>
                {' '}
                {SuffixAdd(MatricsTotal?.totalSearchViews)}
              </p>
            </div>
          </div>
        </div>
        {/* <div className='flex'>
                    <button className={`${month === true ? "bg-listing rounded-3xl text-white -me-5" : " rounded-tr-0 rounded-tl-3xl rounded-bl-3xl rounded-br-0 border-2 border-borderColor"} font-medium py-2 px-4  w-24 transition-all duration-600`} onClick={() => { setMonth(true); setDay(false) }} >Month</button>
                    <button className={`${day === true ? "bg-listing rounded-3xl  text-white -ms-5" : " border-l-0 border-r-2 rounded-tr-3xl rounded-tl-0 rounded-bl-0 rounded-br-3xl roun border-b-2 border-t-2 border-borderColor"} font-medium py-2 px-4 w-24 transition-all duration-600`} onClick={() => { setMonth(false); setDay(true) }} >day</button>
                </div> */}
      </div>
      <Chart
        options={AreaData?.options}
        series={AreaData?.series}
        type='area'
        width='100%'
        height='300px'
      />
    </div>
  )
}

export default TotalMapSearch
