import React, { useEffect, useState } from 'react'
import { Chart } from 'react-google-charts'
import { format } from 'date-fns'
import { API_URL } from '../../env/env'
import httpservice from '../../Service/httpservice'
import { useQuery } from '@tanstack/react-query'

const ViewByDevice = ({ startDate, endDate, groupId, userId, setload }) => {
  const [deviceView, SetDeviceView] = useState('')

  const SuffixAdd = data => {
    if (data > 1000 && data < 100000) {
      return (data / 1000).toFixed(2) + 'K'
    } else if (data > 99999) {
      return (data / 100000).toFixed(3) + 'L'
    } else {
      return data
    }
  }

  //   useEffect(() => {
  //     ViewByDevice()
  //   }, [startDate, endDate, groupId])

  const { data, isPending } = useQuery({
    queryFn: () => groupId !== undefined && ViewByDevice(),
    queryKey: ['ViewByDevice', startDate, endDate, groupId],
    staleTime: 1000 * 3600,
    cacheTime: 600000
    // onSuccess: ViewsAndActivityData => {
    //   setViewsAndActivityData(ViewsAndActivityData)
    // }
  })

  useEffect(() => {
    SetDeviceView(data?.data)
  }, [data?.data])

  const ViewByDevice = async () => {
    try {
      const body = {
        userId: userId,
        groupId: groupId,
        startDate: startDate,
        endDate: endDate
      }
      const url = `${API_URL}/other/viewsByDevice`
      const response = await httpservice.post(url, body)
      if (response.status === 200) {
        // console.log(response.data.data, 'responseforViewByDevice')
        // SetDeviceView(response.data.data)
        // setload(false)
        return { data: response.data.data }
      } else {
        // setload(false)
      }
    } catch (error) {}
  }

  const chartData = [
    ['Search', 'Search By Device'],
    ['Google Search - Mobile', deviceView?.mobileSearchPercentage],
    ['Maps Mobile', deviceView?.mobileMapPercentage],
    [
      `${deviceView?.desktopSearchCount} - ${deviceView?.desktopSearchPercentage}% \n Google Search - Desktop`,
      deviceView?.desktopSearchPercentage
    ],
    ['Maps Desktop', deviceView?.desktopMapPercentage]
  ]

  const chartOptions = {
    pieHole: 0.6,
    tooltip: {
      isHtml: true,
      trigger: 'none'
    },
    vAxis: {
      textStyle: {
        bold: true,
        fontWeight: 800
      }
    },
    legend: {
      position: 'none'
    },
    chartArea: {
      top: '-70px'
    },

    fontSize: 9.5,
    colors: ['#FD2254', '#817AF3', '#00B7FE', '#FDCA40'],
    backgroundColor: 'transparent',
    plugins: {
      customTooltip: {
        font: {
          weight: 'bold'
        }
      }
    }
  }

  return (
    <div className=' '>
      <h2 className='text-viewByDevice font-semibold text-xl pt-1'>
        Views by Device
      </h2>
      <p className='text-tableHeading text-2xl font-medium pt-1'>
        {deviceView?.totalCount > 1000 && deviceView?.totalCount < 100000
          ? `${(deviceView?.totalCount / 1000).toFixed(2)}K`
          : deviceView?.totalCount > 100000
          ? `${(deviceView?.totalCount / 100000).toFixed(3)}L`
          : deviceView?.totalCount}
      </p>
      <p className='text-TuskColor text-sm pt-1'>
        {' '}
        People viewed your business profile
      </p>
      <p className='text-insightsTopHeading text-xs col-6 pt-1'>
        Platform and devices that people used to find your profile
      </p>
      <div className='row   mt-3'>
        <div className='col-6 pe-0'>
          <div className='flex flex-col '>
            <div className='flex items-center gap-2'>
              <p className='w-2 h-2 bg-googleMobile rounded-full'></p>
              <p>
                <span className='w-5 h-5 bg-'></span>Google Search - Mobile
              </p>
            </div>
            <p className='text-tableHeading font-semibold text-lg ps-3'>
              <span> {SuffixAdd(deviceView?.mobileSearchCount)}</span>-{' '}
              {deviceView?.mobileSearchPercentage}%
            </p>
          </div>
          <div className='flex flex-col pt-3'>
            <div className='flex items-center gap-2'>
              <p className='w-2 h-2 bg-listing rounded-full'></p>
              <p className='text-tableHeading '>Google Maps - Mobile</p>
            </div>
            <p className='text-tableHeading font-semibold text-lg ps-3'>
              <span>{SuffixAdd(deviceView?.mobileMapCount)}</span>-{' '}
              {deviceView?.mobileMapPercentage}%
            </p>
          </div>
          <div className='flex flex-col pt-3'>
            <div className='flex items-center gap-2'>
              <p className='w-2 h-2 bg-googleDeskTop rounded-full'></p>
              <p className='text-tableHeading '>Google Search - Desktop</p>
            </div>
            {/* <p><span>{deviceView?.desktopCount}</span> - {deviceView?.desktopSearchPercentage}%</p> */}
            <p className='text-tableHeading font-semibold text-lg ps-3'>
              <span>{SuffixAdd(deviceView?.desktopCount)}</span>-{' '}
              {deviceView?.desktopSearchPercentage}%
            </p>
          </div>
          <div className='flex flex-col pt-3'>
            <div className='flex items-center gap-2'>
              <p className='w-2 h-2 bg-TotalColor rounded-full'></p>
              <p>
                <span></span>Google Maps - Desktop
              </p>
            </div>
            <p className='text-tableHeading font-semibold text-lg ps-3'>
              <span>{SuffixAdd(deviceView?.desktopMapCount)}</span>-{' '}
              {deviceView?.desktopMapPercentage}%
            </p>
          </div>
        </div>
        <div className='col-5 -mt-16 ps-0 flex justify-center'>
          <Chart
            width={350}
            height={350}
            chartType='PieChart'
            loader={<div>Loading Chart</div>}
            data={chartData}
            options={chartOptions}
          />
        </div>
      </div>

      <div className='flex justify-end gap-4 -mt-4'>
        <div className='flex items-center flex-col'>
          <div className='flex items-center flex-col'>
            <img src='https://pro-manage.s3.ap-south-1.amazonaws.com/desktop.svg' />
            <p className='text-xs font-medium'>Desktop</p>
          </div>
          <p className='font-semibold'>
            {SuffixAdd(deviceView?.desktopCount + deviceView?.desktopMapCount)}{' '}
            -{' '}
            {(
              deviceView?.desktopSearchPercentage +
              deviceView?.desktopMapPercentage
            ).toFixed(2)}
            %
          </p>
        </div>
        <div className='flex items-center flex-col'>
          <div className='flex items-center flex-col'>
            <img src='https://pro-manage.s3.ap-south-1.amazonaws.com/mobile.svg' />
            <p className='text-xs font-medium'>Mobile</p>
          </div>
          <p className='font-semibold'>
            {SuffixAdd(
              deviceView?.mobileSearchCount + deviceView?.mobileMapCount
            )}{' '}
            -{' '}
            {(
              deviceView?.mobileSearchPercentage +
              deviceView?.mobileMapPercentage
            ).toFixed(2)}
            %
          </p>
        </div>
      </div>
    </div>
  )
}

export default ViewByDevice
