import React from 'react'
import { Link } from 'react-router-dom'

const NotFoundPage = () => {
  return (
    <div className='flex justify-center flex-col items-center h-screen '>
      <img
        src='https://pro-manage.s3.ap-south-1.amazonaws.com/404-error.svg'
        className='img-fluid w-[50%]'
      />
      {/* <button>
        <Link to='/'>Go Home</Link>
      </button> */}
    </div>
  )
}

export default NotFoundPage
