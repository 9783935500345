import React, { useEffect, useState } from 'react'
import { API_URL } from '../../env/env'
import httpservice from '../../Service/httpservice'
import { Bar } from 'react-chartjs-2'
import { useQuery } from '@tanstack/react-query'

const SearchChart = ({ startDate, endDate, userId, groupId }) => {
  const { data: SearchReviewReport, isPending } = useQuery({
    queryFn: () => groupId !== undefined && SearchReport(),
    queryKey: ['SearchReport', userId, groupId, startDate, endDate],
    staleTime: 1000 * 3600,
    cacheTime: 600000
  })

  const [searchData, setSearchData] = useState([])
  const [totalImpression, setTotalImpression] = useState([])
  const [newDate, setNewDate] = useState([])

  // console.log(SearchReviewReport?.data, 'SearchReviewReport')

  useEffect(() => {
    setSearchData(
      SearchReviewReport?.data?.searchKeyword.map(item => {
        const date = new Date(item.insightDate)
        const formattedDate = date.toLocaleString('default', {
          month: 'short',
          year: 'numeric'
        })
        return { ...item, insightDate: formattedDate }
      })
    )

    setTotalImpression(SearchReviewReport?.data?.totalImpression)

    const formattedDates = SearchReviewReport?.data?.searchKeyword.map(item => {
      const date = new Date(item.insightDate)
      return date.toLocaleString('default', {
        month: 'short',
        year: 'numeric'
      })
    })
    setNewDate(formattedDates)
  }, [SearchReviewReport?.data])

  const newTime = startDate.split('-')
  newTime[2] = '01'
  const startDate1 = newTime.join('-')

  const newTime1 = endDate.split('-')
  newTime1[2] = '01'
  const endDate1 = newTime1.join('-')

  const [date1, setDate1] = useState([])

  useEffect(() => {
    const generateMonthList = (startDate, endDate) => {
      const start = new Date(startDate)
      const end = new Date(endDate)
      const monthList = []

      while (start <= end) {
        monthList.push(
          new Date(start).toLocaleString('default', {
            month: 'short',
            year: 'numeric'
          })
        )

        start.setMonth(start.getMonth() + 1)
      }

      return monthList
    }

    const months = generateMonthList(startDate, endDate)
    setDate1(months)
  }, [startDate, endDate])

  const SearchReport = async () => {
    try {
      const body = {
        userId: userId,
        groupId: groupId,
        startDate: startDate1,
        endDate: endDate1
      }

      const url = `${API_URL}/other/getSearchReport`
      const response = await httpservice.post(url, body)
      if (response.status === 200) {
        return { data: response.data.data }
      } else {
        return { data: [] }
      }
    } catch (error) {
      // console.log(error)
    }
  }

  // console.log('date1', newDate, searchData)

  const formattedDateNew = []
  if (newDate?.length !== date1.length) {
    date1?.map((item, index) => {
      const existingIndex = newDate?.includes(item)
      // console.log(existingIndex, 'existingIndex', typeof existingIndex)
      if (existingIndex === false) {
        // console.log(date1[index], 'date1')

        const formattedDate = {
          insightDate: date1[index],
          totalImpressions: 0
        }

        formattedDateNew.push(formattedDate)
      }
    })
  }

  // console.log(searchData, 'searchData', formattedDateNew)

  let finalOut = [...(searchData || []), ...formattedDateNew]
  finalOut = finalOut.sort(
    (a, b) => new Date(a.insightDate) - new Date(b.insightDate)
  )

  // console.log('newSearchData', finalOut)

  const labels =
    finalOut?.map(item => {
      const date = new Date(item?.insightDate)
      return date.toLocaleDateString('en-US', { month: 'long' }).slice(0, 3)
    }) || []

  const predefinedColors = [
    '#85C950',
    '#E0E0E2',
    '#FEC248',
    '#FF5A34',
    '#048DAD',
    '#3A4352',
    '#A2338F',
    '#860838',
    '#F09'
  ]

  const dataValues =
    finalOut && (finalOut?.map(item => item?.totalImpressions) || [])
  const repeatTimes = Math.ceil(finalOut.length / predefinedColors.length)
  const repeatedColors = Array.from(
    { length: repeatTimes },
    () => predefinedColors
  ).flat()
  const dataInput = {
    labels: labels,
    datasets: [
      {
        data: dataValues,
        backgroundColor: repeatedColors.slice(0, dataValues.length),
        borderColor: repeatedColors.slice(0, dataValues.length),
        borderWidth: 1,
        fill: false,
        borderRadius: 5,
        barPercentage: 0.4,
        maxBarThickness: 60
      }
    ]
  }

  // // console.log(data, 'data')

  const options = {
    type: 'bar',
    data: dataValues,
    hAxis: {
      title: 'Month',
      gridlines: {
        color: 'transparent'
      }
    },
    tooltip: {
      trigger: 'both'
    },
    focusTarget: 'category',
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        type: 'linear',
        beginAtZero: true,
        grid: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  }

  return (
    <>
      <p className='text-viewByDevice font-semibold text-xl pt-1'>
        {' '}
        Impressions
      </p>
      <p className='text-tableHeading font-semibold text-3xl'>
        {totalImpression?.totalImpressions > 1000 &&
        totalImpression?.totalImpressions < 100000
          ? totalImpression?.totalImpressions / 1000 + 'K'
          : totalImpression?.totalImpressions > 99999
          ? (totalImpression?.totalImpressions / 100000).toFixed(3) + 'L'
          : totalImpression?.totalImpressions}
      </p>
      <p className='text-insightsTopHeading text-xs mt-2'>
        Searches showed your Business Profile in the search results
      </p>
      <div className='mt-4 '>
        <Bar data={dataInput} options={options} width={200} height={135} />
      </div>
    </>
  )
}

export default SearchChart
