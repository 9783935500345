import { Button, Modal } from 'flowbite-react'
import React from 'react'

const PasswordChange = ({
  openPasswordModal,
  setOpenPasswordModal,
  OldPassword,
  SetOldPassword,
  NewPassword,
  SetNewPassword,
  confirmPassword,
  SetConfirmPassword,
  SavePassword
}) => {
  return (
    <div>
      <Modal
        show={openPasswordModal}
        onClose={() => setOpenPasswordModal(false)}
      >
        <Modal.Header className='p-3'>Password Change</Modal.Header>
        <Modal.Body>
          <div className='space-y-6'>
            <form className=''>
              <div className='relative'>
                <input
                  type='text'
                  id='current_password'
                  className='block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'
                  placeholder=' '
                  value={OldPassword}
                  name='currentpassword'
                  onChange={e => SetOldPassword(e.target.value)}
                  required
                />
                <label
                  for='current_password'
                  className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  Enter the Current Password
                </label>
              </div>
              <div className='relative mt-4'>
                <input
                  type='text'
                  value={NewPassword}
                  id='new_password'
                  className='block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'
                  placeholder=' '
                  name='newpassword'
                  onChange={e => SetNewPassword(e.target.value)}
                  required
                />
                <label
                  for='new_password'
                  className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  Enter the New Password
                </label>
              </div>
              <div className='relative mt-4'>
                <input
                  type='text'
                  value={confirmPassword}
                  id='confirm_password'
                  className='block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'
                  placeholder=' '
                  name='confirmpassword'
                  onChange={e => SetConfirmPassword(e.target.value)}
                  required
                />
                <label
                  for='confirm_password'
                  className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  Enter the Confirm Password
                </label>
              </div>

              <div className='flex justify-end gap-3 mt-4'>
                <button
                  type='submit'
                  className='px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:outline-none'
                  onClick={SavePassword}
                >
                  Save
                </button>
                <Button
                  color='gray'
                  onClick={() => {
                    setOpenPasswordModal(false)
                  }}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default PasswordChange
