import React from 'react'
import { Button, Modal } from 'flowbite-react'
const Profile = ({
  openProfileModal,
  setOpenProfileModal,
  setIsDisabled,
  setIsDisabled1,
  profilePic,
  IsDisabled2,
  handleFileSelector,
  handleFileChange,
  selectedFile,
  setIsDisabled2,
  setSelectedFile,
  setProfilePic,
  tokenDetails,
  profileEdit,
  IsDisabled,
  handleProfile,
  IsDisabled1,
  handleEditProfile,
  setProfileEdit
}) => {
  // console.log(profileEdit, 'profileEdit?.roleDetails?.roleName', tokenDetails)
  return (
    <div>
      <Modal
        show={openProfileModal}
        onClose={() => {
          setProfileEdit(tokenDetails?.info)
          setOpenProfileModal(false)
          setIsDisabled(true)
          setIsDisabled1(true)
          setProfilePic(tokenDetails?.info?.picture)
          setSelectedFile('')
          setIsDisabled2('edit')
        }}
      >
        <Modal.Header className='p-3'>Profile Edit</Modal.Header>
        <Modal.Body>
          <div className='space-y-6'>
            <form className=''>
              <div className='flex flex-col gap-3 mx-auto my-auto relative justify-center items-center w-24 h-32 pb-3'>
                <img
                  src={`${profilePic}`}
                  className=' rounded-full w-24 h-32'
                  alt='profile'
                />
                <div className=' flex items-center cursor-pointer'>
                  {IsDisabled2 === 'edit' ? (
                    <img
                      src='https://pro-manage.s3.ap-south-1.amazonaws.com/EditProfileIcon.svg'
                      alt='edit'
                      className='img-fluid absolute top-16 right-0 '
                      onClick={() => setIsDisabled2('upload')}
                    />
                  ) : IsDisabled2 === 'upload' ? (
                    <div className=''>
                      <p
                        className='text-sm text-listing font-semibold w-80 flex justify-center cursor-pointer'
                        onClick={handleFileSelector}
                      >
                        Choose Your Profile Pic
                      </p>
                      <input
                        type='file'
                        id='upload_file'
                        className='hidden'
                        onChange={handleFileChange}
                      />
                    </div>
                  ) : IsDisabled2 === 'cross' ? (
                    <img
                      src='https://pro-manage.s3.ap-south-1.amazonaws.com/Cross.svg'
                      alt='cross'
                      className='cursor-pointer rounded absolute top-0 right-0 hover:bg-stone-200 '
                      onClick={() => {
                        setProfilePic(tokenDetails?.info?.picture)
                        setIsDisabled2('edit')
                        setSelectedFile('')
                      }}
                    />
                  ) : (
                    'No Image'
                  )}
                  {selectedFile && (
                    <div className='flex items-center  p-2 mb-2 rounded-lg shadow-photoName'>
                      <img
                        src='https://pro-manage.s3.ap-south-1.amazonaws.com/showPhoto.svg'
                        alt='photo'
                        className='img-fluid'
                      />
                      <p className=' font-normal text-md text-RecommentationText'>
                        {' '}
                        {selectedFile?.name}
                      </p>
                      {/* <img src="https://pro-manage.s3.ap-south-1.amazonaws.com/DeleteIconforPhoto.svg" alt='ProfileDelete' className='cursor-pointer' onClick={() => setSelectedFile(null)} /> */}
                    </div>
                  )}
                </div>
              </div>

              <div className='relative '>
                <input
                  type='text'
                  id='floating_outlined'
                  class={`block px-2.5 pe-5 pb-2.5 pt-4 w-full text-sm  bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
                    IsDisabled
                      ? 'cursor-not-allowed text-gray-400'
                      : 'cursor-text text-gray-900 '
                  }`}
                  placeholder=' '
                  name='name'
                  value={profileEdit?.name}
                  disabled={IsDisabled}
                  onChange={e => handleProfile(e, 'text')}
                  required
                />
                <div className='absolute inset-y-0 end-2 flex items-center ps-3 cursor-pointer'>
                  <img
                    src='https://pro-manage.s3.ap-south-1.amazonaws.com/EditProfileIcon.svg'
                    alt='edit'
                    className='img-fluid'
                    onClick={() => setIsDisabled(false)}
                  />
                </div>
                <label
                  for='floating_outlined'
                  className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  Enter the Name *
                </label>
              </div>
              <div className='relative mt-4'>
                <input
                  type='tel'
                  maxLength='10'
                  minLength='10'
                  pattern='[0-9]*'
                  id='Number'
                  class={`block px-2.5 pe-5 pb-2.5 pt-4 w-full text-sm  bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
                    IsDisabled1
                      ? 'cursor-not-allowed text-gray-400'
                      : 'cursor-text text-gray-900 '
                  }`}
                  placeholder='  '
                  name='phone'
                  value={profileEdit?.phone}
                  disabled={IsDisabled1}
                  onChange={e => handleProfile(e, 'number')}
                  required
                />
                <label
                  for='Number'
                  className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  Enter Mobile Number
                </label>
                <div className='absolute inset-y-0 end-2 flex items-center ps-3 cursor-pointer'>
                  <img
                    src='https://pro-manage.s3.ap-south-1.amazonaws.com/EditProfileIcon.svg'
                    alt='edit'
                    className='img-fluid'
                    onClick={() => setIsDisabled1(false)}
                  />
                </div>
              </div>
              <div className='relative mt-4'>
                <input
                  type='email'
                  id='Enter_Email'
                  className='block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'
                  placeholder=' '
                  value={profileEdit?.email}
                  disabled={true}
                />

                <label
                  for='Enter_Email'
                  className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  Enter Email *
                </label>
              </div>
              {/* <div className='relative mt-4'>
                <input
                  type='role'
                  id='Enter_Role'
                  className='block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'
                  placeholder='  '
                  value={tokenDetails?.info?.roleDetails?.roleName}
                  disabled={true}
                />
                <label
                  for='Enter_Role'
                  className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  Role *
                </label>
              </div> */}
              <div className='relative mt-4'>
                <input
                  type='role'
                  id='Enter_Role'
                  className='block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'
                  placeholder='  '
                  value={tokenDetails?.info?.accessTypeDetails?.accessTypeName}
                  disabled={true}
                />
                <label
                  for='Enter_Role'
                  className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  Access Type *
                </label>
              </div>
              <div className='flex justify-end gap-3 mt-4'>
                <button
                  type='submit'
                  className='px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:outline-none'
                  onClick={e => {
                    handleEditProfile(e)
                  }}
                >
                  Save
                </button>
                <Button
                  color='gray'
                  onClick={() => {
                    setProfileEdit(tokenDetails?.info)
                    setOpenProfileModal(false)
                    setIsDisabled(true)
                    setIsDisabled1(true)
                    setProfilePic(tokenDetails?.info?.picture)
                    setSelectedFile('')
                    setIsDisabled2('edit')
                  }}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Profile
