import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

const initialValue = {
  UserInfo: []
}

export const UserInfo = create(
  persist(() => initialValue, {
    name: 'tmbUserData1',
    storage: createJSONStorage(() => localStorage),
    partialize: state => ({ UserInfo: state.UserInfo })
  })
)

export const setUserInfo = item =>
  UserInfo.setState(state => {
    return { UserInfo: item }
  })
