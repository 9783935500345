import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

const initialValue = {
  CalenderInfo: [],
  CalenderInsightInfo: [],
  CalenderQuestionInfo: []
}

export const CalenderInfo = create(
  persist(() => initialValue, {
    name: 'CalenderInfo',
    storage: createJSONStorage(() => localStorage),
    partialize: state => ({ CalenderInfo: state.CalenderInfo })
  })
)

export const CalenderInsightInfo = create(
  persist(() => initialValue, {
    name: 'CalenderInsightInfo',
    storage: createJSONStorage(() => localStorage),
    partialize: state => ({ CalenderInsightInfo: state.CalenderInsightInfo })
  })
)

export const CalenderQuestionInfo = create(
  persist(() => initialValue, {
    name: 'CalenderQuestionInfo',
    storage: createJSONStorage(() => localStorage),
    partialize: state => ({ CalenderQuestionInfo: state.CalenderQuestionInfo })
  })
)

export const setCalenderData = item => {
  CalenderInfo.setState(state => {
    return { CalenderInfo: item }
  })
}
export const setCalenderInsightsData = item => {
  CalenderInsightInfo.setState(state => {
    return { CalenderInsightInfo: item }
  })
}

export const setCalenderQuestionData = item => {
  CalenderQuestionInfo.setState(state => {
    return { CalenderQuestionInfo: item }
  })
}
