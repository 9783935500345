import React, { useContext } from 'react'
import { AuthContext } from '../context/AuthContext'

// import { BounceLetterLoader } from "react-spinner-overlay";
const Loader = () => {
  const { currentUser } = useContext(AuthContext)
  return (
    <div
      className={`fixed inset-0 ${
        currentUser?.info ? 'bg-white bg-opacity-75 ' : '  bg-white'
      } flex justify-center items-center z-50 text-center tracking-widest`}
    >
      <p className='flex justify-center items-center text-center'>
        <img
          className='w-28'
          src='https://pro-manage.s3.ap-south-1.amazonaws.com/tusklogo.gif'
          alt=''
        />
      </p>
    </div>
  )
}

export default Loader
