import React, { useContext, useEffect, useState } from 'react'
import { API_URL } from '../env/env'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import FirstForm from '../Components/MultiStepForm/FirstForm'
import SecondForm from '../Components/MultiStepForm/SecondForm'
import ThirdForm from '../Components/MultiStepForm/ThirdForm'
import FourthForm from '../Components/MultiStepForm/FourthForm'
import FifthForm from '../Components/MultiStepForm/FifthForm'
import SixthForm from '../Components/MultiStepForm/SixthForm'
import { AuthContext } from '../context/AuthContext'
import Loader from '../loader/loader'
import httpservice from '../Service/httpservice'

const StepOne = ({ values, onNext }) => {
  const [oldAdditional, setOldAdditional] = useState(
    values?.categories?.additionalCategories || []
  )
  const [title, setTitle] = useState(values?.title || '')

  const [primaryCategory, setprimaryCategory] = useState(
    values?.categories?.primaryCategory || ''
  )
  const [additionalCategories, setAdditionalCategories] = useState(
    values?.categories?.additionalCategories || []
  )

  const [storeCode, setStoreCode] = useState(values?.storeCode || '')

  const handleNext = () => {
    if (!title) {
      toast.error('Please enter Business Name')
      return
    }
    if (!primaryCategory) {
      toast.error('Please select Primary category')
      return
    }
    const validPayload = {
      languageCode: 'en',
      title,
      categories: {
        primaryCategory: primaryCategory
      }
    }

    if (additionalCategories?.length) {
      validPayload.categories.additionalCategories = additionalCategories
    }

    if (storeCode?.length) {
      validPayload.storeCode = storeCode
    }

    onNext(validPayload)
  }

  return (
    <div>
      <FirstForm
        title={title}
        setTitle={setTitle}
        storeCode={storeCode}
        setStoreCode={setStoreCode}
        primaryCategory={primaryCategory}
        setprimaryCategory={setprimaryCategory}
        oldAdditional={oldAdditional}
        setOldAdditional={setOldAdditional}
        additionalCategories={additionalCategories}
        setAdditionalCategories={setAdditionalCategories}
        handleNext={handleNext}
      />
    </div>
  )
}

const StepTwo = ({ values, onNext, onPrevious }) => {
  const [address, setAddress] = useState(
    values?.storefrontAddress?.addressLines[0] || ''
  )
  const [longitude, setLongitude] = useState(values?.latlng?.longitude || '')
  const [latitude, setlatitude] = useState(values?.latlng?.latitude || '')
  const [pincode, setPincode] = useState(
    values.storefrontAddress?.postalCode || ''
  )
  const [city, setCity] = useState(values?.storefrontAddress?.locality || '')
  const [state, setState] = useState(
    values?.storefrontAddress?.administrativeArea || ''
  )

  const handleNext = () => {
    if (!address) return toast.error('Please enter the Address ')
    if (!longitude) return toast.error('Please enter the longitude')
    if (!latitude) return toast.error('Please enter the latitude')
    if (!pincode) return toast.error('Please enter the pincode')
    if (!city) return toast.error('Please enter the city')
    if (!state) return toast.error('Please enter the state')
    onNext({
      ...values,
      storefrontAddress: {
        regionCode: 'IN',
        postalCode: pincode,
        administrativeArea: state,
        locality: city,
        addressLines: [address]
      },
      latlng: {
        latitude: latitude,
        longitude: longitude
      }
    })
  }

  const handlePrevious = () => {
    onPrevious({
      ...values,
      storefrontAddress: {
        regionCode: 'IN',
        postalCode: pincode,
        administrativeArea: state,
        locality: city,
        addressLines: [address]
      },
      latlng: {
        latitude: Number(latitude),
        longitude: Number(longitude)
      }
    })
  }
  // console.log(values, 'values', longitude)

  return (
    <div>
      <SecondForm
        address={address}
        setAddress={setAddress}
        longitude={longitude}
        setLongitude={setLongitude}
        latitude={latitude}
        setlatitude={setlatitude}
        pincode={pincode}
        setPincode={setPincode}
        city={city}
        setCity={setCity}
        state={state}
        setState={setState}
        handleNext={handleNext}
        onPrevious={handlePrevious}
      />
    </div>
  )
}

const StepThree = ({ values, onNext, onPrevious }) => {
  const [primaryNumber, setPrimaryNumber] = useState(
    values?.phoneNumbers?.primaryPhone || ''
  )
  const [alternateNumber, setAlternateNumber] = useState(
    values?.phoneNumbers || ''
  )
  const [websiteUri, setWebsiteUrl] = useState(values.websiteUri || '')

  const handleNext = () => {
    if (!primaryNumber) return toast.error('Please enter primary Number')

    const phoneNumbers = alternateNumber?.additionalPhones?.length
      ? {
          primaryPhone: primaryNumber,
          additionalPhones: alternateNumber?.additionalPhones
        }
      : { primaryPhone: primaryNumber }

    const updatedValues = { phoneNumbers, websiteUri }

    if (websiteUri?.length === 0) {
      delete updatedValues.websiteUri
    }

    onNext({ ...values, ...updatedValues })
  }

  const handlePrevious = () => {
    const phoneNumbers = alternateNumber?.additionalPhones?.length
      ? {
          primaryPhone: primaryNumber,
          additionalPhones: alternateNumber?.additionalPhones
        }
      : { primaryPhone: primaryNumber }

    const updatedValues = { phoneNumbers, websiteUri }
    onPrevious({ ...values, ...updatedValues })
  }

  return (
    <div>
      <ThirdForm
        primaryNumber={primaryNumber}
        setPrimaryNumber={setPrimaryNumber}
        alternateNumber={alternateNumber}
        setAlternateNumber={setAlternateNumber}
        websiteUri={websiteUri}
        setWebsiteUrl={setWebsiteUrl}
        handleNext={handleNext}
        onPrevious={handlePrevious}
      />
    </div>
  )
}

const StepFour = ({ values, onNext, onPrevious }) => {
  const businessDays = [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY'
  ]

  const [workingPeriods, setWorkingPeriod] = useState(
    businessDays
      ?.map(day =>
        values?.regularHours?.periods?.find(period => period.openDay === day)
      )
      .filter(period => period !== undefined)
  )

  const [additionalWorkingPeriod, setAdditionalWorkingPeriod] = useState(
    values?.regularHours?.periods?.filter(
      period => !workingPeriods.includes(period)
    )
  )

  const [toggleOption, setToggleOption] = useState(
    businessDays?.map(
      day =>
        values?.regularHours?.periods?.some(period => period.openDay === day) ??
        false
    )
  )
  const [additionalHr, setAdditionalHr] = useState(
    businessDays?.map(
      day =>
        values?.regularHours?.periods?.filter(period => period.openDay === day)
          ?.length === 2 ?? false
    )
  )

  const handleNext = () => {
    const combinedHours = [
      ...(workingPeriods?.length ? workingPeriods : []),
      ...(additionalWorkingPeriod?.length ? additionalWorkingPeriod : [])
    ]

    // console.log(combinedHours, 'combinedHours')
    onNext({
      ...values,
      ...(combinedHours &&
        combinedHours.length >= 1 && {
          regularHours: {
            periods: combinedHours
          }
        })
    })
  }

  const handlePrevious = () => {
    const combinedHours = [
      ...(workingPeriods?.length ? workingPeriods : []),
      ...(additionalWorkingPeriod?.length ? additionalWorkingPeriod : [])
    ]
    onPrevious({
      ...values,
      ...(combinedHours &&
        combinedHours.length >= 1 && {
          regularHours: {
            periods: combinedHours
          }
        })
    })
  }

  // console.log(values, 'values')
  return (
    <div>
      <FourthForm
        businessDays={businessDays}
        workingPeriods={workingPeriods}
        setWorkingPeriod={setWorkingPeriod}
        additionalWorkingPeriod={additionalWorkingPeriod}
        setAdditionalWorkingPeriod={setAdditionalWorkingPeriod}
        additionalHr={additionalHr}
        toggleOption={toggleOption}
        setToggleOption={setToggleOption}
        setAdditionalHr={setAdditionalHr}
        handleNext={handleNext}
        onPrevious={handlePrevious}
      />
    </div>
  )
}

const StepFive = ({ values, onNext, onPrevious }) => {
  const [description, setDescription] = useState(
    values?.profile?.description || ''
  )

  const handleNext = () => {
    if (!description) return toast.error('Please enter the description ')

    onNext({
      ...values,
      profile: {
        description
      }
    })
  }
  // console.log(values, 'values')

  const handlePrevious = () => {
    onPrevious({
      ...values,
      profile: {
        description
      }
    })
  }
  return (
    <div>
      <FifthForm
        description={description}
        setDescription={setDescription}
        handleNext={handleNext}
        onPrevious={handlePrevious}
      />
    </div>
  )
}

const StepSix = ({
  values,
  onConfirm,
  onPrevious,
  everyPrevious,
  isUploading,
  setIsUploading
}) => {
  const handleConfirm = () => {
    onConfirm(values)
  }

  const days = [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY'
  ]
  return (
    <SixthForm
      onPrevious={onPrevious}
      everyPrevious={everyPrevious}
      handleConfirm={handleConfirm}
      days={days}
      values={values}
      setIsUploading={setIsUploading}
      isUploading={isUploading}
    />
  )
}

const AddBusiness = () => {
  const [step, setStep] = useState(1)
  const [formValues, setFormValues] = useState({})
  const [load, setload] = useState(true)
  const [isUploading, setIsUploading] = useState(false)

  const navigate = useNavigate()

  const handleNext = data => {
    setFormValues({ ...formValues, ...data })
    setStep(step + 1)
  }

  const handlePrevious = data => {
    setFormValues({ ...formValues, ...data })
    setStep(step - 1)
  }

  const handleEveryPrevios = value => {
    setStep(step - value)
  }

  const { userInfo, groupId } = useContext(AuthContext)
  const handleConfirm = async locationData => {
    setIsUploading(true)
    try {
      let body = {
        userId: userInfo.info?.userId,
        groupId: groupId?.groupId,
        locationData
      }
      let url = `${API_URL}/location/create`

      // console.log(body, 'body')

      const response = await httpservice.post(url, body)

      if (response.status === 200) {
        toast.success(response?.data?.message)
        navigate(-1)
        setIsUploading(false)
      } else {
        toast.error(response?.data?.message)
        setIsUploading(false)
      }
    } catch (error) {
      setIsUploading(false)
      toast.error(error?.response?.data?.message)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setload(false)
    }, 1000)
  }, [])

  return (
    <div className='container md:px-4 py-2'>
      {load && <Loader />}
      <h2 className='text-2xl font-semibold text-Heading mt-4'>
        Start Create Business Profile
      </h2>
      <div
        className={`${step === 6 ? '' : 'flex justify-center items-center'}`}
      >
        {step === 1 && <StepOne values={formValues} onNext={handleNext} />}
        {step === 2 && (
          <StepTwo
            values={formValues}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        )}
        {step === 3 && (
          <StepThree
            values={formValues}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        )}
        {step === 4 && (
          <StepFour
            values={formValues}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        )}
        {step === 5 && (
          <StepFive
            values={formValues}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        )}
        {step === 6 && (
          <StepSix
            values={formValues}
            onConfirm={handleConfirm}
            onPrevious={handlePrevious}
            everyPrevious={handleEveryPrevios}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
          />
        )}
      </div>
    </div>
  )
}

export default AddBusiness
