import React from 'react'

const FourthForm = ({
  businessDays,
  workingPeriods,
  setWorkingPeriod,
  additionalWorkingPeriod,
  setAdditionalWorkingPeriod,
  additionalHr,
  toggleOption,
  setToggleOption,
  setAdditionalHr,
  handleNext,
  onPrevious
}) => {
  const handleToggleButton = (index, day, ToggleOption) => {
    // console.log(ToggleOption, 'ToggleOption', day)

    setToggleOption(prevState => {
      const newState = [...prevState]
      newState[index] = !newState[index]
      return newState
    })
    setAdditionalHr(prevState => {
      const newState = [...prevState]
      newState[index] = false
      return newState
    })

    if (ToggleOption === true) {
      setWorkingPeriod(prevState => {
        return prevState?.filter(item => item?.openDay !== day)
      })
      setAdditionalWorkingPeriod(prevState => {
        return prevState?.filter(item => item?.openDay !== day)
      })
    }
  }

  const handleChangeTimeSelection = async (
    index,
    Opentime,
    day,
    CloseTime,
    AdditionalHr
  ) => {
    let [startHours, startMinutes] = Opentime?.split(':') || []

    let [endHours, endMinutes] = CloseTime?.split(':') || []

    if (startHours === '00' || endHours === '00') {
      return
    }

    // For additional period only
    if (AdditionalHr === true) {
      setAdditionalWorkingPeriod(prevState => {
        const newState = prevState?.length ? [...prevState] : []
        const existingIndex = newState.findIndex(item => item?.openDay === day)
        if (existingIndex !== -1) {
          newState[existingIndex] = {
            openDay: day,
            openTime: { hours: startHours, minutes: startMinutes },
            closeDay: day,
            closeTime: { hours: endHours, minutes: endMinutes }
          }
        } else {
          newState.push({
            openDay: day,
            openTime: { hours: startHours, minutes: startMinutes },
            closeDay: day,
            closeTime: { hours: endHours, minutes: endMinutes }
          })
        }
        return newState
      })

      return
    }

    setWorkingPeriod(prevState => {
      const newState = prevState?.length ? [...prevState] : []

      // console.log(
      //   newState.filter(item => item?.openDay === day),
      //   'newState'
      // )

      const existingItemIndex = newState.findIndex(
        item => item?.openDay === day
      )

      if (existingItemIndex !== -1) {
        newState[existingItemIndex] = {
          openDay: day,
          openTime: { hours: startHours, minutes: startMinutes },
          closeDay: day,
          closeTime: { hours: endHours, minutes: endMinutes }
        }
      } else {
        newState.push({
          openDay: day,
          openTime: { hours: startHours, minutes: startMinutes },
          closeDay: day,
          closeTime: { hours: endHours, minutes: endMinutes }
        })
      }
      return newState
    })
  }

  const addNewSelection = index => {
    setAdditionalHr(prevState => {
      const newState = [...prevState]
      newState[index] = true
      return newState
    })
  }

  const AdditionaldeleteSelection = (index, day) => {
    setAdditionalHr(prevState => {
      const newState = [...prevState]
      newState[index] = false
      return newState
    })

    setAdditionalWorkingPeriod(prevState => {
      return prevState?.filter(item => item?.openDay !== day)
    })
  }

  const generateTimeOptions = () => {
    const options = []
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = `${(hour < 10 ? '0' : '') + hour}:${
          (minute < 10 ? '0' : '') + minute
        }`
        options.push(formattedHour)
      }
    }
    options.push('24:00')
    return options
  }

  const timeOptions = generateTimeOptions()
  return (
    <div>
      <div className='flex items-center'>
        <div className=''>
          <img
            src='https://pro-manage.s3.ap-south-1.amazonaws.com/step4.svg'
            className='img-fluid'
            alt='vector'
          />
        </div>
        <div className=' bg-white   rounded-2xl'>
          <div className='flex justify-between pb-2 ps-4 pt-4 pe-4'>
            <h2 className='text-2xl	font-medium'>Add business hours</h2>
          </div>
          <p className=' mb-3 ps-4 pe-10 text-businessProfile'>
            Let customers know when you are open for business{' '}
          </p>
          <form className=''>
            {businessDays?.map((item, index) => (
              <div className={`ps-4 pe-4 pb-3 flex   items-center gap-3`}>
                <p className='col-3'>{item}</p>

                <label className='relative inline-flex items-center cursor-pointer'>
                  <input
                    type='checkbox'
                    value=''
                    className='sr-only peer'
                    checked={toggleOption[index]}
                    onClick={() =>
                      handleToggleButton(index, item, toggleOption[index])
                    }
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:none peer-focus:ring-blue-0 dark:peer-focus:ring-listing rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-listing"></div>
                </label>

                <div className=' flex flex-col gap-4'>
                  {toggleOption[index] === true && (
                    <div className='flex justify-between items-center gap-2'>
                      <div className='relative '>
                        <select
                          className='rounded-3 border'
                          id={`OpenTime-${item}`}
                          defaultValue={
                            workingPeriods
                              ?.filter(item1 => item1?.openDay === item)
                              .map(filteredItem =>
                                filteredItem?.openTime
                                  ? `${String(
                                      filteredItem.openTime.hours
                                    ).padStart(2, '0')}:${
                                      filteredItem.openTime.minutes ?? '00'
                                    }`
                                  : '00:00'
                              )[0] || ''
                          }
                          onChange={e =>
                            handleChangeTimeSelection(
                              index,
                              e.target.value,
                              item,
                              document.getElementById(`CloseTime-${item}`).value
                            )
                          }
                        >
                          {timeOptions.map((time, timeIndex) => (
                            <option key={timeIndex} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>
                        <label
                          className={`absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 `}
                        >
                          Open At
                        </label>
                      </div>
                      <div className='relative '>
                        <select
                          className='rounded-3 border'
                          id={`CloseTime-${item}`}
                          defaultValue={
                            workingPeriods
                              ?.filter(item1 => item1?.openDay === item)
                              .map(filteredItem =>
                                filteredItem?.closeTime
                                  ? `${String(
                                      filteredItem.closeTime.hours
                                    ).padStart(2, '0')}:${
                                      filteredItem.closeTime.minutes ?? '00'
                                    }`
                                  : '00:00'
                              )[0] || ''
                          }
                          onChange={e =>
                            handleChangeTimeSelection(
                              index,
                              document.getElementById(`OpenTime-${item}`).value,
                              item,
                              e.target.value
                            )
                          }
                        >
                          {timeOptions.map((time, timeIndex) => (
                            <option key={timeIndex} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>
                        <label className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'>
                          Close At
                        </label>
                      </div>

                      <p
                        className={`font-semibold text-2xl text-listing cursor-pointer `}
                        onClick={() => addNewSelection(index, item)}
                      >
                        +
                      </p>
                    </div>
                  )}

                  {toggleOption[index] === false && (
                    <p className='font-normal text-base text-black'>Closed</p>
                  )}

                  {toggleOption[index] === true &&
                    additionalHr[index] === true && (
                      <div className='flex justify-between items-center gap-2  '>
                        <div className='relative'>
                          <select
                            className='rounded-3 border'
                            id={`AdditionalOpenTime-${item}`}
                            defaultValue={
                              additionalWorkingPeriod
                                ?.filter(item1 => item1?.openDay === item)
                                .map(filteredItem =>
                                  filteredItem?.openTime
                                    ? `${String(
                                        filteredItem.openTime.hours
                                      ).padStart(2, '0')}:${
                                        filteredItem.openTime.minutes ?? '00'
                                      }`
                                    : '00:00'
                                )[0] || ''
                            }
                            onChange={e =>
                              handleChangeTimeSelection(
                                index,
                                e.target.value,
                                item,
                                document.getElementById(
                                  `AdditionalCloseTime-${item}`
                                ).value,
                                true
                              )
                            }
                          >
                            {timeOptions.map((time, timeIndex) => (
                              <option key={timeIndex} value={time}>
                                {time}
                              </option>
                            ))}
                          </select>
                          <label className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'>
                            Open At
                          </label>
                        </div>
                        <div className='relative '>
                          <select
                            className='rounded-3 border'
                            id={`AdditionalCloseTime-${item}`}
                            defaultValue={
                              additionalWorkingPeriod
                                ?.filter(item1 => item1?.openDay === item)
                                .map(filteredItem =>
                                  filteredItem?.closeTime
                                    ? `${String(
                                        filteredItem.closeTime.hours
                                      ).padStart(2, '0')}:${
                                        filteredItem.closeTime.minutes ?? '00'
                                      }`
                                    : '00:00'
                                )[0] || ''
                            }
                            onChange={e =>
                              handleChangeTimeSelection(
                                index,
                                document.getElementById(
                                  `AdditionalOpenTime-${item}`
                                ).value,
                                item,
                                e.target.value,
                                true
                              )
                            }
                          >
                            {timeOptions.map((time, timeIndex) => (
                              <option key={timeIndex} value={time}>
                                {time}
                              </option>
                            ))}
                          </select>
                          <label className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'>
                            Close At
                          </label>
                        </div>
                        <img
                          src='https://pro-manage.s3.ap-south-1.amazonaws.com/Delete.svg'
                          className='img-fluid cursor-pointer'
                          alt='alternative'
                          onClick={() => AdditionaldeleteSelection(index, item)}
                        />
                      </div>
                    )}
                </div>
              </div>
            ))}

            <div className='bg-formBottom flex justify-around pb-4 p-2  rounded-b-2xl'>
              <button
                type='button'
                onClick={onPrevious}
                className='bg-formBottom border-1 border-listing text-listing text-xl mt-3 rounded-xl w-44 pt-2 pb-2'
              >
                Previous
              </button>
              <button
                type='button'
                onClick={handleNext}
                className='bg-listing text-light text-xl mt-3 rounded-xl w-44 pt-2 pb-2'
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default FourthForm
