import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { useLocation } from 'react-router-dom'
import Loader from '../loader/loader'
import { Empty, Popover } from 'antd'
import { API_URL } from '../env/env'
import httpservice from '../Service/httpservice'
const QuestionNotification = () => {
  const {
    userInfo,
    groupId,
    tokenInfo,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleQuestionCountNotification,
    handleReviewNotification,
    handleLocationNotification,
    locationNewNotification
  } = useContext(AuthContext)

  const [questionNotification, setQuestionNotification] = useState([])

  useEffect(() => {
    tokenInfo()
  }, [])

  useEffect(() => {
    handleLocationCountNotification(true)
    handleReviewCountNotification(true)
    handleQuestionCountNotification(true)
    // handleReviewNotification(true)
    // handleLocationNotification(true)
    if (groupId) {
      handleNotification()
    }
  }, [groupId])

  const handleNotification = async () => {
    try {
      const currentDate = new Date()
      const startDate = new Date()
      startDate.setDate(startDate.getDate() - 20)
      const formattStartDate = startDate.toISOString().split('T')[0]
      const formattedEndDate = currentDate.toISOString().split('T')[0]
      let url = `${API_URL}/qa/notification`
      let body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        startDate: formattStartDate,
        endDate: formattedEndDate,
        newNotification: 0
      }
      let response = await httpservice.post(url, body)

      if (response.status === 200) {
        // console.log(response?.data?.data, 'notification')
        setQuestionNotification(response?.data?.data?.notification)
        setIsPending(true)
        setload(false)
      } else {
        setQuestionNotification([])
        setIsPending(true)
        setload(false)
      }
    } catch {
      setIsPending(true)
      setload(false)
      setQuestionNotification([])
    }
  }

  const [load, setload] = useState(true)

  const [isPending, setIsPending] = useState(false)

  const location = useLocation()

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)

  const totalPages = Math.ceil(questionNotification?.length / pageSize)

  const changePage = pageNumber => setCurrentPage(pageNumber)

  const changePageSize = size => {
    setPageSize(size)
    setCurrentPage(1)
  }

  useEffect(() => {
    tokenInfo()
  }, [])
  const indexOfLastItem = currentPage * pageSize
  const indexOfFirstItem = indexOfLastItem - pageSize
  const currentItems = questionNotification?.slice(
    indexOfFirstItem,
    indexOfLastItem
  )

  const EmptyWithShadow = ({ condition }) => (
    <div
      className={`${condition ? '' : 'hidden'}`}
      style={{
        boxShadow: condition ? '0px 2px 5px rgba(0, 0, 0, 0.1)' : 'none',
        borderRadius: condition ? '0 0 10px 10px' : '0'
      }}
    >
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className='mt-0 pt-5' />
    </div>
  )

  const [ques, setQues] = useState('')

  const content = (
    <div className=' text-sm text-gray-500 dark:text-gray-400 p-2'>
      <div className='flex justify-start items-center w-[30rem]'>
        <div className='px-3 py-2'>
          <h4 className='text-black text-lg font-bold'>Question</h4>
          <p className='text-xs text-notVerified mt-2 min-h-[50px] max-h-[100px] overflow-x-auto'>
            {ques}
          </p>
        </div>
      </div>
    </div>
  )
  return (
    <div>
      {load && <Loader />}
      <div className='relative overflow-x-auto rounded-t-none roundered-b-4 p-8 pt-1  bg-custom-background4 m-6 mt-0'>
        <div className='flex justify-end  items-center'>
          <div className=' pb-3 col-2 '>
            <select
              id='pageSize'
              className='bg-white border   border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
              value={pageSize}
              onChange={e => changePageSize(e.target.value)}
            >
              {' '}
              {[25, 50, 100]?.map(option => (
                <option key={option} value={option}>
                  Show {option} Entries
                </option>
              ))}
            </select>
          </div>
        </div>
        <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
          <thead className='text-base text-start text-gray-700 capitalize bg-newTableBG dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              {/* <th scope='col' className='px-6 py-3 font-semibold rounded-s-lg'>
                Sl.no
              </th> */}
              <th scope='col' className='px-6 py-3 font-semibold  rounded-s-lg'>
                EditedBy
              </th>
              <th scope='col' className='px-6 py-3 font-semibold'>
                Group
              </th>
              <th scope='col' className='px-6 py-3 font-semibold'>
                Location
              </th>
              <th scope='col' className='px-6 py-3 font-semibold'>
                Question
              </th>
              <th scope='col' className='px-6 py-3 font-semibold'>
                Answer
              </th>
              <th scope='col' className='px-6 py-3 font-semibold'>
                Action
              </th>
              <th scope='col' className='px-6 py-3 font-semibold rounded-e-lg'>
                Date&Time
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((item, index) => (
              <tr
                key={index}
                className={`text-start rounded-b-2xl dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600 ${
                  index % 2 === 0
                    ? 'bg-custom-background4'
                    : 'bg-custom-background5'
                }`}
              >
                <td className='px-6 py-2 border-r text-notificationCount font-semibold col-1'>
                  {item?.editedBy?.name}
                </td>
                <td className='px-6 py-2 border-r text-notificationCount font-semibold col-1'>
                  {item?.group?.name}
                </td>
                <td className='px-6 py-2 border-r font-medium text-notificationTime col-2'>
                  {item?.location?.name}
                  {item?.location?.address}
                </td>
                <td className='px-6 py-2 border-r text-notificationCount  font-medium col-3'>
                  {item?.quesAns?.ques.length > 60 ? (
                    <span>
                      {item?.quesAns?.ques.slice(0, 60)}
                      <Popover
                        placement='leftTop'
                        content={content}
                        trigger='click'
                        className=''
                        onClick={() => setQues(item?.quesAns?.ques)}
                      >
                        <span className='text-blue-500 cursor-pointer'>
                          ...Read More
                        </span>
                      </Popover>
                    </span>
                  ) : (
                    item?.quesAns?.ques
                  )}
                </td>
                <td className='px-6 py-2 border-r text-notificationCount  font-medium col-2'>
                  {item?.quesAns?.ans?.length > 60 ? (
                    <span>
                      {item?.quesAns?.ans.slice(0, 60)}
                      <Popover
                        placement='leftTop'
                        content={content}
                        trigger='click'
                        className=''
                        onClick={() => setQues(item?.quesAns?.ans)}
                      >
                        <span className='text-blue-500 cursor-pointer'>
                          ...Read More
                        </span>
                      </Popover>
                    </span>
                  ) : (
                    item?.quesAns?.ans
                  )}
                </td>
                <td className='px-6 py-2 border-r text-notificationCount  font-medium col-2'>
                  {item?.comment}
                </td>
                <td className='px-6 py-2 font-medium text-ViewActivity col-1'>
                  <p className='mb-0'>{item?.updatedDate} </p>
                  <p className='mb-0'>{item?.updatedTime}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <EmptyWithShadow condition={currentItems?.length === 0} />

        <div className='flex justify-end items-center mt-4'>
          <div className='me-2 font-medium text-notificationCount'>
            {/* Showing {currentItems?.length} out of {totalPages} */}
            Total Results {questionNotification?.length}
          </div>
          {currentPage !== 1 && (
            <div
              className='me-2 cursor-pointer'
              onClick={() => changePage(currentPage - 1)}
            >
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/PaginationNextArrow.svg'
                alt='PaginationNextArrow'
                className='transform rotate-180 w-8 h-8'
              />
            </div>
          )}
          <div className='gap-2 flex'>
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i}
                onClick={() => changePage(i + 1)}
                className={`px-2.5 py-1  rounded-md ${
                  i + 1 === currentPage
                    ? 'bg-listing text-white font-semibold dark:bg-gray-800'
                    : 'border text-paginationColor font-semibold'
                }`}
              >
                {i + 1}
              </button>
            ))}
          </div>
          {totalPages > 1 && currentPage < totalPages && (
            <div
              className='ms-2 cursor-pointer'
              onClick={() => changePage(currentPage + 1)}
            >
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/PaginationNextArrow.svg'
                alt='PaginationNextArrow'
                className='w-8 h-8'
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default QuestionNotification
