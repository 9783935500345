import React, { useContext, useState } from 'react'
import { AccountDetails } from '../../Store/AccountInfo'
import { LocationDetails } from '../../Store/LocationInfo'
import { API_URL } from '../../env/env'
import httpservice from '../../Service/httpservice'
import { Button, Modal } from 'flowbite-react'
import { toast } from 'react-toastify'
import { handleInputValidation } from '../../helper/validator'
import { AuthContext } from '../../context/AuthContext'

const NumberModal = ({
  AlternateNumber,
  SetAlternateNumber,
  primaryPhone,
  setOpenModalNumber,
  openModalNumber,
  setPrimaryPhone,
  invalidateAndRefetch,
  handleLocationCountNotification
}) => {
  // const id = AccountDetails((state) => state.AccountDetails);
  // const tokenDetails = JSON.parse(localStorage.getItem("tmbUserData"));
  const { userInfo, groupId } = useContext(AuthContext)
  const Locationid = LocationDetails(state => state.LocationDetails)
  const [isDisabled, setIsDisabled] = useState(true)
  const handleAdd = () => {
    if (
      !AlternateNumber?.additionalPhones ||
      AlternateNumber?.additionalPhones?.length < 2
    ) {
      SetAlternateNumber(prevAlternateNumber => ({
        ...prevAlternateNumber,
        additionalPhones: [...(prevAlternateNumber.additionalPhones || []), '']
      }))
    }
  }

  const handleChangeAltNumber = (index, newValue) => {
    if (
      AlternateNumber?.additionalPhones &&
      index < AlternateNumber.additionalPhones.length
    ) {
      if (/^[\d\s]+$/.test(newValue) || newValue === '') {
        if (newValue.length <= 12) {
          SetAlternateNumber(prevAlternateNumber => {
            const updatedPhones = [...prevAlternateNumber.additionalPhones]
            updatedPhones[index] = newValue
            return {
              ...prevAlternateNumber,
              additionalPhones: updatedPhones
            }
          })
        }
      }
    }
  }

  const handleRemove = indexToRemove => {
    if (AlternateNumber?.additionalPhones) {
      SetAlternateNumber(prevAlternateNumber => ({
        ...prevAlternateNumber,
        additionalPhones: prevAlternateNumber.additionalPhones.filter(
          (_, index) => index !== indexToRemove
        )
      }))
    }
  }

  const handleEditNumber = async e => {
    e.preventDefault()
    try {
      // console.log(AlternateNumber)
      let body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        locationId: Locationid,
        sectionName: 'Phone Number',
        updateData: {
          phoneNumbers: {
            primaryPhone: primaryPhone,
            additionalPhones: AlternateNumber?.additionalPhones
          }
        }
      }

      const url = `${API_URL}/location/update?updateMask=phoneNumbers`
      const res = await httpservice.patch(url, body)

      if (res.status === 200) {
        setOpenModalNumber(false)
        setIsDisabled(true)
        invalidateAndRefetch()
        if (userInfo?.info?.roleDetails?.roleId === 1) {
          handleLocationCountNotification(true)
        }
        toast.success('Number updated, reflects in 10.')
      } else {
        setIsDisabled(true)
        toast.error(res?.data?.message)
        setOpenModalNumber(false)
      }
    } catch (err) {
      setOpenModalNumber(false)
      setIsDisabled(true)
      toast.error(err?.response?.data?.message)
      // console.log(err)
    }
  }
  return (
    <div>
      <Modal
        show={openModalNumber}
        onClose={() => {
          setOpenModalNumber(false)
          setIsDisabled(true)
        }}
      >
        <Modal.Header className='p-3'>
          <p className=' font-medium text-2xl'>Edit Contact Number</p>
        </Modal.Header>
        <Modal.Body>
          <div className='space-y-6'>
            <p className='text-md font-medium text-listing dark:text-gray-400'>
              Provide a local phone number instead of a helpline number, if
              possible
            </p>
            <form>
              <div className='relative '>
                <input
                  type='text'
                  id='Primary Number'
                  className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
                    isDisabled
                      ? 'cursor-not-allowed text-gray-400'
                      : 'cursor-text text-gray-900 '
                  }  `}
                  placeholder=' '
                  disabled={isDisabled}
                  value={primaryPhone}
                  onChange={e =>
                    handleInputValidation(e.target.value, setPrimaryPhone, 3)
                  }
                />
                <div className='absolute inset-y-0 end-2 flex items-center ps-3 cursor-pointer'>
                  <img
                    src='https://pro-manage.s3.ap-south-1.amazonaws.com/OverViewEdit.svg'
                    alt='edit'
                    className='img-fluid'
                    onClick={() => setIsDisabled(false)}
                  />
                </div>
                <label
                  for='Primary Number'
                  className='absolute text-sm font-semibold text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  Primary Number
                </label>
              </div>
              {AlternateNumber?.additionalPhones?.map((phone, index) => (
                <div key={`phone-input-${index}`} className='relative mt-4'>
                  <div className='relative'>
                    <div className='relative '>
                      <input
                        className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer cursor-text text-gray-900  `}
                        placeholder=' '
                        value={phone}
                        onChange={e =>
                          handleChangeAltNumber(index, e.target.value)
                        }
                        type='text'
                        id={`additional-number-input-${index}`}
                      />
                      <div className='absolute inset-y-0 end-2 flex items-center ps-3 cursor-pointer'>
                        <img
                          src='https://pro-manage.s3.ap-south-1.amazonaws.com/Delete.svg'
                          alt='edit'
                          className='img-fluid'
                          onClick={() => handleRemove(index)}
                        />
                      </div>
                      <label
                        for='Alternate'
                        htmlFor={`additional-number-input-${index}`}
                        className='absolute text-sm font-semibold text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                      >
                        {index === 0
                          ? 'Alternate Number 1'
                          : `Alternate Number ${index + 1}`}
                      </label>
                    </div>
                  </div>
                </div>
              ))}

              {(!AlternateNumber?.additionalPhones ||
                AlternateNumber?.additionalPhones?.length < 2) && (
                <p
                  className={`text-listing font-semibold font-md mt-4 cursor-pointer ${
                    AlternateNumber?.additionalPhones?.length === 2
                      ? 'hidden'
                      : ''
                  }`}
                  onClick={handleAdd}
                >
                  + Add Another Number
                </p>
              )}
            </form>
            <div className='mt-3 bg-caution rounded-lg'>
              {/* <p className='text-decoration-underline text-cautionText text-lg mt-3 p-2 pb-0 font-medium'>Caution</p> */}
              <div className=' p-2 flex'>
                <i className='fa fa-warning text-red-600'></i>
                <p className='text-cautionText flex gap-1'>
                  <img
                    src='https://pro-manage.s3.ap-south-1.amazonaws.com/caution.svg'
                    className='w-5'
                  />
                  Updating the Phone Number might take time around 10 - 15 min{' '}
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='pt-0 justify-end'>
          <div className='flex justify-end gap-3 mt-4'>
            <button
              color='gray'
              onClick={() => {
                setOpenModalNumber(false)
                setIsDisabled(true)
              }}
              className='px-4 py-2 text-md font-medium text-center border-1 text-gray  rounded-lg focus:outline-none'
            >
              Cancel
            </button>
            <button
              type='submit'
              className='px-4 py-2 text-md font-medium text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none'
              onClick={e => handleEditNumber(e)}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default NumberModal
