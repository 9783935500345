// Production Means let production = true
// Development Means let production = false

let production = true

const environment = {
  API_URL: production
    ? 'https://cub.tuskmelonpro.com/api'
    : 'http://localhost:8000/api'
}

module.exports = environment
