export const handleInputValidation = (value, setStateValue, type) => {
    let alphabetOnly = /^[a-zA-Z]+$/;
    let alphabetAndSpace = /^[a-zA-Z\s]+$/;
    let numberOnly = /^[0-9]*$/;
    let numberAndSpace = /^[\d\s]+$/;
    let email = /^[a-zA-Z0-9@.]*$/; // Alphabet, number, @, .
    let decimal = /^[0-9.]*$/; // Numeric characters and a single dot

    switch (type) {
        case 0: // Name validation
            if (alphabetOnly.test(value) || value === '') {
                setStateValue(value);
            }
            break;
        case 1: // Name with space validation
            if (alphabetAndSpace.test(value) || value === '') {
                setStateValue(value);
            }
            break;
        case 2: // Phone number with 10 digits validation
            if (numberOnly.test(value) || value === '') {
                if (value.length <= 10) {
                    setStateValue(value);
                }
            }
            break;
        case 3: // Phone number with space and 12 digits validation
            if (numberAndSpace.test(value) || value === '') {
                if (value.length <= 12) {
                    setStateValue(value);
                }
            }
            break;
        case 4: // Email validation
            if (email.test(value) || value === '') {
                setStateValue(value);
            }
            break;
        case 5: // Decimal validation
            if (decimal.test(value) || value === '') {
                setStateValue(value);
            }
            break;
        default:
            setStateValue(value);
            break;
    }
}
