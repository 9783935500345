import { API_URL } from '../env/env'
import React, { useContext, useEffect, useState } from 'react'
import Upload from '../Components/PhotoUpload'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import httpservice from '../Service/httpservice'
import { AccountDetails } from '../Store/AccountInfo'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import { Empty, Popover } from 'antd'
import Loader from '../loader/loader'

const UploadBulkPost = () => {
  const { userInfo, groupId } = useContext(AuthContext)
  const { tokenInfo } = useContext(AuthContext)
  const [isPending, setIsPending] = useState(false)
  const [load, setload] = useState(true)

  useEffect(() => {
    tokenInfo()
  }, [])

  const [summary, setSummary] = useState('')
  const [actionType, setActionType] = useState('LEARN_MORE')
  const [websiteurl, setWebsiteurl] = useState('')
  const [selectedFile, setSelectedFile] = useState()
  const [selectedFileName, setSelectedFileName] = useState()
  const [locationOptions, setLocationOptions] = useState([])
  const [isUploading, setIsUploading] = useState(false)

  // console.log(locationOptions, 'locationOptions')

  const [checked, setChecked] = useState([])
  const [unChecked, setUnChecked] = useState([])
  const [Total, SetTotal] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  const [checkedData, setCheckedData] = useState([])

  const [unCheckedData, setUnCheckedData] = useState([])

  const [RadioOptions, setRadioOptions] = useState('Total')

  const [listOfLocations, setListOfLocations] = useState([])
  // console.log(listOfLocations, 'listOfLocations')

  const queryClient = useQueryClient()

  useEffect(() => {
    option()
  }, [groupId])

  const navigate = useNavigate()

  const handleFileUpload = e => {
    e.preventDefault()
    setIsUploading(true)
    // console.log('Create Post !!!!!!!! ')
    if (locationOptions.length < 1) {
      toast.error('Please Select Location')
      return
    }

    const formData = new FormData()
    formData.append('userId', userInfo.info?.userId)
    formData.append('groupId', groupId.groupId)
    formData.append('summary', summary)
    formData.append('actionType', actionType || 'NONE')
    formData.append('url', websiteurl)
    formData.append('the_file', selectedFile || '')
    locationOptions.map((item, index) =>
      formData.append(`locationArray[${index}]`, item)
    )
    // console.log('formData : ', formData)
    bulkPostUpload(formData)
  }

  const option = async () => {
    try {
      let body = {
        userId: userInfo.info?.userId,
        groupId: groupId.groupId
      }

      let url = `${API_URL}/location/verified`

      const response = await httpservice.post(url, body)
      if (response.status === 200) {
        // console.log(response, 'locationResponse')
        setListOfLocations(
          response?.data?.data?.map((item, index) => ({
            id: index + 1,
            ...item
          })) || []
        )
        setIsPending(true)
      } else {
        setIsPending(true)
      }
    } catch (error) {
      setIsPending(true)
      // console.log(error, 'error')
    }
  }
  // console.log('locationOptions', locationOptions)

  const bulkPostUpload = async formData => {
    try {
      // console.log(formData, 'formData')
      let url = `${API_URL}/post/bulk_upload`
      const response = await httpservice.post(url, formData)
      if (response.status === 200) {
        // console.log(response, 'bulkpostResponse')
        toast.success(response?.data?.message)
        setSelectedFile(null)
        setSummary('')
        setActionType('LEARN_MORE')
        setWebsiteurl('')
        setIsUploading(false)
        setLocationOptions([])
        setAllCheckBox(false)
        setcheckAll(false)
      } else {
        setIsUploading(false)
        toast.error(response?.data?.message)
      }
    } catch (error) {
      setIsUploading(false)
      // console.log(error, 'error')
      toast.error(error?.response?.data?.message)
    }
  }

  const [allCheckBox, setAllCheckBox] = useState(false)
  const [checkAll, setcheckAll] = useState(false)

  const handleBulkCheckBox = allCheckBox => {
    // console.log(allCheckBox, 'allCheckBox')
    if (allCheckBox === false) {
      setLocationOptions(listOfLocations.map(item => item.locationId))
      setCheckedData(listOfLocations.map(item => item))
      setUnCheckedData([])
    } else {
      setCheckedData([])
      setUnCheckedData(listOfLocations?.map(item => item))
      setLocationOptions([])
    }
    setAllCheckBox(!allCheckBox)
    setcheckAll(!checkAll)
  }

  const handleSingleCheckBox = id => {
    // setUnCheckedData()
    const updatedOptions = locationOptions?.includes(id)
      ? locationOptions.filter(item => item !== id)
      : [...locationOptions, id]

    setLocationOptions(updatedOptions)

    const checkedData = listOfLocations.filter(item =>
      updatedOptions?.includes(item?.locationId)
    )

    const uncheckedData = listOfLocations.filter(
      item => !updatedOptions?.includes(item?.locationId)
    )

    setUnCheckedData(uncheckedData)
    setCheckedData(checkedData)
    setcheckAll(false)
    setAllCheckBox(false)
  }

  // console.log(
  //   locationOptions
  //     .map(item => listOfLocations.filter(item1 => item1?.locationId === item))
  //     ?.flatMap(item => item),
  //   'locationOptions123'
  // )

  const handleCheckButton = RadioValue => {
    if (RadioValue === 'Checked') {
      setCheckedData(
        listOfLocations.filter(item =>
          locationOptions?.includes(item?.locationId)
        )
      )
    }
    if (RadioValue === 'Un-Checked') {
      // console.log(
      //   listOfLocations?.map(
      //     (item, index) => checkedData?.includes(item) === false && item
      //   ),
      //   'uncheckedData'
      // )
      setUnCheckedData(
        listOfLocations.filter(
          item => !locationOptions?.includes(item?.locationId)
        )
      )
    }
    if (RadioValue === 'All') {
      // option()
      // setLocationOptions(listOfLocations.map((item) => item.locationId))
    }
  }

  const filteredTotal = listOfLocations?.filter(
    user =>
      user?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      user?.address?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      user?.city?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      user?.state?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      user?.postal_code?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  )

  const filteredChecked = checkedData?.filter(
    user =>
      user?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      user?.address?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      user?.city?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      user?.state?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      user?.postal_code?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  )

  const filteredUnChecked = unCheckedData?.filter(
    user =>
      user?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      user?.address?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      user?.city?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      user?.state?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
      user?.postal_code?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  )

  const handleReturn = () => {
    navigate(-1)
  }

  const EmptyWithShadow = ({ condition }) => (
    <div
      className={`${condition ? '' : 'hidden'}`}
      style={{
        boxShadow: condition ? '0px 2px 5px rgba(0, 0, 0, 0.1)' : 'none',
        borderRadius: condition ? '0 0 10px 10px' : '0'
      }}
    >
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className='mt-0 pt-5 mb-0' />
    </div>
  )

  const content = (
    <div>
      <p>
        Please Fill All the Required <br /> Fields to Enable Button
      </p>
    </div>
  )

  useEffect(() => {
    if (isPending) {
      // // console.log(isPending, 'ispending', load)
      // debugger
      setload(false)
    }
  }, [isPending])

  return (
    <div className='container p-4  '>
      {load && <Loader />}
      <p className='text-Heading font-semibold text-3xl pt-4 pb-3'>
        Bulk Upload Post
      </p>
      <div className=' rounded-xl p-3 pt-2 bg-custom-background4'>
        <div className='space-y-6  p-3 rounded-md'>
          <div className='flex justify-between items-center mt-4'>
            <div className='flex bg-white rounded-full p-2 w-10'>
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/returnIcon.svg'
                className='img-fluid cursor-pointer'
                onClick={handleReturn}
              />
            </div>
            <div className='flex  gap-3 '>
              <button
                color='gray'
                onClick={() => {
                  setSelectedFile(null)
                  setSummary('')
                  setActionType('LEARN_MORE')
                  setWebsiteurl('')
                  setLocationOptions([])
                  setAllCheckBox(false)
                  setcheckAll(false)
                }}
                type='button'
                className='px-4 py-2 text-md font-medium text-center border-1 text-gray  rounded-lg focus:outline-none'
              >
                Clear
              </button>
              <Popover
                placement='leftTop'
                content={
                  (summary?.length > 1 && actionType === 'NONE') ||
                  (summary?.length > 1 && actionType === 'CALL')
                    ? null
                    : summary?.length > 1 && websiteurl?.length
                    ? null
                    : content
                }
              >
                <button
                  type='submit'
                  className={`px-4 py-2 text-md font-medium text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none ${
                    (summary?.length > 1 && actionType === 'NONE') ||
                    (summary?.length > 1 && actionType === 'CALL')
                      ? 'cursor-pointer'
                      : summary?.length > 1 && websiteurl?.length
                      ? 'cursor-pointer'
                      : 'cursor-not-allowed opacity-50'
                  }`}
                  onClick={handleFileUpload}
                  disabled={
                    summary?.length <= 1 ||
                    (!websiteurl &&
                      actionType !== 'NONE' &&
                      actionType !== 'CALL') ||
                    isUploading
                  }
                >
                  Upload
                </button>
              </Popover>
            </div>
          </div>
          <form>
            <div id='PostUpload'>
              <div>
                {selectedFile ? (
                  <div className='flex justify-center '>
                    <div>
                      <div className='flex flex-col justify-center items-center'>
                        <img
                          src={URL.createObjectURL(selectedFile)}
                          alt='Selected File Preview'
                          id='imageFile'
                          className='img-fluid object-cover '
                          style={{ maxWidth: '100%', maxHeight: '200px' }}
                        />
                        {/* <div className='flex items-center gap-3 pt-3'>
                                                            <p className=' font-semibold text-lg text-listing'><span className='text-black' >File Name :</span> {selectedFileName}</p>
                                                            <img src="https://pro-manage.s3.ap-south-1.amazonaws.com/PostMedia.svg" alt='ProfileDelete' className='cursor-pointer' onClick={() => setSelectedFile(null)} />
                                                        </div> */}
                        <div className='flex items-center justify-between pt-3 p-2 mt-4 rounded-lg w-100 shadow-photoName'>
                          <div className='flex items-center'>
                            <img
                              src={
                                'https://pro-manage.s3.ap-south-1.amazonaws.com/showPhoto.svg'
                              }
                              alt='photo'
                              className='img-fluid'
                            />
                            <p className=' font-normal text-md text-RecommentationText'>
                              {' '}
                              {selectedFileName}
                            </p>
                          </div>
                          <img
                            src='https://pro-manage.s3.ap-south-1.amazonaws.com/DeleteIconforPhoto.svg'
                            alt='ProfileDelete'
                            className='cursor-pointer'
                            onClick={() => setSelectedFile(null)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <Upload
                      setSelectedFileName={setSelectedFileName}
                      setSelectedFile={setSelectedFile}
                      bulkUpload={'true'}
                    />
                    <div className='bg-Recommentation  rounded-lg ps-4 pb-3 pt-1 mt-3'>
                      <p className='mt-3 text-RecommentationText text-sm 	font-semibold '>
                        Recommendations for Google:
                      </p>

                      <p className='mt-2 text-RecommentationText text-xs	font-medium'>
                        • Do not post media which is not related to your
                        business category
                      </p>

                      <p className='mt-2 text-RecommentationText text-xs	font-medium'>
                        • It is advised to have the logo of your brand or
                        textual mention in the picture
                      </p>

                      <p className='mt-2 text-RecommentationText text-xs	font-medium'>
                        • Do not add phone number and links in the description
                        when posting to google
                      </p>

                      <p className='mt-2 text-RecommentationText text-xs	font-medium'>
                        • Photo - 4:3 ratio | JPG or PNG or JPGE format | File
                        size of 10 KB to 5 MB
                      </p>
                    </div>
                  </>
                )}
              </div>

              <h3 className='text-xl font-medium pt-3 pb-2'>Description </h3>
              <div className='relative'>
                <textarea
                  id='message'
                  rows='5'
                  style={{
                    resize: 'none',
                    height: 'auto',
                    minHeight: '150px'
                  }}
                  className={`block p-2.5 bg-custom-background4 w-full text-sm text-gray-900 rounded-lg border ${
                    summary?.length > 1495
                      ? 'border-red-500'
                      : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                  } dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  value={summary}
                  onChange={e => setSummary(e.target.value.slice(0, 1500))}
                ></textarea>

                <div className='absolute bottom-1 right-2 text-xs text-gray-500'>
                  {summary?.length}/1500
                </div>
              </div>

              <div className='mt-3 mb-3'>
                <h3 className='text-xl font-medium  pb-2'>Category </h3>
                <div className='flex  items-center gap-3 cursor-pointer'>
                  <p
                    className={` rounded-lg text-base ps-2.5 pe-2.5 pt-1 pb-1 text-center ${
                      actionType === 'BOOK'
                        ? 'bg-Check border-1 border-listing text-listing font-medium'
                        : 'border-1'
                    }`}
                    onClick={() => setActionType('BOOK')}
                  >
                    Book
                  </p>
                  <p
                    className={` rounded-lg text-base ps-2.5 pe-2.5 pt-1 pb-1 text-center ${
                      actionType === 'ORDER'
                        ? 'bg-Check border-1 border-listing text-listing font-medium'
                        : 'border-1'
                    }`}
                    onClick={() => setActionType('ORDER')}
                  >
                    Order
                  </p>
                  <p
                    className={` rounded-lg ps-2.5 pe-2.5 pt-1 pb-1 text-center ${
                      actionType === 'SIGN_UP'
                        ? 'bg-Check border-1 border-listing text-listing font-medium'
                        : 'border-1'
                    }`}
                    onClick={() => setActionType('SIGN_UP')}
                  >
                    Sign up
                  </p>
                  <p
                    className={` rounded-lg text-base ps-2.5 pe-2.5 pt-1 pb-1 text-center ${
                      actionType === 'BUY'
                        ? 'bg-Check border-1 border-listing text-listing font-medium'
                        : 'border-1'
                    }`}
                    onClick={() => setActionType('BUY')}
                  >
                    Buy
                  </p>
                  <p
                    className={` rounded-lg text-base ps-2.5 pe-2.5 pt-1 pb-1 text-center ${
                      actionType === 'LEARN_MORE'
                        ? 'bg-Check border-1 border-listing text-listing font-medium'
                        : 'border-1'
                    }`}
                    onClick={() => setActionType('LEARN_MORE')}
                  >
                    Learn more
                  </p>
                  <p
                    className={` rounded-lg text-base ps-2.5 pe-2.5 pt-1 pb-1 text-center ${
                      actionType === 'CALL'
                        ? 'bg-Check border-1 border-listing text-listing font-medium'
                        : 'border-1'
                    }`}
                    onClick={() => setActionType('CALL')}
                  >
                    Call
                  </p>
                  <p
                    className={` rounded-lg text-base ps-2.5 pe-2.5 pt-1 pb-1 text-center ${
                      actionType === 'NONE'
                        ? 'bg-Check border-1 border-listing text-listing font-medium'
                        : 'border-1'
                    }`}
                    onClick={() => setActionType('NONE')}
                  >
                    None
                  </p>
                </div>
              </div>
              {/* <p className='text-xl font-medium pb-2 '>Action Type :<span className='capitalize'> {actionType}</span></p> */}

              {actionType !== '' &&
              actionType !== 'NONE' &&
              actionType !== 'CALL' ? (
                <div className='relative'>
                  <input
                    type='text'
                    id='websiteUrl'
                    className='block bg-custom-background4 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'
                    placeholder=' '
                    onChange={e => setWebsiteurl(e.target.value)}
                    value={websiteurl}
                  />
                  <label
                    for='websiteUrl'
                    className='absolute text-md font-medium text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-custom-background4 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                  >
                    websiteUrl
                  </label>
                </div>
              ) : (
                ''
              )}
            </div>
          </form>
        </div>

        <div className='bg-white rounded-lg mt-3'>
          <div className='flex justify-between items-center p-4 pt-3 '>
            <div className='position-relative  col-7'>
              <div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
                <img
                  src='https://pro-manage.s3.ap-south-1.amazonaws.com/searchIcon.svg'
                  alt='searchIcon'
                />
              </div>
              <input
                className='text-sm  col-10  serch_btn ps-12'
                placeholder='Search'
                type='search'
                value={searchTerm}
                onChange={e => {
                  setSearchTerm(e.target.value)
                }}
              />
            </div>
            <div className='flex justify-between gap-3 text-bulkUploadCount text-xs font-semibold'>
              <div className='flex items-center  gap-2'>
                <input
                  id='list-radio-license'
                  type='radio'
                  value='Total'
                  onClick={e => {
                    handleCheckButton(e.target.value)
                    setRadioOptions(e.target.value)
                  }}
                  checked={RadioOptions === 'Total' && true}
                  name='list-radio'
                  className='w-4 h-4 text-listing bg-gray-100 border-gray-300  dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-0 dark:bg-gray-600 dark:border-gray-500'
                />
                <p className=''>
                  Total :{' '}
                  <span className='text-listing text-base'>
                    {listOfLocations.length}
                  </span>
                </p>
              </div>
              <div className='flex items-center gap-2'>
                <input
                  id='list-radio-id'
                  type='radio'
                  value='Checked'
                  onClick={e => {
                    handleCheckButton(e.target.value)
                    setRadioOptions(e.target.value)
                  }}
                  name='list-radio'
                  className='w-4 h-4 text-listing bg-gray-100 border-gray-300  dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-0 dark:bg-gray-600 dark:border-gray-500'
                />
                <p>
                  Checked :{' '}
                  <span className='text-completedBulkPost text-base'>
                    {locationOptions.length}
                  </span>
                </p>
              </div>
              <div className='flex items-center gap-2'>
                <input
                  id='list-radio-military'
                  type='radio'
                  value='Un-Checked'
                  onClick={e => {
                    handleCheckButton(e.target.value)
                    setRadioOptions(e.target.value)
                  }}
                  name='list-radio'
                  className='w-4 h-4 text-listing bg-gray-100 border-gray-300  dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-0 dark:bg-gray-600 dark:border-gray-500'
                />
                <p>
                  Un-Checked :{' '}
                  <span className='text-TotalColor text-base'>
                    {' '}
                    {listOfLocations.length - locationOptions.length}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className='relative overflow-x-auto shadow-md sm:rounded-lg border-[0.5px] m-4 mt-0 border-black'>
            <table className='w-full text-left rtl:text-right text-gray-500 dark:text-gray-400 '>
              <thead className=' text-black text-lg capitalize bg-newTableBG dark:bg-gray-700 dark:text-gray-400'>
                <tr>
                  <th scope='col' className='px-3 py-3 w-40 rounded-s-lg '>
                    {/* <div className="flex items-center gap-3 ">
                                            <input id="checkbox-all-search" onClick={() => handleBulkCheckBox(allCheckBox)} checked={checkAll} type="checkbox" className="w-5 h-5 text-listing bg-gray-100 border-2  focus:ring-0 dark:focus:ring-0 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"

                                            />
                                            <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                            <p className='font-semibold'>Select All</p>
                                        </div> */}

                    <div class='form-group flex items-center gap-4 mb-0'>
                      <input
                        type='checkbox'
                        onClick={() => handleBulkCheckBox(allCheckBox)}
                        checked={checkAll}
                        id='html'
                      />
                      <label
                        htmlFor='html'
                        className='font-semibold before:rounded-sm before:mr-5'
                      >
                        Select All
                      </label>
                    </div>
                  </th>
                  <th scope='col' className='px-3 py-3 rounded-e-lg'>
                    <p className='font-semibold'>Business Profile</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {((RadioOptions === 'Total' && filteredTotal) ||
                  (RadioOptions === 'Checked' && filteredChecked) ||
                  (RadioOptions === 'Un-Checked' && filteredUnChecked)) && (
                  <>
                    {(
                      (RadioOptions === 'Total' && filteredTotal) ||
                      (RadioOptions === 'Checked' && filteredChecked) ||
                      (RadioOptions === 'Un-Checked' && filteredUnChecked)
                    ).map((item, index) => (
                      <tr
                        key={index}
                        className='bg-white dark:bg-gray-800 border-b border-tableLatest hover:bg-gray-50 dark:hover:bg-gray-600'
                      >
                        <td className='w-4  p-3 pe-0 border-r border-tableLatest'>
                          {/* <div className="flex items-center">
                                                        <input id={`checkbox-table-${index}`} onClick={() => handleSingleCheckBox(item?.locationId)} checked={locationOptions?.includes(item?.locationId)} type="checkbox" className="w-5 h-5 text-listing bg-gray-100 border-2 focus:ring-0 dark:focus:ring-0 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600" />
                                                        <label htmlFor={`checkbox-table-${index}`} className="sr-only">checkbox</label>
                                                    </div> */}
                          <div class='form-group formgroup1'>
                            <input
                              type='checkbox'
                              onClick={() =>
                                handleSingleCheckBox(item?.locationId)
                              }
                              checked={locationOptions?.includes(
                                item?.locationId
                              )}
                              id={`checkbox-table-${index}`}
                            />
                            <label
                              htmlFor={`checkbox-table-${index}`}
                              className='font-semibold before:rounded-sm '
                            ></label>
                          </div>
                        </td>
                        <th
                          scope='row'
                          className='px-3 py-2 flex-wrap font-medium text-gray-900 whitespace-wrap dark:text-white'
                        >
                          <p className='font-normal text-lg text-lightBlack leading-7'>
                            {item?.title}
                          </p>
                          <p className='font-normal text-xs text-businessProfile'>
                            {item?.address} {item?.city} {item?.state}{' '}
                            {item?.postal_code}
                          </p>
                        </th>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
            <EmptyWithShadow
              condition={
                (
                  (RadioOptions === 'Total' && filteredTotal) ||
                  (RadioOptions === 'Checked' && filteredChecked) ||
                  (RadioOptions === 'Un-Checked' && filteredUnChecked)
                )?.length === 0
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadBulkPost
