import React from 'react'
import { Button, Modal } from 'flowbite-react'
const QuestionEditModal = ({
  editQuestionJson,
  openQuestionEditModal,
  setOpenQuestionEditModal,
  editQuestion,
  setEditQuestion,
  handleEditQuestion,
  setSelectedLocation
}) => {
  // console.log(editQuestionJson, 'editQuestionJson')
  return (
    <div>
      <Modal
        dismissible
        show={openQuestionEditModal}
        onClose={() => {
          setOpenQuestionEditModal(false)
          setEditQuestion('')
          setSelectedLocation('')
        }}
      >
        <Modal.Header className='p-3 bg-reviewPopUp rounded-t-2xl    '>
          Edit Question
        </Modal.Header>
        <Modal.Body className=' bg-reviewPopUp'>
          <div className=''>
            <div className='d-flex gap-2 justify-content-start align-items-center'>
              <img
                className='w-14'
                src={
                  editQuestionJson?.details?.author?.type === 'MERCHANT'
                    ? 'https://pro-manage.s3.ap-south-1.amazonaws.com/DummyProfileManager.svg'
                    : editQuestionJson?.details?.author?.profilePhotoUri
                }
                alt='profileImg'
              />
              <p className='text-lg font-medium'>
                {editQuestionJson?.details?.author?.displayName}
              </p>
            </div>
            <div className='d-flex flex-col justify-content-start mt-3'>
              <p className='m-0 text-md font-medium text-black'>
                {' '}
                Business : {editQuestionJson?.title}
              </p>
              <p className='text-sm text-businessProfile'>
                {editQuestionJson.address}
              </p>
            </div>
            <form className='mt-3'>
              <div className='relative'>
                <div className='form-floating relative questionEdit'>
                  <textarea
                    // ref={replyarea}
                    style={{ height: '250px' }}
                    className='form-control comment_flot rounded-2xl border-0 '
                    // placeholder='Leave a comment here'
                    id='floatingTextarea'
                    value={editQuestion}
                    onChange={e =>
                      setEditQuestion(e.target.value?.slice(0, 4000))
                    }
                  ></textarea>
                  <label
                    for='floatingTextarea'
                    className='text-businessProfile mb-3'
                  >
                    Edit Your Question
                  </label>
                </div>
                <div className='absolute bottom-1 right-2 text-xs text-gray-500'>
                  {editQuestion?.length}
                  /4000
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className='pt-3 justify-end '>
          <div className='flex justify-end gap-4 bg-white '>
            <button
              type='submit'
              className='px-4 py-2 text-md font-medium text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none  '
              onClick={handleEditQuestion}
            >
              Send
            </button>
            <button
              color='gray'
              onClick={() => {
                openQuestionEditModal(false)
                setEditQuestion('')
                setSelectedLocation('')
              }}
              className='px-4 py-2 text-md font-medium text-center border-1 text-gray  rounded-lg focus:outline-none'
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default QuestionEditModal
