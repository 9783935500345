import React, { useContext } from 'react'
import { AccountDetails } from '../../Store/AccountInfo'
import { LocationDetails } from '../../Store/LocationInfo'
import { API_URL } from '../../env/env'
import httpservice from '../../Service/httpservice'
import { Button, Modal } from 'flowbite-react'
import { toast } from 'react-toastify'
import { AuthContext } from '../../context/AuthContext'

const DescriptionModal = ({
  openModalDescription,
  setOpenModalDescription,
  Description,
  SetDescription,
  invalidateAndRefetch,
  handleLocationCountNotification
}) => {
  // const id = AccountDetails((state) => state.AccountDetails);
  // const tokenDetails = JSON.parse(localStorage.getItem("tmbUserData"));
  const { userInfo, groupId } = useContext(AuthContext)
  const Locationid = LocationDetails(state => state.LocationDetails)
  const handleEditDespcription = async e => {
    e.preventDefault()
    try {
      let body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        locationId: Locationid,
        sectionName: 'Description',
        updateData: {
          profile: {
            description: Description
          }
        }
      }
      const url = `${API_URL}/location/update?updateMask=profile.description`
      const res = await httpservice.patch(url, body)

      if (res.status === 200) {
        setOpenModalDescription(false)
        invalidateAndRefetch()
        if (userInfo?.info?.roleDetails?.roleId === 1) {
          handleLocationCountNotification(true)
        }
        toast.success('Description updated, reflects in 10.')
      } else {
        setOpenModalDescription(false)
        toast.error(res?.data?.message)
      }
    } catch (error) {
      setOpenModalDescription(false)
      toast.error(error?.response?.data?.message)
      // console.log(error)
    }
  }
  return (
    <div>
      <Modal
        show={openModalDescription}
        onClose={() => setOpenModalDescription(false)}
      >
        <Modal.Header className='p-3'>
          <p className=' font-medium text-2xl'>Edit Description</p>
        </Modal.Header>
        <Modal.Body>
          <div className='space-y-6'>
            <p className='text-md font-medium text-listing dark:text-gray-400'>
              Describe your business, highlight the top services you offer,
              showcase your products, and outline the competitive advantages and
              benefits you provide.
            </p>
            <form>
              <div className='relative'>
                <textarea
                  value={Description}
                  onChange={e => {
                    SetDescription(e.target.value.slice(0, 750))
                  }}
                  id='default-input'
                  className='bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                  placeholder='Description'
                  rows={10}
                />
                <div className='absolute bottom-1 right-2 text-xs text-gray-500'>
                  {Description?.length}/750
                </div>
              </div>

              <div className='mt-3 bg-caution rounded-lg'>
                {/* <p className='text-decoration-underline text-cautionText text-lg mt-3 p-2 pb-0 font-medium'>Caution</p> */}
                <div className=' p-2 flex'>
                  {/* <i className="fa fa-warning text-red-600" ></i> */}
                  <p className='text-cautionText flex gap-1'>
                    <img
                      src='https://pro-manage.s3.ap-south-1.amazonaws.com/caution.svg'
                      className='w-5'
                    />
                    No sales offers,links,URL, or contact number
                  </p>
                </div>
                <div className=' p-2 flex'>
                  {/* <i className="fa fa-warning text-red-600" ></i> */}
                  <p className='text-cautionText flex gap-1'>
                    <img
                      src='https://pro-manage.s3.ap-south-1.amazonaws.com/caution.svg'
                      className='w-5'
                    />
                    Updating the Description might take time around 10 - 15 min{' '}
                  </p>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className='pt-0 justify-end'>
          <div className='flex justify-end gap-3 mt-4'>
            <button
              color='gray'
              onClick={() => {
                setOpenModalDescription(false)
              }}
              className='px-4 py-2 text-md font-medium text-center border-1 text-gray  rounded-lg focus:outline-none'
            >
              Cancel
            </button>
            <button
              type='submit'
              className='px-4 py-2 text-md font-medium text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none'
              onClick={e => handleEditDespcription(e)}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default DescriptionModal
