export const lineGraph=<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_249_967)">
<path d="M4.99875 14.2496H0.73875C0.53625 14.2496 0.375 14.4146 0.375 14.6134V23.3246C0.375 23.5271 0.54 23.6884 0.73875 23.6884H4.99875C5.20125 23.6884 5.3625 23.5234 5.3625 23.3246V14.6134C5.3625 14.4109 5.1975 14.2496 4.99875 14.2496ZM4.635 22.9609H1.10625V14.9809H4.635V22.9609ZM11.0887 11.5534H6.82875C6.62625 11.5534 6.465 11.7184 6.465 11.9171V23.3246C6.465 23.5271 6.63 23.6884 6.82875 23.6884H11.0887C11.2913 23.6884 11.4525 23.5234 11.4525 23.3246V11.9209C11.4525 11.7184 11.2875 11.5571 11.0887 11.5571V11.5534ZM10.725 22.9609H7.19625V12.2846H10.725V22.9609ZM17.175 8.82337H12.915C12.7125 8.82337 12.5513 8.98837 12.5513 9.18712V23.3284C12.5513 23.5309 12.7162 23.6921 12.915 23.6921H17.175C17.3775 23.6921 17.5387 23.5271 17.5387 23.3284V9.18712C17.5387 8.98462 17.3737 8.82337 17.175 8.82337ZM16.8113 22.9646H13.2825V9.55462H16.8113V22.9609V22.9646ZM23.2612 5.47087H19.0012C18.7987 5.47087 18.6375 5.63587 18.6375 5.83462V23.3284C18.6375 23.5309 18.8025 23.6921 19.0012 23.6921H23.2612C23.4637 23.6921 23.625 23.5271 23.625 23.3284V5.83462C23.625 5.63212 23.46 5.47087 23.2612 5.47087ZM22.8975 22.9646H19.3688V6.20212H22.8975V22.9609V22.9646ZM18.9 4.57462C19.095 4.57462 19.2563 4.42087 19.2638 4.22587L19.3837 1.31962C19.3912 1.11712 19.2337 0.948368 19.035 0.940868L16.1287 0.820868C15.93 0.809618 15.7575 0.970867 15.75 1.16962C15.7425 1.37212 15.9 1.54087 16.0988 1.54837L18.0375 1.62712C16.065 3.16837 9.93 7.58962 0.9675 10.5109C0.77625 10.5746 0.67125 10.7809 0.735 10.9721C0.78375 11.1259 0.93 11.2234 1.08375 11.2234C1.12125 11.2234 1.15875 11.2159 1.19625 11.2046C10.4662 8.18212 16.7588 3.56962 18.6225 2.09212L18.5363 4.19212C18.5287 4.39462 18.6863 4.56337 18.885 4.57087C18.8888 4.57087 18.8962 4.57087 18.9 4.57087V4.57462Z" fill="#323B4B"/>
</g>
<defs>
<clipPath id="clip0_249_967">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
