import React, { useContext, useEffect, useState } from 'react'
import { AccountDetails } from '../../Store/AccountInfo'
import { LocationDetails } from '../../Store/LocationInfo'
import { API_URL } from '../../env/env'
import httpservice from '../../Service/httpservice'
import { Button, Modal } from 'flowbite-react'
import AdditionalCategoryInput from '../AdditionalCategoryInput'
import { toast } from 'react-toastify'
import { AuthContext } from '../../context/AuthContext'

const CategoryModal = ({
  setAdditionalCategories,
  additionalCategories,
  primaryCategoryold,
  openModalCategories,
  setOpenModalCategories,
  setNewAdditional,
  setShowDropdown,
  setShowDropdown1,
  showDropdown,
  showDropdown1,
  newAdditional,
  invalidateAndRefetch,
  staticCategories,
  handleLocationCountNotification
}) => {
  // console.log(
  //   primaryCategoryold,
  //   'primaryCategoryold',
  //   additionalCategories,
  //   newAdditional
  // )

  // console.log(additionalCategories?.length, 'additionalCategories')
  const [TotalCountAddCat, setTotalCountAddCat] = useState(
    staticCategories?.length
  )
  const MAX_ADDITIONAL_CATEGORIES = 8 - additionalCategories?.length
  const [newAdditionalInputs, setNewAdditionalInputs] = useState(
    Array.from({ length: MAX_ADDITIONAL_CATEGORIES }, () => '')
  )
  // const id = AccountDetails((state) => state.AccountDetails);
  // const tokenDetails = JSON.parse(localStorage.getItem("tmbUserData"));
  const Locationid = LocationDetails(state => state.LocationDetails)
  const [dataList, setDataList] = useState([])
  const [AddnewCategory, setAddnewCategory] = useState(0)
  const [primaryCategory, setprimaryCategory] = useState(primaryCategoryold)
  const [isDisabled, setIsDisabled] = useState(true)
  // const [AddnewCategory, setAddnewCategory] = useState(0)

  // console.log(AddnewCategory, 'AddnewCategory', staticCategories)

  const { userInfo, groupId } = useContext(AuthContext)
  const [isDisabled1, setIsDisabled1] = useState(true)
  useEffect(() => {
    setprimaryCategory(primaryCategoryold)
  }, [primaryCategoryold])
  const [index, setIndex] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const handleEditCategory = async e => {
    e.preventDefault()
    try {
      let body = {
        userId: userInfo.info?.userId,
        groupId: groupId?.groupId,
        locationId: Locationid,
        sectionName: 'Categories',
        updateData: {
          categories: {
            primaryCategory,
            additionalCategories
          }
        }
      }
      // console.log(body, 'body')
      const res = await httpservice.patch(
        `${API_URL}/location/update?updateMask=categories`,
        body
      )
      // console.log(res, 'res')
      if (res.status === 200) {
        setOpenModalCategories(false)
        setIsDisabled(true)
        setInputValue('')
        setAddnewCategory(0)
        if (userInfo?.info?.roleDetails?.roleId === 1) {
          handleLocationCountNotification(true)
        }
        invalidateAndRefetch()
        toast.success('Category Updated,reflects in 10.')
      } else {
        setOpenModalCategories(false)
        setIsDisabled(true)
        toast.error(res?.data?.message)
      }
    } catch (error) {
      setOpenModalCategories(false)
      setIsDisabled(true)
      toast.error(error?.response?.data?.message)
      // console.log(error)
    }
  }

  const handleAddNewCategory = async (index, data) => {
    if (AddnewCategory >= 8) {
      toast.error('Maximum 8 categories can be added')
      return
    }
    setAddnewCategory(AddnewCategory + 1)
  }
  // const handleRemove = RemovedCatName => {
  //   const filteredAdditionalCat = oldAdditional.filter(
  //     category => category?.displayName !== RemovedCatName
  //   )
  //   setOldAdditional(filteredAdditionalCat)
  //   // console.log(RemovedCatName, 'RemovedCatName')
  //   // console.log(filteredAdditionalCat, 'RemovedCatName')

  //   setAdditionalCategories(prevState =>
  //     prevState.filter(category => category?.displayName !== RemovedCatName)
  //   )
  // }
  const handleRemove = categoryName => {
    const updatedCategories = additionalCategories?.filter(
      category => category?.displayName !== categoryName
    )
    const updatedCategories2 = newAdditional?.filter(
      category => category?.displayName !== categoryName
    )
    setAdditionalCategories(updatedCategories || [])
    setNewAdditional(updatedCategories2 || [])
  }

  // console.log(additionalCategories, 'newAdditionalInputs', newAdditional)

  const handleAltCat = cat => {
    setAdditionalCategories(prevCategories => [...(prevCategories || []), cat])
  }

  const handleclick = cat => {
    setprimaryCategory(cat)
    setShowDropdown(false)
  }

  const handleAdditionalCategory = async (index, data) => {
    const updatedInputs = Array.isArray(newAdditionalInputs)
      ? [...(newAdditionalInputs || [])]
      : []
    updatedInputs[index] = data
    setNewAdditionalInputs({ name: [...(updatedInputs || [])] })

    try {
      const body = {
        userId: userInfo?.info?.userId,
        displayName: data
      }
      const res = await httpservice.post(
        `${API_URL}/other/business_categories`,
        body
      )
      // console.log(res, 'res')

      const updatedDataList = [...(dataList || [])]
      updatedDataList[index] = res?.data?.data?.categories

      setDataList(updatedDataList)
      setShowDropdown1(true)
    } catch (error) {
      console.error(error)
    }
  }

  const handleBusinessCategory = async data => {
    setprimaryCategory({ displayName: data })
    try {
      const body = {
        userId: userInfo.info?.userId,
        displayName: data
      }
      const res = await httpservice.post(
        `${API_URL}/other/business_categories`,
        body
      )
      // console.log(res, 'res')
      setDataList(res?.data?.data?.categories)
      setShowDropdown(true)
    } catch (error) {
      console.error(error)
    }
  }

  // console.log(primaryCategory, 'primaryCategory')

  // console.log(AddnewCategory + additionalCategories?.length, 'newAdditional')
  return (
    <div>
      <Modal
        show={openModalCategories}
        onClose={() => {
          setOpenModalCategories(false)
          setIsDisabled(true)
          setShowDropdown(false)
          setInputValue('')
          setAddnewCategory(0)
          setAdditionalCategories([])
          setNewAdditionalInputs('')
        }}
      >
        <Modal.Header className='p-3'>
          <p className=' font-medium text-2xl'>Edit Category</p>
        </Modal.Header>
        <Modal.Body>
          <div className='space-y-6'>
            <p className='text-md font-medium text-listing dark:text-gray-400'>
              Choose categories that exactly represent your business. The choice
              of your primary and secondary categories contributes majorly to
              your search rankings.
            </p>
            <form>
              <div className='relative w-full mb-4'>
                <div className='relative '>
                  <input
                    type='text'
                    id='primaryCategory'
                    className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
                      isDisabled
                        ? 'cursor-not-allowed text-gray-400'
                        : 'cursor-text text-gray-900 '
                    }  `}
                    placeholder=' '
                    disabled={isDisabled}
                    value={primaryCategory?.displayName}
                    onChange={e => handleBusinessCategory(e.target.value)}
                  />
                  <div className='absolute inset-y-0 end-2 flex items-center ps-3 cursor-pointer'>
                    <img
                      src='https://pro-manage.s3.ap-south-1.amazonaws.com/OverViewEdit.svg'
                      alt='edit'
                      className='img-fluid'
                      onClick={() => setIsDisabled(false)}
                    />
                  </div>
                  <label
                    for='primaryCategory'
                    className='absolute text-sm font-semibold text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                  >
                    Primary Category
                  </label>
                </div>

                {showDropdown && primaryCategory?.displayName && (
                  <div className=' top-full mt-2 bg-white border border-gray-300 shadow-lg rounded-lg h-auto min-h-0 max-h-72 overflow-y-scroll'>
                    {dataList.map((item, index) => (
                      <div
                        key={index}
                        index={index}
                        setIndex={setIndex}
                        className='p-2 cursor-pointer hover:bg-gray-100'
                        onClick={() => {
                          handleclick(item)
                        }}
                      >
                        {item.displayName}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {newAdditional?.length > 0 &&
                newAdditional?.map(item => (
                  <div className='relative w-full mt-4'>
                    <div className='relative '>
                      <input
                        type='text'
                        id='AdditionalCategory'
                        className={`cursor-not-allowed text-gray-400 block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 pee  `}
                        placeholder=' '
                        disabled={true}
                        value={item?.displayName}
                      />
                      <div className='absolute inset-y-0 end-2 flex items-center ps-3 cursor-pointer'>
                        <img
                          src='https://pro-manage.s3.ap-south-1.amazonaws.com/Delete.svg'
                          alt='edit'
                          className='img-fluid'
                          onClick={() => handleRemove(item?.displayName)}
                        />
                      </div>
                      <label
                        for='AdditionalCategory'
                        className='absolute text-sm font-semibold text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                      >
                        Additional Category
                      </label>
                    </div>
                    {/* <input type="text" value={item.name} id="simple-search" className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search branch name..." required /> */}
                  </div>
                ))}

              {[...Array(AddnewCategory)].map((_, index) => (
                <AdditionalCategoryInput
                  key={index}
                  index={index}
                  setIndex={setIndex}
                  handleAdditionalCategory={handleAdditionalCategory}
                  handleAltCat={handleAltCat}
                  dataList={dataList}
                  AddnewCategory={AddnewCategory}
                  setAddnewCategory={setAddnewCategory}
                  additionalCategories={additionalCategories}
                  setAdditionalCategories={setAdditionalCategories}
                  addBusiness={true}
                />
              ))}
              {primaryCategory?.name?.length > 0 && (
                <div className={`pt-3`}>
                  <p
                    className=' text-listing text-base font-semibold cursor-pointer'
                    onClick={() => handleAddNewCategory()}
                  >
                    {(AddnewCategory || 0) + (staticCategories?.length || 0) <
                      8 && ' + Add Additional Categories'}
                  </p>
                </div>
              )}

              <div className='mt-3 bg-caution rounded-lg'>
                {/* <p className='text-decoration-underline text-cautionText text-lg mt-3 p-2 pb-0 font-medium'>Caution</p> */}
                <div className=' p-2 flex'>
                  {/* <i className="fa fa-warning text-red-600" ></i> */}
                  <p className='text-cautionText gap-1 flex'>
                    <img
                      src='https://pro-manage.s3.ap-south-1.amazonaws.com/caution.svg'
                      className='w-5'
                    />
                    Updating the Primary Category might lead to the profile
                    getting suspended by Google
                  </p>
                </div>
                <div className='mt-3 p-2 flex'>
                  {/* <i className="fa fa-warning text-red-600" ></i> */}
                  <p className='text-cautionText gap-1 flex'>
                    <img
                      src='https://pro-manage.s3.ap-south-1.amazonaws.com/caution.svg'
                      className='w-5'
                    />
                    Updating the Category might take time around 10 - 15 min
                  </p>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className='pt-0 justify-end'>
          <div className='flex justify-end gap-3 mt-4'>
            <button
              color='gray'
              onClick={() => {
                setOpenModalCategories(false)
                setIsDisabled(true)
                setShowDropdown(false)
                setInputValue('')
                setAddnewCategory(0)
                setNewAdditionalInputs('')
                setAdditionalCategories([])
              }}
              className='px-4 py-2 text-md font-medium text-center border-1 text-gray  rounded-lg focus:outline-none'
            >
              Cancel
            </button>
            <button
              type='submit'
              className='px-4 py-2 text-md font-medium text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none'
              onClick={e => handleEditCategory(e)}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CategoryModal
