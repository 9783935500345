import React, { useRef } from 'react'
import httpservice from '../../Service/httpservice'
import { Button, Modal } from 'flowbite-react'
import { toast } from 'react-toastify'
import { set } from 'date-fns'

const ReviewFilter = ({
  openModalReview,
  setOpenModalReview,
  selectedValues,
  selectedOption,
  responseFilter,
  handleChange,
  handleResponse,
  handleFilter,
  setSelectedValues,
  rating,
  responetype,
  setResponse,
  setSelectedOption,
  reviewType
}) => {
  const handleCheckboxChange = value => {
    const updatedValues = selectedValues.split(',').filter(val => val !== '')
    const stringValue = String(value)
    const selectedIndex = updatedValues.indexOf(stringValue)
    if (selectedIndex === -1) {
      updatedValues.push(stringValue)
    } else {
      updatedValues.splice(selectedIndex, 1)
    }
    const formattedOutput = updatedValues.join(',')
    setSelectedValues(formattedOutput)
  }
  // console.log(selectedValues, 'selectedValues')
  const modalRef = useRef(null)

  // console.log(responseFilter?.length, 'responetype')

  return (
    <div>
      <Modal
        show={openModalReview}
        onClose={() => {
          setOpenModalReview(false)
          setSelectedValues(rating)
          setResponse(responetype)
          setSelectedOption(reviewType)
        }}
      >
        <Modal.Header className='p-3 bg-reviewPopUp rounded-t-2xl    '>
          Review Filter
        </Modal.Header>
        <div className='relative w-full max-w-2xl max-h-full'>
          <div className='relative  dark:bg-gray-700'>
            {/* <div className="flex items-center bg-reviewPopUp justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                                Review Filter
                            </h3>
                            <img
                                src="https://pro-manage.s3.ap-south-1.amazonaws.com/Cross.svg"
                                alt="cross"
                                className="cursor-pointer hover:bg-gray-200 rounded"
                                onClick={() => { setOpenModalReview(false); setSelectedValues("") }}
                            />
                        </div> */}

            <div className='md:p-5 space-y-4 bg-reviewPopUp'>
              <form className=''>
                <p className='text-lg font-semibold text-filter mb-3 '>
                  Review Type
                </p>
                <div className='flex gap-4 mt-2'>
                  <div className='flex items-center mb-4 '>
                    <input
                      id='AllReview'
                      type='radio'
                      name='countries'
                      value='0'
                      className={`w-4 h-4 text-listing ${
                        selectedOption === '0'
                          ? 'border-listing'
                          : 'border-businessProfile'
                      } focus:ring-0 focus:ring-white`}
                      checked={
                        selectedOption === '0'
                          ? true
                          : selectedOption?.length === 0
                          ? true
                          : false
                      }
                      onClick={e => handleChange(e)}
                    />
                    <label
                      // htmlFor="country-option-1"
                      className={`block ms-2 text-lg dark:text-gray-300 ${
                        selectedOption === '0'
                          ? 'text-black'
                          : 'text-businessProfile'
                      }`}
                    >
                      All Review Types
                    </label>
                  </div>

                  <div className='flex items-center mb-4'>
                    <input
                      id='RatingOnly'
                      type='radio'
                      name='countries'
                      value='1'
                      className={`w-4 h-4 text-listing ${
                        selectedOption === '1'
                          ? 'border-listing'
                          : 'border-businessProfile'
                      } focus:ring-0 focus:ring-white`}
                      onClick={e => handleChange(e)}
                      checked={selectedOption === '1' ? true : false}
                    />
                    <label
                      for='country-option-2'
                      className={`block ms-2 text-lg dark:text-gray-300 ${
                        selectedOption === '1'
                          ? 'text-black'
                          : 'text-businessProfile'
                      }`}
                    >
                      Rating Only
                    </label>
                  </div>

                  <div className='flex items-center mb-4'>
                    <input
                      id='ReviewText'
                      type='radio'
                      name='countries'
                      value='2'
                      className={`w-4 h-4 text-listing ${
                        selectedOption === '2'
                          ? 'border-listing'
                          : 'border-businessProfile'
                      } focus:ring-0 focus:ring-white`}
                      onClick={e => handleChange(e)}
                      checked={selectedOption === '2' ? true : false}
                    />
                    <label
                      for='country-option-3'
                      className={`block ms-2 text-lg dark:text-gray-300 ${
                        selectedOption === '2'
                          ? 'text-black'
                          : 'text-businessProfile'
                      }`}
                    >
                      With Review text
                    </label>
                  </div>
                </div>
                <p className='text-xl font-semibold text-filter mb-3'>
                  Respones
                </p>
                <div className='flex gap-4 mt-2'>
                  <div className='flex items-center mb-4'>
                    <input
                      id='AllResponse'
                      type='radio'
                      name='Respones'
                      value='0'
                      checked={
                        responseFilter === '0'
                          ? true
                          : responseFilter?.length === 0
                          ? true
                          : false
                      }
                      className={`w-4 h-4 text-listing ${
                        responseFilter === '0'
                          ? 'border-listing'
                          : 'border-businessProfile'
                      } focus:ring-0 focus:ring-white`}
                      onClick={handleResponse}
                    />
                    <label
                      for='AllResponse'
                      className={`block ms-2 text-lg dark:text-gray-300 ${
                        responseFilter === '0'
                          ? 'text-black'
                          : 'text-businessProfile'
                      }`}
                    >
                      All Responses
                    </label>
                  </div>

                  <div className='flex items-center mb-4'>
                    <input
                      id='Replied'
                      type='radio'
                      name='Respones'
                      value='1'
                      className={`w-4 h-4 text-listing ${
                        responseFilter === '1'
                          ? 'border-listing'
                          : 'border-businessProfile'
                      } focus:ring-0 focus:ring-white`}
                      checked={responseFilter === '1' ? true : false}
                      onClick={handleResponse}
                    />
                    <label
                      for='Replied'
                      className={`block ms-2 text-lg dark:text-gray-300 ${
                        responseFilter === '1'
                          ? 'text-black'
                          : 'text-businessProfile'
                      }`}
                    >
                      Replied
                    </label>
                  </div>

                  <div className='flex items-center mb-4'>
                    <input
                      id='NotReplied'
                      type='radio'
                      name='Respones'
                      value='2'
                      className={`w-4 h-4 text-listing ${
                        responseFilter === '2'
                          ? 'border-listing'
                          : 'border-businessProfile'
                      } focus:ring-0 focus:ring-white`}
                      onClick={handleResponse}
                      checked={responseFilter === '2' ? true : false}
                    />
                    <label
                      for='NotReplied'
                      className={`block ms-2 text-lg dark:text-gray-300 ${
                        responseFilter === '2'
                          ? 'text-black'
                          : 'text-businessProfile'
                      }`}
                    >
                      Not Replied
                    </label>
                  </div>
                </div>
                <p className='text-xl font-semibold text-filter mb-3'>
                  Ratings
                </p>
                <div className='flex gap-4 mt-3'>
                  {[1, 2, 3, 4, 5].map(number => (
                    <div className='flex items-center gap-1' key={number}>
                      <input
                        id={`checked-checkbox-${number}`}
                        type='checkbox'
                        value={number}
                        className='w-4 h-4 text-listing border-gray-600 rounded-sm focus:ring-white dark:ring-offset-gray-800 focus:ring-0 dark:bg-gray-700 dark:border-gray-600 cursor-pointer'
                        onChange={() => handleCheckboxChange(number)}
                        checked={selectedValues.includes(String(number))}
                      />
                      <label
                        labelFor={`checked-checkbox-${number}`}
                        className='ms-2 text-lg dark:text-gray-300 cursor-pointer d-flex align-items-center gap-1'
                      >
                        <span> {number}</span>{' '}
                        <span>
                          <svg
                            width='17'
                            height='16'
                            viewBox='0 0 17 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M8.36578 1.10579C8.57304 0.688656 9.16808 0.688657 9.37534 1.10579L11.068 4.51248C11.15 4.67767 11.3077 4.79224 11.4902 4.81925L15.2532 5.37631C15.714 5.44452 15.8978 6.01044 15.5652 6.33645L12.8483 8.99896C12.7165 9.12806 12.6563 9.31345 12.687 9.49533L13.32 13.2463C13.3975 13.7056 12.9161 14.0553 12.5033 13.8397L9.13152 12.0785C8.96802 11.9931 8.7731 11.9931 8.6096 12.0785L5.23783 13.8397C4.82498 14.0553 4.34358 13.7056 4.42109 13.2463L5.05413 9.49533C5.08483 9.31345 5.02459 9.12806 4.89285 8.99896L2.17594 6.33645C1.84327 6.01044 2.02715 5.44452 2.48791 5.37631L6.25092 4.81925C6.43338 4.79224 6.59108 4.67767 6.67316 4.51248L8.36578 1.10579Z'
                              fill='#FEA500'
                            />
                          </svg>
                        </span>
                      </label>
                      {/* FontAwesomeIcon or other additional elements */}
                    </div>
                  ))}
                </div>
              </form>
            </div>

            <div className='flex items-center justify-end p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 gap-4'>
              <button
                type='submit'
                className='px-4 py-2 text-lg font-medium text-center text-white bg-listing rounded-lg   focus:outline-none  hover:bg-indigo-500'
                onClick={handleFilter}
              >
                Send
              </button>
              <button
                color='gray'
                onClick={() => {
                  setOpenModalReview(false)
                  setSelectedValues(rating)
                  setResponse(responetype)
                  setSelectedOption(reviewType)
                }}
                className='px-4 py-2 text-md font-medium text-center border-1 text-gray  rounded-lg focus:outline-none'
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ReviewFilter
