import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate, json } from 'react-router-dom'
import { AccountDetails } from '../Store/AccountInfo'
import { LocationDetails } from '../Store/LocationInfo'
import httpservice from '../Service/httpservice'
import { API_URL } from '../env/env'
import { Button, Modal } from 'flowbite-react'
import AddModal from '../Components/BusinessProfileTopBarModal/AddModal'
import { toast } from 'react-toastify'
import DeleteModal from '../Components/PostModal/DeleteModal'
import { Chart } from 'react-google-charts'
import { AuthContext } from '../context/AuthContext'

const TopBar = () => {
  const { userInfo, groupId } = useContext(AuthContext)
  const Locationid = LocationDetails(state => state.LocationDetails)
  const [cover, setCover] = useState('')
  const [logo, setLogo] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [category, setCategory] = useState('Cover')
  const [selectFileName, setSelectedFileName] = useState('')
  const [isUploading, setIsUploading] = useState(false)

  const { pathname } = useLocation()
  const navigate = useNavigate()
  const tabs = [
    {
      title: 'OverView',
      Url: 'businessprofile/overview'
    },
    {
      title: 'Media',
      Url: 'businessprofile/media'
    },
    {
      title: 'Post',
      Url: 'businessprofile/post'
    },
    {
      title: 'Service',
      Url: 'businessprofile/service'
    },
    {
      title: 'Review',
      Url: 'businessprofile/review'
    }
  ]

  const ListPhoto = async () => {
    try {
      const body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        locationId: Locationid
      }
      const url = `${API_URL}/media/500`
      const response = await httpservice.post(url, body)
      // console.log('List response ==> ', response)
      const photoData = response?.data?.data?.mediaItems

      let checkCover = false
      let checkLogo = false

      if (photoData?.length > 0) {
        for (const photo of photoData) {
          if (photo?.locationAssociation?.category === 'COVER' && !checkCover) {
            // console.log('Cover ===> ', photo?.googleUrl)
            const image = {
              url: photo?.googleUrl,
              name: photo?.name,
              category: photo?.locationAssociation?.category
            }
            checkCover = true
            setCover(image)
          }
          if (
            photo?.locationAssociation?.category === 'PROFILE' &&
            !checkLogo
          ) {
            // console.log('LOGO ===> ', photo?.googleUrl)
            const image = {
              url: photo.sourceUrl ? photo?.sourceUrl : photo?.googleUrl,
              name: photo?.name,
              category: photo?.locationAssociation?.category
            }
            checkLogo = true
            setLogo(image)
          }

          // console.log('LOOP', cover, logo)

          if (checkCover && checkLogo) {
            // console.log('LOOP123')
            break
          }
        }
      }
    } catch (error) {
      // console.log('error ==> ', error)
    }
  }

  const DeletePhoto = async name => {
    try {
      setIsUploading(true)
      const photosplit = name.split('/')
      const photoid = photosplit[photosplit.length - 1]

      // console.log('Photoid ==> ', photoid)
      const body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        locationId: Locationid
      }

      const url = `${API_URL}/media/delete/${photoid}`
      const response = await httpservice.post(url, body)
      // console.log('response Delete ===> ', response)
      if (response?.status === 200) {
        setCover('')
        setLogo('')
        ListPhoto()
        setIsUploading(false)
        setDeleteModal(false)
        toast.success('Photo deleted successfully')
      }
      if (response.data.status === false) {
        toast.error(response?.data?.message)
        setIsUploading(false)
      }
    } catch (error) {
      setIsUploading(false)
      toast.error(error?.response?.data?.message)
      // console.log('error ==> ', error)
    }
  }

  const handleFileUpload = e => {
    setIsUploading(true)
    e.preventDefault()
    // console.log('Create Post !!!!!!!! ')

    const formData = new FormData()
    const data = {
      userId: userInfo?.info?.userId,
      groupId: groupId?.groupId,
      locationId: Locationid,
      mediaFormat: 'PHOTO',
      category: category,
      the_file: selectedFile || ''
    }
    // console.log('formData data : ', data)

    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value)
    }
    // console.log('formData : ', formData)
    CreatePhoto(formData)
  }

  const CreatePhoto = async formData => {
    try {
      const url = `${API_URL}/media`
      const response = await httpservice.post(url, formData)
      // console.log('Create Photo Response:', response)
      if (response?.status === 200) toast.success(response?.data?.message)
      ListPhoto()
      if (response?.data?.status === false) {
        toast.success(response?.data?.message)
        setIsUploading(false)
      }
      setSelectedFile(null)
      setIsModalOpen(false)
    } catch (error) {
      setIsUploading(false)
      setIsModalOpen(false)
      setSelectedFile(null)
      toast.error(error?.response?.data?.message)
    }
  }

  useEffect(() => {
    ListPhoto()
    HandleprofileScore()
  }, [])

  const [isDeleteModal, setDeleteModal] = useState(false)
  const [deletePostName, setDeletePostName] = useState('')

  const handleDeletePhoto = name => {
    setDeleteModal(true)
    setDeletePostName(name)
  }

  const status = localStorage.getItem('refreshTokenInError')

  const [profileScore, setProfileScore] = useState()

  const HandleprofileScore = async () => {
    try {
      let body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId
      }

      let url = `${API_URL}/location/profile_score/${Locationid}`

      const response = await httpservice.post(url, body)
      if (response.status === 200) {
        // console.log(response, 'profileScore')
        setProfileScore(
          (response?.data?.data?.address?.score || 0) +
            (response?.data?.data?.businessCategory?.score || 0) +
            (response?.data?.data?.businessHours?.score || 0) +
            (response?.data?.data?.businessName?.score || 0) +
            (response?.data?.data?.description?.score || 0) +
            (response?.data?.data?.phoneNumber?.score || 0) +
            (response?.data?.data?.photos?.score || 0) +
            (response?.data?.data?.posts?.score || 0) +
            (response?.data?.data?.serviceItems?.score || 0)
        )
      }
    } catch (error) {
      // console.log(error)
    }
  }

  // console.log(profileScore, 'profileScore')

  const chartData = [
    ['Label', 'Score'],
    ['Profile Score', profileScore],
    ['Remaining', 100 - profileScore]
  ]

  const chartOptions = {
    title: 'Profile Score',
    titleTextStyle: {
      fontSize: 14,
      color: '#333',
      paddingTop: 20
      // fontWeight: 500
    },
    pieHole: 0.6,
    slices: {
      0: { color: '#637EFF' },
      1: { color: '#696D6E' }
    },
    tooltip: {
      isHtml: true,
      trigger: 'none'
    },
    legend: {
      position: 'none'
    },
    // startAngle: 180,
    pieSliceText: 'none',
    backgroundColor: 'transparent'
    // fontSize: "0rem"
  }

  return (
    <div className='container md:px-4 py-2 '>
      <>
        <div className='ms-4'>
          <h2 className='text-2xl font-semibold text-Heading mt-3'>Profile</h2>

          <div className='flex gap-20 mt-4'>
            <div className='h-56 relative profileScore border-1 border-overView rounded-2xl w-64 shadow-md flex justify-center items-center bg-white'>
              <div className='  pt-3'>
                {/* <p>Profile Score</p> */}
                <Chart
                  width={'100%'}
                  height={'250px'}
                  chartType='PieChart'
                  loader={<div>Loading Chart</div>}
                  data={chartData}
                  options={chartOptions}
                />
              </div>
              <div className='absolute top-[50%]  font-semibold text-xl '>
                {profileScore}%
              </div>
            </div>

            <div className='h-56 border-1 border-overView rounded-2xl w-64 shadow-md bg-white'>
              {cover ? (
                <>
                  <div>
                    <img
                      src={cover?.url}
                      alt='Profilepost'
                      className='h-40 max-h-40 max-w-[100%] rounded-2xl p-2'
                    />
                  </div>

                  <div className='flex justify-around mt-3 items-center '>
                    <p>Cover Photo</p>
                    {userInfo?.info?.accessTypeDetails?.accessTypeId === 1 ? (
                      ''
                    ) : (
                      <button
                        className='px-6 py-1 text-md font-nornal text-center text-white bg-listing rounded-lg hover:opacity-90 focus:outline-none'
                        onClick={() => handleDeletePhoto(cover?.name)}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className=' text-center flex justify-center items-center '>
                    <img
                      src='https://pro-manage.s3.ap-south-1.amazonaws.com/NoImg.png'
                      alt='img'
                      className='h-40 max-h-40 max-w-[100%] rounded-2xl p-2'
                    />
                  </div>
                  <div className='flex justify-around mt-3 items-center'>
                    <p className='text-medium font-medium'>Cover Photo</p>
                    {userInfo?.info?.accessTypeDetails?.accessTypeId === 1 ? (
                      ''
                    ) : (
                      <button
                        className='px-6 py-1 text-md font-nornal text-center text-white bg-listing rounded-lg hover:opacity-90 focus:outline-none'
                        onClick={() => {
                          setIsModalOpen(true)
                          setCategory('COVER')
                        }}
                      >
                        Add
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>

            <div className='h-56 border-1 border-overView rounded-2xl w-64 shadow-md bg-white'>
              {logo ? (
                <>
                  <div className='flex justify-center'>
                    <img
                      src={logo?.url}
                      alt='Profilepost'
                      className='h-40 max-h-40 max-w-[100%] rounded-2xl p-2'
                    />
                  </div>
                  <div className='flex justify-around mt-3  items-center'>
                    <p className='text-lg font-medium'>Logo</p>

                    {userInfo?.info?.accessTypeDetails?.accessTypeId === 1 ? (
                      ''
                    ) : (
                      <button
                        className='px-6 py-1 text-md font-nornal text-center text-white bg-listing rounded-lg hover:opacity-90 focus:outline-none'
                        onClick={() => handleDeletePhoto(logo?.name)}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className='h-40 text-center flex justify-center items-center'>
                    <img
                      src='https://pro-manage.s3.ap-south-1.amazonaws.com/NoImg.png'
                      alt='img'
                      className='h-40 max-h-40 max-w-[100%] rounded-2xl p-2'
                    />
                  </div>
                  <div className='flex justify-around mt-3 items-center'>
                    <p className='text-medium font-medium'>Logo</p>
                    {userInfo?.info?.accessTypeDetails?.accessTypeId === 1 ? (
                      ''
                    ) : (
                      <button
                        className='px-6 py-1 text-md font-nornal text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none'
                        onClick={() => {
                          setIsModalOpen(true)
                          setCategory('PROFILE')
                        }}
                      >
                        Add
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <aside
          id='default-sidebar'
          className=' md:relative fixed top-0 left-0 z-40 transition-transform -translate-x-full sm:translate-x-0'
          aria-label='Sidenav'
        >
          <div className='overflow-y-auto ms-4 me-4 rounded-3xl mt-10 bg-white border-1 border-overView'>
            <ul className='space-y-2 flex justify-around'>
              {tabs.map(item => (
                <li
                  className={
                    pathname.includes(`${item.Url}`)
                      ? ' text-lg font-medium px-5 mt-0 py-2.5 text-tabColor block capitalize border-b-2  border-tabColor'
                      : 'text-black text-lg mt-0 py-2.5 capitalize hover:bg-slate-200 rounded-full px-5 '
                  }
                  onClick={() => navigate(`/${item.Url}`)}
                  style={{ cursor: 'pointer' }}
                  key={item.title}
                >
                  {item.title}
                </li>
              ))}
            </ul>
          </div>
        </aside>
      </>

      {/* Create Media */}

      <AddModal
        setSelectedFileName={setSelectedFileName}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        selectFileName={selectFileName}
        category={category}
        handleFileUpload={handleFileUpload}
        isUploading={isUploading}
      />

      <DeleteModal
        isDeleteModal={isDeleteModal}
        setDeleteModal={setDeleteModal}
        deletePost={DeletePhoto}
        deletePostName={deletePostName}
        isUploading={isUploading}
      />

      {/* <Modal show={isDeleteModal} onClose={() => setDeleteModal(false)}>
                <Modal.Body>
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this product?</h3>
                        <button data-modal-hide="popup-modal" type="button"
                            className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center me-2"
                            onClick={(e) => DeletePhoto(e, deletePostName)}
                            on>
                            Yes, I'm sure
                        </button>
                        <button data-modal-hide="popup-modal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                            onClick={() => setDeleteModal(false)}
                        >No, cancel</button>
                    </div>
                </Modal.Body>
            </Modal> */}
    </div>
  )
}

export default TopBar
