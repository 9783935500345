const usePermission = (
  requiredPermission,
  userAccess,
  accessTypeId,
  roleBase
) => {
  if (requiredPermission === 'login') {
    return true
  } else {
    const hasPermission = userAccess?.some(
      access => access.accessName === requiredPermission && access.isAcccess
    )

    if (roleBase) {
      if (roleBase !== 1) {
        return false
      } else if (accessTypeId === 1) {
        return false
      }
    }

    return hasPermission
  }
}

export default usePermission
