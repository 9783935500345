import React, { useState } from 'react'
import { toast } from 'react-toastify'

const Upload = ({
  setSelectedFileName,
  setSelectedFile,
  FromPost,
  bulkUpload,
  category
}) => {
  const [isDragOver, setIsDragOver] = useState(false)

  const handleFileChange = fileList => {
    if (fileList.length > 0) {
      const file = fileList[0]
      const fileName = file.name
      const fileSize = file.size
      const fileSizeInKB = fileSize / 1024
      const fileSizeInMB = fileSizeInKB / 1024
      const arr = file.type.split('/')

      if (category === 'COVER' || category === 'PROFILE') {
        const image = new Image()
        const reader = new FileReader()

        reader.onload = event => {
          image.onload = () => {
            // console.log(image.width, image.height, 'image')
            if (
              (category === 'COVER' &&
                image.width === 1024 &&
                image.height === 576) ||
              (category === 'PROFILE' &&
                image.width >= 250 &&
                image.height >= 250)
            ) {
              if (
                (arr[arr.length - 1] === 'png' ||
                  arr[arr.length - 1] === 'jpeg' ||
                  arr[arr.length - 1] === 'jpg') &&
                (fileSizeInKB >= 10 || fileSizeInMB <= 5)
              ) {
                setSelectedFileName(fileName)
                setSelectedFile(file)
              } else {
                toast.error(
                  ` ${
                    fileSizeInKB < 10 || fileSizeInMB > 5
                      ? 'Check file size'
                      : 'file format only png,jpg,jpeg,webp'
                  }`
                )
              }
            } else {
              toast.error('Image dimensions should be within specified range.')
            }
          }
          image.src = event.target.result
        }
        reader.readAsDataURL(file)
      } else {
        if (file.type === 'video/mp4') {
          if (fileSizeInMB <= 100) {
            setSelectedFileName(fileName)
            setSelectedFile(file)
          } else {
            toast.error('Video file size should be less than or equal to 75MB')
          }
        } else if (
          file.type === 'image/png' ||
          file.type === 'image/jpeg' ||
          file.type === 'image/jpg'
        ) {
          if (
            (arr[arr.length - 1] === 'png' ||
              arr[arr.length - 1] === 'jpeg' ||
              arr[arr.length - 1] === 'jpg') &&
            (fileSizeInKB >= 10 || fileSizeInMB <= 5)
          ) {
            setSelectedFileName(fileName)
            setSelectedFile(file)
          } else {
            toast.error(
              ` ${
                fileSizeInKB < 10 || fileSizeInMB > 5
                  ? 'Check file size'
                  : 'file format only png,jpg,jpeg,webp'
              }`
            )
          }
        } else {
          toast.error('Unsupported file format')
        }
      }
    }
  }

  const preventDefaults = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragEnter = e => {
    preventDefaults(e)
    setIsDragOver(true)
  }

  const handleDragLeave = e => {
    preventDefaults(e)
    setIsDragOver(false)
  }

  const handleFileInputChange = event => {
    const fileList = event.target.files
    handleFileChange(fileList)
  }

  // Handler for drop event
  const handleDrop = e => {
    preventDefaults(e)
    setIsDragOver(false)

    const fileList = Array.from(e.dataTransfer.files)
    handleFileChange(fileList)
  }
  return (
    <div>
      <form
        className={`form-container flex justify-center ${
          isDragOver ? 'border-purple-700' : ''
        }`}
        encType='multipart/form-data'
        onDragEnter={handleDragEnter}
        onDragOver={preventDefaults}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div
          className={`flex flex-col items-center justify-center ${
            FromPost === 'true' || bulkUpload === 'true' ? '' : ' ps-4 pe-4 '
          } rounded-xl w-100`}
        >
          <div
            className={`rounded-xl bg-white text-center w-100  ${
              FromPost === 'true' || bulkUpload === 'true' ? '' : 'shadow-md'
            } ${bulkUpload !== 'true' && 'p-4'}`}
          >
            <div
              className={` ${
                FromPost === 'true' || bulkUpload === 'true'
                  ? ''
                  : 'border-1 border-dashed border-gray-500 rounded-lg p-2.5 borderForBulk'
              } ${bulkUpload === 'true' && 'pt-4 pb-4 borderForBulk'} `}
            >
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/uploadIconSvg.svg'
                alt='DragAndDrop'
                className='img-fluid mx-auto my-auto'
              />
              <label className='label mt-3 flex gap-2 justify-center'>
                <input
                  type='file'
                  className='hidden'
                  onChange={handleFileInputChange}
                />
                <p
                  className={` ${
                    FromPost === 'true' || bulkUpload === 'true'
                      ? 'text-xs'
                      : 'text-base'
                  } text-listing font-medium cursor-pointer`}
                >
                  Click here{' '}
                  <span className='text-black'>
                    to upload or drop media here
                  </span>
                </p>
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Upload
