import React, { useContext, useState } from 'react'
import { AccountDetails } from '../../Store/AccountInfo'
import { LocationDetails } from '../../Store/LocationInfo'
import { API_URL } from '../../env/env'
import httpservice from '../../Service/httpservice'
import { Button, Modal } from 'flowbite-react'
import { toast } from 'react-toastify'
import { AuthContext } from './../../context/AuthContext'

const AddressModal = ({
  openModalAddress,
  setOpenModalAddress,
  Address,
  SetAddress,
  Region,
  LanguageCode,
  PostalCode,
  SetPostalCode,
  administrativeArea,
  setadministrativeArea,
  Locality,
  SetLocality,
  invalidateAndRefetch,
  handleLocationCountNotification
}) => {
  // const id = AccountDetails((state) => state.AccountDetails);
  // const tokenDetails = JSON.parse(localStorage.getItem("tmbUserData"));
  const { userInfo, groupId } = useContext(AuthContext)
  const Locationid = LocationDetails(state => state.LocationDetails)
  const [isDisabled, setIsDisabled] = useState(true)
  const [isDisabled1, setIsDisabled1] = useState(true)
  const [isDisabled2, setIsDisabled2] = useState(true)
  const [isDisabled3, setIsDisabled3] = useState(true)

  const handleAddressName = async e => {
    e.preventDefault()
    try {
      let body = {
        userId: userInfo.info?.userId,
        groupId: groupId?.groupId,
        locationId: Locationid,
        sectionName: 'Address',
        updateData: {
          storefrontAddress: {
            regionCode: Region,
            languageCode: LanguageCode,
            postalCode: PostalCode,
            administrativeArea: administrativeArea,
            locality: Locality,
            addressLines: Address
          }
        }
      }

      const url = `${API_URL}/location/update?updateMask=storefrontAddress`
      const res = await httpservice.patch(url, body)

      if (res.status === 200) {
        setOpenModalAddress(false)
        setIsDisabled(true)
        setIsDisabled1(true)
        setIsDisabled2(true)
        setIsDisabled3(true)
        invalidateAndRefetch()
        if (userInfo?.info?.roleDetails?.roleId === 1) {
          handleLocationCountNotification(true)
        }
        toast.success('Address Updated,reflects in 10.')
      } else {
        setOpenModalAddress(false)
        setIsDisabled(true)
        setIsDisabled1(true)
        setIsDisabled2(true)
        setIsDisabled3(true)
        toast.error(res?.data?.message)
      }
    } catch (error) {
      setOpenModalAddress(false)
      setIsDisabled(true)
      setIsDisabled1(true)
      setIsDisabled2(true)
      setIsDisabled3(true)
      toast.error(error?.response?.data?.message)
      // console.log(error)
    }
  }

  const handleAddressChange = (index, newValue) => {
    const updatedAddresses = [...Address]
    updatedAddresses[index] = newValue
    SetAddress(updatedAddresses)
  }

  // console.log(Address, 'Address')

  return (
    <div>
      <Modal
        show={openModalAddress}
        onClose={() => {
          setOpenModalAddress(false)
          setIsDisabled(true)
          setIsDisabled1(true)
          setIsDisabled2(true)
          setIsDisabled3(true)
        }}
      >
        <Modal.Header className='p-3'>
          <p className=' font-medium text-2xl'>Edit Address</p>
        </Modal.Header>
        <Modal.Body>
          <div className='space-y-6'>
            <p className='text-md font-medium text-listing dark:text-gray-400'>
              Provide a precise address of your business location. Make sure the
              address exactly matches other listings across the web, if any
            </p>
            <form>
              <div>
                {Address?.length > 0 &&
                  Address.map((address, index) => (
                    <>
                      <div className='relative '>
                        <input
                          type='text'
                          className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
                            isDisabled
                              ? 'cursor-not-allowed text-gray-400'
                              : 'cursor-text text-gray-900 '
                          }  `}
                          placeholder=' '
                          value={address}
                          disabled={isDisabled}
                          onChange={e =>
                            handleAddressChange(index, e.target.value)
                          }
                          id={`address-input-${index}`}
                        />
                        <div className='absolute inset-y-0 end-2 flex items-center ps-3 cursor-pointer'>
                          <img
                            src='https://pro-manage.s3.ap-south-1.amazonaws.com/OverViewEdit.svg'
                            alt='edit'
                            className='img-fluid'
                            onClick={() => setIsDisabled(false)}
                          />
                        </div>
                        <label
                          for={`address-input-${index}`}
                          htmlFor={`address-input-${index}`}
                          className='absolute text-sm font-semibold text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                        >
                          {' '}
                          Address Line {index + 1}
                        </label>
                      </div>
                    </>
                  ))}
              </div>
              <div className='mt-4'>
                <div className='relative'>
                  <input
                    type='text'
                    id='city'
                    disabled={isDisabled1}
                    value={Locality}
                    onChange={e => {
                      SetLocality(e.target.value)
                    }}
                    className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
                      isDisabled1
                        ? 'cursor-not-allowed text-gray-400'
                        : 'cursor-text text-gray-900 '
                    }  `}
                    placeholder=' '
                  />
                  <div className='absolute inset-y-0 end-2 flex items-center ps-3 cursor-pointer'>
                    <img
                      src='https://pro-manage.s3.ap-south-1.amazonaws.com/OverViewEdit.svg'
                      alt='edit'
                      className='img-fluid'
                      onClick={() => setIsDisabled1(false)}
                    />
                  </div>
                  <label
                    for='city'
                    className='absolute  text-sm font-semibold text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                  >
                    City
                  </label>
                </div>
              </div>
              <div className='mt-4'>
                <div className='relative'>
                  <input
                    type='text'
                    id='State'
                    disabled={isDisabled2}
                    value={administrativeArea}
                    onChange={e => {
                      setadministrativeArea(e.target.value)
                    }}
                    className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
                      isDisabled2
                        ? 'cursor-not-allowed text-gray-400'
                        : 'cursor-text text-gray-900 '
                    }  `}
                    placeholder=' '
                  />
                  <div className='absolute inset-y-0 end-2 flex items-center ps-3 cursor-pointer'>
                    <img
                      src='https://pro-manage.s3.ap-south-1.amazonaws.com/OverViewEdit.svg'
                      alt='edit'
                      className='img-fluid'
                      onClick={() => setIsDisabled2(false)}
                    />
                  </div>
                  <label
                    for='State'
                    className='absolute  text-sm font-semibold text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                  >
                    State
                  </label>
                </div>
              </div>
              <div className='mt-4'>
                <div className='relative'>
                  <input
                    type='text'
                    id='postal-code'
                    disabled={isDisabled3}
                    value={PostalCode}
                    onChange={e => {
                      SetPostalCode(e.target.value)
                    }}
                    className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
                      isDisabled3
                        ? 'cursor-not-allowed text-gray-400'
                        : 'cursor-text text-gray-900 '
                    }  `}
                    placeholder=' '
                  />
                  <div className='absolute inset-y-0 end-2 flex items-center ps-3 cursor-pointer'>
                    <img
                      src='https://pro-manage.s3.ap-south-1.amazonaws.com/OverViewEdit.svg'
                      alt='edit'
                      className='img-fluid'
                      onClick={() => setIsDisabled3(false)}
                    />
                  </div>
                  <label
                    for='postal-code'
                    className='absolute  text-sm font-semibold text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                  >
                    Postal-code
                  </label>
                </div>
                <div className='mt-3 bg-caution rounded-lg'>
                  {/* <p className='text-decoration-underline text-cautionText text-lg mt-3 p-2 pb-0 font-medium'>Caution</p> */}
                  <div className='mt-0 p-2 flex'>
                    {/* <i className="fa fa-warning text-red-600" ></i> */}
                    <p className='text-cautionText flex gap-1'>
                      <img
                        src='https://pro-manage.s3.ap-south-1.amazonaws.com/caution.svg'
                        className='w-5'
                      />
                      Updating the Address might lead to the profile getting
                      suspended by Google
                    </p>
                  </div>
                  <div className=' p-2  pt-1 flex'>
                    {/* <i className="fa fa-warning text-red-600" ></i> */}
                    <p className='text-cautionText flex gap-1'>
                      <img
                        src='https://pro-manage.s3.ap-south-1.amazonaws.com/caution.svg'
                        className='w-5'
                      />
                      Updating the Address might take time around 10 - 15min{' '}
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className='pt-0 justify-end'>
          <div className='flex justify-end gap-3 mt-4'>
            <button
              color='gray'
              onClick={() => {
                setOpenModalAddress(false)
                setIsDisabled(true)
                setIsDisabled1(true)
                setIsDisabled2(true)
                setIsDisabled3(true)
              }}
              className='px-4 py-2 text-md font-medium text-center border-1 text-gray  rounded-lg focus:outline-none'
            >
              Cancel
            </button>
            <button
              type='submit'
              className='px-4 py-2 text-md font-medium text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none'
              onClick={e => handleAddressName(e)}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AddressModal
