import { Modal } from 'flowbite-react'
import React from 'react'
import Upload from '../PhotoUpload'

const CreateModal = ({
  isModalOpen,
  setIsModalOpen,
  summary,
  setSummary,
  setActionType,
  websiteurl,
  setWebsiteurl,
  selectedFile,
  setSelectedFile,
  selectedFileName,
  actionType,
  handleFileUpload,
  setSelectedFileName,
  isUploading
}) => {
  return (
    <div>
      {/* Create Post */}
      <Modal
        show={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
          setSummary('')
          setActionType('LEARN_MORE')
          setSelectedFile(null)
        }}
        className=''
        size='2xl'
      >
        <Modal.Header className='p-3'>
          <p className=' font-semibold text-2xl'>Upload Post</p>
        </Modal.Header>
        <Modal.Body className='overflow-x-hidden'>
          <div className='space-y-6'>
            <form>
              <div id='PostUpload'>
                <div>
                  {selectedFile ? (
                    <div className='flex justify-center '>
                      <div>
                        <div className='flex flex-col justify-center items-center'>
                          <img
                            src={URL.createObjectURL(selectedFile)}
                            alt='Selected File Preview'
                            id='imageFile'
                            className='img-fluid object-cover '
                            style={{ maxWidth: '100%', maxHeight: '200px' }}
                          />
                          {/* <div className='flex items-center gap-3 pt-3'>
                                                            <p className=' font-semibold text-lg text-listing'><span className='text-black' >File Name :</span> {selectedFileName}</p>
                                                            <img src="https://pro-manage.s3.ap-south-1.amazonaws.com/PostMedia.svg" alt='ProfileDelete' className='cursor-pointer' onClick={() => setSelectedFile(null)} />
                                                        </div> */}
                          <div className='flex items-center justify-between pt-3 p-2 mt-4 rounded-lg w-100 shadow-photoName'>
                            <div className='flex items-center'>
                              <img
                                src={
                                  'https://pro-manage.s3.ap-south-1.amazonaws.com/showPhoto.svg'
                                }
                                alt='photo'
                                className='img-fluid'
                              />
                              <p className=' font-normal text-md text-RecommentationText'>
                                {' '}
                                {selectedFileName}
                              </p>
                            </div>
                            <img
                              src='https://pro-manage.s3.ap-south-1.amazonaws.com/DeleteIconforPhoto.svg'
                              alt='ProfileDelete'
                              className='cursor-pointer'
                              onClick={() => setSelectedFile(null)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <Upload
                        setSelectedFileName={setSelectedFileName}
                        setSelectedFile={setSelectedFile}
                      />
                      <div className='bg-Recommentation  rounded-lg ps-4 pb-3 pt-1 mt-3'>
                        <p className='mt-3 text-RecommentationText text-xs 	font-medium'>
                          Recommendations for Google:
                        </p>

                        <p className='mt-2 text-RecommentationText text-xs	font-medium'>
                          • Do not post media which is not related to your
                          business category
                        </p>

                        <p className='mt-2 text-RecommentationText text-xs	font-medium'>
                          • It is advised to have the logo of your brand or
                          textual mention in the picture
                        </p>

                        <p className='mt-2 text-RecommentationText text-xs	font-medium'>
                          • Do not add phone number and links in the description
                          when posting to google
                        </p>

                        <p className='mt-2 text-RecommentationText text-xs	font-medium'>
                          • Photo - 4:3 ratio | JPG or PNG or JPGE format | File
                          size of 10 KB to 5 MB
                        </p>
                      </div>
                    </>
                  )}
                </div>

                <h3 className='text-2xl font-semibold pt-3 pb-2'>
                  Description{' '}
                </h3>
                <div className='relative'>
                  <textarea
                    id='message'
                    rows='5'
                    className={`block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg  border ${
                      summary?.length > 1500
                        ? 'border-red-500'
                        : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                    }   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    value={summary}
                    onChange={e => setSummary(e.target.value.slice(0, 1500))}
                  ></textarea>
                  <div className='absolute bottom-1 right-2 text-xs text-gray-500'>
                    {summary?.length}/1500
                  </div>
                </div>
                <div className='mt-3 mb-3'>
                  <div className='flex justify-between items-center gap-2 cursor-pointer'>
                    <p
                      className={` rounded-lg ps-2.5 pe-2.5 pt-1 pb-1 text-center ${
                        actionType === 'BOOK'
                          ? 'bg-Check border-1 border-listing text-listing font-medium'
                          : 'border-1'
                      }`}
                      onClick={() => setActionType('BOOK')}
                    >
                      Book
                    </p>
                    <p
                      className={` rounded-lg ps-2.5 pe-2.5 pt-1 pb-1 text-center ${
                        actionType === 'ORDER'
                          ? 'bg-Check border-1 border-listing text-listing font-medium'
                          : 'border-1'
                      }`}
                      onClick={() => setActionType('ORDER')}
                    >
                      Order
                    </p>
                    <p
                      className={` rounded-lg ps-2.5 pe-2.5 pt-1 pb-1 text-center ${
                        actionType === 'SIGN_UP'
                          ? 'bg-Check border-1 border-listing text-listing font-medium'
                          : 'border-1'
                      }`}
                      onClick={() => setActionType('SIGN_UP')}
                    >
                      Sign up
                    </p>
                    <p
                      className={` rounded-lg ps-2.5 pe-2.5 pt-1 pb-1 text-center ${
                        actionType === 'SHOP'
                          ? 'bg-Check border-1 border-listing text-listing font-medium'
                          : 'border-1'
                      }`}
                      onClick={() => setActionType('SHOP')}
                    >
                      Shop
                    </p>
                    <p
                      className={` rounded-lg ps-2.5 pe-2.5 pt-1 pb-1 text-center ${
                        actionType === 'LEARN_MORE'
                          ? 'bg-Check border-1 border-listing text-listing font-medium'
                          : 'border-1'
                      }`}
                      onClick={() => setActionType('LEARN_MORE')}
                    >
                      Learn more
                    </p>
                    <p
                      className={` rounded-lg ps-2.5 pe-2.5 pt-1 pb-1 text-center ${
                        actionType === 'CALL'
                          ? 'bg-Check border-1 border-listing text-listing font-medium'
                          : 'border-1'
                      }`}
                      onClick={() => setActionType('CALL')}
                    >
                      Call
                    </p>
                    <p
                      className={` rounded-lg ps-2.5 pe-2.5 pt-1 pb-1 text-center ${
                        actionType === 'NONE'
                          ? 'bg-Check border-1 border-listing text-listing font-medium'
                          : 'border-1'
                      }`}
                      onClick={() => setActionType('NONE')}
                    >
                      None
                    </p>
                  </div>
                </div>
                {/* <p className='text-xl font-medium pb-2 '>Action Type :<span className='capitalize'> {actionType}</span></p> */}
                {actionType !== '' &&
                actionType !== 'NONE' &&
                actionType !== 'CALL' ? (
                  <div className='relative'>
                    <input
                      type='text'
                      id='websiteUrl'
                      className='block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer'
                      placeholder=' '
                      onChange={e => setWebsiteurl(e.target.value)}
                    />
                    <label
                      for='websiteUrl'
                      className='absolute text-md font-medium text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                    >
                      websiteUrl
                    </label>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className='pt-0 justify-end'>
          <div className='flex justify-end gap-3 mt-4'>
            <button
              color='gray'
              onClick={() => {
                setIsModalOpen(false)
                setSelectedFile(null)
                setSummary('')
                setActionType('LEARN_MORE')
              }}
              className='px-4 py-2 text-md font-medium text-center border-1 text-gray  rounded-lg focus:outline-none'
            >
              Cancel
            </button>
            <button
              type='submit'
              className={`px-4 py-2 text-md font-medium text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none ${
                (summary?.length > 1 && actionType === 'NONE') ||
                (summary?.length > 1 && actionType === 'CALL')
                  ? 'cursor-pointer'
                  : summary?.length > 1 && websiteurl?.length
                  ? 'cursor-pointer'
                  : 'cursor-not-allowed opacity-50'
              }`}
              onClick={handleFileUpload}
              disabled={
                summary?.length <= 1 ||
                (!websiteurl &&
                  actionType !== 'NONE' &&
                  actionType !== 'CALL') ||
                isUploading
              }
            >
              Upload
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CreateModal
