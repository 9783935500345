import React, { useEffect, useState } from 'react'
import { API_URL } from '../../env/env'
import { format } from 'date-fns'
import Chart from 'react-apexcharts' // Import ApexCharts
import httpservice from '../../Service/httpservice'
import { Popover } from 'antd'
import { useQuery } from '@tanstack/react-query'

const TotalActivity = ({ startDate, endDate, userId, groupId, setload }) => {
  const [month, setMonth] = useState(false)
  const [day, setDay] = useState(true)
  const [date1, setDate1] = useState()
  const [newDate, setNewDate] = useState([])

  const SuffixAdd = data => {
    if (data > 1000 && data < 100000) {
      return (data / 1000).toFixed(2) + 'K'
    } else if (data > 99999) {
      return (data / 100000).toFixed(3) + 'L'
    } else {
      return data
    }
  }

  //   useEffect(() => {
  //     Activity()
  //   }, [startDate, endDate, groupId, month])

  const { data, isPending } = useQuery({
    queryFn: () => Activity(),
    queryKey: ['Activity', startDate, endDate, groupId, month],
    staleTime: 1000 * 3600,
    // onSuccess: ViewsAndActivityData => {
    //   setViewsAndActivityData(ViewsAndActivityData)
    // }
    cacheTime: 600000
  })

  useEffect(() => {
    if (isPending === false) {
      setload(false)
    }
  }, [isPending])

  const [activityData, SetActivityData] = useState([])
  const [totalActivityData, setTotallActivityData] = useState([])

  useEffect(() => {
    SetActivityData(data?.data?.ActivityData)
    setTotallActivityData(data?.data?.TotalActivity)
  }, [data])

  useEffect(() => {
    if (month) {
      const generateMonthList = (startDate, endDate) => {
        const start = new Date(startDate)
        const end = new Date(endDate)
        const monthList = []

        while (start <= end) {
          monthList.push(
            new Date(start).toLocaleString('default', {
              month: 'short',
              year: 'numeric'
            })
          )
          start.setMonth(start.getMonth() + 1)
        }

        return monthList
      }

      const months = generateMonthList(startDate, endDate)
      setDate1(months)
    }
  }, [startDate, endDate, month])

  const Activity = async () => {
    try {
      let body = {
        userId: userId,
        groupId: groupId,
        startDate: startDate,
        endDate: endDate
      }
      // let url = `${API_URL}/other/activitiesMetrics`
      let url
      if (month) {
        url = `${API_URL}/other/activitiesMetrics/monthly`
      } else {
        url = `${API_URL}/other/activitiesMetrics`
      }
      const response = await httpservice.post(url, body)
      if (response?.status === 200) {
        if (month) {
          // console.log(response?.data?.data, 'setTotallActivityData')
          SetActivityData(
            response?.data?.data?.insights?.map(item => {
              const date = new Date(item?.insightDate)
              return {
                ...item,
                insightDate: date?.toLocaleString('default', {
                  month: 'short',
                  year: 'numeric'
                })
              }
            })
          )

          const formattedDates = response?.data?.data?.insights?.map(item => {
            const date = new Date(item.insightDate)
            return date.toLocaleString('default', {
              month: 'short',
              year: 'numeric'
            })
          })

          setNewDate(formattedDates)
        } else {
          // console.log(response?.data?.data?.insights, 'setTotallActivityData')
          //   SetActivityData(response?.data?.data?.insights)
          return {
            data: {
              ActivityData: response?.data?.data?.insights,
              TotalActivity: response?.data?.data
            }
          }
        }

        // SetActivityData(response?.data?.data?.dateWiseInsights)
        // setTotallActivityData(response?.data?.data)
      }
    } catch (error) {}
  }

  const formattedDateNew = []
  if (newDate?.length !== date1?.length) {
    date1?.map((item, index) => {
      const existingIndex = newDate?.includes(item)
      // console.log(existingIndex, 'existingIndex', typeof existingIndex)
      if (existingIndex === false) {
        // console.log(date1[index], 'date1')

        const formattedDate = {
          insightDate: date1[index],
          directionRequest: 0,
          websiteClicks: 0
        }
        // console.log(formattedDate, 'formattedDate')
        formattedDateNew.push(formattedDate)
      }
    })
  }

  let finalOut = [...(activityData || []), ...(formattedDateNew || [])]
  if (month) {
    finalOut = finalOut.sort(
      (a, b) => new Date(a.reviewDate) - new Date(b.reviewDate)
    )
  }

  // console.log(activityData, 'activityData')

  const currentDate1 = new Date(startDate)
  const tempNewChartData1 = []
  if (!month) {
    while (currentDate1.toISOString().split('T')[0] <= endDate) {
      const formattedDate = currentDate1.toISOString().split('T')[0]

      const existingIndex = activityData?.findIndex(
        entry => entry.insightDate === formattedDate
      )

      if (existingIndex === -1) {
        const newEntry = {
          insightDate: formattedDate,
          directionRequest: 0,
          websiteClicks: 0
        }
        tempNewChartData1.push(newEntry)
      }

      currentDate1.setDate(currentDate1.getDate() + 1)
    }

    if (tempNewChartData1.length > 0) {
      SetActivityData(prevState =>
        [...prevState, ...tempNewChartData1].sort(
          (a, b) => new Date(a.insightDate) - new Date(b.insightDate)
        )
      )
    }
  }

  if (activityData?.length > 0) {
    var ActivityOptions = {
      series: [
        {
          name: 'directionRequest',
          data: month
            ? finalOut?.map(item => item?.directionRequest)
            : activityData?.map(item => item?.directionRequest)
        },
        {
          name: 'websiteClicks',
          data: month
            ? finalOut?.map(item => item?.websiteClicks)
            : activityData?.map(item => item?.websiteClicks)
        }
      ],
      options: {
        chart: {
          height: 300
          // zoom: {
          //     enabled: false,
          // },
        },
        xaxis: {
          type: 'datetime',
          categories: month
            ? finalOut?.map(item => item?.insightDate)
            : activityData?.map(item => item?.insightDate)
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 2
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy'
          }
        },
        colors: ['#34A853', '#FF0099'],
        legend: {
          show: false
        },
        grid: {
          borderColor: 'transparent'
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download:
              '<img src="https://pro-manage.s3.ap-south-1.amazonaws.com/DownloadIcons.svg" className="ico-download" width="20">',
            selection: true,
            pan: true,
            reset: true,
            customIcons: []
          }
        }
      }
    }
  }

  const content = (
    <div className=''>
      <p className='text-businessProfile text-xs p-2'>
        Drag the chart to zoom in, allowing you to
        <br /> view the detailed records.
      </p>
    </div>
  )
  return (
    <div className='mt-5  rounded-2xl p-4 shadow-filter1 border-[0.1px] border-cardInsights'>
      <h1 className='text-xl font-semibold flex items-center gap-2'>
        Clicks{' '}
        <span>
          <Popover placement='rightTop' content={content} className=''>
            <img
              src='https://pro-manage.s3.ap-south-1.amazonaws.com/InformationIcon.svg'
              alt='image'
            />
          </Popover>
        </span>
      </h1>
      <div className='flex justify-between items-center gap-4 pt-3 pb-3'>
        <div className='flex gap-4'>
          <div className='flex items-baseline  gap-2 '>
            <p className='w-2 h-2 bg-sky-500 rounded-full '></p>
            <div>
              <p className='text-black text-md'>Total </p>
              <p className='text-black font-semibold text-lg'>
                {SuffixAdd(
                  totalActivityData?.totalWebsiteClicks +
                    totalActivityData?.totalDirectionRequest
                )}
              </p>
            </div>
          </div>
          <div className='flex gap-2 items-baseline'>
            <p className='w-2 h-2 bg-websiteClick  rounded-full'></p>
            <div>
              <p className='text-black  text-md'>TotalDirectionRequest </p>
              <p className='text-black font-semibold text-lg'>
                {SuffixAdd(totalActivityData?.totalDirectionRequest)}
              </p>
            </div>
          </div>
          <div className='flex gap-2 items-baseline'>
            <p className='w-2 h-2 bg-directionRequest   rounded-full'></p>
            <div>
              <p className='text-black  text-md'>TotalWebsiteClicks </p>
              <p className='text-black font-semibold text-lg'>
                {' '}
                {SuffixAdd(totalActivityData?.totalWebsiteClicks)}
              </p>
            </div>
          </div>
        </div>
        {/* <div className='flex'>
                    <button className={`${month === true ? "bg-listing rounded-3xl text-white -me-5 z-10" : "  rounded-tr-0 rounded-tl-3xl rounded-bl-3xl rounded-br-0 border-2 border-borderColor"} font-medium py-2 px-4  w-24 transition-all duration-600`} onClick={() => { setMonth(true); setDay(false) }} >Month</button>
                    <button className={`${day === true ? "bg-listing rounded-3xl  text-white -ms-5 z-20" : " border-l-0 border-r-2 rounded-tr-3xl rounded-tl-0 rounded-bl-0 rounded-br-3xl roun border-b-2 border-t-2 border-borderColor"} font-medium py-2 px-4 w-24 transition-all duration-600`} onClick={() => { setMonth(false); setDay(true) }} >day</button>
                </div> */}
      </div>
      {/* <Chart
                chartType="LineChart"
                width="100%"
                height="400px"
                data={ActivityData}
                options={ActivityOptions}
            /> */}
      <Chart
        options={ActivityOptions?.options}
        series={ActivityOptions?.series}
        type='line'
        width='100%'
        height='300px'
      />
    </div>
  )
}

export default TotalActivity
