import React, { useContext, useState } from 'react'
import httpservice from '../../Service/httpservice'
import { API_URL } from '../../env/env'
import { AuthContext } from '../../context/AuthContext'
import AdditionalCategoryInput from '../AdditionalCategoryInput'
import { toast } from 'react-toastify'

const FirstForm = ({
  title,
  setTitle,
  storeCode,
  setStoreCode,
  primaryCategory,
  setprimaryCategory,
  oldAdditional,
  setOldAdditional,
  additionalCategories,
  setAdditionalCategories,
  handleNext
}) => {
  const { userInfo } = useContext(AuthContext)

  const [dataList, setDataList] = useState([])
  const [showDropdown, setShowDropdown] = useState(false)
  const [index, setIndex] = useState(null)
  const [AddnewCategory, setAddnewCategory] = useState(0)

  // console.log(AddnewCategory, 'additionalCategories')

  const handleBusinessCategory = async data => {
    setprimaryCategory({ displayName: data })
    try {
      const body = {
        userId: userInfo.info?.userId,
        displayName: data
      }
      const res = await httpservice.post(
        `${API_URL}/other/business_categories`,
        body
      )
      // console.log(res, 'res')
      setDataList(res?.data?.data?.categories)
      setShowDropdown(true)
    } catch (error) {
      console.error(error)
    }
  }

  const handleclick = cat => {
    // console.log(cat, 'cat')
    setprimaryCategory(cat)
    setShowDropdown(false)
  }

  const handleRemove = RemovedCatName => {
    const filteredAdditionalCat = oldAdditional.filter(
      category => category?.displayName !== RemovedCatName
    )
    setOldAdditional(filteredAdditionalCat)
    // console.log(RemovedCatName, 'RemovedCatName')
    // console.log(filteredAdditionalCat, 'RemovedCatName')

    setAdditionalCategories(prevState =>
      prevState.filter(category => category?.displayName !== RemovedCatName)
    )
  }

  const handleAdditionalCategory = async (index, data) => {
    try {
      const body = {
        userId: userInfo.info?.userId,
        displayName: data
      }
      const res = await httpservice.post(
        `${API_URL}/other/business_categories`,
        body
      )
      // console.log(res, 'res')

      const updatedDataList = [...dataList]
      updatedDataList[index] = res?.data?.data?.categories

      setDataList(updatedDataList)
    } catch (error) {
      console.error(error)
    }
  }

  const handleAltCat = cat => {
    setAdditionalCategories(prevCategories => [...prevCategories, cat])
  }

  const handleAddNewCategory = async (index, data) => {
    if (AddnewCategory >= 8) {
      toast.error('Maximum 8 categories can be added')
      return
    }
    setAddnewCategory(AddnewCategory + 1)
  }

  return (
    <div>
      <div className='flex items-center'>
        <div className=''>
          <img
            src='https://pro-manage.s3.ap-south-1.amazonaws.com/step1.svg'
            className='img-fluid'
            alt='vector'
          />
        </div>
        <div className='bg-white   rounded-2xl'>
          <div className='flex justify-between pb-2 ps-4 pt-4 pe-4'>
            <h2 className='text-2xl	font-medium'>
              Start building your Business Profile
            </h2>
          </div>
          <p className='col-10 mb-3 ps-4'>
            This will help you get discoverd by customer on Google Search and
            Maps
          </p>
          <form className=''>
            <div className='mb-3  ps-4 pe-4'>
              <div className='relative '>
                <input
                  type='text'
                  id='Businessname'
                  className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 'cursor-text text-gray-900   `}
                  placeholder=' '
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  required
                />
                <label
                  for='Businessname'
                  className='absolute text-sm font-medium text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  Business name
                </label>
              </div>
            </div>

            <div className='mb-3  ps-4 pe-4'>
              <div className='relative '>
                <input
                  type='text'
                  id='StoreCode'
                  className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 'cursor-text text-gray-900   `}
                  placeholder=' '
                  value={storeCode}
                  onChange={e => setStoreCode(e.target.value)}
                  required
                />
                <label
                  for='StoreCode'
                  className='absolute text-sm font-medium text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  StoreCode (Optional)
                </label>
              </div>
            </div>

            <div className={`ps-4 mb-4 pe-4`}>
              <div className='relative '>
                <input
                  type='text'
                  id='Businesscategory'
                  className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 'cursor-text text-gray-900   `}
                  placeholder=' '
                  value={primaryCategory?.displayName}
                  onChange={e => handleBusinessCategory(e.target.value)}
                />
                <label
                  for='Businesscategory'
                  className='absolute text-sm font-medium text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  Business Category
                </label>
              </div>
              {showDropdown && primaryCategory?.displayName?.length > 0 && (
                <div className=' top-full mt-2 bg-white border border-gray-300 shadow-lg rounded-lg h-auto min-h-0 max-h-72 overflow-y-scroll'>
                  {dataList.map((item, index) => (
                    <div
                      key={index}
                      index={index}
                      setIndex={setIndex}
                      className='p-2 cursor-pointer hover:bg-gray-100'
                      onClick={() => {
                        handleclick(item)
                      }}
                    >
                      {item.displayName}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className={`ps-4 mb-4 pe-4`}>
              {oldAdditional?.length > 0 &&
                oldAdditional?.map(item => (
                  <div className='relative w-full mt-4'>
                    <div className='relative '>
                      <input
                        type='text'
                        id='AdditionalCategory'
                        className={`cursor-not-allowed text-gray-400 block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 pee  `}
                        placeholder=' '
                        disabled={true}
                        value={item?.displayName}
                      />
                      <div className='absolute inset-y-0 end-2 flex items-center ps-3 cursor-pointer'>
                        <img
                          src='https://pro-manage.s3.ap-south-1.amazonaws.com/Delete.svg'
                          alt='edit'
                          className='img-fluid'
                          onClick={() => handleRemove(item?.displayName)}
                        />
                      </div>
                      <label
                        for='AdditionalCategory'
                        className='absolute text-sm font-medium text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                      >
                        Additional Category
                      </label>
                    </div>
                  </div>
                ))}
            </div>

            <div className={`ps-4 mb-4 pe-4`}>
              {[...Array(AddnewCategory)].map((_, index) => (
                <AdditionalCategoryInput
                  key={index}
                  index={index}
                  setIndex={setIndex}
                  handleAdditionalCategory={handleAdditionalCategory}
                  handleAltCat={handleAltCat}
                  dataList={dataList}
                  AddnewCategory={AddnewCategory}
                  setAddnewCategory={setAddnewCategory}
                  additionalCategories={additionalCategories}
                  setAdditionalCategories={setAdditionalCategories}
                  addBusiness={true}
                />
              ))}
            </div>

            {primaryCategory?.name?.length > 0 && (
              <div className={`ps-4 mb-4 pe-4`}>
                <p
                  className=' text-listing text-base font-semibold cursor-pointer'
                  onClick={() => handleAddNewCategory()}
                >
                  {AddnewCategory < 8 && ' + Add Additional Categories'}
                </p>
              </div>
            )}

            <div className='bg-formBottom flex justify-center pb-4 rounded-b-2xl'>
              <button
                type='button'
                onClick={handleNext}
                className='bg-listing text-light text-xl mt-3 rounded-xl w-80 pt-2 pb-2'
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default FirstForm
