import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { AuthContextProvider } from './context/AuthContext'
import 'react-toastify/dist/ReactToastify.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const root = ReactDOM.createRoot(document.getElementById('root'))
const queryClient = new QueryClient()
root.render(
  <AuthContextProvider>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <ToastContainer autoClose={2000} />
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  </AuthContextProvider>
)
