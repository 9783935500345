import { Button, Modal } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import '../../Styles/userManagementEdit.css'
import { AccountDetails } from '../../Store/AccountInfo'
import { API_URL } from '../../env/env'
import httpservice from '../../Service/httpservice'
import { Empty } from 'antd'

const ViewDetails = ({
  isModalOpen,
  setIsModalOpen1,
  userId,
  groupId,
  bulkPostLocationId
}) => {
  useEffect(() => {
    if (bulkPostLocationId) {
      BranchInfo()
    }
  }, [bulkPostLocationId, isModalOpen === true])

  const [UploadDetails, SetUploadDetails] = useState([])
  const [TotalCount, setTotalCount] = useState([])
  const [searchvalue, setSearchValue] = useState('')
  const [completed, setCompleted] = useState([])
  const [processing, setProcessing] = useState([])

  // console.log(bulkPostLocationId, 'bulkPostLocationId')
  const BranchInfo = async () => {
    try {
      let body = {
        userId: userId
      }
      let url = `${API_URL}/post/bulk_upload/list/${bulkPostLocationId}`

      const response = await httpservice.post(url, body)
      // console.log(response, 'responseDeveloper')
      if (response?.status === 200) {
        // console.log(response.data?.data, 'response')
        SetUploadDetails(response.data?.data?.locationDetails)
        setCompleted(
          response.data?.data?.locationDetails.filter(
            item => item?.status === 'Completed'
          )
        )
        setProcessing(
          response.data?.data?.locationDetails.filter(
            item => item?.status === 'Pending'
          )
        )
        setTotalCount(response.data?.data)
      }
    } catch (error) {}
  }
  // console.log(typeof UploadDetails, 'UploadDetails', UploadDetails)

  const handleFilteredData = e => {
    if (e.target.value === 'All') {
      BranchInfo()
      return
    }
    if (e.target.value === 'Completed') {
      SetUploadDetails(completed)
    }
    if (e.target.value === 'Pending') {
      SetUploadDetails(processing)
    }
  }

  // console.log(TotalCount, 'TotalCount')

  // const handleSearch = (searchText) => {
  //     setSearchValue(searchText);
  //     // console.log(searchText, "handleSearch", UploadDetails);

  //     const checkExample = [...UploadDetails];

  //     if (searchText.trim() === "") {
  //         BranchInfo()
  //     } else {
  //         SetUploadDetails(checkExample.filter((item) =>
  //             item.title.toLowerCase().includes(searchText.toLowerCase()) ||
  //             item.address.toLowerCase().includes(searchText.toLowerCase()) ||
  //             item.city.toLowerCase().includes(searchText.toLowerCase()) ||
  //             item.state.toLowerCase().includes(searchText.toLowerCase())
  //         ));
  //     }
  // };

  const filteredData = UploadDetails?.filter(
    item =>
      item?.title?.toLowerCase()?.includes(searchvalue?.toLowerCase()) ||
      item?.address?.toLowerCase()?.includes(searchvalue?.toLowerCase()) ||
      item?.city?.toLowerCase()?.includes(searchvalue?.toLowerCase()) ||
      item?.state?.toLowerCase()?.includes(searchvalue?.toLowerCase())
  )

  return (
    <div>
      <Modal
        show={isModalOpen}
        onClose={() => {
          setIsModalOpen1(false)
          setSearchValue('')
        }}
        size={'3xl'}
        className={isModalOpen ? 'slide-down' : ''}
      >
        {/* <Modal.Header className='px-3 py-1 border-b-0  pt-2'>
                </Modal.Header> */}
        <Modal.Body className=''>
          <div className='flex justify-between items-center'>
            <p className=' font-semibold text-xl '>View Details</p>
            <div className=' col-md-3 col-6'>
              <select
                id='bulkStatus'
                onChange={e => handleFilteredData(e)}
                className='bg-custom-background4 border-1 text-listing border-listing text-base rounded-lg focus:ring-listing focus:border-listing block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white'
              >
                <option selected value='All'>
                  All
                </option>
                <option value='Completed'>Completed</option>
                <option value='Pending'>Processing</option>
              </select>
            </div>
          </div>
          <div className='flex justify-between items-center pt-2'>
            <div className='position-relative col-6'>
              <div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
                <img
                  src='https://pro-manage.s3.ap-south-1.amazonaws.com/searchIcon.svg'
                  alt='searchIcon'
                />
              </div>
              <input
                className='rounded-md col-12 text-sm   bg-violet-200 serch_btn ps-12'
                placeholder='Search'
                type='search'
                value={searchvalue}
                onChange={e => setSearchValue(e.target.value)}
              />
            </div>
            <div className='flex justify-between items-center gap-3 text-bulkUploadCount text-xs font-semibold'>
              <p className='flex items-center gap-2'>
                Total{' '}
                <span className='text-listing text-base '>
                  {TotalCount.completed + TotalCount.pending}
                </span>
              </p>
              <p className='flex items-center gap-2'>
                Completed{' '}
                <span className='text-completedBulkPost  text-base'>
                  {TotalCount.completed}
                </span>
              </p>
              <p className='flex items-center gap-2'>
                Processing{' '}
                <span className='text-TotalColor  text-base'>
                  {TotalCount.pending}
                </span>
              </p>
            </div>
          </div>
          <div className='relative overflow-x-auto mt-4'>
            <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
              <thead className='text-base text-gray-700  capitalize bg-custom-background3 dark:bg-gray-700 dark:text-gray-400'>
                <tr>
                  <th
                    scope='col'
                    className='px-3 py-2  font-semibold rounded-s-lg'
                  >
                    S.No
                  </th>
                  <th scope='col' className='px-3 font-semibold  py-2'>
                    Uploaded
                  </th>
                  <th
                    scope='col'
                    className='px-3 font-semibold  rounded-e-lg py-2'
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData?.map((item, index) => (
                  <tr
                    className={` dark:bg-gray-800 dark:border-gray-700 border-b border-r border-l `}
                  >
                    <td
                      className={`px-2 py-2 text-xs font-medium  border-r border-r-tableBorder  text-gray-900 dark:text-white`}
                    >
                      <p className='text-businessProfile text-medium '>
                        {index + 1}
                      </p>
                    </td>
                    <td
                      className={` px-2 py-2  text-start  border-r text-xs border-r-tableBorder `}
                    >
                      <p className='text-black text-base'>{item?.title}</p>
                      <p className='flex flex-wrap text-xs text-notVerified'>
                        {item?.address}
                        {item?.city} {item?.state}{' '}
                      </p>
                    </td>
                    <td
                      className={` px-2 py-2 text-xs font-medium text-center flex justify-center `}
                    >
                      <img
                        src={`${
                          item?.status === 'Completed'
                            ? 'https://pro-manage.s3.ap-south-1.amazonaws.com/completedTickSign.svg'
                            : 'https://pro-manage.s3.ap-south-1.amazonaws.com/ProcessingTickSign.svg'
                        }`}
                        alt='img'
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div>
              {filteredData?.lenght === 0 && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='pt-0 border-t-0 justify-end'>
          <div className='flex justify-end items-center gap-4 mt-4'>
            <button
              className='px-3 py-2 text-base  w-24 font-medium text-center text-listing  hover:bg-listing hover:text-white border-1 border-listing rounded-lg  focus:outline-none'
              onClick={() => {
                setIsModalOpen1(false)
                setSearchValue('')
              }}
            >
              {' '}
              Cancel{' '}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ViewDetails
