import React, { useContext, useEffect, useRef, useState } from 'react'
import httpservice from '../Service/httpservice'
import { API_URL } from '../env/env'
import { LocationDetails } from '../Store/LocationInfo'
import moment from 'moment'
import Loader from '../loader/loader'
import { toast } from 'react-toastify'
import CreateModal from '../Components/MediaModal/CreateModal'
import DeleteModal from '../Components/PostModal/DeleteModal'
import { useQuery } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'
import { AuthContext } from '../context/AuthContext'
import { ThreeDots } from 'react-loader-spinner'
import ViewModal from '../Components/MediaModal/ViewModal'

const BusinessMedia = () => {
  const Locationid = LocationDetails(state => state.LocationDetails)

  const [photoData, setPhotoData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [category, setCategory] = useState('ADDITIONAL')
  const [mediaFormat, setMediaFormat] = useState('PHOTO')
  const [isDeleteModal, setDeleteModal] = useState(false)
  const [deletePostName, setDeletePostName] = useState('')
  const [SelectedFileName, setSelectedFileName] = useState('')
  const [load, setload] = useState(true)
  const [openviewmodal, setOpenViewModal] = useState(false)
  const [type, setType] = useState('')
  const [url, setUrl] = useState('')
  const [isUploading, setIsUploading] = useState(false)

  // const [PhotoData,setPhotoData]useState([])

  const [mediaStatus, setMediaStatus] = useState(false)

  const queryClient = useQueryClient()

  const {
    tokenInfo,
    userInfo,
    groupId,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleReviewNotification,
    handleLocationNotification,
    handleQuestionCountNotification
  } = useContext(AuthContext)

  useEffect(() => {
    tokenInfo()
  }, [])

  useEffect(() => {
    if (userInfo?.info?.roleDetails?.roleId === 1) {
      handleLocationCountNotification(true)
      handleReviewCountNotification(true)
      handleQuestionCountNotification(true)
    }
    // handleReviewNotification(true)
    // handleLocationNotification(true)
  }, [groupId, userInfo])

  const invalidateAndRefetch = () => {
    queryClient.invalidateQueries(['MediaPhoto'])
  }

  // const { data: photoData, isPending } = useQuery({
  //   queryFn: () => MediaPhoto(),
  //   queryKey: ['MediaPhoto', { Locationid }],
  //   // enabled: mediaStatus !== false,
  //   staleTime: 1000 * 3600,
  //   cacheTime: 3600
  // })

  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const elementRef = useRef(null)
  const [pageToken, setPageToken] = useState('')
  const [totalPage, setTotalPage] = useState('')

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const firstEntry = entries[0]
        if (firstEntry.isIntersecting && hasMore === true) {
          if (pageToken?.length) {
            MediaPhoto()
          }
        }
      },
      {
        rootMargin: '120px'
      }
    )

    if (observer && elementRef.current) {
      observer.observe(elementRef.current)
    }

    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [elementRef, hasMore, pageToken])

  useEffect(() => {
    MediaPhoto()
  }, [])

  const MediaPhoto = async () => {
    try {
      const body = {
        userId: userInfo.info?.userId,
        groupId: groupId?.groupId,
        locationId: Locationid
      }
      const url = `${API_URL}/media/all?pageToken=${pageToken}`
      const response = await httpservice.post(url, body)
      // console.log('List response ==> ', response)
      if (response?.status === 200) {
        setPageToken(
          response?.data?.data?.nextPageToken
            ? response?.data?.data?.nextPageToken
            : ''
        )
        setHasMore(true)

        // console.log('List response ==> ', response)
        // setPostData(response?.data?.data?.localPosts);
        setload(false)
      } else {
        setPageToken('')
        setHasMore(false)
        // console.log('List response ==> ', response)
        // setPostData(response?.data?.data?.localPosts);
        setload(false)
        // setPostStatus(true)
      }
      setPhotoData(prevProducts => {
        const newData = response?.data?.data?.mediaItems || []
        return Array.isArray(prevProducts)
          ? [...prevProducts, ...(newData || [])]
          : newData
      })
      setMediaStatus(true)
      // return { data: response?.data?.data?.mediaItems }
    } catch (error) {
      setMediaStatus(true)
      setPhotoData([])
      setload(false)
      // console.log('error ==> ', error)
    }
  }

  const deletePhoto = async name => {
    try {
      setIsUploading(true)
      const photosplit = name.split('/')
      const photoid = photosplit[photosplit.length - 1]

      // console.log('Photoid ==> ', photoid)
      const body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        locationId: Locationid
      }

      const url = `${API_URL}/media/delete/${photoid}`

      const response = await httpservice.post(url, body)
      if (response?.status === 200) {
        setDeleteModal(false)
        invalidateAndRefetch()
        setPageToken('')
        setPhotoData([])
        MediaPhoto()
        setIsUploading(false)
        toast.success('Photo deleted successfully')
      }
      if (response.data.status === false) {
        toast.error(response.data.message)
        setIsUploading(false)
      }
    } catch (error) {
      setIsUploading(false)
      toast.error(error?.response?.data?.message)
      // console.log('error ==> ', error)
    }
  }

  const handleFileUpload = e => {
    setIsUploading(true)
    e.preventDefault()
    const formData = new FormData()
    const data = {
      userId: userInfo.info?.userId,
      groupId: groupId?.groupId,
      locationId: Locationid,
      mediaFormat: mediaFormat,
      category: category,
      the_file: selectedFile || ''
    }
    // console.log('formData data : ', data)

    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value)
    }
    // console.log('formData : ', formData)
    CreatePhoto(formData)
  }

  // useEffect(() => {
  //   setload(isPending)
  // }, [isPending])

  const CreatePhoto = async formData => {
    try {
      const url = `${API_URL}/media`
      const response = await httpservice.post(url, formData)
      // console.log('Create Photo Response:', response)
      if (response?.status === 200) {
        toast.success('Photo uploaded successfully')
        setPhotoData([])
        setPageToken('')
        setIsUploading(false)
        MediaPhoto()
        invalidateAndRefetch()
      }
      if (response?.data?.status === false) {
        toast.error(response.data.message)
        setIsUploading(false)
      }

      setSelectedFile(null)
      setIsModalOpen(false)
      setCategory('ADDITIONAL')
      setMediaFormat('PHOTO')
    } catch (error) {
      setCategory('ADDITIONAL')
      setMediaFormat('PHOTO')
      setSelectedFile(null)
      setIsModalOpen(false)
      setIsUploading(false)
      toast.error(error?.response?.data?.message)
      // console.log('Error creating post:', error)
    }
  }

  const handleDeletePhoto = name => {
    setDeleteModal(true)
    setDeletePostName(name)
  }

  // console.log(pageToken, 'pageToken')

  const allProfileOrCover = photoData?.every(
    item =>
      item?.locationAssociation?.category === 'PROFILE' ||
      item?.locationAssociation?.category === 'COVER'
  )

  console.log('Media Data ==> ', photoData, allProfileOrCover)

  return (
    <>
      {load && <Loader />}
      <div className='px-10 pb-5'>
        <div className=' justify-between pt-2 items-center flex'>
          <h1 className='text-3xl font-semibold mb-2'>Media</h1>
          {userInfo?.info?.accessTypeDetails?.accessTypeId === 1 ? (
            ''
          ) : (
            <button
              className='px-3 py-1.5 flex gap-2 text-base font-medium text-center text-white bg-listing rounded-lg hover:opacity-95 focus:outline-none'
              onClick={() => setIsModalOpen(true)}
            >
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/AddIcon+(2).svg'
                className='img-fluid'
                alt='Profilepost'
              />
              Media Upload
            </button>
          )}
        </div>

        {photoData?.length > 0 && (
          <div>
            <div className='pt-4'>
              <div className='grid grid-cols-3 gap-4'>
                {photoData?.map(
                  (photo, index) =>
                    !(
                      photo?.locationAssociation?.category === 'COVER' ||
                      photo?.locationAssociation?.category === 'PROFILE' ||
                      photo?.locationAssociation?.category === 'LOGO'
                    ) && (
                      <div
                        key={index}
                        className='border-1 bg-white border-overView  rounded-2xl p-1'
                      >
                        <div className='m-auto flex justify-center bg-gray-100 rounded-lg'>
                          {photo?.mediaFormat === 'VIDEO' ? (
                            <div
                              className='cursor-pointer'
                              onClick={() => {
                                setType('Video')
                                setUrl(
                                  photo?.sourceUrl
                                    ? photo?.sourceUrl
                                    : photo?.googleUrl
                                )
                                setOpenViewModal(true)
                              }}
                            >
                              <video
                                controls
                                className='h-48 object-cover max-h-52 max-w-[100%] w-[100%]'
                              >
                                <source
                                  src={
                                    photo?.sourceUrl
                                      ? photo?.sourceUrl
                                      : photo?.googleUrl
                                  }
                                  type='video/mp4'
                                />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          ) : (
                            <div
                              className='cursor-pointer'
                              onClick={() => {
                                setType('Image')
                                setUrl(
                                  photo?.sourceUrl
                                    ? photo?.sourceUrl
                                    : photo?.googleUrl
                                )
                                setOpenViewModal(true)
                              }}
                            >
                              <img
                                src={
                                  photo?.sourceUrl
                                    ? photo?.sourceUrl
                                    : photo?.googleUrl
                                }
                                alt='Profilepost'
                                className='h-48 object-cover max-h-52 max-w-[100%] '
                              />
                            </div>
                          )}
                        </div>
                        {/* <img src={photo?.googleUrl} alt="Profilepost" className='w-100 rounded-2xl' style={{ maxWidth: '100%', maxHeight: '200px' }} /> */}
                        <div className='flex justify-between p-3 pt-3'>
                          <div className=''>
                            <p className='text-base font-light'>Posted Date</p>

                            <p className='text-base font-medium'>
                              {' '}
                              {moment
                                .utc(photo?.createTime)
                                .tz('Asia/Calcutta')
                                .format('DD MMM YYYY')}
                            </p>
                          </div>
                          {userInfo?.info?.accessTypeDetails?.accessTypeId ===
                          1 ? (
                            ''
                          ) : (
                            <div className='flex gap-3'>
                              <img
                                src='https://pro-manage.s3.ap-south-1.amazonaws.com/PostMedia.svg'
                                className='cursor-pointer w-8 mt-3 hover:opacity-80'
                                alt='Profilepost'
                                onClick={() => handleDeletePhoto(photo?.name)}
                              />
                            </div>
                          )}
                          {/* <div className='flex gap-3'>
                            <img
                              src='https://pro-manage.s3.ap-south-1.amazonaws.com/PostMedia.svg'
                              className='cursor-pointer w-8 mt-3 hover:opacity-80'
                              alt='Profilepost'
                              onClick={() => handleDeletePhoto(photo?.name)}
                            />
                          </div> */}
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        {allProfileOrCover && (
          <h1 className='text-center   fw-semibold flex justify-center'>
            <img
              src='https://pro-manage.s3.ap-south-1.amazonaws.com/NoImgMeans.svg'
              alt='noData'
              className='img-fluid'
            />
          </h1>
        )}
      </div>
      <div>
        <CreateModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          setCategory={setCategory}
          mediaFormat={mediaFormat}
          setMediaFormat={setMediaFormat}
          setSelectedFileName={setSelectedFileName}
          SelectedFileName={SelectedFileName}
          category={category}
          // setIsUploading={setIsUploading}
          handleFileUpload={handleFileUpload}
          isUploading={isUploading}
        />

        <DeleteModal
          isDeleteModal={isDeleteModal}
          setDeleteModal={setDeleteModal}
          deletePost={deletePhoto}
          deletePostName={deletePostName}
          isUploading={isUploading}
        />
      </div>

      <div className=''>
        <h2
          className='mb-4 fw-semibold'
          ref={elementRef}
          style={{ textAlign: 'center' }}
        >
          {pageToken?.length > 0 ? (
            <div className='flex justify-center'>
              <ThreeDots
                visible={true}
                height='80'
                width='80'
                color='#817AF3'
                radius='9'
                ariaLabel='three-dots-loading'
                wrapperStyle={{}}
                wrapperClass=''
              />
            </div>
          ) : (
            ''
          )}
          {/* Loading */}
        </h2>
      </div>
      <ViewModal
        url={url}
        type={type}
        setOpenViewModal={setOpenViewModal}
        openviewmodal={openviewmodal}
      />
    </>
  )
}

export default BusinessMedia
