import { Modal } from 'flowbite-react'
import React from 'react'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
const PostDetails = ({ popUpComment, setpopUpComment, postComment }) => {
  // console.log(postComment, 'postComment')
  return (
    <div>
      <Modal show={popUpComment} onClose={() => setpopUpComment(false)}>
        <Modal.Header className='p-3'>
          <p className=' font-medium text-2xl'>Post Details</p>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              {postComment?.media ? (
                postComment.media.map((item, ind) => (
                  <div key={ind} className='m-auto flex justify-center'>
                    {item.googleUrl && (
                      <img
                        src={item.googleUrl}
                        alt='Profilepost'
                        className='h-48 object-cover  rounded-2xl'
                        style={{ maxWidth: '100%', maxHeight: '200px' }}
                      />
                    )}
                  </div>
                ))
              ) : (
                <img
                  className='flex justify-center items-center p-2  w-100'
                  src='https://pro-manage.s3.ap-south-1.amazonaws.com/dummyImg.jpg'
                  alt='Profilepost'
                />
              )}
            </div>
            <div className='pt-2'>
              <p className='text-2xl font-semibold'>
                {' '}
                {moment
                  .utc(postComment?.updateTime)
                  .tz('Asia/Calcutta')
                  .format('DD MMM YYYY ')}
              </p>
              <p className='text-base font-medium text-notificationCount'>
                {moment
                  .utc(postComment?.updateTime)
                  .tz('Asia/Calcutta')
                  .format(' hh:mm:ss A')}
              </p>
              <p className='text-base font-medium'>Posted Date</p>
            </div>
            <div className='mt-3'>
              <p className='text-lg leading-relaxed'>{postComment?.summary}</p>
            </div>
            <div className='flex gap-3 mt-3'>
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/GoogleLogo+1.svg'
                width={30}
                height={30}
                alt='logo'
                className='w-5'
              />
              <p className='font-medium text-xl'>Google</p>
            </div>
            <hr className='mt-2' />
            {postComment?.callToAction?.actionType && (
              <div className='mt-3'>
                <p>Button Text</p>
                <p className='text-lg font-semibold '>
                  {postComment?.callToAction?.actionType}
                </p>
              </div>
            )}
            {postComment?.callToAction?.url && (
              <div className='mt-2'>
                <p>Button Action</p>
                <Link
                  className='text-lg font-semibold text-listing'
                  to={postComment?.callToAction?.url}
                >
                  {postComment?.callToAction?.url}
                </Link>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default PostDetails
