import React from 'react'

const FifthForm = ({ description, setDescription, handleNext, onPrevious }) => {
  return (
    <div>
      <div className='flex items-center'>
        <div className=''>
          <img
            src='https://pro-manage.s3.ap-south-1.amazonaws.com/step5.svg'
            className='img-fluid'
            alt='vector'
          />
        </div>
        <div className=' bg-white   rounded-2xl'>
          <div className='flex justify-between pb-2 ps-4 pt-4 pe-4'>
            <h2 className='text-2xl	font-medium'>Add business descripation</h2>
          </div>
          <p className='col-10 mb-3 ps-4 text-businessProfile'>
            Let customers learn more about your business by adding a description{' '}
          </p>
          <form className=''>
            <div className='ps-4 pe-4 mb-4 flex gap-3 relative'>
              <textarea
                id='message'
                rows='8'
                class='block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                placeholder='Write your business descripation'
                value={description}
                onChange={e => setDescription(e.target.value.slice(0, 750))}
              ></textarea>
              <div className='absolute bottom-2 right-9 text-xs text-gray-500'>
                <p>{description?.length}/750</p>
              </div>
            </div>

            <div className='bg-formBottom flex justify-around pb-4 p-2  rounded-b-2xl'>
              <button
                type='button'
                onClick={onPrevious}
                className='bg-formBottom border-1 border-listing text-listing text-xl mt-3 rounded-xl w-44 pt-2 pb-2'
              >
                Previous
              </button>
              <button
                type='button'
                onClick={handleNext}
                className='bg-listing text-light text-xl mt-3 rounded-xl w-44 pt-2 pb-2'
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default FifthForm
