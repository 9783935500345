import React, { useContext, useEffect, useState } from 'react'
import { AccountDetails } from '../Store/AccountInfo'
import { CalenderInsightInfo } from '../Store/CalenderInfo'
import moment from 'moment'
import { API_URL } from '../env/env'
import httpservice from '../Service/httpservice'
import StarRating from '@rubenvara/react-star-rating'
import { useNavigate } from 'react-router-dom'
import { Empty, Table } from 'antd'
import { AuthContext } from '../context/AuthContext'
import Loader from '../loader/loader'

const AllBranchReviewReport = () => {
  const navigate = useNavigate()
  const { userInfo, groupId } = useContext(AuthContext)
  const [load, setload] = useState(true)
  const [isPending, setIsPending] = useState(false)

  const date = CalenderInsightInfo(state => state.CalenderInsightInfo)

  const [startDate, setStartDate] = useState(
    moment.utc(date?.startDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
  )
  const [endDate, setEndDate] = useState(
    moment.utc(date?.endDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
  )

  useEffect(() => {
    setStartDate(
      moment.utc(date?.startDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
    )
    setEndDate(
      moment.utc(date?.endDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
    )
  }, [date])

  useEffect(() => {
    overAllbranch()
  }, [groupId, startDate, endDate])

  const [overalldata, setOverAllData] = useState([])

  const overAllbranch = async () => {
    try {
      let body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        startDate: startDate,
        endDate: endDate
      }
      let url = `${API_URL}/location/all_business_by_date_filter`
      const response = await httpservice.post(url, body)
      if (response.status === 200) {
        // console.log(response.data.data, 'OverAllBranch')
        setOverAllData(response.data.data)
        setIsPending(true)
      } else {
        setOverAllData([])
        setIsPending(true)
      }
    } catch (error) {
      setOverAllData([])
      setIsPending(true)
    }
  }

  const config = {
    fullColor: '#FEA500',
    size: 12,
    showText: false
  }

  const handleReturn = () => {
    navigate(-1)
  }

  const [searchTerm, setsearchTerm] = useState('')
  const [filteredValue, setFilteredValue] = useState('')

  useEffect(() => {
    const FilteredData = overalldata?.filter(
      item =>
        item.title?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
        item.address?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
        item.city?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
        item.state?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
        item.postal_code?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    )
    setFilteredValue(FilteredData)
  }, [searchTerm, overalldata])

  const data = filteredValue

  const columns = [
    // {
    //     title: <span className="bg-custom-background3 p-0">Sl.No.</span>,
    //     dataIndex: 'slNo',
    //     key: 'slNo',
    //     render: (text, record, index) => index + 1,
    //     className: `font-semibold`,
    // },
    {
      title: 'Business',
      dataIndex: 'title',
      key: 'title',
      render: (text, item) => (
        <div
          className={` flex flex-col text-gray-900 whitespace-wrap dark:text-white`}
        >
          <p className='font-medium text-md'>{item?.title}</p>
          <p className='text-xs '>{item?.address}</p>
        </div>
      )
    },
    {
      title: 'Rating',
      dataIndex: 'Rating',
      key: 'Rating',
      render: (rating, item) => (
        <div>
          <p className='flex items-center gap-2 font-medium'>
            {item?.average_rating}{' '}
            <StarRating
              rating={item?.average_rating ? item?.average_rating : 0}
              style={{ marginBottom: '0px' }}
              config={config}
            />
          </p>
        </div>
      )
    },
    {
      title: 'Reviews',
      dataIndex: 'Reviews',
      key: 'Reviews',
      render: (text, item) => (
        <p className='font-medium'>
          {item?.repliedCount + item?.notRepliedCount}
        </p>
      )
    },
    {
      title: 'Replied',
      dataIndex: 'Replied',
      key: 'Replied',
      render: (text, item) => (
        <p className='font-medium'>{item?.repliedCount}</p>
      )
    },
    {
      title: 'Not Replied',
      dataIndex: 'Not Replied',
      key: ' Not Replied',
      render: (text, item) => (
        <p className='font-medium'> {item?.notRepliedCount}</p>
      )
    }
  ]

  const EmptyWithShadow = ({ condition }) => (
    <div
      className={`${condition ? '' : 'hidden'}`}
      style={{
        boxShadow: condition ? '0px 2px 5px rgba(0, 0, 0, 0.1)' : 'none',
        borderRadius: condition ? '0 0 10px 10px' : '0' // Adjust the border-radius values as needed
      }}
    >
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className='mt-0 pt-5' />
    </div>
  )

  useEffect(() => {
    if (isPending) {
      // // console.log(isPending, 'ispending', load)
      // debugger
      setload(false)
    }
  }, [isPending])

  return (
    <div>
      {load && <Loader />}
      <div className='container p-10 pt-2'>
        <div className='flex bg-white rounded-full p-2 w-10'>
          <img
            src='https://pro-manage.s3.ap-south-1.amazonaws.com/returnIcon.svg'
            className='img-fluid cursor-pointer'
            onClick={handleReturn}
          />
        </div>
        <div className='bg-oldDate rounded-2xl ps-6 pe-6 pt-3 mt-4 pb-4'>
          <p className='text-xl font-semibold mb-3'>
            OverAll Branches by Reviews
          </p>
          <div className='position-relative  col-7  mb-4'>
            <div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/searchIcon.svg'
                alt='searchIcon'
              />
            </div>
            <input
              className='text-sm  col-10  serch_btn ps-12'
              placeholder='Search'
              type='search'
              value={searchTerm}
              onChange={e => {
                setsearchTerm(e.target.value)
              }}
            />
          </div>
          <div className='relative overflow-x-auto shadow-base rounded-lg  mt-2'>
            <table className='w-full text-md font-normal  text-left rtl:text-right  dark:text-gray-400'>
              <thead className='capitalize bg-custom-background3 dark:bg-gray-700 dark:text-gray-400 '>
                <tr>
                  <th scope='col' className='px-2 py-2 font-semibold'>
                    Business
                  </th>
                  <th scope='col' className='px-2 py-2 font-semibold'>
                    Rating
                  </th>
                  <th scope='col' className='px-2 py-2 font-semibold'>
                    Reviews
                  </th>
                  <th scope='col' className='px-2 py-2 font-semibold'>
                    Replied
                  </th>
                  <th scope='col' className='w-36 px-2 py-2 font-semibold'>
                    Not Replied
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredValue &&
                  filteredValue?.map(item => (
                    <tr className='bg-transparent border text-base border-tableLatest dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600'>
                      <td className='px-2 py-2 font-normal  border-r border-r-tableLatest  whitespace-wrap  text-gray-900 flex flex-col  dark:text-white'>
                        <p className='font-medium text-md'>{item?.title}</p>
                        <p className='text-xs '>
                          {item?.address} {item?.postal_code}
                        </p>
                      </td>
                      <td className='px-2 py-2 border-r border-r-tableLatest'>
                        <p className='flex items-center gap-2 font-medium'>
                          {item?.average_rating}{' '}
                          <StarRating
                            rating={
                              item?.average_rating ? item?.average_rating : 0
                            }
                            style={{ marginBottom: '0px' }}
                            config={config}
                          />
                        </p>
                      </td>
                      <td className='px-2 py-2 border-r border-r-tableLatest'>
                        <p className='font-medium'>
                          {item?.repliedCount + item?.notRepliedCount}
                        </p>
                      </td>
                      <td className='px-2 py-2 border-r border-r-tableLatest'>
                        <p className='font-medium'>{item?.repliedCount}</p>
                      </td>
                      <td className='px-2 py-2'>
                        <p className='font-medium'> {item?.notRepliedCount}</p>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <EmptyWithShadow condition={filteredValue?.length === 0} />
          </div>

          {/* <Table
                        columns={columns}
                        dataSource={data}
                        className="w-full text-sm bg-oldDate text-left relative overflow-x-auto shadow-md sm:rounded-lg rtl:text-right text-gray-500 dark:text-gray-400 "
                        headerClassName={() => 'bg-custom-background3 dark:bg-gray-700 dark:text-gray-400 text-md text-tableHeading capitalize'}
                        bordered
                    /> */}
        </div>

        {/* {
                    filteredValue?.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                } */}
      </div>
    </div>
  )
}

export default AllBranchReviewReport
