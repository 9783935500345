import React from 'react'
import httpservice from '../../Service/httpservice'
import { Button, Modal } from 'flowbite-react'
import StarRating from '@rubenvara/react-star-rating'

const ReviewReply = ({
  openModalReply,
  setOpenModalReply,
  reply,
  ownerResponse,
  setOwnerResponse,
  replyMessage,
  setReplyMessage,
  handleReply,
  isUploading
}) => {
  const convertStarRatingToNumber = starRatingText => {
    const textToNumber = {
      ONE: 1,
      TWO: 2,
      THREE: 3,
      FOUR: 4,
      FIVE: 5
    }
    const uppercaseText = (starRatingText || '').toUpperCase()
    return textToNumber[uppercaseText] || 0
  }
  const config = {
    fullColor: '#FEA500',
    size: 14,
    showText: false
  }
  // console.log(reply, "reply", ownerResponse, replyMessage)
  return (
    <div>
      <Modal
        show={openModalReply}
        onClose={() => {
          setOpenModalReply(false)
          setReplyMessage('')
        }}
        // className="custom-modal"
      >
        <Modal.Header className='p-3 bg-reviewPopUp rounded-t-2xl    '>
          Reviews Reply
        </Modal.Header>
        <Modal.Body className=' bg-reviewPopUp'>
          <div className=''>
            <div className='d-flex justify-content-start align-items-center'>
              <img
                className='w-14'
                src={reply?.details?.review?.reviewer?.profilePhotoUrl}
                alt=''
              />
              <div className='d-flex flex-col justify-content-start ms-3'>
                <p className='font-semibold mb-0 '>
                  {' '}
                  {reply?.details?.review?.reviewer?.displayName}
                </p>
                <div className='d-flex flex-col justify-content-start'>
                  <p
                    className='m-0'
                    style={{ color: 'gray', fontSize: '14px' }}
                  >
                    {reply?.address}
                  </p>
                  <p
                    className='m-0'
                    style={{ color: 'gray', fontSize: '14px' }}
                  >
                    {reply?.city} ,<span> {reply?.state}</span> ,
                    <span> {reply?.postal_code}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-start align-items-center mt-3'>
              <img
                className='w-7'
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/googleLogo.svg'
                alt=''
              />{' '}
              <span className='ms-3 font-semibold  text-center text-xl text-amber-500'>
                {convertStarRatingToNumber(reply?.details?.review?.starRating)}
              </span>
              <p className='ms-2 '>
                {/* <ReactStars
                                    count={5} // onChange={ratingChanged}
                                    size={26}
                                    edit={false}
                                    value={convertStarRatingToNumber(reply?.details?.review?.starRating)}
                                    isHalf={true}
                                    emptyIcon={<i className="far fa-star"></i>}
                                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                                    fullIcon={<i className="fa fa-star"></i>}
                                    activeColor="#FEA500"
                                /> */}
                <StarRating
                  rating={convertStarRatingToNumber(
                    reply?.details?.review?.starRating
                  )}
                  style={{ marginBottom: '0px' }}
                  config={config}
                />
              </p>
            </div>
            <div className='d-flex justify-content-start align-items-center mt-3'>
              <p className='ms-1'>{reply?.details?.review?.comment}</p>
            </div>
            <form className='mt-3'>
              <div className='relative'>
                <div className='form-floating relative'>
                  <textarea
                    // ref={replyarea}
                    style={{ height: '250px' }}
                    className='form-control comment_flot rounded-2xl border-0'
                    placeholder='Leave a comment here'
                    id='floatingTextarea'
                    // className=""
                    value={
                      reply?.reply?.replyComment ? ownerResponse : replyMessage
                    }
                    onChange={
                      reply?.reply?.replyComment
                        ? e => setOwnerResponse(e.target.value?.slice(0, 4000))
                        : e => setReplyMessage(e.target.value?.slice(0, 4000))
                    }
                  ></textarea>
                  <label
                    for='floatingTextarea'
                    className='text-businessProfile'
                  >
                    Enter reply message here...
                  </label>
                </div>
                <div className='absolute bottom-1 right-2 text-xs text-gray-500'>
                  {ownerResponse?.length
                    ? ownerResponse?.length
                    : replyMessage?.length}
                  /4000
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className='pt-3 justify-end '>
          <div className='flex justify-end gap-4 bg-white '>
            <button
              type='submit'
              className='px-4 py-2 text-md font-medium text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none  '
              onClick={handleReply}
              disabled={isUploading}
            >
              Send
            </button>
            <button
              color='gray'
              onClick={() => {
                setOpenModalReply(false)
                setReplyMessage('')
              }}
              className='px-4 py-2 text-md font-medium text-center border-1 text-gray  rounded-lg focus:outline-none'
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ReviewReply
