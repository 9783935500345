import { Modal } from 'flowbite-react'
import React from 'react'
import Upload from '../PhotoUpload'

const CreateModal = ({
  isModalOpen,
  setIsModalOpen,
  selectedFile,
  setSelectedFile,
  setCategory,
  mediaFormat,
  setMediaFormat,
  setSelectedFileName,
  SelectedFileName,
  category,
  handleFileUpload,
  isUploading
}) => {
  console.log(isUploading, 'isUploading')
  return (
    <div>
      <Modal
        show={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
          setCategory('ADDITIONAL')
          setMediaFormat('PHOTO')
          setSelectedFile(null)
        }}
      >
        <Modal.Header className='p-3'>
          <p className=' font-semibold text-2xl'>Photo Upload</p>
        </Modal.Header>
        <Modal.Body>
          <div className='space-y-6'>
            <form>
              <div id='PostUpload'>
                {selectedFile ? (
                  <div className='flex justify-center '>
                    <div>
                      <div className='flex flex-col justify-center items-center'>
                        {mediaFormat === 'PHOTO' ? (
                          <img
                            src={URL.createObjectURL(selectedFile)}
                            alt='Selected File Preview'
                            id='imageFile'
                            className='img-fluid object-cover '
                            style={{ maxWidth: '100%', maxHeight: '200px' }}
                          />
                        ) : (
                          <video
                            controls
                            className='w-100 rounded-2xl'
                            style={{ maxWidth: '100%', maxHeight: '200px' }}
                          >
                            <source
                              src={URL.createObjectURL(selectedFile)}
                              type='video/mp4'
                            />
                            Your browser does not support the video tag.
                          </video>
                        )}
                        <div className='flex items-center justify-between pt-3 p-2 mt-4 rounded-lg w-100 shadow-photoName'>
                          <div className='flex items-center'>
                            <img
                              src={`${
                                mediaFormat === 'PHOTO'
                                  ? 'https://pro-manage.s3.ap-south-1.amazonaws.com/showPhoto.svg'
                                  : 'https://pro-manage.s3.ap-south-1.amazonaws.com/ShowVideo.svg'
                              }`}
                              alt='photo'
                              className='img-fluid'
                            />
                            <p className=' font-normal text-md text-RecommentationText'>
                              {' '}
                              {SelectedFileName}
                            </p>
                          </div>
                          <img
                            src='https://pro-manage.s3.ap-south-1.amazonaws.com/DeleteIconforPhoto.svg'
                            alt='ProfileDelete'
                            className='cursor-pointer'
                            onClick={() => setSelectedFile(null)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <Upload
                      setSelectedFileName={setSelectedFileName}
                      setSelectedFile={setSelectedFile}
                    />
                    <div className='bg-Recommentation  rounded-lg ps-4 pb-3 pt-1 mt-3'>
                      <p className='mt-2 text-RecommentationText text-xs 	font-medium'>
                        Recommendations for Google Media Guidelines:
                      </p>

                      {/* <p className='mt-2 text-RecommentationText text-xs 	font-medium'>Media Guidelines</p> */}

                      <p className='mt-2 text-RecommentationText text-xs 	font-medium'>
                        • Photo: Use a 4:3 ratio | JPG or JPEG or PNG format |
                        File size between 10 KB and 5 MB.{' '}
                      </p>

                      <p className='mt-2 text-RecommentationText text-xs 	font-medium'>
                        • Video: Keep it up to 30 seconds long | File size up to
                        75 MB | Ensure a minimum resolution of 720 pixels.
                      </p>
                    </div>
                  </>
                )}

                <p className='text-lg font-medium mt-3 mb-2'>Media Format</p>
                <div className='flex gap-3 cursor-pointer'>
                  <p
                    className={` rounded-lg ps-4 pe-4 pt-1 pb-1 text-center ${
                      mediaFormat === 'PHOTO'
                        ? 'bg-Check border-1 border-listing text-listing font-medium'
                        : 'border-1'
                    }`}
                    onClick={() => setMediaFormat('PHOTO')}
                  >
                    Photo
                  </p>
                  <p
                    className={` rounded-lg ps-4 pe-4 pt-1 pb-1 text-center ${
                      mediaFormat === 'VIDEO'
                        ? 'bg-Check border-1 border-listing text-listing font-medium'
                        : 'border-1'
                    }`}
                    onClick={() => setMediaFormat('VIDEO')}
                  >
                    Video
                  </p>
                </div>
                <p className='text-lg font-medium mt-3 mb-2'>Category</p>
                <div className='flex gap-3 cursor-pointer'>
                  <p
                    className={` rounded-lg ps-2.5 pe-2.5 pt-1 pb-1 text-center ${
                      category === 'EXTERIOR'
                        ? 'bg-Check border-1 border-listing text-listing font-medium'
                        : 'border-1'
                    }`}
                    onClick={() => setCategory('EXTERIOR')}
                  >
                    EXTERIOR
                  </p>
                  <p
                    className={` rounded-lg ps-2.5 pe-2.5 pt-1 pb-1 text-center ${
                      category === 'INTERIOR'
                        ? 'bg-Check border-1 border-listing text-listing font-medium'
                        : 'border-1'
                    }`}
                    onClick={() => setCategory('INTERIOR')}
                  >
                    INTERIOR
                  </p>
                  <p
                    className={` rounded-lg ps-2.5 pe-2.5 pt-1 pb-1 text-center ${
                      category === 'AT_WORK'
                        ? 'bg-Check border-1 border-listing text-listing font-medium'
                        : 'border-1'
                    }`}
                    onClick={() => setCategory('AT_WORK')}
                  >
                    AT_WORK
                  </p>
                  <p
                    className={` rounded-lg ps-2.5 pe-2.5 pt-1 pb-1 text-center ${
                      category === 'ADDITIONAL'
                        ? 'bg-Check border-1 border-listing text-listing font-medium'
                        : 'border-1'
                    }`}
                    onClick={() => setCategory('ADDITIONAL')}
                  >
                    ADDITIONAL
                  </p>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className='pt-0 justify-end'>
          <div className='flex justify-end gap-3 mt-4'>
            <button
              color='gray'
              onClick={() => {
                setIsModalOpen(false)
                setSelectedFile(null)
                setCategory('ADDITIONAL')
                setMediaFormat('PHOTO')
              }}
              className='px-4 py-2 text-md font-medium text-center border-1 text-gray  rounded-lg focus:outline-none'
            >
              Cancel
            </button>
            <button
              type='submit'
              className={`px-4 py-2 text-md font-medium text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none ${
                !selectedFile
                  ? 'opacity-50 cursor-not-allowed'
                  : 'cursor-pointer'
              }`}
              onClick={handleFileUpload}
              disabled={!selectedFile || isUploading}
            >
              Upload
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CreateModal
