import React from 'react'
import { Button, Modal } from 'flowbite-react'
const DeleteModal = ({
  openDeleteModal,
  setOpenDeleteModal,
  handleDelete,
  type,
  setSelectedLocation
}) => {
  return (
    <div>
      <Modal
        show={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false)
        }}
      >
        <Modal.Body className='overflow-x-hidden p-3'>
          <div className='p-3'>
            <div className='flex justify-center items-center'>
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/trash12.svg'
                alt=''
              />
            </div>
            <p className='text-center pt-3 text-TuskColor text-2xl font-semibold'>
              Do you want to delete this {type} ?
            </p>
            <div className='text-center flex justify-center items-center gap-4 mt-3'>
              <button
                className='border-1 p-2 ps-3 pe-3 rounded-md border-newCancelButton text-md font-medium text-EditUser'
                onClick={() => setOpenDeleteModal(false)}
              >
                Cancel
              </button>
              <button
                className='bg-deleteButton p-2 ps-3 pe-3 rounded-md text-white'
                onClick={() => {
                  setOpenDeleteModal(false)
                  handleDelete(type)
                  setSelectedLocation('')
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default DeleteModal
