import React from 'react'
import { handleInputValidation } from '../../helper/validator'

const ThirdForm = ({
  primaryNumber,
  setPrimaryNumber,
  alternateNumber,
  setAlternateNumber,
  websiteUri,
  setWebsiteUrl,
  handleNext,
  onPrevious
}) => {
  const handleChangeAltNumber = (index, newValue) => {
    if (
      alternateNumber?.additionalPhones &&
      index < alternateNumber.additionalPhones.length
    ) {
      if (/^[\d\s]+$/.test(newValue) || newValue === '') {
        if (newValue.length <= 12) {
          setAlternateNumber(prevAlternateNumber => {
            const updatedPhones = [...prevAlternateNumber.additionalPhones]
            updatedPhones[index] = newValue
            return {
              ...prevAlternateNumber,
              additionalPhones: updatedPhones
            }
          })
        }
      }
    }
  }

  const handleRemove = indexToRemove => {
    if (alternateNumber?.additionalPhones) {
      setAlternateNumber(prevAlternateNumber => ({
        ...prevAlternateNumber,
        additionalPhones: prevAlternateNumber.additionalPhones.filter(
          (_, index) => index !== indexToRemove
        )
      }))
    }
  }

  const handleAdd = () => {
    if (
      !alternateNumber?.additionalPhones ||
      alternateNumber?.additionalPhones?.length < 2
    ) {
      setAlternateNumber(prevAlternateNumber => ({
        ...prevAlternateNumber,
        additionalPhones: [...(prevAlternateNumber.additionalPhones || []), '']
      }))
    }
  }
  return (
    <div>
      <div className='flex items-center'>
        <div className=''>
          <img
            src='https://pro-manage.s3.ap-south-1.amazonaws.com/step3.svg'
            className='img-fluid'
            alt='vector'
          />
        </div>
        <div className=' bg-white   rounded-2xl'>
          <div className='flex justify-between pb-2 ps-4 pt-4 pe-4'>
            <h2 className='text-2xl	font-medium text-businessProfile'>
              What contact details do you want to
              <br /> show to customers?
            </h2>
          </div>
          <p className='col-10 mb-3 ps-4'>
            Help customers get in touch by including this info on your listing{' '}
          </p>
          <form className=''>
            <div className='mb-3  ps-4 pe-4'>
              <div className='relative '>
                <input
                  type='text'
                  id='Primary Number'
                  className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 'cursor-text text-gray-900   `}
                  placeholder=' '
                  value={primaryNumber}
                  onChange={e =>
                    handleInputValidation(e.target.value, setPrimaryNumber, 3)
                  }
                />
                <label
                  for='Primary Number'
                  className='absolute text-sm font-medium text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  Primary Number
                </label>
              </div>
            </div>
            {primaryNumber.length >= 10 && (
              <div className='mb-3  ps-4 pe-4'>
                {alternateNumber &&
                  alternateNumber?.additionalPhones?.map((phone, index) => (
                    <div key={`phone-input-${index}`} className='relative mt-4'>
                      <div className='relative'>
                        <div className='relative '>
                          <input
                            className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 'cursor-text text-gray-900   `}
                            placeholder=' '
                            value={phone}
                            onChange={e =>
                              handleChangeAltNumber(index, e.target.value)
                            }
                            type='text'
                            id={`additional-number-input-${index}`}
                          />
                          <div className='absolute inset-y-0 end-2 flex items-center ps-3 cursor-pointer'>
                            <img
                              src='https://pro-manage.s3.ap-south-1.amazonaws.com/Delete.svg'
                              alt='edit'
                              className='img-fluid'
                              onClick={() => handleRemove(index)}
                            />
                          </div>
                          <label
                            for='Alternate'
                            htmlFor={`additional-number-input-${index}`}
                            className='absolute text-sm font-medium text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                          >
                            {index === 0
                              ? 'Alternate Number 1'
                              : `Alternate Number ${index + 1}`}
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                {(!alternateNumber?.additionalPhones ||
                  alternateNumber?.additionalPhones?.length < 2) && (
                  <p
                    className={`text-listing font-semibold font-md mt-3 cursor-pointer col-5 ${
                      alternateNumber?.length === 2 ? 'hidden' : ''
                    }`}
                    onClick={handleAdd}
                  >
                    + Add Another Number
                  </p>
                )}
              </div>
            )}

            <div className='ps-4  mt-2 mb-4 pe-4'>
              {/* <div className="mb-3 ">
                                <input type="text" placeholder='Website (optional)' id="large-input" className="block w-full p-3 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    value={websiteUrl} onChange={(e) => setWebsiteUrl(e.target.value)} />
                            </div> */}

              <div className='relative '>
                <input
                  type='text'
                  id='Website'
                  className={`block px-2.5 pe-5 font-medium rounded-lg pb-2.5 pt-4 w-full text-md bg-transparent border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer 'cursor-text text-gray-900   `}
                  placeholder=' '
                  value={websiteUri}
                  onChange={e => setWebsiteUrl(e.target.value)}
                />
                <label
                  for='Website'
                  className='absolute text-sm font-medium text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                >
                  Website (optional)
                </label>
              </div>
            </div>

            <div className='bg-formBottom flex justify-around pb-4 p-2  rounded-b-2xl'>
              <button
                type='button'
                onClick={onPrevious}
                className='bg-formBottom border-1 border-listing text-listing text-xl mt-3 rounded-xl w-44 pt-2 pb-2'
              >
                Previous
              </button>
              <button
                type='button'
                onClick={handleNext}
                className='bg-listing text-light text-xl mt-3 rounded-xl w-44 pt-2 pb-2'
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ThirdForm
