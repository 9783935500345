import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const initialValue = {
  AccountDetails: "",
};

// export const AccountDetails = create(() =>
// persist(()=>{

//   initialValue, {
//    name: "AccountDetails",
//    partialize: (state) => ({ AccountDetails: state.AccountDetails }),
//   )
// }

  
// });

export const AccountDetails = create(
  persist(() => initialValue, {
    name: "AccountDetails",
    storage: createJSONStorage(() => localStorage),
    partialize: (state) => ({ AccountDetails: state.AccountDetails }),
  })
);


export const setAccountData = (item)=>
AccountDetails.setState((state)=>{
  return{ AccountDetails: item }
})