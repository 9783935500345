import { Button, Modal } from 'flowbite-react'
import React, { useContext, useEffect, useState } from 'react'
import '../../Styles/userManagementEdit.css'
import { AccountDetails } from '../../Store/AccountInfo'
import { API_URL } from '../../env/env'
import httpservice from '../../Service/httpservice'
import { handleInputValidation } from '../../helper/validator'
import { toast } from 'react-toastify'
import { AuthContext } from '../../context/AuthContext'

const EditUserModal = ({
  openEditUserModal,
  setOpenEditUserModal,
  usersData,
  roleId,
  getSingleLocation,
  setUsersData,
  tokenDetails,
  setIsUploading,
  isUploading
}) => {
  const [UsersData, setusersData] = useState(usersData)
  useEffect(() => {
    setUserName(usersData.name)
    SetPhoneNumber(usersData?.phone)
    setRole(usersData?.roleDetails?.roleId)
    setusersData(usersData)
    setnewData(usersData)
    setaccess_type(usersData?.accessTypeDetails?.accessTypeId)
  }, [usersData])

  // console.log(usersData.accessTypeDetails, 'usersData')

  const [access_type, setaccess_type] = useState('')
  const [getAllAccessType, setAllAccessType] = useState('')
  const [allRoles, setAllRoles] = useState('')

  // useEffect(() => {
  //   setuserAccess(usersData.userAccess?.filter((item) => item.isAcccess === true) || []);
  // }, [usersData, UsersData])

  const { userInfo, groupId } = useContext(AuthContext)

  const [UserName, setUserName] = useState(usersData.name)
  const [phoneNumber, SetPhoneNumber] = useState(usersData?.phone)
  const [Role, setRole] = useState('')
  // const [accessType , setAccessType]= useState('')

  useEffect(() => {
    getaccess_type()
    getAllRole()
  }, [])
  // const [userAccess, setuserAccess] = useState()
  // console.log('UserData !!!!!', tokenDetails)
  const [newData, setnewData] = useState(usersData)
  console.log(newData, 'newData')
  const handleEdit = async e => {
    setIsUploading(true)
    e.preventDefault()
    try {
      // console.log(e, 'helloWorld')
      let body = {
        userId: tokenDetails?.info?.userId,
        name: UserName,
        phone: phoneNumber,
        role: 2,
        access_type: access_type,
        userAccess: newData?.userAccess
          ?.filter(item => item?.isAcccess === true)
          ?.map(item => item.accessId)
      }
      console.log(body, 'responseUserMANAGEMENT')
      const url = `${API_URL}/user/${usersData?.userId}`
      const response = await httpservice.patch(url, body)
      // console.log(response, 'responseUserMANAGEMENT')
      if (response.status === 200) {
        setOpenEditUserModal(false)
        getSingleLocation()
        setIsUploading(false)
        toast.success(response?.data?.message)
      } else {
        setIsUploading(false)
        setOpenEditUserModal(false)
        toast.success(response?.data?.message)
      }
    } catch (error) {
      setIsUploading(false)
      setOpenEditUserModal(false)
      toast?.error(error?.response?.data?.message)
      // console.log(error, 'userManagement')
    }
  }

  const handleToggleAccess = index => {
    let updatedUserAccess = [...UsersData.userAccess]
    updatedUserAccess[index].isAcccess = !updatedUserAccess[index].isAcccess
    setnewData({ ...UsersData, userAccess: updatedUserAccess })
  }

  const getaccess_type = async () => {
    try {
      const body = {
        userId: userInfo?.info?.userId
      }
      const url = `${API_URL}/other/all_access_type`
      const response = await httpservice.post(url, body)
      if (response.status === 200) {
        setAllAccessType(response?.data?.data)
      } else {
        setAllAccessType('')
        // toast.error(response?.data?.message)
      }
    } catch (error) {
      setAllAccessType('')
      // toast.error(error.response?.data?.message)
    }
  }
  const getAllRole = async () => {
    try {
      const body = {
        userId: userInfo?.info?.userId
      }
      const url = `${API_URL}/other/all_roles`
      const response = await httpservice.post(url, body)
      if (response.status === 200) {
        setAllRoles(response?.data?.data)
      } else {
        setAllRoles('')
        // toast.error(response?.data?.message)
      }
    } catch (error) {
      setAllRoles('')
      // toast.error(error.response?.data?.message)
    }
  }

  return (
    <div>
      <Modal
        show={openEditUserModal}
        onClose={() => {
          setOpenEditUserModal(false)
          setUsersData('')
        }}
        size={'4xl'}
      >
        <Modal.Header className='px-3 py-1 h-16 bg-reviewPopUp pt-2  rounded-t-2xl'>
          <p className=' font-semibold text-2xl'>Edit User</p>
        </Modal.Header>
        <Modal.Body className='bg-reviewPopUp'>
          <div className='space-y-6'>
            <form className='flex flex-col gap-3'>
              <div className='flex justify-between items-center'>
                <div class=''>
                  <label
                    for='username'
                    class='block mb-2 text-sm font-medium text-filter dark:text-white'
                  >
                    User Name
                  </label>
                  <input
                    type='text'
                    id='username'
                    class='bg-gray-50 border border-gray-300 text-filter text-base rounded-md focus:ring-none focus:border-none block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-none dark:focus:border-none'
                    value={UserName}
                    onChange={e =>
                      handleInputValidation(e.target.value, setUserName, 1)
                    }
                    required
                  />
                </div>
                <div>
                  <label
                    for='username'
                    class='block mb-2 text-sm font-medium text-filter dark:text-white'
                  >
                    Access Type
                  </label>
                  <select
                    className='justify-between items-center rounded border w-[208px] bg-gray-50 self-center flex   px-2.5 py-2.5 border-solid border-slate-300 max-md:max-w-full max-md:flex-wrap'
                    onChange={e => setaccess_type(e?.target?.value)}
                  >
                    <option
                      className=''
                      value={access_type?.accessTypeId}
                      selected
                      disabled
                    >
                      {usersData?.accessTypeDetails?.accessTypeName}
                    </option>
                    {getAllAccessType &&
                      getAllAccessType?.map((accessType, index) => (
                        <option
                          className='text-black'
                          value={`${accessType?.accessTypeId}`}
                        >
                          {accessType.accessTypeName}
                        </option>
                      ))}
                    <img
                      loading='lazy'
                      src='https://cdn.builder.io/api/v1/image/assets/TEMP/85f10bfc566cb3624fbb68310ab7db1faa5a9f73116c304ff0ea80f18546383d?'
                      className='aspect-[3.92] object-contain object-center w-full overflow-hidden shrink-0 flex-1 max-w-[119px]'
                      alt=''
                    />
                  </select>
                </div>
                <div class=''>
                  <label
                    for='Phonenumber'
                    class='block mb-2 text-sm font-medium text-filter dark:text-white'
                  >
                    Phone Number
                  </label>
                  <input
                    type='tel'
                    id='Phonenumber'
                    class='bg-gray-50 border border-gray-300 text-filter text-base rounded-md focus:ring-none focus:border-none block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-none dark:focus:border-none'
                    value={phoneNumber}
                    onChange={e =>
                      handleInputValidation(e?.target?.value, SetPhoneNumber, 2)
                    }
                    required
                  />
                </div>
                {/* <div className="relative">
                  <input type="tel" id="Phonenumber" className="w-[224px]  block px-2.5 pb-2.5 pt-3 text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder="Phonenumber" name='Phonenumber' value={phoneNumber} onChange={(e) => handleInputValidation(e?.target?.value, SetPhoneNumber, 2)} />
                  <label for="Phonenumber" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-reviewPopUp dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Phone Number</label>
                </div> */}
              </div>

              <div className='flex justify-between items-center '>
                <div class=''>
                  <label
                    for='email'
                    class='block mb-2 text-sm font-medium text-filter dark:text-white'
                  >
                    Email Id
                  </label>
                  <input
                    type='email'
                    id='email'
                    class='bg-gray-50 cursor-not-allowed border border-gray-300 text-filter text-base rounded-md focus:ring-none focus:border-none block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-none dark:focus:border-none'
                    value={usersData?.email}
                    disabled={true}
                    required
                  />
                </div>

                {/* <div className="relative w-56">
                  <input type="email" id="email" className={`block px-2.5 w-100  pb-2.5 pt-3 text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer `}
                    placeholder="Email" name='email' value={usersData?.email} />
                  <label for="email" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-reviewPopUp dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Email</label>
                </div> */}

                {/* <div className="relative ">
                  <input type="text" id="Createdby" className={`block px-2.5 pe-5 pb-2.5 pt-3 text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer `}
                    placeholder="Created by" name='Createdby' value={usersData?.created_by || ""} readOnly />
                  <label for="Createdby" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-reviewPopUp dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"> Created by</label>
                </div> */}

                <div class=''>
                  <label
                    for='text'
                    class='block mb-2 text-sm font-medium text-filter dark:text-white'
                  >
                    Created by
                  </label>
                  <input
                    type='text'
                    id='text'
                    class='bg-gray-50 border cursor-not-allowed border-gray-300 text-filter text-base rounded-md focus:ring-none focus:border-none block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-none dark:focus:border-none'
                    value={usersData?.created_by || ''}
                    disabled={true}
                    required
                  />
                </div>

                <div class=''>
                  <label
                    for='createdOn'
                    class='block mb-2 text-sm font-medium text-filter dark:text-white'
                  >
                    {' '}
                    Created On
                  </label>
                  <input
                    type='text'
                    id='createdOn'
                    class='bg-gray-50 cursor-not-allowed border border-gray-300 text-filter text-base rounded-md focus:ring-none focus:border-none block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-none dark:focus:border-none'
                    value={`${usersData?.created_date}, ${
                      usersData?.created_time || ''
                    }`}
                    disabled={true}
                  />
                </div>

                {/* <div className="relative">
                  <input
                    type="text"
                    id="createdOn"
                    className={`block px-2.5 pe-5 pb-2.5 pt-3 text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer `}
                    placeholder="Created On"
                    name="createdOn"
                    value={`${usersData?.created_date}, ${usersData?.created_time || ""}`}
                    readOnly
                  />
                  <label
                    htmlFor="createdOn"
                    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-reviewPopUp px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4"
                  >
                    Created On
                  </label>
                </div> */}
              </div>

              <div className='bg-reviewPopUp rounded-lg p-3'>
                <h2 className='font-medium text-black pb-2'>Menus</h2>
                <div className='row row-cols-4  '>
                  {UsersData?.userAccess?.map(
                    (item, index) =>
                      item?.accessId !== 8 &&
                      item?.accessId !== 6 && (
                        <div className='mt-2 mb-2' key={index}>
                          <label className='card border-0 bg-transparent bg-checkbox-blue bg-image-white-20'>
                            <input
                              className='card__input d-none'
                              type='checkbox'
                              checked={item?.isAcccess}
                              onClick={() => handleToggleAccess(index)}
                            />
                            <div className='card__body flex items-center'>
                              <h2 className='text-md font-medium text-black ps-1'>
                                {item?.accessName}{' '}
                              </h2>
                              <span className='card__body-cover-checkbox mt-1'>
                                <svg
                                  className='card__body-cover-checkbox--svg'
                                  viewBox='0 0 12 10'
                                >
                                  <polyline points='1.5 6 4.5 9 10.5 1'></polyline>
                                </svg>
                              </span>
                            </div>
                          </label>
                        </div>
                      )
                  )}
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className='pt-2 justify-center'>
          <div className='flex justify-center items-center gap-4 mt-4'>
            <button
              type='submit'
              className='px-3 py-2.5 text-base w-60  font-medium text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none'
              onClick={e => handleEdit(e)}
              disabled={isUploading}
            >
              {' '}
              Save Profile
            </button>
            <button
              className='px-3 py-2.5 text-base  w-60 font-medium text-center text-listing  border-1 border-listing rounded-lg  focus:outline-none'
              onClick={() => {
                setOpenEditUserModal(false)
              }}
            >
              {' '}
              Cancel{' '}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default EditUserModal
