import React, { useEffect, useRef, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { CalenderInfo, setCalenderData } from '../Store/CalenderInfo'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'

const Calender = ({ Title }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [timePeriod, setTimePeriod] = useState(3)
  const { pathname } = useLocation()
  const [initialized, setInitialized] = useState(true)
  const [WeekPeriod, setWeekPeriod] = useState(0)

  useEffect(() => {
    if (localStorage.getItem('ReviewcalenderUse') !== 'true') {
      const currentDate = new Date()
      const currentMonth = currentDate.getMonth()
      const currentYear = currentDate.getFullYear()
      const previousMonthStartDate = new Date(
        currentYear,
        currentMonth - timePeriod,
        currentDate.getDate() - WeekPeriod
      )
      // console.log(previousMonthStartDate, 'previousMonthStartDate', currentDate)
      if (initialized) {
        setCalenderData({
          startDate: new Date(
            currentYear,
            currentMonth - timePeriod,
            currentDate.getDate() - WeekPeriod
          ),
          endDate: currentDate
        })
      }
      setSelectedStartDate(
        new Date(
          currentYear,
          currentMonth - timePeriod,
          currentDate.getDate() - WeekPeriod
        )
      )
      setSelectedEndDate(currentDate)
      setInitialized(false)
      localStorage.setItem('ReviewcalenderUse', true)
    }
  }, [])

  const date = CalenderInfo(state => state.CalenderInfo)

  const [selectedStartDate, setSelectedStartDate] = useState(date?.startDate)
  const [selectEndDate, setSelectedEndDate] = useState(date?.endDate)

  const handleDate = e => {
    e.preventDefault()
    setCalenderData({
      startDate: selectedStartDate,
      endDate: selectEndDate
    })
    setStart(moment.utc(selectedStartDate).tz('Asia/Calcutta').format('DD-MMM'))
    setEnd(moment.utc(selectEndDate).tz('Asia/Calcutta').format('DD-MMM'))
    setIsOpen(false)
    localStorage.setItem('calender', true)
    // window.location.reload(true);
    // console.log('Selected start date:', selectedStartDate)
    // console.log('Selected end date:', selectEndDate)
  }

  const [startDate, setStart] = useState(
    moment.utc(date?.startDate).tz('Asia/Calcutta').format('DD-MMM')
  )
  const [endDate, setEnd] = useState(
    moment.utc(date?.endDate).tz('Asia/Calcutta').format('DD-MMM')
  )

  const dropdownRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [setIsOpen])

  const handleDateClick = event => {
    // Prevent the click event from bubbling up to the document
    event.stopPropagation()
  }

  function onChange1 (date, dateString) {
    // console.log(date?.$d, selectedStartDate, 'Datepicker')
    setSelectedStartDate(date?.$d)
  }
  function onChange2 (date, dateString) {
    // console.log(date?.$d, selectedStartDate, 'Datepicker')
    setSelectedEndDate(date?.$d)
  }

  const handleTimePeriodChange = (week, months) => {
    const currentDate = new Date()
    const currentMonth = currentDate.getMonth()

    // console.log(timePeriod, 'timePeriod', WeekPeriod)

    const currentYear = currentDate.getFullYear()
    const newStartDate = dayjs(currentDate)
      .subtract(months, 'month')
      .subtract(week, 'week')
      .format('YYYY-MM-DD')
    // console.log(newStartDate, 'newStartDate')
    if (localStorage.getItem('ReviewcalenderUse') !== 'true') {
      if (initialized) {
        setCalenderData({
          startDate: new Date(
            currentYear,
            currentMonth - timePeriod,
            currentDate.getDate() - WeekPeriod
          ),
          endDate: currentDate
        })
      }
    }

    setInitialized(false)

    setSelectedStartDate(newStartDate)
    setSelectedEndDate(moment(currentDate).format('YYYY-MM-DD'))
  }

  return (
    <div>
      <div className='container md:px-4 py-2 '>
        <div className='lg:mt-3 flex justify-between '>
          <h2 className='text-2xl font-semibold text-Heading'>{Title}</h2>

          <div
            ref={dropdownRef}
            className={`dropdown  ${
              pathname.includes('/businessprofile') ||
              pathname.includes('/listing')
                ? 'hidden'
                : 'block'
            }`}
          >
            <div
              className={`m-1 rounded-xl d-flex gap-2 btn hover:bg-gray-100  hover:border-slate-500  border-slate-300 fs-6 align-items-center 
               `}
              onClick={() => setIsOpen(!isOpen)}
            >
              {' '}
              <span>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g clip-path='url(#clip0_115_790)'>
                    <path
                      d='M21.75 24H2.25C1.005 24 0 22.995 0 21.75V3.75C0 2.505 1.005 1.5 2.25 1.5H21.75C22.995 1.5 24 2.505 24 3.75V21.75C24 22.995 22.995 24 21.75 24ZM2.25 3C1.83 3 1.5 3.33 1.5 3.75V21.75C1.5 22.17 1.83 22.5 2.25 22.5H21.75C22.17 22.5 22.5 22.17 22.5 21.75V3.75C22.5 3.33 22.17 3 21.75 3H2.25Z'
                      fill='black'
                    />
                    <path
                      d='M6.75 6C6.33 6 6 5.67 6 5.25V0.75C6 0.33 6.33 0 6.75 0C7.17 0 7.5 0.33 7.5 0.75V5.25C7.5 5.67 7.17 6 6.75 6ZM17.25 6C16.83 6 16.5 5.67 16.5 5.25V0.75C16.5 0.33 16.83 0 17.25 0C17.67 0 18 0.33 18 0.75V5.25C18 5.67 17.67 6 17.25 6ZM23.25 9H0.75C0.33 9 0 8.67 0 8.25C0 7.83 0.33 7.5 0.75 7.5H23.25C23.67 7.5 24 7.83 24 8.25C24 8.67 23.67 9 23.25 9Z'
                      fill='black'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_115_790'>
                      <rect width='24' height='24' fill='white' />
                    </clipPath>
                  </defs>
                </svg>
              </span>{' '}
              <span className='fw-medium ps-1'>
                {moment
                  .utc(date?.startDate)
                  .tz('Asia/Calcutta')
                  .format('DD-MMM')}
              </span>{' '}
              to{' '}
              <span className='fw-medium'>
                {moment.utc(date?.endDate).tz('Asia/Calcutta').format('DD-MMM')}
              </span>
              <span>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M18 9L12 15L6 9'
                    stroke='black'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </span>
            </div>
            {isOpen && (
              <ul
                className=' mt-1 pt-2  border  absolute right-2 bg-white divide-y w-[555px] divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 rounded-box '
                style={{ zIndex: 99 }}
              >
                <div className='flex gap-6 p-3 w-100'>
                  <div className='flex flex-col gap-2'>
                    <li
                      className='mt-2 cursor-pointer hover:bg-blue-400 hover:text-white  rounded px-2 py-1'
                      onClick={() => {
                        setWeekPeriod(1)
                        setTimePeriod(0)
                        handleTimePeriodChange(1, 0)
                      }}
                    >
                      {' '}
                      Last Week
                    </li>
                    <li
                      className=' cursor-pointer hover:bg-blue-400 hover:text-white  rounded px-2 py-1'
                      onClick={() => {
                        setWeekPeriod(0)
                        setTimePeriod(1)
                        handleTimePeriodChange(0, 1)
                      }}
                    >
                      {' '}
                      Last 1 Month
                    </li>
                    <li
                      className='px-2 py-1  hover:bg-blue-400 rounded hover:text-white    cursor-pointer'
                      onClick={() => {
                        setWeekPeriod(0)
                        setTimePeriod(2)
                        handleTimePeriodChange(0, 2)
                      }}
                    >
                      {' '}
                      Last 2 Months
                    </li>
                    <li
                      className='px-2 py-1  hover:bg-blue-400 rounded hover:text-white  cursor-pointer'
                      onClick={() => {
                        setWeekPeriod(0)
                        setTimePeriod(3)
                        handleTimePeriodChange(0, 3)
                      }}
                    >
                      {' '}
                      Last 3 Months
                    </li>
                    {/* <li
                      className="px-2 py-1  hover:bg-blue-400 rounded hover:text-white  cursor-pointer"
                      onClick={() => setTimePeriod(6)}
                    >
                      {" "}
                      Last 6 Months
                    </li> */}
                    {/* <li
                      className="px-2 py-1  hover:bg-blue-400 rounded hover:text-white  cursor-pointer"
                      onClick={() => setTimePeriod(12)}
                    >
                      {" "}
                      Last 1 Year
                    </li>
                    <li
                      className="px-2 py-1  hover:bg-blue-400 rounded hover:text-white  cursor-pointer"
                      onClick={() => setTimePeriod(24)}
                    >
                      {" "}
                      Last 2 Year
                    </li> */}
                  </div>
                  <form
                    onSubmit={handleDate}
                    className='flex flex-col justify-start items-center border-s-2 ps-4'
                    onClick={handleDateClick}
                  >
                    {/* <div className='flex gap-3 items-center justify-center'>
                      <p>Start Date :</p>
                      <DatePicker
                        selected={selectedStartDate}
                        onChange={date => setSelectedStartDate(date)}
                        className='border rounded w-48 focus:outline-none focus:ring-0 '
                        dateFormat='yyyy-MM-dd'
                      />
                    </div>
                    <div className='flex mt-4 gap-3 items-center justify-center'>
                      <p>End Date :</p>
                      <DatePicker
                        selected={selectEndDate}
                        onChange={date => setSelectedEndDate(date)}
                        className='border rounded  w-48 focus:outline-none focus:ring-0'
                        dateFormat='yyyy-MM-dd'
                      />
                    </div> */}

                    <div className='flex gap-3 items-center justify-center'>
                      <p>Start Date :</p>
                      <DatePicker
                        defaultValue={
                          selectedStartDate ? dayjs(selectedStartDate) : null
                        }
                        value={dayjs(selectedStartDate)}
                        onChange={onChange1}
                        className='border rounded w-48 focus:outline-none focus:ring-0 '
                        dateFormat='yyyy-MM-dd'
                      />
                    </div>
                    <div className='flex mt-4 gap-3 items-center justify-center'>
                      <p>End Date :</p>
                      <DatePicker
                        defaultValue={dayjs(selectEndDate)}
                        value={dayjs(selectEndDate)}
                        onChange={onChange2}
                        className='border rounded  w-48 focus:outline-none focus:ring-0'
                        dateFormat='yyyy-MM-dd'
                      />
                    </div>

                    <div className='mt-10'>
                      <button
                        type='submit'
                        className='text-white mt-3 bg-violet-400 hover:bg-violet-500 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Calender
