import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import ViewByDevice from '../Components/Discovery/ViewByDevice'
import { CalenderInsightInfo } from '../Store/CalenderInfo'
import moment from 'moment'
import PerformaneComponent from '../Components/Performance/PerformanceComponent'
import OverAllReview from '../Components/InsightOverAllReview'
import SearchChart from '../Components/CommenCharts/SearchChart'
import Loader from './../loader/loader'
import TotalViewsAndActivity from '../Components/Discovery/TotalViewsAndActivity'
import { UserInfo } from '../Store/UserInfo'
import { API_URL } from '../env/env'
import httpservice from '../Service/httpservice'
import { Link, useNavigate } from 'react-router-dom'
import InsightQuestions from '../Components/InsightQuestions'
const Insights = () => {
  const {
    tokenInfo,
    userInfo,
    groupId,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleQuestionCountNotification,
    handleReviewNotification,
    handleLocationNotification
  } = useContext(AuthContext)
  const [load, setload] = useState(true)

  const navigate = useNavigate()

  // console.log(userInfo, 'userInformation')

  useEffect(() => {
    tokenInfo()
  }, [])

  useEffect(() => {
    if (userInfo?.info?.roleDetails?.roleId === 1) {
      if (groupId) {
        handleLocationCountNotification(true)
        handleReviewCountNotification(true)
        handleQuestionCountNotification(true)
        // handleReviewNotification(true)
        // handleLocationNotification(true)
      }
    }
    // handleLocationCountNotification(true)
    // handleReviewCountNotification(true)
    // handleReviewNotification(true)
    // handleLocationNotification(true)
  }, [groupId, userInfo])

  const [allResponse, setAllResponse] = useState('')

  const date = CalenderInsightInfo(state => state.CalenderInsightInfo)
  const userInformation = UserInfo(state => state.UserInfo)
  // console.log(userInformation, 'userInformation')

  const [startDate, setStartDate] = useState(
    moment.utc(date?.startDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
  )
  const [endDate, setEndDate] = useState(
    moment.utc(date?.endDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
  )

  useEffect(() => {
    setStartDate(
      moment.utc(date?.startDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
    )
    setEndDate(
      moment.utc(date?.endDate).tz('Asia/Calcutta').format('YYYY-MM-DD')
    )
  }, [date])

  const calculatePreviousDate = (startDate, endDate) => {
    const startDateMoment = moment.utc(startDate).tz('Asia/Calcutta')
    const endDateMoment = moment.utc(endDate).tz('Asia/Calcutta')

    const monthsDiff = endDateMoment.diff(startDateMoment, 'months')
    const weekDiff = endDateMoment.diff(startDateMoment, 'weeks')
    const yearsDiff = endDateMoment.diff(startDateMoment, 'years')

    // console.log(monthsDiff, 'monthsDiff', yearsDiff, weekDiff)

    let previousDate
    let difference

    if (monthsDiff >= 11 && weekDiff <= 3) {
      previousDate = startDateMoment
        .subtract(monthsDiff, 'months')
        .format('YYYY-MM-DD')
      difference = { monthsDiff, type: 'Months' }
    } else if (yearsDiff >= 1) {
      previousDate = startDateMoment
        .subtract(yearsDiff, 'years')
        .format('YYYY-MM-DD')
      difference = { yearsDiff, type: 'Years' }
    } else {
      previousDate = startDateMoment
        .subtract(weekDiff, 'weeks')
        .format('YYYY-MM-DD')
      difference = { weekDiff, type: 'Weeks' }
    }
    return { previousDate, difference }
  }

  const previousDate = calculatePreviousDate(startDate, endDate)

  const handelGetAllResponse = async () => {
    try {
      const currentDate = new Date()
      const startDate = new Date(currentDate)
      startDate.setMonth(startDate.getMonth() - 3)
      // console.log('im heree')
      const body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        page: 1,
        pageSize: 6,
        qaType: 0,
        sortBy: 0,
        startDate: startDate?.toISOString().split('T')[0],
        endDate: currentDate?.toISOString().split('T')[0]
      }

      const url = `${API_URL}/qa/all`
      const response = await httpservice.post(url, body)
      if (response.status === 200) {
        // console.log(response, 'allResponse')
        setAllResponse(response)
      } else {
        setAllResponse([])
      }
    } catch (err) {
      setAllResponse([])
    }
  }

  useEffect(() => {
    handelGetAllResponse()
  }, [groupId?.groupId])

  // console.log(allResponse?.data?.data, 'allResponse')
  return (
    <div className='bg-InsightBackGround' data-aos='fade-right'>
      {load && <Loader />}
      <div className='insights  p-10 pt-0  container'>
        <p className='text-md mb-4 font-normal text-gray-900 dark:text-white'>
          Data Synced From{' '}
          <span className='font-medium'>
            {new Date(startDate).toLocaleDateString('en-us', {
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            })}
          </span>{' '}
          till{' '}
          <span className='font-medium'>
            {new Date(endDate).toLocaleDateString('en-us', {
              day: 'numeric',
              month: 'short',
              year: 'numeric'
            })}
          </span>
        </p>
        <TotalViewsAndActivity
          startDate={startDate}
          endDate={endDate}
          userId={userInfo.info?.userId}
          groupId={groupId?.groupId}
        />

        <div className=' row flex mt-5 ps-3'>
          <div className='shadow-filter1 rounded-3xl p-3 col-5  border-[0.1px] border-cardInsights'>
            <ViewByDevice
              startDate={startDate}
              endDate={endDate}
              userId={userInfo.info?.userId}
              groupId={groupId?.groupId}
              setload={setload}
            />
          </div>
          <div className='col-7 ps-0'>
            <PerformaneComponent size={true} setload={setload} />
          </div>
        </div>

        <div className='flex gap-3 mt-5 pe-3 '>
          <div className='col-5 pe-0 '>
            <OverAllReview
              startDate={startDate}
              endDate={endDate}
              previousDate={previousDate}
              userId={userInfo.info?.userId}
              groupId={groupId?.groupId}
              size={true}
            />
          </div>
          <div className='col-7 shadow-filter1 rounded-2xl p-3  border-[0.1px] border-cardInsights'>
            <SearchChart
              startDate={startDate}
              endDate={endDate}
              userId={userInfo.info?.userId}
              groupId={groupId?.groupId}
            />
          </div>
        </div>

        <InsightQuestions allResponse={allResponse} userInfo={userInfo} />
      </div>
    </div>
  )
}

export default Insights
