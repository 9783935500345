import React, { useContext, useEffect } from 'react'
import InsightReviews from '../Components/InsightReviews'
import { AuthContext } from '../context/AuthContext'

const InsightsReview = () => {
  const {
    userInfo,
    tokenInfo,
    groupId,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleQuestionCountNotification,
    handleReviewNotification,
    handleLocationNotification
  } = useContext(AuthContext)

  useEffect(() => {
    tokenInfo()
  }, [])

  useEffect(() => {
    if (userInfo?.info?.roleDetails?.roleId === 1) {
      handleLocationCountNotification(true)
      handleReviewCountNotification(true)
      handleQuestionCountNotification(true)
      // handleReviewNotification(true)
      // handleLocationNotification(true)
    }
  }, [groupId, userInfo])

  return (
    <div className='bg-InsightBackGround'>
      <div className='container  p-10 pt-0'>
        <div>
          <InsightReviews />
        </div>
      </div>
    </div>
  )
}

export default InsightsReview
