import { Navigate, useRoutes } from 'react-router-dom'
import Home from '../pages/Home'
import NotFoundPage from '../pages/NotFoundPage'
import Insights from '../pages/InsightDashboard'
import Listings from '../pages/Listings'
import BusinessProfile from './../pages/BusinessProfile'
import Login from '../pages/Login'
import { ProtectedRoute } from './../route/ProtectedRoute'
import Review from '../pages/Review'
import OverView from '../pages/BusinessOverView'
import Service from '../pages/BusinessService'
import Media from '../pages/BusinessMedia'
import Post from '../pages/BusinessPost'
import SingleBranchReview from '../pages/BusinessReview'
import AddBusiness from '../pages/AddBusiness'
import Questions from '../pages/Questions'
import UserManagement from '../pages/UserManagement'
import Discovery from '../pages/InsightDiscovery'
import Performance from '../pages/InsightPerformance'
import InsightsReview from '../pages/InsightReview'
import OverallBranchs from '../pages/AllBranchReviewReport'
import BulkUpload from '../pages/BulkUpload'
import UploadBulkPost from '../pages/BulkPostUpload'
import { UserInfo } from '../Store/UserInfo'
import ReviewNotification from './../pages/ReviewNotification'
import LocationNotification from '../pages/LocationNotification'
import QuestionNotification from './../pages/QuestionNotification'
import { useState } from 'react'

export const Routes = () => {
  const userAccess = UserInfo(state => state.UserInfo)
  // console.log(userAccess?.userAccess, 'userAccess')

  const routes = useRoutes([
    {
      path: '/',
      element: <Login />
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      ),
      children: [
        {
          path: 'insights/dashboard',
          element: (
            <ProtectedRoute
              requiredPermission={'Insights'}
              userAccess={userAccess?.userAccess}
            >
              <Insights />
            </ProtectedRoute>
          )
        },
        {
          path: 'insights/discovery',
          element: (
            <ProtectedRoute
              requiredPermission={'Insights'}
              userAccess={userAccess?.userAccess}
            >
              <Discovery />
            </ProtectedRoute>
          )
        },
        {
          path: 'insights/performance',
          element: (
            <ProtectedRoute
              requiredPermission={'Insights'}
              userAccess={userAccess?.userAccess}
            >
              <Performance />
            </ProtectedRoute>
          )
        },
        {
          path: 'insights/review',
          element: (
            <ProtectedRoute
              requiredPermission={'Insights'}
              userAccess={userAccess?.userAccess}
            >
              <InsightsReview />
            </ProtectedRoute>
          )
        },
        {
          path: 'insights/allbranchreport',
          element: (
            <ProtectedRoute
              requiredPermission={'Insights'}
              userAccess={userAccess?.userAccess}
            >
              <OverallBranchs />
            </ProtectedRoute>
          )
        }
      ]
    },
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      ),
      children: [
        {
          path: 'listings',
          element: (
            <ProtectedRoute
              requiredPermission={'Listings'}
              userAccess={userAccess?.userAccess}
            >
              <Listings />
            </ProtectedRoute>
          )
        },
        {
          path: 'reviews',
          element: (
            <ProtectedRoute
              requiredPermission={'Reviews'}
              userAccess={userAccess?.userAccess}
            >
              <Review />
            </ProtectedRoute>
          )
        },
        // {
        //   path: 'review1',
        //   element: <Review1 />
        // },
        {
          path: 'businessprofile',
          element: (
            <ProtectedRoute
              requiredPermission={'Business Profile'}
              userAccess={userAccess?.userAccess}
            >
              <BusinessProfile />
            </ProtectedRoute>
          )
        },
        {
          path: 'businessprofile/overview',
          element: (
            <ProtectedRoute
              requiredPermission={'Business Profile'}
              userAccess={userAccess?.userAccess}
            >
              <OverView />
            </ProtectedRoute>
          )
        },
        {
          path: 'businessprofile/media',
          element: (
            <ProtectedRoute
              requiredPermission={'Business Profile'}
              userAccess={userAccess?.userAccess}
            >
              <Media />
            </ProtectedRoute>
          )
        },
        {
          path: 'businessprofile/post',
          element: (
            <ProtectedRoute
              requiredPermission={'Business Profile'}
              userAccess={userAccess?.userAccess}
            >
              <Post />
            </ProtectedRoute>
          )
        },
        {
          path: 'businessProfile/service',
          element: (
            <ProtectedRoute
              requiredPermission={'Business Profile'}
              userAccess={userAccess?.userAccess}
            >
              <Service />
            </ProtectedRoute>
          )
        },
        {
          path: 'businessprofile/review',
          element: (
            <ProtectedRoute
              requiredPermission={'Business Profile'}
              userAccess={userAccess?.userAccess}
            >
              <SingleBranchReview />
            </ProtectedRoute>
          )
        },

        {
          path: 'businessprofile/addBusiness',
          element: (
            <ProtectedRoute
              requiredPermission={'Business Profile'}
              userAccess={userAccess?.userAccess}
              accessTypeId={userAccess?.info?.accessTypeDetails?.accessTypeId}
            >
              <AddBusiness />
            </ProtectedRoute>
          )
        },
        {
          path: 'questions',
          element: (
            <ProtectedRoute
              requiredPermission={'Question & Answer'}
              userAccess={userAccess?.userAccess}
            >
              <Questions />
            </ProtectedRoute>
          )
        },
        {
          path: 'usermanagement',
          element: (
            <ProtectedRoute
              requiredPermission={'User Management'}
              userAccess={userAccess?.userAccess}
              roleBase={userAccess?.info?.roleDetails?.roleId}
            >
              <UserManagement />
            </ProtectedRoute>
          )
        },
        {
          path: 'bulkUpload',
          element: (
            <ProtectedRoute
              requiredPermission={'Bulk Upload'}
              userAccess={userAccess?.userAccess}
            >
              <BulkUpload />
            </ProtectedRoute>
          )
        },
        {
          path: 'bulkUpload/upload',
          element: (
            <ProtectedRoute
              requiredPermission={'Bulk Upload'}
              userAccess={userAccess?.userAccess}
              accessTypeId={userAccess?.info?.accessTypeDetails?.accessTypeId}
            >
              <UploadBulkPost />
            </ProtectedRoute>
          )
        },
        {
          path: 'useractivity/review',
          element: (
            <ProtectedRoute
              requiredPermission={'User Activity'}
              userAccess={userAccess?.userAccess}
            >
              <ReviewNotification />
            </ProtectedRoute>
          )
        },
        {
          path: 'useractivity/location',
          element: (
            <ProtectedRoute
              requiredPermission={'User Activity'}
              userAccess={userAccess?.userAccess}
            >
              <LocationNotification />
            </ProtectedRoute>
          )
        },
        {
          path: 'useractivity/question',
          element: (
            <ProtectedRoute
              requiredPermission={'User Activity'}
              userAccess={userAccess?.userAccess}
            >
              <QuestionNotification />
            </ProtectedRoute>
          )
        }
      ]
    },
    {
      path: '*',
      element: <NotFoundPage />
    }
  ])

  return <>{routes}</>
}
