import { Modal } from 'flowbite-react'
import React, { useContext, useEffect, useState } from 'react'
import { AccountDetails } from '../../Store/AccountInfo'
import { LocationDetails } from '../../Store/LocationInfo'
import { API_URL } from '../../env/env'
import httpservice from '../../Service/httpservice'
// import { Button, Modal } from "flowbite-react";
import { toast } from 'react-toastify'
import { AuthContext } from '../../context/AuthContext'

const RegularTimeModal = ({
  openModalRegularHour,
  setOpenModalRegularHour,
  setworkingHr1,
  newDayStatus,
  setInitialSelectedTimes,
  days,
  dayStatus,
  handleCheckboxChange,
  initialSelectedTimes,
  timeOptions,
  workingHr,
  workingHr1,
  invalidateAndRefetch,
  handleLocationCountNotification
}) => {
  // const id = AccountDetails((state) => state.AccountDetails);
  // const tokenDetails = JSON.parse(localStorage.getItem("tmbUserData"));
  const { userInfo, groupId } = useContext(AuthContext)
  const Locationid = LocationDetails(state => state.LocationDetails)
  let cache = {}

  const handleCheckboxChange1 = index => {
    const selectedDay = days[index]
    // console.log(selectedDay, 'selectedDay')

    setInitialSelectedTimes(prevTimes => {
      const updatedTimes = [...prevTimes]
      updatedTimes[index] = !updatedTimes[index]
      return updatedTimes
    })

    setNewWorkingHr(prevWorkingHr => {
      const updatedWorkingHr = [...prevWorkingHr]
      const filteredWorkingHr = updatedWorkingHr.filter(
        item => item.openDay !== selectedDay
      )
      return filteredWorkingHr
    })
    setNewDayStatus1(prevWorkingHr => {
      const updatedWorkingHr = [...prevWorkingHr]
      const filteredWorkingHr = updatedWorkingHr.filter(
        item => item.openDay !== selectedDay
      )
      return filteredWorkingHr
    })

    setSelectedDropdownIndices(prevSelectedDropdownIndices => {
      const updatedSelectedDropdownIndices = [...prevSelectedDropdownIndices]
      const result = updatedSelectedDropdownIndices.filter(
        item => item !== index
      )
      return result
    })
    // console.log(selectedDropdownIndices, 'selectedDropdownIndices')
  }

  const handleChangeSelection = (index, startValue, endValue) => {
    // console.log(index, startValue, endValue, 'startValue')
    const selectedDay = days[index]
    const [startHours, startMinutes] = startValue?.split(':') || []
    const [endHours, endMinutes] = endValue?.split(':') || []

    // console.log(!initialSelectedTimes[index], 'initialSelectedTimes[index]')

    if (!initialSelectedTimes[index]) {
      // // console.log("in111111111111111111")/
      setNewWorkingHr(prevWorkingHr => {
        return prevWorkingHr.filter(item => item.openDay !== selectedDay)
      })
      return
    }
    setNewWorkingHr(prevWorkingHr => {
      const updatedWorkingHr = [...prevWorkingHr]
      const existingIndex = updatedWorkingHr.findIndex(
        item => item.openDay === selectedDay
      )

      // console.log(existingIndex, 'existingIndex')

      if (existingIndex === -1) {
        updatedWorkingHr.push({
          openDay: selectedDay,
          openTime: { hours: startHours, minutes: startMinutes },
          closeDay: selectedDay,
          closeTime: { hours: endHours, minutes: endMinutes }
        })
      } else {
        updatedWorkingHr[existingIndex].openTime = {
          hours: startHours,
          minutes: startMinutes
        }
        updatedWorkingHr[existingIndex].closeTime = {
          hours: endHours,
          minutes: endMinutes
        }
      }

      return updatedWorkingHr
    })
  }

  useEffect(() => {
    setNewWorkingHr(workingHr?.filter(item => item !== undefined))
    setNewDayStatus1(workingHr1?.filter(item => !workingHr.includes(item)))
  }, [workingHr, workingHr1])

  const [newWorkinhHr, setNewWorkingHr] = useState(
    workingHr?.filter(item => item !== 'undefined')
  )
  const [newDayStatus1, setNewDayStatus1] = useState(
    workingHr1?.filter(item => !workingHr.includes(item))
  )

  // let result = workingHr1.filter(item => !workingHr.includes(item));

  // console.log(newWorkinhHr, 'initialSelectedTimes', newDayStatus1)
  const handleChangeSelection2 = (index, startValue, endValue) => {
    const selectedDay = days[index]
    const [startHours, startMinutes] = startValue.split(':')
    const [endHours, endMinutes] = endValue.split(':')

    if (index === initialSelectedTimes[index]) {
      // console.log(
      //   'handlechange23',
      //   index,
      //   selectedDropdownIndices,
      //   newWorkinhHr
      // )
      setNewWorkingHr(prev => {
        const changeNewWorking = [...prev]
        const finalOut = changeNewWorking.filter(
          (item, ItemIndex) => ItemIndex !== index
        )
        // console.log(finalOut, 'finalout')
        return finalOut
      })
    }

    setNewDayStatus1(prevWorkingHr => {
      const updatedWorkingHr = [...prevWorkingHr]
      const existingIndex = updatedWorkingHr.findIndex(
        item => item.openDay === selectedDay
      )

      if (existingIndex === -1) {
        updatedWorkingHr.push({
          openDay: selectedDay,
          openTime: { hours: startHours, minutes: startMinutes },
          closeDay: selectedDay,
          closeTime: { hours: endHours, minutes: endMinutes }
        })
      } else {
        updatedWorkingHr[existingIndex].openTime = {
          hours: startHours,
          minutes: startMinutes
        }
        updatedWorkingHr[existingIndex].closeTime = {
          hours: endHours,
          minutes: endMinutes
        }
      }

      return updatedWorkingHr
    })
  }

  const combinedWorkingHr = [...(newDayStatus1 || []), ...(newWorkinhHr || [])]

  // console.log(
  //   'newDayStatus1',
  //   newDayStatus1,
  //   'newWorkinhHr',
  //   newWorkinhHr,
  //   'combinedWorkingHr',
  //   combinedWorkingHr
  // )

  const handleRegularHr = async (e, item) => {
    e.preventDefault()
    try {
      let body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        locationId: Locationid,
        sectionName: 'Regular Hours',
        updateData: {
          regularHours: {
            periods: combinedWorkingHr
          }
        }
      }

      const url = `${API_URL}/location/update?updateMask=regularHours`

      const res = await httpservice.patch(url, body)
      if (res.status === 200) {
        setOpenModalRegularHour(false)
        if (userInfo?.info?.roleDetails?.roleId === 1) {
          handleLocationCountNotification(true)
        }
        invalidateAndRefetch()

        toast.success('Regular hours updated, reflects in 10.')
      } else {
        setOpenModalRegularHour(false)
        toast.error(res?.data?.message)
      }
      // console.log(res)
    } catch (error) {
      toast.error(error?.response?.data?.message)
      setOpenModalRegularHour(false)
    }
  }
  const [showDropdowns, setShowDropdowns] = useState(false)
  // const [selectedDropdownIndex, setSelectedDropdownIndex] = useState(-1);
  const [selectedDropdownIndices, setSelectedDropdownIndices] = useState([])
  // console.log(selectedDropdownIndices, 'setSelectedDropdownIndices165')
  const addNewSelection = index => {
    const updatedIndices = [...selectedDropdownIndices]
    const currentIndex = updatedIndices.indexOf(index)

    // console.log(updatedIndices, 'updatedIndices', currentIndex)

    if (currentIndex === -1) {
      updatedIndices.splice(index, 1, index)
      // updatedIndices.push(index);
    } else {
      updatedIndices.splice(currentIndex, 1)
    }
    setShowDropdowns(true)
    // console.log(index, 'index', updatedIndices)
    setSelectedDropdownIndices(updatedIndices)
  }

  const [indexTimeValues, setIndexTimeValues] = useState([])

  const countOccurrences = (dayToCount, workingHours) => {
    const filteredObjects = workingHours?.filter(
      obj => obj?.openDay === dayToCount
    )
    return {
      count: filteredObjects?.length,
      objects: filteredObjects
    }
  }

  useEffect(() => {
    const newCountByDay = {}
    const countArray = []
    const matchingObjectsArray = []

    days.forEach(day => {
      const { count: numberOfOccurrences, objects: matchingObjects } =
        countOccurrences(day, workingHr1)
      // console.log(numberOfOccurrences, 'numberOfOccurrences')
      // console.log(matchingObjects, 'matchingObjects')

      newCountByDay[day] = numberOfOccurrences
      countArray.push(numberOfOccurrences)
      matchingObjectsArray.push(matchingObjects)
    })

    const countByDayValues = Object.values(newCountByDay)
    const Value = matchingObjectsArray?.filter(
      (acc, count) => acc?.length >= 2 && acc
    )
    const countByDayValue = Value?.flatMap((item, index) => item)

    const result1 = countByDayValue?.filter(
      (item, index) => index % 2 !== 0 && item
    )

    const result = days?.map(
      day => result1?.find(item => item?.openDay === day) || 'null'
    )

    setIndexTimeValues(result)

    const indicesOfCountGreaterThanEqual2 = countByDayValues?.reduce(
      (acc, count, index) => {
        if (count >= 2) {
          acc.push(index)
        } else {
          acc.push('null')
        }
        return acc
      },
      []
    )

    setSelectedDropdownIndices(indicesOfCountGreaterThanEqual2)
    setShowDropdowns(true)
  }, [workingHr])

  // console.log('selectedDropdownIndices', selectedDropdownIndices)

  // console.log(
  //   indexTimeValues?.map(item => item?.openTime?.hours),
  //   'indexTimeValues'
  // )

  const deleteSelection = (index1, itemIndex) => {
    // console.log(index1, 'index1')

    setSelectedDropdownIndices(
      selectedDropdownIndices?.filter(item => item !== index1)
    )
    setSelectedDropdownIndices(prev => [...prev, 'null'])

    // console.log(selectedDropdownIndices, 'selectedDropdownIndices', index1)

    if (index1 === selectedDropdownIndices[index1]) {
      setNewDayStatus1(prevWorkingHr => {
        const updatedWorkingHr = [...prevWorkingHr]
        const selectedDay = days[index1]

        // console.log(selectedDay, 'selectedDay')
        const filteredWorkingHr = updatedWorkingHr.filter(
          item => item.openDay !== selectedDay
        )

        // console.log(filteredWorkingHr, 'filteredWorkingHr')

        return filteredWorkingHr
      })

      setIndexTimeValues(prev => {
        const BackupValue = [...prev]
        const filteredVallue = BackupValue.filter(
          (item, index) => index !== index1
        )
        return filteredVallue
      })
    } else {
      setNewWorkingHr(prevWorkingHr => {
        const updatedWorkingHr = [...prevWorkingHr]

        // console.log(updatedWorkingHr, 'updatedWorkingHr')

        const filteredWorkingHr = updatedWorkingHr.filter(
          (item, index) => index !== index1
        )

        // console.log(filteredWorkingHr, 'filteredWorkingHr')

        return filteredWorkingHr
      })
    }
  }

  // console.log(selectedDropdownIndices, 'selectedDropdownIndices')

  return (
    <div>
      <Modal
        show={openModalRegularHour}
        onClose={() => {
          setOpenModalRegularHour(false)
          setInitialSelectedTimes(newDayStatus)
        }}
      >
        <Modal.Header className='p-3'>
          <p className=' font-medium text-2xl'>Edit Working Hours</p>
        </Modal.Header>
        <Modal.Body>
          <div className='space-y-6'>
            {
              <>
                {days.map((day, index) =>
                  !dayStatus[index] ? (
                    <div key={index} className='flex items-center gap-3'>
                      <p className='col-2'>{day}</p>
                      <label className='relative inline-flex items-center cursor-pointer'>
                        <input
                          type='checkbox'
                          className='sr-only peer'
                          onChange={() => handleCheckboxChange1(index)}
                          checked={initialSelectedTimes[index]}
                        />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <p className='ps-3'></p>
                      </label>

                      <div className='flex flex-col'>
                        <div className='flex gap-3'>
                          {initialSelectedTimes[index] && (
                            <>
                              <div className='relative ms-3'>
                                <select
                                  id={`timeSelect-${index}-start`}
                                  className='rounded-3 border'
                                  onChange={e =>
                                    handleChangeSelection(
                                      index,
                                      e.target.value,
                                      document.getElementById(
                                        `timeSelect-${index}-end`
                                      ).value
                                    )
                                  }
                                >
                                  {timeOptions.map((time, timeIndex) => (
                                    <option key={timeIndex} value={time}>
                                      {time}
                                    </option>
                                  ))}
                                </select>
                                <label
                                  htmlFor={`timeSelect-${index}-start`}
                                  className={`absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 `}
                                >
                                  Open At
                                </label>
                              </div>
                              <div className='relative '>
                                <select
                                  id={`timeSelect-${index}-end`}
                                  className='rounded-3 border'
                                  onChange={e =>
                                    handleChangeSelection(
                                      index,
                                      document.getElementById(
                                        `timeSelect-${index}-start`
                                      ).value,
                                      e.target.value
                                    )
                                  }
                                >
                                  {timeOptions.map((time, timeIndex) => (
                                    <option key={timeIndex} value={time}>
                                      {time}
                                    </option>
                                  ))}
                                </select>
                                <label
                                  htmlFor={`timeSelect-${index}-end`}
                                  className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                                >
                                  Close At
                                </label>
                              </div>
                              <p
                                className={`font-semibold text-2xl text-listing cursor-pointer ${
                                  selectedDropdownIndices[index] === index
                                    ? 'hidden'
                                    : ''
                                }`}
                                onClick={() => addNewSelection(index)}
                              >
                                +
                              </p>
                            </>
                          )}
                        </div>
                        <div className='flex flex-col gap-3 '>
                          {showDropdowns &&
                            selectedDropdownIndices?.includes(index) && (
                              <div
                                key={index}
                                className='flex items-center space-x-3 pt-3'
                              >
                                <div className='relative ms-3'>
                                  <select
                                    id={`timeSelect1-${index}-start`}
                                    className='rounded-3 border'
                                    onChange={e =>
                                      handleChangeSelection2(
                                        index,
                                        e.target.value,
                                        document.getElementById(
                                          `timeSelect1-${index}-end`
                                        ).value
                                      )
                                    }
                                  >
                                    {timeOptions.map((time, timeIndex) => (
                                      <option key={timeIndex} value={time}>
                                        {time}
                                      </option>
                                    ))}
                                  </select>
                                  <label
                                    htmlFor={`timeSelect1-${index}-start`}
                                    className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                                  >
                                    Open At
                                  </label>
                                </div>
                                <div className='relative '>
                                  <select
                                    id={`timeSelect1-${index}-end`}
                                    className='rounded-3 border'
                                    onChange={e =>
                                      handleChangeSelection2(
                                        index,
                                        document.getElementById(
                                          `timeSelect1-${index}-start`
                                        ).value,
                                        e.target.value
                                      )
                                    }
                                  >
                                    {timeOptions.map((time, timeIndex) => (
                                      <option key={timeIndex} value={time}>
                                        {time}
                                      </option>
                                    ))}
                                  </select>
                                  <label
                                    htmlFor={`timeSelect1-${index}-end`}
                                    className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                                  >
                                    Close At
                                  </label>
                                </div>

                                <button
                                  className=''
                                  onClick={() => deleteSelection(index)}
                                >
                                  <img
                                    src='https://pro-manage.s3.ap-south-1.amazonaws.com/Delete.svg'
                                    alt='alternative'
                                  />
                                </button>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {workingHr &&
                        workingHr
                          ?.filter(item => item?.openDay === day)
                          ?.map((item, itemIndex) => (
                            <div
                              key={itemIndex}
                              className='flex items-center gap-3'
                            >
                              {!cache[item.closeDay]
                                ? ((cache[item.closeDay] = 1),
                                  (
                                    <>
                                      <p className='col-2'>{item?.openDay}</p>
                                      <label className='relative  inline-flex items-center cursor-pointer'>
                                        <input
                                          type='checkbox'
                                          className='sr-only peer'
                                          onChange={() =>
                                            handleCheckboxChange(index)
                                          }
                                          checked={initialSelectedTimes[index]}
                                        />
                                        <div className="w-11  h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                        <p className='ps-3'></p>
                                      </label>
                                    </>
                                  ))
                                : cache[item.closeDay]++ && ''}

                              {initialSelectedTimes[index] && (
                                <div
                                  className={`flex flex-col ${
                                    selectedDropdownIndices[index] === index
                                      ? 'item-start'
                                      : ' items-center'
                                  }`}
                                >
                                  <div className='flex items-center gap-3'>
                                    <div
                                      className={`relative ${
                                        selectedDropdownIndices[index] === index
                                          ? 'ms-2'
                                          : 'ms-2'
                                      }`}
                                    >
                                      <select
                                        id={`timeSelect-${index}-${itemIndex}`}
                                        className='rounded-3 border'
                                        defaultValue={
                                          item?.openTime
                                            ? `${String(
                                                item?.openTime.hours
                                              ).padStart(2, '0')}:${
                                                item?.openTime.minutes ?? '00'
                                              }`
                                            : '00:00'
                                        }
                                        onChange={e =>
                                          handleChangeSelection(
                                            index,
                                            e.target.value,
                                            document.getElementById(
                                              `timeSelect-close-${index}-${itemIndex}`
                                            )?.value || '00:00'
                                          )
                                        }
                                      >
                                        {timeOptions.map((time, timeIndex) => (
                                          <option key={timeIndex} value={time}>
                                            {time}
                                          </option>
                                        ))}
                                      </select>
                                      <label
                                        htmlFor={`timeSelect-${index}-${itemIndex}`}
                                        className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                                      >
                                        Open At
                                      </label>
                                    </div>

                                    <div className='relative'>
                                      <select
                                        id={`timeSelect-close-${index}-${itemIndex} `}
                                        className={`rounded-3 border ${item?.closeTime?.hours}`}
                                        // defaultValue={`${item?.closeTime?.hours ? item?.closeTime?.hours : ""}:${item?.closeTime?.minutes ? item?.closeTime?.minutes : '00'}`}
                                        defaultValue={
                                          item?.closeTime
                                            ? `${String(
                                                item.closeTime.hours
                                              ).padStart(2, '0')}:${
                                                item.closeTime.minutes ?? '00'
                                              }`
                                            : '00:00'
                                        }
                                        onChange={e =>
                                          handleChangeSelection(
                                            index,
                                            document.getElementById(
                                              `timeSelect-${index}-${itemIndex}`
                                            )?.value || '00:00',
                                            e.target.value
                                          )
                                        }
                                      >
                                        {timeOptions.map((time, timeIndex) => (
                                          <option key={timeIndex} value={time}>
                                            {time}
                                          </option>
                                        ))}
                                      </select>
                                      <label
                                        htmlFor={`timeSelect-close-${index}-${itemIndex}`}
                                        className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                                      >
                                        Close At
                                      </label>
                                    </div>
                                    <p
                                      className={`font-semibold text-2xl text-listing cursor-pointer ${
                                        selectedDropdownIndices[index] === index
                                          ? 'hidden'
                                          : ''
                                      }`}
                                      onClick={() => addNewSelection(index)}
                                    >
                                      +
                                    </p>
                                  </div>
                                  <div className='flex flex-col gap-3 '>
                                    {showDropdowns &&
                                      selectedDropdownIndices?.includes(
                                        index
                                      ) && (
                                        <div
                                          key={index}
                                          className='flex items-center pt-3 space-x-3'
                                        >
                                          <div className='relative ms-2'>
                                            <select
                                              id={`timeSelect-${index}-${itemIndex}1`}
                                              className='rounded-3 border'
                                              defaultValue={
                                                indexTimeValues[index]?.openTime
                                                  ? `${String(
                                                      indexTimeValues[index]
                                                        .openTime.hours ?? '00'
                                                    ).padStart(2, '0')}:${
                                                      indexTimeValues[index]
                                                        .openTime.minutes ??
                                                      '00'
                                                    }`
                                                  : '00:00'
                                              }
                                              onChange={e =>
                                                handleChangeSelection2(
                                                  index,
                                                  e.target.value,
                                                  document.getElementById(
                                                    `timeSelect-close-${index}-${itemIndex}1`
                                                  ).value
                                                )
                                              }
                                            >
                                              {timeOptions.map(
                                                (time, timeIndex) => (
                                                  <option value={time}>
                                                    {time}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                            <label
                                              htmlFor={`timeSelect-${index}-${itemIndex}1`}
                                              className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                                            >
                                              Open At
                                            </label>
                                          </div>

                                          <div className='relative ms-3'>
                                            <select
                                              id={`timeSelect-close-${index}-${itemIndex}1`}
                                              className='rounded-3 border'
                                              defaultValue={
                                                indexTimeValues[index]
                                                  ?.closeTime
                                                  ? `${String(
                                                      indexTimeValues[index]
                                                        .closeTime.hours ?? '00'
                                                    ).padStart(2, '0')}:${
                                                      indexTimeValues[index]
                                                        .closeTime.minutes ??
                                                      '00'
                                                    }`
                                                  : '00:00'
                                              }
                                              onChange={e =>
                                                handleChangeSelection2(
                                                  index,
                                                  document.getElementById(
                                                    `timeSelect-${index}-${itemIndex}1`
                                                  ).value,
                                                  e.target.value
                                                )
                                              }
                                            >
                                              {timeOptions.map(
                                                (time, timeIndex) => (
                                                  <option value={time}>
                                                    {time}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                            <label
                                              htmlFor={`timeSelect-close-${index}-${itemIndex}1`}
                                              className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1'
                                            >
                                              Close At
                                            </label>
                                          </div>

                                          <button
                                            className=''
                                            onClick={() =>
                                              deleteSelection(index, itemIndex)
                                            }
                                          >
                                            <img
                                              src='https://pro-manage.s3.ap-south-1.amazonaws.com/Delete.svg'
                                              alt='alternative'
                                            />
                                          </button>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                    </>
                  )
                )}
              </>
            }
          </div>
        </Modal.Body>
        <Modal.Footer className='pt-0 justify-end'>
          <div className='flex justify-end gap-3 mt-4'>
            <button
              color='gray'
              onClick={() => {
                setOpenModalRegularHour(false)
                setInitialSelectedTimes(newDayStatus)
              }}
              className='px-4 py-2 text-md font-medium text-center border-1 text-gray  rounded-lg focus:outline-none'
            >
              Cancel
            </button>
            <button
              type='submit'
              className='px-4 py-2 text-md font-medium text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none'
              onClick={e => handleRegularHr(e)}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default RegularTimeModal
