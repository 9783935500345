import React, { useContext, useEffect, useState } from 'react'
import httpservice from '../Service/httpservice'
import { API_URL } from '../env/env'
import { AuthContext } from '../context/AuthContext'
import { useQuery } from '@tanstack/react-query'
import StarRating from '@rubenvara/react-star-rating'
import NotificationTopBar from '../Components/NotificationTopBar/TopBar'
import { useLocation } from 'react-router-dom'
import Loader from '../loader/loader'
import { Empty } from 'antd'

const ReviewNotification = () => {
  const {
    userInfo,
    groupId,
    tokenInfo,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleQuestionCountNotification,
    handleReviewNotification,
    handleLocationNotification,
    reviewNewNotification
  } = useContext(AuthContext)

  const location = useLocation()

  const [load, setload] = useState(true)

  // const { data, isPending } = useQuery({
  //   queryFn: () => groupId !== undefined && handleNotification(),
  //   queryKey: ['handleNotification', groupId],
  //   staleTime: 1000 * 3600,
  //   cacheTime: 600000
  //   // onSuccess: ViewsAndActivityData => {
  //   //   setViewsAndActivityData(ViewsAndActivityData)
  //   // }
  // })

  useEffect(() => {
    tokenInfo()
  }, [])

  useEffect(() => {
    handleLocationCountNotification(true)
    handleReviewCountNotification(true)
    handleQuestionCountNotification(true)
    // if (groupId) {
    handleNotification()
    // }
  }, [groupId])

  const [isPending, setIsPending] = useState(false)

  const [reviewNotification, setReviewNotification] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)

  const handleNotification = async () => {
    try {
      const currentDate = new Date()
      const startDate = new Date()
      startDate.setDate(startDate.getDate() - 20)
      const formattStartDate = startDate.toISOString().split('T')[0]
      const formattedEndDate = currentDate.toISOString().split('T')[0]
      let url = `${API_URL}/review/notification`
      let body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        startDate: formattStartDate,
        endDate: formattedEndDate,
        newNotification: 0
      }
      let response = await httpservice.post(url, body)

      if (response.status === 200) {
        // console.log(response?.data?.data, 'notification')
        setReviewNotification(response?.data?.data?.notification)
        setIsPending(true)
      } else {
        setIsPending(true)
        setReviewNotification([])
      }
    } catch {
      setIsPending(true)
      setReviewNotification([])
    }
  }

  const indexOfLastItem = currentPage * pageSize
  const indexOfFirstItem = indexOfLastItem - pageSize
  const currentItems = reviewNotification?.slice(
    indexOfFirstItem,
    indexOfLastItem
  )

  const totalPages = Math.ceil(reviewNotification?.length / pageSize)

  const changePage = pageNumber => setCurrentPage(pageNumber)

  const changePageSize = size => {
    setPageSize(size)
    setCurrentPage(1)
  }

  const convertStarRatingToNumber = starRatingText => {
    // console.log(starRatingText, 'starRatingText')
    const textToNumber = {
      ONE: 1.0,
      TWO: 2.0,
      THREE: 3.0,
      FOUR: 4.0,
      FIVE: 5.0
    }
    const uppercaseText = (starRatingText || '').toUpperCase()
    // console.log(textToNumber[uppercaseText], 'textToNumber')
    return textToNumber[uppercaseText] || 0
  }
  const config = {
    fullColor: '#FEA500',
    size: 12,
    showText: false
  }
  const [isReadMore, setIsReadMore] = useState(true)
  const [isReadMore1, setIsReadMore1] = useState(true)
  const limitedContent = 30
  let fullContent
  const handleReadMore = (comments, currentIndex) => {
    // console.log(comments, 'comments')
    if (comments?.length > limitedContent) {
      // console.log(isReadMore, 'isReadMore')

      setIsReadMore(prevState => ({
        ...prevState,
        [currentIndex]: !prevState[currentIndex]
      }))
    }
  }
  const handleReadMore1 = (comments, currentIndex) => {
    // console.log(comments, 'comments')
    if (comments?.length > limitedContent) {
      // console.log(isReadMore, 'isReadMore')

      setIsReadMore1(prevState => ({
        ...prevState,
        [currentIndex]: !prevState[currentIndex]
      }))
    }
  }

  const EmptyWithShadow = ({ condition }) => (
    <div
      className={`${condition ? '' : 'hidden'}`}
      style={{
        boxShadow: condition ? '0px 2px 5px rgba(0, 0, 0, 0.1)' : 'none',
        borderRadius: condition ? '0 0 10px 10px' : '0'
      }}
    >
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className='mt-0 pt-5' />
    </div>
  )

  useEffect(() => {
    if (isPending) {
      // // console.log(isPending, 'ispending', load)
      // debugger
      setload(false)
    }
  }, [isPending])
  return (
    <div>
      {/* {['/notification/review', '/notification/location'].includes(
        location.pathname
      ) && <NotificationTopBar  />} */}
      {load && <Loader />}
      <div className='relative overflow-x-auto rounded-t-none roundered-b-4 p-8 pt-1  bg-custom-background4 m-6 mt-0'>
        <div className='flex justify-end  items-center'>
          <div className=' pb-3 col-2 '>
            <select
              id='pageSize'
              className='bg-white border   border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
              value={pageSize}
              onChange={e => changePageSize(e.target.value)}
            >
              {' '}
              {[25, 50, 100]?.map(option => (
                <option key={option} value={option}>
                  Show {option} Entries
                </option>
              ))}
            </select>
          </div>
        </div>
        <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
          <thead className='text-base text-start text-gray-700 capitalize bg-newTableBG dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              {/* <th scope='col' className='px-6 py-3 font-semibold rounded-s-lg'>
                Sl.no
              </th> */}
              <th scope='col' className='px-6 py-3 font-semibold  rounded-s-lg'>
                User Name
              </th>
              <th scope='col' className='px-6 py-3 font-semibold'>
                Reviewer Name
              </th>
              <th scope='col' className='px-6 py-3 font-semibold'>
                Review
              </th>
              <th scope='col' className='px-6 py-3 font-semibold'>
                Review Reply
              </th>
              <th scope='col' className='px-6 py-3 font-semibold'>
                Business Profile
              </th>
              <th scope='col' className='px-6 py-3 font-semibold rounded-e-lg'>
                Date&Time
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr
                key={index}
                className={`text-start rounded-b-2xl dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600 ${
                  index % 2 === 0
                    ? 'bg-custom-background4'
                    : 'bg-custom-background5'
                }`}
              >
                {/* <td className='px-6 border-r py-4 text-notificationCount font-semibold whitespace-nowrap dark:text-white'>
                  {index + 1}
                </td> */}
                <td className='px-6 py-2 border-r text-notificationCount font-semibold col-1'>
                  {item?.editedBy?.name}
                </td>
                <td className='px-6 py-2 border-r text-notificationCount font-semibold col-2'>
                  {item?.reply?.reviewer_name}
                </td>
                <td className='px-6 py-2 border-r font-medium text-notificationTime col-2'>
                  {' '}
                  <StarRating
                    rating={convertStarRatingToNumber(item?.reply?.rating)}
                    style={{ marginBottom: '0px' }}
                    config={config}
                  />
                  <p className='text-notificationTime text-[13.6px] text-start'>
                    {item?.reply?.comment?.length > 30
                      ? item?.reply?.comment.slice(
                          0,
                          isReadMore[index] === undefined
                            ? isReadMore
                              ? limitedContent
                              : fullContent
                            : isReadMore[index]
                            ? fullContent
                            : limitedContent
                        ) + '...'
                      : item?.reply?.comment}
                    <span
                      className='text-listing cursor-pointer'
                      onClick={() =>
                        handleReadMore(item?.reply?.comment, index)
                      }
                    >
                      {' '}
                      {item?.reply?.comment?.length
                        ? isReadMore[index]
                          ? 'Read less'
                          : 'Read more'
                        : ''}
                    </span>
                  </p>
                </td>
                <td className='px-6 py-2 border-r font-medium text-notificationTime col-2'>
                  <p className='text-notificationTime text-[13.6px] text-start'>
                    {item?.reply?.reply_comment?.length > 30
                      ? item?.reply?.reply_comment.slice(
                          0,
                          isReadMore1[index] === undefined
                            ? isReadMore1
                              ? limitedContent
                              : fullContent
                            : isReadMore1[index]
                            ? fullContent
                            : limitedContent
                        ) + '...'
                      : item?.reply?.reply_comment}
                    <span
                      className='text-listing cursor-pointer'
                      onClick={() =>
                        handleReadMore1(item?.reply?.reply_comment, index)
                      }
                    >
                      {' '}
                      {item?.reply?.reply_comment?.length > 30
                        ? isReadMore[index]
                          ? 'Read less'
                          : 'Read more'
                        : ''}
                    </span>
                  </p>
                </td>
                <td className='px-6 py-2 border-r text-notificationCount font-semibold col-2'>
                  {item?.location?.name}
                  <p className='text-[10px] font-normal text-notificationCount mb-0'>
                    {item?.location?.address}
                  </p>
                </td>
                <td className='px-6 py-2 font-medium text-ViewActivity col-1'>
                  <p className='mb-0'>{item?.updatedDate} </p>
                  <p className='mb-0'>{item?.updatedTime}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <EmptyWithShadow condition={currentItems?.length === 0} />

        <div className='flex justify-end items-center mt-4'>
          <div className='me-2 font-medium text-notificationCount'>
            {/* Showing {currentItems?.length} out of {totalPages} */}
            Total Results {reviewNotification?.length}
          </div>
          {currentPage !== 1 && (
            <div
              className='me-2 cursor-pointer'
              onClick={() => changePage(currentPage - 1)}
            >
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/PaginationNextArrow.svg'
                alt='PaginationNextArrow'
                className='transform rotate-180 w-8 h-8'
              />
            </div>
          )}
          <div className='gap-2 flex'>
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i}
                onClick={() => changePage(i + 1)}
                className={`px-2.5 py-1  rounded-md ${
                  i + 1 === currentPage
                    ? 'bg-listing text-white font-semibold dark:bg-gray-800'
                    : 'border text-paginationColor font-semibold'
                }`}
              >
                {i + 1}
              </button>
            ))}
          </div>
          {totalPages > 1 && currentPage < totalPages && (
            <div
              className='ms-2 cursor-pointer'
              onClick={() => changePage(currentPage + 1)}
            >
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/PaginationNextArrow.svg'
                alt='PaginationNextArrow'
                className='w-8 h-8'
              />
            </div>
          )}
        </div>
      </div>
      {/* Pagination */}
    </div>
  )
}

export default ReviewNotification
