import React, { useContext, useEffect, useState } from 'react'
import httpservice from '../Service/httpservice'
import { API_URL } from '../env/env'
import { AccountDetails } from '../Store/AccountInfo'
import AddUserModal from '../Components/UserManagementModal/AddUserModal'
import EditUserModal from '../Components/UserManagementModal/EditUserModal'
import { Link } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import { toast } from 'react-toastify'
import useDebounce from '../hooks/useDebounce'
import { Empty } from 'antd'
import DeleteUserModal from '../Components/UserManagementModal/DeleteUserModal'
import Loader from '../loader/loader'

function UserManagement () {
  const [load, setload] = useState(true)
  const [users, setUsers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedRole, setSelectedRole] = useState('All')
  const [openAddUserModal, setOpenAddUserModal] = useState(false)
  const [openEditUserModal, setOpenEditUserModal] = useState(false)
  const [usersData, setUsersData] = useState([])
  const [userAccess, setUserAccess] = useState([])
  const debounceSearchValue = useDebounce(searchTerm, 500)
  const [FilterDetails, setFilterDetails] = useState()

  const { userInfo, groupId } = useContext(AuthContext)
  const roleId = userInfo?.info?.roleDetails?.roleId

  const [isUploading, setIsUploading] = useState(false)

  // console.log(typeof roleId, 'tokenDetails')
  const {
    tokenInfo,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleQuestionCountNotification,
    handleReviewNotification,
    handleLocationNotification
  } = useContext(AuthContext)

  // console.log(usersData, 'usersData', users)

  useEffect(() => {
    handleFilterRole()
  }, [])

  useEffect(() => {
    tokenInfo()
  }, [])

  useEffect(() => {
    if (userInfo?.info?.roleDetails?.roleId === 1) {
      handleLocationCountNotification(true)
      handleReviewCountNotification(true)
      handleQuestionCountNotification(true)
      // handleReviewNotification(true)
      // handleLocationNotification(true)
    }
  }, [groupId, userInfo])

  useEffect(() => {
    getSingleLocation()
    setUserAccess(userInfo.userAccess)
  }, [debounceSearchValue, openEditUserModal])

  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false)
  const [user, setDeleteUser] = useState()

  const handleDeleteModal = userData => {
    setOpenDeleteUserModal(!openDeleteUserModal)
    setDeleteUser(userData)
  }

  const getSingleLocation = async () => {
    try {
      const body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId
      }

      const url = `${API_URL}/user/all`
      const res = await httpservice.post(url, body)

      if (res.status === 200) {
        // console.log(res?.data?.data, 'filteredUsers')
        setUsers(res.data.data)
        setload(false)
      } else {
        setload(false)
      }
    } catch (error) {
      setUsers('')
      setload(false)
      // console.log(error)
    }
  }
  const getFilteredData = async roleId => {
    try {
      if (roleId === 'All') {
        getSingleLocation()
        return
      }
      const body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        roleId: roleId
      }

      const url = `${API_URL}/user/roleBased`
      const res = await httpservice.post(url, body)

      if (res.status === 200) {
        setUsers(res.data.data)
      } else {
        setUsers([])
      }
    } catch (error) {
      setUsers([])
      // console.log(error)
    }
  }

  const handleSearch = e => {
    setSearchTerm(e.target.value)
  }

  const handleRoleFilter = e => {
    setSelectedRole(e.target.value)
    getFilteredData(e.target.value)
  }

  const filteredUsers =
    users &&
    users?.filter(
      user =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (user.email.toLowerCase().includes(searchTerm.toLowerCase()) &&
          (selectedRole === 'All' ||
            user.roleDetails.roleName === selectedRole))
    )

  // console.log('filteredUsers', users)

  const handleEditButton = user => {
    // console.log(user, 'Edit button ==> ')
    setUsersData(user)
    setOpenEditUserModal(true)
  }

  const handleDeleteUser = async user => {
    // console.log(user, 'user')
    setIsUploading(true)
    try {
      let body = {
        userId: userInfo?.info?.userId
      }
      const url = `${API_URL}/user/delete/${user.userId}`
      const response = await httpservice.post(url, body)
      if (response.status === 200) {
        getSingleLocation()
        setIsUploading(false)
        toast.success(response?.data?.message)
      }
    } catch (error) {
      setIsUploading(false)
      // console.log(error)
      toast.error(error?.response?.data?.message)
    }
  }

  const handleFilterRole = async () => {
    try {
      let body = {
        userId: userInfo?.info?.userId
      }
      const url = `${API_URL}/other/all_roles`
      const response = await httpservice.post(url, body)
      if (response.status === 200) {
        // console.log(response?.data?.data, 'response')
        setFilterDetails(response?.data?.data)
      }
    } catch (error) {}
  }

  const EmptyWithShadow = ({ condition }) => (
    <div
      className={`${condition ? '' : 'hidden'}`}
      style={{
        boxShadow: condition ? '0px 2px 5px rgba(0, 0, 0, 0.1)' : 'none',
        borderRadius: condition ? '0 0 10px 10px' : '0' // Adjust the border-radius values as needed
      }}
    >
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className='mt-0 pt-5' />
    </div>
  )

  return (
    <>
      {load && <Loader />}
      <div className='text-start m-4 fw-semibold fs-3 text-zinc-600'>
        User Management
      </div>
      <div className='mx-3 px-4 border rounded-xl  py-3 '>
        <div className='row  justify-content-between '>
          <div className='col-5 position-relative  '>
            <div className='absolute inset-y-0 start-2 flex items-center ps-3 pointer-events-none'>
              <img
                src='https://pro-manage.s3.ap-south-1.amazonaws.com/searchIcon.svg'
                alt='searchIcon'
              />
            </div>
            <input
              className='rounded-lg w-100 p-2.5 ps-10 bg-bgSearch serch_btn text-sm  '
              placeholder='Search User'
              type='search'
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <div className='col-6  justify-content-end  d-flex align-items-center'>
            <p className=''>
              <span className='fs-6'> Filter By : </span>
              <select
                className='rounded-xl border-slate-400 py-2 fs-6'
                name=''
                id=''
                value={selectedRole}
                onChange={handleRoleFilter}
              >
                <option value='All'>All</option>
                {FilterDetails?.map(item => (
                  <>
                    <option value={`${item.id}`}>{item.roleName}</option>
                  </>
                ))}
              </select>
            </p>
          </div>
        </div>
      </div>
      {userInfo?.info?.accessTypeDetails?.accessTypeId === 3 ? (
        <div className=' flex justify-end  mt-7 container     '>
          <div
            className='flex bg-listing border-indigo-400 p-2 cursor-pointer gap-3 border-solid rounded-xl'
            onClick={() => setOpenAddUserModal(true)}
          >
            <img
              src='https://cdn.builder.io/api/v1/image/assets/TEMP/ed55fae927c3453deaefe18132eca16eb47ecd223443a6a600e5268a4c8e2ca3?'
              className='aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full'
              alt='AddUserIcon'
            />
            <div className='text-white text-center my-auto'>Add User</div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className='mx-3 mt-4'>
        <div className='relative overflow-x-auto shadow-md sm:rounded-lg'>
          <table className='w-full text-sm text-left rtl:text-right  '>
            <thead className='text-base dark:bg-gray-700 dark:text-gray-400 bg-custom-background3  '>
              <tr>
                <th scope='col' className='px-6 py-3 font-semibold'>
                  User
                </th>
                {/* <th scope='col' className='px-6 py-3 font-semibold'>
                  Role
                </th> */}
                <th scope='col' className='px-6 py-3 font-semibold'>
                  Phone Number
                </th>
                <th scope='col' className='px-6 py-3 font-semibold'>
                  Email
                </th>
                <th scope='col' className='px-6 py-3 font-semibold'>
                  Access Type
                </th>
                {/* {users.some((user) => user.roleDetails.roleId === 3) && ( */}
                {/* !(userInfo?.info?.roleDetails?.roleId === 3) && ( */}
                {userInfo?.info?.accessTypeDetails?.accessTypeId === 3 && (
                  <th scope='col' className='px-10 py-3 font-semibold'>
                    Action
                  </th>
                )}
                {/* ) */}
              </tr>
            </thead>
            <tbody>
              {filteredUsers &&
                filteredUsers?.map(user => (
                  <tr
                    key={user.id}
                    className='odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700'
                  >
                    <td className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>
                      {user.name}
                    </td>
                    {/* <td className='px-6 py-4'>{user?.roleDetails.roleName}</td> */}
                    <td className='px-6 py-4'>{user.phone}</td>
                    <td className='px-6 py-4'>{user?.email}</td>
                    <td className='px-6 py-4'>
                      {user?.accessTypeDetails?.accessTypeName}
                    </td>
                    {userInfo?.info?.accessTypeDetails?.accessTypeId === 3 && (
                      <td className='py-4 flex gap-4'>
                        <img
                          src='https://pro-manage.s3.ap-south-1.amazonaws.com/OverViewEdit.svg'
                          alt=''
                          className='cursor-pointer'
                          onClick={() => handleEditButton(user)}
                        />
                        <img
                          src='https://pro-manage.s3.ap-south-1.amazonaws.com/PostMedia.svg'
                          alt=''
                          className='cursor-pointer'
                          onClick={() => handleDeleteModal(user)}
                        />
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* {
          filteredUsers?.length === 0 &&
          <div >
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        } */}

        <EmptyWithShadow condition={filteredUsers?.length === 0} />
      </div>

      <AddUserModal
        openAddUserModal={openAddUserModal}
        setOpenAddUserModal={setOpenAddUserModal}
        roleId={roleId}
        tokenDetails={userInfo}
        getSingleLocation={getSingleLocation}
        setIsUploading={setIsUploading}
        isUploading={isUploading}
      />

      <EditUserModal
        openEditUserModal={openEditUserModal}
        setOpenEditUserModal={setOpenEditUserModal}
        usersData={usersData}
        tokenDetails={userInfo}
        roleId={roleId}
        getSingleLocation={getSingleLocation}
        setUsersData={setUsersData}
        setIsUploading={setIsUploading}
        isUploading={isUploading}
      />

      <DeleteUserModal
        openDeleteUserModal={openDeleteUserModal}
        setOpenDeleteUserModal={setOpenDeleteUserModal}
        user={user}
        handleDelete={handleDeleteUser}
        setIsUploading={setIsUploading}
        isUploading={isUploading}
      />
    </>
  )
}

export default UserManagement
