import React, { useContext, useEffect, useRef, useState } from "react";
import httpservice from "../Service/httpservice";
import { AccountDetails } from "../Store/AccountInfo";
import { LocationDetails } from "../Store/LocationInfo";
import moment from "moment-timezone";
import { Button, Modal, ModalBody } from "flowbite-react";
import { API_URL } from "../env/env";
import Loader from "../loader/loader";
import { toast } from "react-toastify";
import ReviewComponent from "../Components/ReviewComponent";
import ReviewReply from "../Components/ReviewModal/ReviewReply";
import { AuthContext } from "../context/AuthContext";

const BusinessReview = () => {
  const [reviews, SetReview] = useState([]);
  const [load, setload] = useState(true);
  const Locationid = LocationDetails((state) => state.LocationDetails);
  const [pageToken, SetPageToken] = useState("");
  const [reply, setReply] = useState("");
  const [replyMessage, setReplyMessage] = useState("");
  const [ownerResponse, setOwnerResponse] = useState("");
  const [openModalReply, setOpenModalReply] = useState(false);
  const elementRef = useRef(null);
  const [hasMore, setHasMore] = useState(false);

  const [reviewStatus, setReviewStatus] = useState();

  const {
    tokenInfo,
    userInfo,
    groupId,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleQuestionCountNotification,
    handleReviewNotification,
    handleLocationNotification,
  } = useContext(AuthContext);

  useEffect(() => {
    tokenInfo();
  }, []);

  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (userInfo?.info?.roleDetails?.roleId === 1) {
      handleLocationCountNotification(true);
      handleReviewCountNotification(true);
      handleQuestionCountNotification(true);
      // handleReviewNotification(true)
      // handleLocationNotification(true)
    }
  }, [groupId, userInfo]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting && hasMore === true) {
          rating();
        }
      },
      {
        rootMargin: "120px",
      }
    );

    if (observer && elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [elementRef, hasMore, pageToken]);
  const rating = async () => {
    let body;
    try {
      if (!pageToken) {
        body = {
          userId: userInfo?.info?.userId,
          groupId: groupId?.groupId,
          locationId: Locationid,
          pageSize: 10,
        };
      } else {
        body = {
          userId: userInfo?.info?.userId,
          groupId: groupId?.groupId,
          locationId: Locationid,
          pageToken: pageToken,
          pageSize: 10,
        };
      }

      const url = `${API_URL}/review/location_reviews?pageToken=${pageToken}`;
      const response = await httpservice.post(url, body);

      // console.log(response, 'nextPageToken')
      if (response.status === 200) {
        // debugger
        if (response?.data?.pageToken) {
          // console.log('response', response?.data?.pageToken)
          setHasMore(true);
          SetPageToken(response?.data?.pageToken);
        } else {
          // console.log('responseElse', response?.data?.pageToken)
          setHasMore(false);
          SetPageToken("");
        }
        // console.log(response, '12Response')
        SetReview((prevProducts) => {
          const newData = response?.data?.data || [];
          return Array.isArray(prevProducts)
            ? [...prevProducts, ...newData]
            : newData;
        });
      } else {
        setReviewStatus(true);
        SetReview([]);
      }
    } catch (error) {
      setReviewStatus(true);
      setHasMore(false);
      // console.log(error, 'error')
    }
  };

  const handlereplyClick = (item) => {
    // console.log(item, 'item')
    setReply(item);
    setOwnerResponse(item?.reply?.replyComment);
    setOpenModalReply(true);
  };

  useEffect(() => {
    rating();
  }, []);

  // console.log(reviews, 'reviews')

  const handleReply = async (e) => {
    setIsUploading(true);
    e.preventDefault();
    try {
      let body;
      if (ownerResponse?.length > 1) {
        body = {
          userId: userInfo?.info?.userId,
          groupId: groupId?.groupId,
          locationId: Locationid,
          comment: ownerResponse,
          accessToken: userInfo?.token,
        };
      } else {
        body = {
          userId: userInfo?.info?.userId,
          groupId: groupId?.groupId,
          locationId: Locationid,
          comment: replyMessage,
          accessToken: userInfo?.token,
        };
      }
      const url = `${API_URL}/review/reply/${reply?.details?.review?.reviewId}`;
      const response = await httpservice.post(url, body);
      // console.log(response?.data?.data, 'review')
      if (response.status === 200) {
        setOpenModalReply(false);
        // SetPageToken("");
        // SetReview([]);
        SetReview((prevState) =>
          prevState.map((item) =>
            item?.details?.review?.reviewId ===
            response?.data?.data.details?.review?.reviewId
              ? {
                  ...item,
                  ...response?.data?.data,
                }
              : item
          )
        );
        setIsUploading(false);
        setReplyMessage("");
        if (userInfo?.info?.roleDetails?.roleId === 1) {
          handleReviewCountNotification(true);
        }
        toast.success("Review Post successfully");
      }
    } catch (error) {
      setOpenModalReply(false);
      setIsUploading(false);
      toast.error(error?.response?.data?.message);
      // // console.log(error)
    }
  };
  // const bgColors = ["bg-reviewTable1", "bg-custom-background5", "bg-custom-background5", "bg-reviewTable1"];

  // console.log(reply, 'reply')

  const handleReviewDelete = async (reviewId) => {
    try {
      setIsUploading(true);
      // console.log(reviewId, 'reviewId')
      let body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        locationId: Locationid,
      };
      const url = `${API_URL}/review/reply/delete/${reviewId}`;
      const response = await httpservice.post(url, body);
      if (response.status === 200) {
        SetReview((prevState) =>
          prevState.map((item) =>
            item?.details?.review?.reviewId ===
            response?.data?.data.details?.review?.reviewId
              ? {
                  ...item,
                  ...response?.data?.data,
                }
              : item
          )
        );
        // SetReview([])
        if (userInfo?.info?.roleDetails?.roleId === 1) {
          handleReviewCountNotification(true);
        }
        setIsUploading(false);
        // rating()
        toast.success("Review Deleted successfully");
      }
    } catch (error) {
      setIsUploading(false);
      toast.success(error?.response?.data?.message);
    }
  };

  // console.log(reviewStatus, 'reviewStatus')

  useEffect(() => {
    if (reviews.length > 0) {
      setload(false);
    }

    if (reviewStatus === true) {
      setload(false);
    }
  }, [reviews, reviewStatus]);

  return (
    <div className="container md:px-10 py-3  ">
      {load && <Loader />}

      <div className="grid grid-cols-1 gap-4">
        <ReviewComponent
          reviews={reviews}
          handlereplyClick={handlereplyClick}
          handleReviewDelete={handleReviewDelete}
          timeZone={false}
          userRestriction={userInfo?.info?.accessTypeDetails?.accessTypeId}
          isUploading={isUploading}
        />
        {reviews.length === 0 && (
          <div className="text-center">
            <h1 className="text-center   fw-semibold flex justify-center">
              <img
                src="https://pro-manage.s3.ap-south-1.amazonaws.com/NoImgMeans.svg"
                alt="noData"
                className="img-fluid"
              />
            </h1>
          </div>
        )}
      </div>

      <ReviewReply
        openModalReply={openModalReply}
        setOpenModalReply={setOpenModalReply}
        reply={reply}
        ownerResponse={ownerResponse}
        setOwnerResponse={setOwnerResponse}
        replyMessage={replyMessage}
        setReplyMessage={setReplyMessage}
        handleReply={handleReply}
        isUploading={isUploading}
      />

      <div className="">
        <h2
          className="mb-4 fw-semibold"
          ref={elementRef}
          style={{ textAlign: "center" }}
        >
          {hasMore ? "Loading items..." : ""}
        </h2>
      </div>
    </div>
  );
};

export default BusinessReview;
