import React from 'react'

const Counts = ({
  Count1,
  Count2,
  Count3,
  heading1,
  heading2,
  heading3,
  Image1,
  Image2,
  Image3,
  image4,
  total
}) => {
  // https://pro-manage.s3.ap-south-1.amazonaws.com/LiveIcon.png
  // https://pro-manage.s3.ap-south-1.amazonaws.com/InProgressIcon.png
  // https://pro-manage.s3.ap-south-1.amazonaws.com/RejectedIcon.png
  return (
    <div className='container md:px-4 py-2 mb-4'>
      <div className={` mt-2 mb-2 ${total ? 'lg:ms-8 lg:me-8' : ''}  `}>
        <div className='flex justify-center items-center  rounded-2xl py-9 shadow-filter1 '>
          {total && (
            <div
              className={`md:flex grid md:text-left text-center justify-center ${
                total ? 'md:col-2 col-3' : 'md:col-3 col-4'
              } pt-3 pb-3  gap-2 md:border-e`}
            >
              <img src={image4} className='img-fluid' alt='liveIcon' />
              <div className='my-auto'>
                <p className='font-medium'>Total</p>
                <p className='text-listing fw-semibold   text-start'>
                  {Count1 + Count2 + Count3}
                </p>
              </div>
            </div>
          )}
          <div
            className={`md:flex grid md:text-left text-center justify-center ${
              total ? 'md:col-2 col-3' : 'md:col-3 col-4'
            } pt-3 pb-3  gap-2 md:border-e`}
          >
            <img src={Image1} className='img-fluid' alt='liveIcon' />
            <div className='my-auto'>
              <p className='font-medium'>{heading1}</p>
              <p className='text-listing fw-semibold   text-start'>{Count1}</p>
            </div>
          </div>
          <div
            className={`md:flex grid md:text-left text-center justify-center ${
              total ? 'md:col-2 col-3' : 'md:col-3 col-4'
            } pt-3 pb-3 gap-2 md:border-e`}
          >
            <img src={Image2} className='img-fluid' alt='liveIcon' />
            <div className='my-auto'>
              <p className='font-medium'>{heading2}</p>
              <p className='text-listing fw-semibold text-start'>{Count2}</p>
            </div>
          </div>
          <div
            className={`md:flex grid md:text-left text-center justify-center ${
              total ? 'md:col-2 col-3' : 'md:col-3 col-4'
            } pt-3 pb-3  gap-2 md:border-e`}
          >
            <img src={Image3} className='img-fluid' alt='liveIcon' />
            <div className='my-auto'>
              <p className='font-medium'>{heading3}</p>
              <p className='text-listing fw-semibold text-start '>{Count3}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Counts
