import React, { useContext, useEffect, useState } from "react";
import { AccountDetails, setAccountData } from "../Store/AccountInfo";
import httpservice from "../Service/httpservice";
import { API_URL } from "../env/env";
import Profile from "./HeaderModal/Profile";
import { toast } from "react-toastify";
import PasswordChange from "./HeaderModal/PasswordChange";
import { AuthContext } from "../context/AuthContext";
import { UserInfo } from "../Store/UserInfo";
import { Link, useNavigate } from "react-router-dom";
import { Popover, Tabs } from "antd";
import moment from "moment-timezone";
import Loader from "../loader/loader";

const TopNavbar = () => {
  const { logout } = useContext(AuthContext);

  const {
    userInfo,
    groupId,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleReviewNotification,
    handleLocationNotification,
    handeleQuestionNotification,
    handleQuestionCountNotification,
    locationCountNotification,
    reviewCountNotification,
    reviewNewNotification,
    locationNewNotification,
    questionNewNotification,
    questionCountNotification,
  } = useContext(AuthContext);

  // console.log(
  //   reviewNewNotification,
  //   'reviewNewNotification',
  //   locationNewNotification
  // )

  const navigate = useNavigate();
  const [load, setload] = useState(false);
  // const tokenDetails = JSON.parse(localStorage.getItem('tmbUserData'))
  // const userInfo = UserInfo(state => state.UserInfo)
  // const {userId,groupI}
  const [profilePic, setProfilePic] = useState(userInfo.info?.picture);
  const [selectedFile, setSelectedFile] = useState(null);

  const [popoverVisible, setPopoverVisible] = useState(false);

  const handleReadMoreClick = () => {
    // Close the Popover when "Read More" is clicked
    setPopoverVisible(!popoverVisible);
  };

  const handlePopoverVisibleChange = (visible) => {
    // Update the Popover visibility state
    setPopoverVisible(visible);
  };

  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [IsDisabled, setIsDisabled] = useState(true);
  const [IsDisabled1, setIsDisabled1] = useState(true);
  const [IsDisabled2, setIsDisabled2] = useState("edit");

  const [accountInfo, setAccountInfo] = useState();
  const [profileEdit, setProfileEdit] = useState({
    name: "",
    phone: "",
  });

  const [OldPassword, SetOldPassword] = useState("");
  const [NewPassword, SetNewPassword] = useState("");
  const [confirmPassword, SetConfirmPassword] = useState("");

  const id = AccountDetails((state) => state?.AccountDetails);

  useEffect(() => {
    if (userInfo?.info?.roleDetails?.roleId === 1) {
      if (groupId !== undefined) {
        handleLocationCountNotification(true);
        handleReviewCountNotification(true);
        // handeleQuestionNotification
        handleQuestionCountNotification(true);
      }
    }
  }, [groupId, userInfo]);

  useEffect(() => {
    if (!id) {
      setAccountData(`accounts/${userInfo?.info?.sub}`);
    }
    if (userInfo?.token) {
      getAccountInfo();
    }
    if (userInfo) {
      setProfileEdit(userInfo?.info);
    }
  }, []);

  // const accountInformation = useAccountInfo((state)=>state.accountInfo)

  const getAccountInfo = async () => {
    try {
      const body = {
        userId: userInfo?.info?.userId,
      };
      let url = `${API_URL}/group`;
      // console.log(body, 'body')
      const response = await httpservice.post(url, body);
      const storedOption = localStorage.getItem("selectedOption");
      // console.log(response?.data?.data, 'Top response')
      // console.log('storedOption', storedOption)
      localStorage.setItem("GroupChange", "false");
      let arr = [];
      if (storedOption) {
        response?.data?.data.map((ele, i) => {
          arr.push(ele);
          if (ele.groupName === storedOption) {
            const temp = arr[0];
            arr[0] = ele;
            arr[i] = temp;
          }
        });
      }
      console.warn("ARR", arr, arr.length, arr[0]);
      setAccountInfo(arr?.length ? arr : response?.data?.data);

      setAccountData(arr[0] ? arr[0] : response?.data?.data[0]);

      // console.log(response?.data?.data[0], 'response')
    } catch (error) {
      console.error("Error fetching account info:", error);
    }
  };

  const handleAccountInfo = (title) => {
    setTimeout(() => {
      setload(true);
    }, 10);

    setTimeout(() => {
      setload(false);
    }, 1000);
    // console.log(title, 'value')
    const value = accountInfo?.find((item) => item.title === title);
    localStorage.setItem("GroupChange", "true");
    // const value2 = accountInfo?.find((item) => item.title !== title)
    // localStorage.setItem("AnotherOption", value2.groupId)
    localStorage.setItem("selectedOption", value.groupName);
    // console.log(value, 'value')
    if (value) {
      setAccountData(value);
    }
  };

  // console.log(accountInfo, 'accountInfo')

  const handleEditProfile = async (e) => {
    e.preventDefault();

    // console.log('Profile Save ===> ', selectedFile)
    try {
      const formData = new FormData();
      const data = {
        userId: profileEdit?.userId,
        name: profileEdit?.name,
        phone: profileEdit?.phone,
        profile_picture: selectedFile || "",
      };
      // console.log('formData data : ', data)

      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value);
      }
      // console.log('formData : ', formData)

      const url = `${API_URL}/user/profile`;
      // console.log('Request URL & Body :', url, formData)

      const response = await httpservice.put(url, formData);
      // console.log('Profile Response:', response)
      if (response?.status === 200) {
        userInfo.info = userInfo?.info || {};
        userInfo.info.name = response?.data?.data?.name;
        userInfo.info.phone = response?.data?.data?.phone;
        userInfo.info.picture = response?.data?.data?.imageUrl;
        // localStorage.setItem('tmbUserData', JSON.stringify(tokenDetails))
        setOpenProfileModal(false);
        toast.success("Profile Updated Successfully");
        setProfileEdit(userInfo?.info);
        setOpenProfileModal(false);
        setIsDisabled(true);
        setIsDisabled1(true);

        setSelectedFile("");
        setIsDisabled2("edit");
      }
      if (response?.data?.status === false) toast.error(response.data.message);
      setProfileEdit(userInfo?.info);
      setIsDisabled(true);
      setIsDisabled1(true);
      setProfilePic(userInfo?.info?.picture);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      // console.log('Error Edit Profile :', error)
    }
  };

  const handleProfile = (e, text) => {
    const { name, value } = e.target;
    // console.log('Value Profile ====> ', name, value)

    // Check if text is specified as "number"
    if (text === "number") {
      // Use regular expression to allow only numbers
      if (!/^\d*$/.test(value)) {
        // If input is not a number, do not update the state
        return;
      }
    } else if (text === "text") {
      // Use regular expression to allow only alphabetic characters and spaces
      if (!/^[A-Za-z\s]*$/.test(value)) {
        // If input is not text, do not update the state
        return;
      }
    }

    // Update profileEdit state with the new value
    setProfileEdit((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    // console.log('Edit Profile ====> ', profileEdit)
  };

  const SavePassword = async (e) => {
    e.preventDefault();
    try {
      let body = {
        userId: userInfo?.info?.userId,
        oldPassword: OldPassword,
        newPassword: NewPassword,
      };

      const url = `${API_URL}/user/changePassword`;

      if (OldPassword && NewPassword && confirmPassword) {
        if (NewPassword === confirmPassword) {
          const response = await httpservice.post(url, body);
          // console.log('Change Password Response:', response)
          if (response?.status === 200) {
            setOpenPasswordModal(false);
            SetOldPassword("");
            SetNewPassword("");
            SetConfirmPassword("");
            setIsDisabled(true);
            setIsDisabled1(true);
            toast.success("Password Updated Successfully");
          }
        } else {
          toast.error("New Password and Confirm Password does not match");
        }
      } else {
        toast.error("Please fill all the fields");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      // console.log('Change Password Error:', error)
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // console.log('Selected File:', file)
    setProfilePic(URL.createObjectURL(file));
    setSelectedFile(file);
    setIsDisabled2("cross");
  };

  const handleFileSelector = () => {
    const fileInput = document.getElementById("upload_file");
    if (fileInput) {
      fileInput.click();
    }
  };

  const url = window.location.pathname;
  // console.log(url, 'url')
  const [reviewNotication, SetReviewNotification] = useState([]);
  const [LocationNotification, setLocationNotification] = useState([]);

  // function calculateElapsedTime (updatedDate, updatedTime) {
  //   const updatedDateTime = moment(
  //     `${updatedDate} ${updatedTime}`,
  //     'YYYY-MM-DD hh:mm A'
  //   )

  //   // console.log(updatedDateTime, 'updatedDateTime')
  //   const currentTime = moment()
  //   const elapsed = moment.duration(currentTime.diff(updatedDateTime))

  //   const hours = elapsed.hours()
  //   const minutes = elapsed.minutes()

  //   // console.log(hours, 'hours', minutes, 'minutes')

  //   if (hours > 0) {
  //     return `${hours}h`
  //   } else {
  //     return `${minutes}min`
  //   }
  // }

  const calculateElapsedTime = (updatedDate, updatedTime) => {
    const currentTime = new Date();
    const updateDateTime = new Date(updatedDate + " " + updatedTime);
    // console.log(updateDateTime, 'updateDateTime', updatedDate, updatedTime)

    // Checking if updateDateTime is a valid date
    if (isNaN(updateDateTime.getTime())) {
      return "Invalid date or time";
    }

    const difference = currentTime.getTime() - updateDateTime.getTime();
    const seconds = Math.floor(difference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    let result = "";

    if (days > 0) {
      result = `${days} days`;
    } else if (days === 0 && hours > 0) {
      result = `${hours % 24} hours`;
    } else if (days === 0 && hours === 0 && minutes > 0) {
      result = `${minutes % 60} minutes`;
    } else {
      result = `${seconds % 60} seconds`;
    }

    return result;
  };

  // console.log(reviewCountNotification, 'reviewCountNotification')

  const items = [
    {
      key: "2",
      label: (
        <div className="flex gap-2 items-center text-center font-medium">
          Review{" "}
          <span className="badge bg-notification rounded-full p-[1.13px 6.8px 1.13px 6.8] text-[10px] text-notificationCount">
            {reviewCountNotification?.newNotificationCount || 0}
          </span>
        </div>
      ),
      children: (
        <>
          {reviewNewNotification?.slice(0, 5)?.map((item, index) => (
            <>
              <div className="bg-newNotification flex justify-between items-center gap-3  p-3">
                <div>
                  <p className="text-notificationCount">
                    <span className="font-semibold text-listing pe-2">
                      {item?.editedBy?.name}
                    </span>
                    Replied to{" "}
                    <span className="font-semibold text-listing ">
                      {item?.reply?.reviewer_name}
                    </span>{" "}
                  </p>
                  <p className="text-notificationBranchName text-xs">
                    {item?.location?.address?.length >= 50
                      ? item?.location?.address.slice(0, 50)
                      : item?.location?.address}
                    ...
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center text-notificationTime">
                  <p>
                    {calculateElapsedTime(item?.updatedDate, item?.updatedTime)}{" "}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                  >
                    <circle
                      cx="4.82979"
                      cy="5.03292"
                      r="3.62667"
                      fill="#817AF3"
                    />
                  </svg>
                </div>
              </div>
              <hr />
            </>
          ))}

          {/* <div className='p-2.5 text-center text-NotificationReadMore font-semibold'>
              <Link
                className='cursor-pointer text-decoration-none hover:text-NotificationReadMore'
                to={'/notification/review'}
              >
                Read More
              </Link>
            </div> */}

          <div className="flex justify-center items-center">
            {reviewNewNotification?.length === 0 && (
              <img
                src="https://pro-manage.s3.ap-south-1.amazonaws.com/lost-connection.svg"
                alt="no Connection"
              />
            )}
          </div>
          <div
            className={`p-2.5 text-center text-NotificationReadMore font-semibold `}
          >
            <Link
              className={`cursor-pointer text-decoration-none hover:text-NotificationReadMore ${
                reviewNewNotification?.length === 0 && "hidden"
              }`}
              to={"/useractivity/review"}
              onClick={handleReadMoreClick}
            >
              Read More
            </Link>
            {reviewNewNotification?.length === 0 && <hr className="pb-4" />}
          </div>
        </>
      ),
    },
    {
      key: "3",
      label: (
        <div className="flex gap-2 items-center text-center font-medium">
          <p>Business Profile</p>
          <span className="badge bg-notification rounded-full p-[1.13px 6.8px 1.13px 6.8] text-[10px] text-notificationCount">
            {locationCountNotification?.newNotificationCount || 0}
          </span>
        </div>
      ),
      children: (
        <>
          {locationNewNotification?.slice(0, 5)?.map((item, index) => (
            <>
              <div className="bg-newNotification flex justify-between items-center gap-3  p-3">
                <div>
                  <p className="text-notificationCount">
                    <span className="font-semibold text-listing pe-2">
                      {item?.editedBy?.name}
                    </span>
                    Change{" "}
                    <span className="font-semibold text-listing ">
                      {item?.updatedData?.section_name}
                    </span>{" "}
                  </p>
                  <p className="text-notificationBranchName text-xs">
                    {item?.location?.address?.length >= 50
                      ? item?.location?.address.slice(0, 50)
                      : item?.location?.address}
                    ...
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center text-notificationTime">
                  <p>
                    {calculateElapsedTime(item?.updatedDate, item?.updatedTime)}{" "}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                  >
                    <circle
                      cx="4.82979"
                      cy="5.03292"
                      r="3.62667"
                      fill="#817AF3"
                    />
                  </svg>
                </div>
              </div>
              <hr />
            </>
          ))}

          <div className="flex justify-center items-center">
            {locationNewNotification?.length === 0 && (
              <img
                src="https://pro-manage.s3.ap-south-1.amazonaws.com/lost-connection.svg"
                alt="no Connection"
              />
            )}
          </div>

          <div className="p-2.5 text-center text-NotificationReadMore font-semibold">
            <Link
              // className={`cursor-pointer text-decoration-none hover:text-NotificationReadMore`}
              className={`cursor-pointer text-decoration-none hover:text-NotificationReadMore ${
                locationNewNotification?.length === 0 && "hidden"
              }`}
              to={"/useractivity/location"}
              onClick={handleReadMoreClick}
            >
              Read More
            </Link>
            {locationNewNotification?.length === 0 && <hr className="pb-4" />}
          </div>
        </>
      ),
    },
    {
      key: "4",
      label: (
        <div className="flex gap-2 items-center text-center font-medium">
          <p>Question and Answer</p>
          <span className="badge bg-notification rounded-full p-[1.13px 6.8px 1.13px 6.8] text-[10px] text-notificationCount">
            {questionCountNotification?.newNotificationCount || 0}
          </span>
        </div>
      ),
      children: (
        <>
          {questionNewNotification?.slice(0, 5)?.map((item, index) => (
            <>
              <div className="bg-newNotification flex justify-between items-center gap-3  p-3">
                <div>
                  <p className="text-notificationCount">
                    <span className="font-semibold text-listing pe-2">
                      {item?.editedBy?.name}
                    </span>
                    Change{" "}
                    <span className="font-semibold text-listing ">
                      {item?.comment}
                    </span>{" "}
                  </p>
                  <p className="text-notificationBranchName text-xs">
                    {item?.location?.address?.length >= 50
                      ? item?.location?.address.slice(0, 50)
                      : item?.location?.address}
                    ...
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center text-notificationTime">
                  <p>
                    {calculateElapsedTime(item?.updatedDate, item?.updatedTime)}{" "}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                  >
                    <circle
                      cx="4.82979"
                      cy="5.03292"
                      r="3.62667"
                      fill="#817AF3"
                    />
                  </svg>
                </div>
              </div>
              <hr />
            </>
          ))}

          <div className="flex justify-center items-center">
            {questionNewNotification?.length === 0 && (
              <img
                src="https://pro-manage.s3.ap-south-1.amazonaws.com/lost-connection.svg"
                alt="no Connection"
              />
            )}
          </div>

          <div className="p-2.5 text-center text-NotificationReadMore font-semibold">
            <Link
              // className={`cursor-pointer text-decoration-none hover:text-NotificationReadMore`}
              className={`cursor-pointer text-decoration-none hover:text-NotificationReadMore ${
                questionNewNotification?.length === 0 && "hidden"
              }`}
              to={"/useractivity/question"}
              onClick={handleReadMoreClick}
            >
              Read More
            </Link>
            {questionNewNotification?.length === 0 && <hr className="pb-4" />}
          </div>
        </>
      ),
    },
  ];

  // const handleReviewNotification = async value => {
  //   try {
  //     const currentDate = new Date()
  //     const startDate = new Date()
  //     startDate.setDate(startDate.getDate() - 20)
  //     const formattStartDate = startDate.toISOString().split('T')[0]
  //     const formattedEndDate = currentDate.toISOString().split('T')[0]
  //     let url = `${API_URL}/review/notification`
  //     let body = {
  //       userId: userInfo?.info?.userId,
  //       groupId: groupId?.groupId,
  //       startDate: formattStartDate,
  //       endDate: formattedEndDate,
  //       newNotification: 1
  //     }
  //     let response = await httpservice.post(url, body)

  //     if (response.status === 200) {
  //       // console.log(response?.data?.data, 'notification')
  //       SetReviewNotification(response?.data?.data?.notification)
  //     }
  //   } catch (error) {}
  // }

  // const handleLocationNotification = async () => {
  //   try {
  //     const currentDate = new Date()
  //     const startDate = new Date()
  //     startDate.setDate(startDate.getDate() - 20)
  //     const formattStartDate = startDate.toISOString().split('T')[0]
  //     const formattedEndDate = currentDate.toISOString().split('T')[0]
  //     let url = `${API_URL}/location/notification`
  //     let body = {
  //       userId: userInfo?.info?.userId,
  //       groupId: groupId?.groupId,
  //       startDate: formattStartDate,
  //       endDate: formattedEndDate,
  //       newNotification: 1
  //     }
  //     let response = await httpservice.post(url, body)

  //     if (response.status === 200) {
  //       // console.log(response?.data?.data, 'notification')
  //       setLocationNotification(response?.data?.data?.notification)
  //     }
  //   } catch (error) {
  //     // console.log(error, 'error')
  //   }
  // }

  // console.log('LocationNotification', LocationNotification, reviewNotication)

  const content = (
    <div>
      <Tabs defaultActiveKey="2" items={items} className="font-work-sans" />
    </div>
  );

  return (
    <div className="top-navbar ps-3 pe-3 pb-2 pt-2  bg-custom-background2">
      {load && <Loader />}
      <div className="md:flex md:flex-row flex-col justify-between items-center ">
        <div className="flex gap-4">
          <select
            className={`${
              url.includes("overAllBranch") ||
              url.includes("overview") ||
              url.includes("media") ||
              url.includes("post") ||
              url.includes("service") ||
              url.includes("businessprofile/review") ||
              url.includes("usermanagement")
                ? "hidden"
                : "block"
            } w-full mt-1 cursor-pointer rounded-lg border-0 font-semibold shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
            onChange={(e) => {
              handleAccountInfo(e.target.value);
            }}
          >
            <option disabled hidden value="">
              Select Account Info
            </option>
            {accountInfo ? (
              accountInfo?.map((item) => (
                <option
                  key={item.groupName}
                  value={item ? item?.name : ""}
                  data-key={item.groupName}
                >
                  {item.title}
                </option>
              ))
            ) : (
              <>null</>
            )}
          </select>
        </div>

        <div className="flex gap-3 items-center ">
          {userInfo?.info?.roleDetails?.roleId === 1 ? (
            <div className={`position-relative`}>
              <Popover
                content={content}
                trigger="click"
                placement="bottomLeft"
                title="User Activity"
                visible={popoverVisible}
                onVisibleChange={handlePopoverVisibleChange}
                className="relative headernotification"
              >
                <img
                  src={
                    "https://pro-manage.s3.ap-south-1.amazonaws.com/NotificationIcon.svg"
                  }
                  className="img-fluid w-10 cursor-pointer"
                  alt="notification"
                  onClick={() => {
                    handleReviewNotification(true);
                    handleLocationNotification(true);
                    handeleQuestionNotification(true);
                    handleReadMoreClick();
                  }}
                />
                <div className="absolute top-[-7px] right-[-7px] w-5 h-5 rounded-full  bg-listing text-white text-[12px] text-center ">
                  <p className=" mb-0">
                    {(locationCountNotification?.newNotificationCount || 0) +
                      (reviewCountNotification?.newNotificationCount || 0) +
                      (questionCountNotification?.newNotificationCount || 0)}
                  </p>
                </div>
                {/* <div className='absolute'>
                <span class='relative flex h-2 w-2'>
                  <span class='animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75'></span>
                  <span class='relative inline-flex rounded-full h-2 w-2 bg-red-600'></span>
                </span>
              </div> */}
              </Popover>
            </div>
          ) : (
            ""
          )}
          <div className="flex justify-center items-center">
            <div className=" m-auto flex justify-center items-center gap-1">
              <img
                src={`${profilePic}`}
                className="w-8 rounded-full"
                alt="profile"
              />
            </div>
            <div className="dropdown">
              <button
                className="btn flex items-center justify-center border-0 dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <p className="font-bold text-sideBar">{userInfo?.info?.name}</p>
              </button>
              <ul
                className="dropdown-menu profilemenu  border-0 rounded-2xl  w-44"
                aria-labelledby="dropdownMenuButton1"
              >
                <li
                  className="p-3 pt-2 pb-2 text-medium  flex justify-between items-center gap-2 cursor-pointer"
                  onClick={() => setOpenProfileModal(true)}
                >
                  <button>Profile</button>
                  <img
                    src="https://pro-manage.s3.ap-south-1.amazonaws.com/Profile.svg"
                    alt="profile"
                  />
                </li>
                {/* <hr /> */}
                {/* <li className='pb-2 pt-2 cursor-pointer' onClick={() => setOpenPasswordModal(true)}>Password Change</li> */}
                <img
                  className={`${
                    userInfo?.info?.roleDetails?.roleId === 1 ? "hidden" : ""
                  }`}
                  src="https://pro-manage.s3.ap-south-1.amazonaws.com/Line.svg"
                  alt="lineImg"
                />

                <li
                  className={`p-3 pt-2 pb-2 text-medium flex justify-between items-center gap-2 cursor-pointer ${
                    userInfo?.info?.roleDetails?.roleId === 1 ? "hidden" : ""
                  }`}
                  onClick={() => logout()}
                >
                  <button>Logout </button>
                  <img
                    src="https://pro-manage.s3.ap-south-1.amazonaws.com/logout.svg"
                    alt="LogOutImg"
                  />
                </li>
                {/* <li className={`p-3 pt-2 pb-2 text-medium flex justify-between items-center gap-2 ${userInfo?.info?.roleDetails?.roleId === 1 ? "hidden" : ""}`}><button onClick={() => logout()}>Logout </button><img src="https://pro-manage.s3.ap-south-1.amazonaws.com/logout.svg" alt="LogOutImg" /></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Profile
        openProfileModal={openProfileModal}
        setOpenProfileModal={setOpenProfileModal}
        setIsDisabled={setIsDisabled}
        setIsDisabled1={setIsDisabled1}
        profilePic={profilePic}
        IsDisabled2={IsDisabled2}
        handleFileSelector={handleFileSelector}
        handleFileChange={handleFileChange}
        selectedFile={selectedFile}
        setIsDisabled2={setIsDisabled2}
        setSelectedFile={setSelectedFile}
        setProfilePic={setProfilePic}
        tokenDetails={userInfo}
        profileEdit={profileEdit}
        IsDisabled={IsDisabled}
        handleProfile={handleProfile}
        IsDisabled1={IsDisabled1}
        handleEditProfile={handleEditProfile}
        setProfileEdit={setProfileEdit}
      />

      <PasswordChange
        openPasswordModal={openPasswordModal}
        setOpenPasswordModal={setOpenPasswordModal}
        OldPassword={OldPassword}
        SetOldPassword={SetOldPassword}
        NewPassword={NewPassword}
        SetNewPassword={SetNewPassword}
        confirmPassword={confirmPassword}
        SetConfirmPassword={SetConfirmPassword}
        SavePassword={SavePassword}
      />
    </div>
  );
};

export default TopNavbar;
