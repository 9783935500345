import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { businessProfile } from '../assert/business-profile'
import { userManagement } from '../assert/user-management'
import { lineGraph } from '../assert/line-graph'
import { employee } from '../assert/employee-work-list'
import { reviewIcon } from '../assert/reviews'
import { upload } from '../assert/upload'
import { questionAndAnswer } from '../assert/question-and-answer'
import { notification } from '../assert/notification'
import { AuthContext } from '../context/AuthContext'

// import { Joyride } from 'react-joyride';

const Sidebar = () => {
  // const [run, setRun] = useState(true)

  // const steps = [
  //   {
  //     target: '.step-1',
  //     content: 'This is the first step!'
  //   },
  //   {
  //     target: '.step-2',
  //     content: 'This is the second step!'
  //   }
  // ]

  const { userInfo } = useContext(AuthContext)

  const images = [
    lineGraph,
    employee,
    reviewIcon,
    businessProfile,
    questionAndAnswer,
    userManagement,
    upload,
    notification
  ]

  useEffect(() => {
    setSideBarMenu(
      userInfo?.userAccess?.filter((item, index) => item?.isAcccess === true)
    )
  }, [userInfo])
  const [sideBar, setSideBarMenu] = useState(
    userInfo?.userAccess?.filter((item, index) => item?.isAcccess === true)
  )
  // console.log(sideBar, 'userAccess', images[0])
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const sideBarMenu = sideBar?.map((item, index) => ({
    menuBar: item?.accessName,
    img: images[index],
    url:
      item?.accessName === 'Question & Answer'
        ? 'questions'
        : item?.accessName.toLowerCase().replace(/\s+/g, '')
  }))

  // console.log(sideBarMenu, 'sideBarMenu')

  return (
    <>
      <button
        data-drawer-target='default-sidebar'
        data-drawer-toggle='default-sidebar'
        aria-controls='default-sidebar'
        type='button'
        className='inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600'
      >
        <span className='sr-only'>Open sidebar</span>
        <svg
          className='w-6 h-6'
          aria-hidden='true'
          fill='currentColor'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            clip-rule='evenodd'
            fill-rule='evenodd'
            d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
          ></path>
        </svg>
      </button>

      <aside
        id='default-sidebar'
        className=' md:relative fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0 '
        aria-label='Sidenav'
      >
        <div
          className='overflow-y-auto py-6 px-2 h-full  dark:bg-gray-800 dark:border-gray-700 	'
          style={{
            background:
              'linear-gradient(358.42deg, #C1BDFF -34%, #ffccf3   79.87%)',
            scrollbarWidth: 'none'
          }}
        >
          <div className='flex items-center justify-center'>
            <img
              src='https://pro-manage.s3.ap-south-1.amazonaws.com/TuskName.svg'
              alt=''
              className=' '
            />
            <h4 className='text-ProjectTitle font-semibold text-xl'>
              Tusk <span className='text-listing'>Manager</span>
            </h4>
          </div>
          <div className='flex flex-col justify-between h-[85vh]'>
            <ul className='space-y-3 mt-3 '>
              {sideBarMenu?.length > 0 &&
                sideBarMenu?.map(item => (
                  <li
                    className={
                      pathname.includes(`${item.url}`)
                        ? 'bg-purple-400 px-3 py-2 text-md font-semibold text-white step-1  capitalize flex items-center rounded-full gap-3'
                        : 'text-sideBar text-md font-medium  px-3 py-2 capitalize flex items-center rounded-full gap-3   hover:bg-sideBarHover  group'
                    }
                    onClick={() => {
                      navigate(
                        `/${
                          item.url === 'insights'
                            ? 'insights/dashboard'
                            : item?.url === 'useractivity'
                            ? 'useractivity/review'
                            : item.url
                        }`
                      )
                    }}
                    style={{ cursor: 'pointer' }}
                    key={item.menuBar}
                  >
                    <span
                      className={
                        pathname.includes(`${item.url}`)
                          ? 'bg-white p-2 rounded-full'
                          : ' group-hover:bg-white  p-2 rounded-full'
                      }
                    >
                      {item?.img}
                    </span>
                    {item.menuBar}
                  </li>
                ))}
            </ul>
            <div className='flex flex-col justify-center items-center p-3 mt-2  position-relative'>
              {/* <img
              src='https://pro-manage.s3.ap-south-1.amazonaws.com/ContactUs.svg'
              alt='contactUsImg'
              className='z-10'
            /> */}
              <div className='bg-bgContactUs rounded-md text-center p-4 pb-0  flex flex-col gap-2 '>
                <p className='text-listing font-bold text-lg '>Contact Us</p>
                <p className='text-sideBar hover:text-listing font-extrabold text-[14px] '>
                  <Link
                    className='text-decoration-none '
                    to='mailto:support@tuskmelon.com'
                  >
                    support@tuskmelon.com
                  </Link>
                </p>
                <p className='text-sideBar hover:text-listing font-extrabold text-[14px] '>
                  <Link
                    className='text-decoration-none '
                    to='mailto:tuskmelon2018@gmail.com'
                  >
                    tuskmelon2018@gmail.com
                  </Link>
                </p>
                <div className='mt-2 pb-2'>
                  <Link
                    to={'https://www.tuskmelonpro.com/privacypolicy'}
                    className='text-termsAndCondtion  text-decoration-underline'
                    target='_blank'
                  >
                    {' '}
                    Privacy Policy
                  </Link>{' '}
                  &
                  <Link
                    to={'https://www.tuskmelonpro.com/termsandcondition'}
                    className='text-termsAndCondtion text-decoration-underline ms-1'
                    target='_blank'
                  >
                    Terms and Conditions
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  )
}

export default Sidebar
