import React, { useEffect, useState } from 'react'
import StarRating from '@rubenvara/react-star-rating'
import { API_URL } from '../env/env'
import httpservice from '../Service/httpservice'
import { useQuery } from '@tanstack/react-query'

const OverAllReview = ({
  userId,
  groupId,
  startDate,
  endDate,
  previousDate,
  size
}) => {
  const [reviewCount, setReviewCount] = useState([])

  //   useEffect(() => {
  //     Reviews()
  //   }, [userId, groupId, startDate, endDate])

  const { data, isPending } = useQuery({
    queryFn: () => groupId !== undefined && Reviews(),
    queryKey: ['Reviews', userId, groupId, startDate, endDate],
    staleTime: 1000 * 3600,
    cacheTime: 600000
    // onSuccess: ViewsAndActivityData => {
    //   setViewsAndActivityData(ViewsAndActivityData)
    // }
  })

  useEffect(() => {
    setReviewCount(data?.data)
  }, [data?.data])

  const formatData = data => {
    if (data >= 100000) {
      return (data / 100000).toFixed(2) + 'L'
    } else if (data >= 1000) {
      return (data / 1000).toFixed(2) + 'K'
    } else {
      return data
    }
  }

  const Reviews = async () => {
    try {
      let body = {
        userId: userId,
        groupId: groupId,
        startDate: startDate,
        endDate: endDate,
        previousStartDate: previousDate?.previousDate,
        previousEndDate: startDate
      }

      const url = `${API_URL}/review/get_average_star_rating`
      const res = await httpservice.post(url, body)
      if (res.status === 200) {
        // setReviewCount(res?.data?.data)
        return { data: res?.data?.data }
      }
    } catch (error) {}
  }

  const config = {
    fullColor: '#FEA500',
    size: 12,
    showText: false
  }

  return (
    <div
      className={`rounded-2xl shadow-filter1 border-[0.1px] border-cardInsights p-4 ${
        size === true ? 'col-12' : 'col-11'
      }`}
    >
      <p className='text-xl font-semibold text-gray-900 dark:text-white'>
        Reviews
      </p>
      <div className='flex justify-between items-center gap-2'>
        <div className='text-center pt-3'>
          <p className='text-3xl font-bold text-ReviewColor'>
            {reviewCount?.ratingDetails?.overallRating}
          </p>
          <p className='text-sm font-medium'>Overall Rating</p>
          <p className='text-lg font-medium text-black text-start pt-2'>
            {formatData(reviewCount?.ratingDetails?.overallReviewCount)}
          </p>
          <p className='text-sm font-medium text-black mb-2 pt-2'>
            Total Reviews
          </p>
          <p className='text-sm font-medium text-black pt-2'>
            {reviewCount?.ratingDetails?.overallPositiveReviewPercentage}%
            Positive
          </p>
        </div>
        <div className='gap-3 flex flex-col'>
          <div className='bg-oldDate rounded-xl p-3'>
            <p className='text-sm font-medium pb-1'>
              {new Date(startDate).toLocaleDateString('en-IN', {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
              })}{' '}
              to{' '}
              {new Date(endDate).toLocaleDateString('en-IN', {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
              })}{' '}
            </p>
            <div className='flex items-center gap-2'>
              <p className='text-sm font-medium '>
                {reviewCount?.ratingDetails?.rating}
              </p>
              <StarRating
                rating={
                  reviewCount?.ratingDetails?.rating
                    ? reviewCount?.ratingDetails?.rating
                    : 0
                }
                style={{ marginBottom: '0px' }}
                config={config}
              />
            </div>
            <p className='text-sm font-medium pb-1'>
              {formatData(reviewCount?.ratingDetails?.reviewCount)} Reviews |{' '}
              {reviewCount?.ratingDetails?.positiveReviewPercentage}% Positive
            </p>
          </div>
          <div className='bg-previousDate rounded-xl p-3 '>
            <p className='text-sm font-medium pb-1'>
              Previous{' '}
              {previousDate?.difference?.yearsDiff
                ? `${previousDate?.difference?.yearsDiff} Years`
                : previousDate?.difference?.monthsDiff
                ? `${previousDate?.difference?.monthsDiff} Months`
                : `${previousDate?.difference?.weekDiff} Weeks`}
            </p>
            <div className='flex items-center gap-2'>
              <p className='text-sm font-medium'>
                {reviewCount?.ratingDetails?.previousRating}
              </p>
              <StarRating
                rating={
                  reviewCount?.ratingDetails?.previousRating
                    ? reviewCount?.ratingDetails?.previousRating
                    : 0
                }
                style={{ marginBottom: '0px' }}
                config={config}
              />
            </div>
            <p className='text-sm font-medium pb-1'>
              {reviewCount?.ratingDetails?.previousReviewCount} Reviews |{' '}
              {reviewCount?.ratingDetails?.previousPositiveReviewPercentage}%
              Positive
            </p>
          </div>
        </div>
      </div>
      <hr className='mt-3' />
      <div>
        <div className='mt-2'>
          {reviewCount?.starRatings?.map((item, index) => (
            <div className='flex flex-col gap-1  '>
              <p>{item.rating} stars</p>
              <div className='flex gap-3 items-center'>
                <span
                  min='0'
                  max='100'
                  step='1'
                  readOnly
                  className='w-96 h-3 appearance-none rounded-md focus:outline-none focus:shadow-outline-indigo'
                  style={{
                    background: `linear-gradient(to right, gold ${item.reviewPercentage}%, #E4EAF0 ${item.reviewPercentage}% 100%)`,
                    WebkitAppearance: 'none',
                    MozAppearance: 'none',
                    appearance: 'none'
                  }}
                ></span>
                <p>{item.reviewPercentage}%</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default OverAllReview
