import React, { useContext } from 'react'
import { AccountDetails } from '../../Store/AccountInfo'
import { LocationDetails } from '../../Store/LocationInfo'
import { API_URL } from '../../env/env'
import httpservice from '../../Service/httpservice'
import { Button, Modal } from 'flowbite-react'
import { toast } from 'react-toastify'
import { AuthContext } from './../../context/AuthContext'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const SpecialHourModal = ({
  OpenModalSpecialHours,
  setOpenModalSpecialHours,
  setDateTimes,
  setSpecialHr,
  specialHr,
  timeOptions,
  showInputs,
  dateTimes,
  setShowInputs,
  updatedDateTimes,
  invalidateAndRefetch,
  initialDateTime,
  handleLocationCountNotification
}) => {
  const { userInfo, groupId } = useContext(AuthContext)
  const Locationid = LocationDetails(state => state.LocationDetails)

  const handleStartDateChange = (index, year, month, day) => {
    const updatedDateTimes = [...(dateTimes || [])]
    updatedDateTimes[index].startDate = { year, month, day }
    updatedDateTimes[index].endDate = { year, month, day }
    setDateTimes(updatedDateTimes)
  }

  // console.log(dateTimes, 'dateTimes', specialHr)

  const handleStartTimeChange = (index, event) => {
    const updatedDateTimes = [...(dateTimes || [])]
    const selectedTime = event.target.value
    const [hours, minutes] = selectedTime.split(':')

    updatedDateTimes[index].openTime = {
      hours: hours,
      minutes: minutes
    }

    setDateTimes(updatedDateTimes)
  }

  const handleEndTimeChange = (index, event) => {
    const updatedDateTimes = [...(dateTimes || [])]
    const selectedTime = event.target.value
    const [hours, minutes] = selectedTime.split(':')

    updatedDateTimes[index].closeTime = {
      hours: hours,
      minutes: minutes
    }

    setDateTimes(updatedDateTimes)
  }

  const handleRemoveDateTime = index => {
    // console.log(index, 'index')
    const updatedDateTimes = [...(dateTimes || [])]
    // console.log(updatedDateTimes, 'updatedDateTimes')
    updatedDateTimes.splice(index, 1)
    setDateTimes(updatedDateTimes)
  }
  const handleRemoveDateTime1 = index => {
    // console.log(index, 'index')
    const updatedDateTimes = [...specialHr]

    // console.log(updatedDateTimes, 'updatedDateTimes')
    updatedDateTimes.splice(index, 1)
    setSpecialHr(updatedDateTimes)
  }

  const handleEditSpecial = async e => {
    e.preventDefault()
    const added = [...(specialHr || []), ...(updatedDateTimes || [])]
    try {
      let body = {
        userId: userInfo.info?.userId,
        groupId: groupId?.groupId,
        locationId: Locationid,
        sectionName: 'Special Hours',
        updateData: {
          specialHours: {
            specialHourPeriods: added
          }
        }
      }

      const url = `${API_URL}/location/update?updateMask=specialHours`
      const res = await httpservice.patch(url, body)
      if (res.status === 200) {
        setOpenModalSpecialHours(false)
        // getSingleLocation();
        setSpecialHr([])
        setDateTimes([])
        if (userInfo?.info?.roleDetails?.roleId === 1) {
          handleLocationCountNotification(true)
        }
        invalidateAndRefetch()
        toast.success('Special hours updated, reflects in 10.')
      } else {
        setOpenModalSpecialHours(false)
      }
    } catch (error) {
      setOpenModalSpecialHours(false)
      toast.error(error?.response?.data?.message)
      // console.log(error)
    }
  }

  const handleAddMore = () => {
    setDateTimes([...(dateTimes || []), initialDateTime])
    setShowInputs(true)
  }
  return (
    <div>
      <Modal
        show={OpenModalSpecialHours}
        onClose={() => {
          setOpenModalSpecialHours(false)
          setSpecialHr([])
          setDateTimes([])
        }}
        size='3xl'
      >
        <Modal.Header className='p-3'>Special Hour</Modal.Header>
        <Modal.Body>
          <div className='space-y-6'>
            <form>
              <div>
                {specialHr?.map((dateTime, index) => (
                  <div key={index} className='flex gap-3 mt-3 items-center '>
                    <div className='rounded-lg cursor-not-allowed'>
                      <DatePicker
                        selected={
                          new Date(
                            dateTime?.startDate?.year,
                            dateTime?.startDate?.month - 1,
                            dateTime?.startDate?.day
                          )
                        }
                        disabled={true}
                        className='cursor-not-allowed'
                        onChange={date => {
                          handleStartDateChange(
                            index,
                            date.getFullYear(),
                            date.getMonth() + 1,
                            date.getDate()
                          )
                        }}
                      />
                    </div>

                    {dateTime && (
                      <>
                        <select
                          className='rounded-lg cursor-not-allowed'
                          disabled={true}
                          defaultValue={
                            dateTime?.openTime?.hours
                              ? `${String(
                                  dateTime?.openTime?.hours ?? '00'
                                ).padStart(2, '0')}:${
                                  dateTime?.openTime?.minutes ?? '00'
                                }`
                              : '00:00'
                          }
                          // value={dateTime?.openTime?.hours}:${dateTime?.openTime?.minutes}`}
                          onChange={e => handleStartTimeChange(index, e)}
                        >
                          <option value=''>Open Time</option>
                          {timeOptions.map((time, timeIndex) => (
                            <option key={timeIndex} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>

                        <select
                          className='rounded-lg cursor-not-allowed'
                          disabled={true}
                          defaultValue={
                            dateTime?.closeTime?.hours
                              ? `${String(
                                  dateTime?.closeTime?.hours ?? '00'
                                ).padStart(2, '0')}:${
                                  dateTime?.closeTime?.minutes ?? '00'
                                }`
                              : '00:00'
                          }
                          onChange={e => handleEndTimeChange(index, e)}
                        >
                          <option value=''> Close Time</option>
                          {timeOptions.map((time, timeIndex) => (
                            <option key={timeIndex} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                    <img
                      src='https://pro-manage.s3.ap-south-1.amazonaws.com/Delete.svg'
                      className='cursor-pointer'
                      alt='Delete'
                      onClick={() => handleRemoveDateTime1(index)}
                    />
                  </div>
                ))}

                {showInputs &&
                  dateTimes?.map((dateTime, index) => (
                    <>
                      <div
                        key={index}
                        className='flex gap-3 mt-3 items-center '
                      >
                        <DatePicker
                          selected={
                            new Date(
                              dateTime?.startDate?.year,
                              dateTime?.startDate?.month - 1,
                              dateTime?.startDate?.day
                            )
                          }
                          onChange={date => {
                            handleStartDateChange(
                              index,
                              date.getFullYear(),
                              date.getMonth() + 1,
                              date.getDate()
                            )
                          }}
                        />

                        {dateTime.showSelect && (
                          <>
                            <select
                              className='rounded-lg'
                              value={`${dateTime.openTime.hours}:${dateTime.openTime.minutes}`}
                              onChange={e => handleStartTimeChange(index, e)}
                            >
                              <option value=''> Open Time</option>
                              {timeOptions.map((time, timeIndex) => (
                                <option key={timeIndex} value={time}>
                                  {time}
                                </option>
                              ))}
                            </select>

                            <select
                              className='rounded-lg'
                              value={`${dateTime.closeTime.hours}:${dateTime.closeTime.minutes}`}
                              onChange={e => handleEndTimeChange(index, e)}
                            >
                              <option value=''> Close Time</option>
                              {timeOptions.map((time, timeIndex) => (
                                <option key={timeIndex} value={time}>
                                  {time}
                                </option>
                              ))}
                            </select>
                          </>
                        )}
                        <img
                          src='https://pro-manage.s3.ap-south-1.amazonaws.com/Delete.svg'
                          className='cursor-pointer'
                          alt='Delete'
                          onClick={() => handleRemoveDateTime(index)}
                        />
                      </div>

                      <label className='relative mt-3 inline-flex items-center cursor-pointer'>
                        <input
                          type='checkbox'
                          className='sr-only peer'
                          onChange={() => {
                            const updatedDateTimes = [...dateTimes]
                            updatedDateTimes[index].showSelect =
                              !updatedDateTimes[index].showSelect
                            if (!updatedDateTimes[index].showSelect) {
                              updatedDateTimes[index].openTime = ''
                              updatedDateTimes[index].closeTime = ''
                            }
                            setDateTimes(updatedDateTimes)
                          }}
                        />
                        <div className="w-11  h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <p className='ps-3'>
                          {dateTimes[index].showSelect === false
                            ? 'Closed'
                            : 'Open'}
                        </p>
                      </label>
                    </>
                  ))}

                <p
                  className='text-md cursor-pointer text-listing font-medium mt-4'
                  onClick={handleAddMore}
                >
                  {' '}
                  + Add more
                </p>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className='pt-3 justify-end'>
          <div className='flex justify-end gap-3 '>
            <button
              color='gray'
              onClick={() => {
                setOpenModalSpecialHours(false)
                setSpecialHr([])
                setDateTimes([])
              }}
              className='px-4 py-2 text-md font-medium text-center border-1 text-gray  rounded-lg focus:outline-none'
            >
              Cancel
            </button>
            <button
              type='submit'
              className='px-4 py-2 text-md font-medium text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none'
              onClick={e => handleEditSpecial(e)}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default SpecialHourModal
