import React, { useState, useEffect, useRef, useContext } from "react";
import moment from "moment-timezone";
import httpservice from "../Service/httpservice";
import Calender from "../Components/ReviewCalender";
// import ReactStars from 'react-rating-stars-component'
import { API_URL } from "../env/env";
import { CalenderInfo } from "../Store/CalenderInfo";
import Loader from "../loader/loader";
import { toast } from "react-toastify";
import ReviewComponent from "../Components/ReviewComponent";
import ReviewReply from "../Components/ReviewModal/ReviewReply";
import ReviewFilter from "../Components/ReviewModal/ReviewFilter";
import { AuthContext } from "../context/AuthContext";
import RangeSlider from "../Components/RangeSlider";

const Review = () => {
  const date = CalenderInfo((state) => state.CalenderInfo);
  // console.log(date, 'date inline 20')

  const {
    userInfo,
    groupId,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleQuestionCountNotification,
    handleReviewNotification,
    handleLocationNotification,
  } = useContext(AuthContext);

  useEffect(() => {
    setStartDate(
      moment.utc(date?.startDate).tz("Asia/Calcutta").format("YYYY-MM-DD")
    );
    setEndDate(
      moment.utc(date?.endDate).tz("Asia/Calcutta").format("YYYY-MM-DD")
    );
  }, [date]);

  const [startDate, setStartDate] = useState(
    moment.utc(date?.startDate).tz("Asia/Calcutta").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment.utc(date?.endDate).tz("Asia/Calcutta").format("YYYY-MM-DD")
  );

  const { tokenInfo } = useContext(AuthContext);

  useEffect(() => {
    tokenInfo();
  }, []);

  useEffect(() => {
    if (userInfo?.info?.roleDetails?.roleId === 1) {
      handleLocationCountNotification(true);
      handleReviewCountNotification(true);
      handleQuestionCountNotification(true);
    }
    // handleReviewNotification(true)
    // handleLocationNotification(true)
  }, [groupId, userInfo]);

  // const startDate = moment.utc(date?.startDate).tz("Asia/Calcutta").format("YYYY-MM-DD");
  // const endDate = moment.utc(date?.endDate).tz("Asia/Calcutta").format("YYYY-MM-DD");

  const [reviews, setReviews] = useState([]);
  const [openModalReview, setOpenModalReview] = useState(false);
  const [openModalReply, setOpenModalReply] = useState(false);
  const [rating, setRating] = useState("");
  const [responetype, setResponseType] = useState("");
  const [reviewType, setreviewType] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(0);
  const [page, setPage] = useState(1);
  const [reply, setReply] = useState("");
  const [replyMessage, setReplyMessage] = useState("");
  const [reviewCounter, setReviewCounter] = useState();
  const [ownerResponse, setOwnerResponse] = useState("");
  const [load, setload] = useState(true);
  const [totalPage, setTotalPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const elementRef = useRef(null);
  const replyarea = useRef(null);
  const [isUploading, setIsUploading] = useState(false);

  if (replyarea.current) {
    replyarea.current.focus();
  }

  const boolean = localStorage.getItem("calender");
  let groupValue = localStorage.getItem("GroupChange");

  useEffect(() => {
    if (groupValue === "true") {
      // console.log('group', groupValue)
      setReviews([]);
      setPage(1);
      localStorage.setItem("GroupChange", "false");
    }
  }, [groupValue, page]);

  useEffect(() => {
    // console.log(boolean === 'true', 'date', typeof boolean)

    if (boolean === "true") {
      // console.log('1', 'count')
      const formattedStartDate = moment
        .utc(date?.startDate)
        .tz("Asia/Calcutta")
        .format("YYYY-MM-DD");
      const formattedEndDate = moment
        .utc(date?.endDate)
        .tz("Asia/Calcutta")
        .format("YYYY-MM-DD");
      // startDate = formattedStartDate;
      // endDate = formattedEndDate
      // setStartDate(formattedStartDate);
      // setEndDate(formattedEndDate);

      handleReview(formattedStartDate, formattedEndDate, 1);
    }
  }, [date, boolean, groupId]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        // console.log('hasMore1', totalPage >= page, page, totalPage)
        if (totalPage >= page) {
          if (firstEntry.isIntersecting && hasMore) {
            handleReview();
          }
        }
      },
      {
        rootMargin: "120px",
      }
    );

    if (observer && elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [
    elementRef,
    hasMore,
    pageSize,
    page,
    sortBy,
    responetype,
    reviewType,
    rating,
    totalPage,
    groupId.groupId,
    startDate,
    endDate,
  ]);

  const [selectedOption, setSelectedOption] = useState("0");
  const [responseFilter, setResponse] = useState("0");

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleResponse = (e) => {
    setResponse(e.target.value);
  };

  const handleReview = async (formattedStartDate, formattedEndDate) => {
    // console.log(
    //   formattedStartDate,
    //   'formattedStartDate',
    //   formattedEndDate,
    //   'formattedEndDate',
    //   startDate,
    //   'startDate',
    //   endDate,
    //   'endDate'
    // )
    try {
      // console.log(typeof boolean, 'date101')
      let body;
      if (boolean === "true" || boolean === true) {
        body = {
          userId: userInfo?.info?.userId,
          groupId: groupId?.groupId,
          page: 1,
          pageSize: pageSize,
          reviewType: Number(reviewType),
          reviewResponseType: Number(responetype),
          sortBy: Number(sortBy),
          ratings: rating,
          startDate: formattedStartDate ? formattedStartDate : startDate,
          endDate: formattedEndDate ? formattedEndDate : endDate,
        };
      } else {
        body = {
          userId: userInfo?.info?.userId,
          groupId: groupId?.groupId,
          page: page,
          pageSize: pageSize,
          reviewType: Number(reviewType),
          reviewResponseType: Number(responetype),
          sortBy: Number(sortBy),
          ratings: rating,
          startDate: formattedStartDate ? formattedStartDate : startDate,
          endDate: formattedEndDate ? formattedEndDate : endDate,
        };
      }
      const url = `${API_URL}/review/all`;
      const response = await httpservice.post(url, body);
      if (response?.status === 200) {
        // console.log(response?.data.totalPages >= page)
        // console.log('no more data', response?.data.totalPages)

        setTotalPage(response?.data.totalPages);

        // console.log(response?.data?.data, 'review')
        setReviewCounter(response?.data);
        setload(false);
        if (boolean === "true") {
          setPage(1);
          setReviews([]);
        }

        // console.log(boolean, 'boolean135')
        if (boolean === "false") {
          if (totalPage >= page) {
            setReviews((prevProducts) => {
              const newData = response?.data?.data || [];
              return Array.isArray(prevProducts)
                ? [...prevProducts, ...newData]
                : newData;
            });
            if (totalPage >= page && boolean === "false") {
              setPage((prevPage) => prevPage + 1);
            }
          } else {
            setHasMore(false);
          }
        }
        localStorage.setItem("calender", false);
      } else {
        setReviews([]);
        setload(false);
      }
    } catch (error) {
      setReviews([]);
      setload(false);
      setReviewCounter("");
      // console.log(error)
    }
  };

  const handlereplyClick = (item) => {
    setOpenModalReply(true);
    // console.log(item, 'item')
    setReply(item);
    setOwnerResponse(item?.reply?.replyComment);
  };

  const initialSelectedValues = "";
  const [selectedValues, setSelectedValues] = useState(initialSelectedValues);

  const handleCheckboxChange = (value) => {
    const updatedValues = selectedValues.split(",").filter((val) => val !== "");
    const stringValue = String(value);
    const selectedIndex = updatedValues.indexOf(stringValue);
    if (selectedIndex === -1) {
      updatedValues.push(stringValue);
    } else {
      updatedValues.splice(selectedIndex, 1);
    }
    const formattedOutput = updatedValues.join(",");
    setSelectedValues(formattedOutput);
  };

  // console.log(selectedValues, 'selectedValues')

  const handleFilter = () => {
    setRating(selectedValues);
    setreviewType(selectedOption);
    setResponseType(responseFilter);
    setOpenModalReview(false);
    setReviews("");
    setPage(1);
  };

  const handleReply = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    try {
      let body;
      if (ownerResponse?.length > 1) {
        body = {
          userId: userInfo?.info?.userId,
          groupId: groupId?.groupId,
          locationId: reply?.locationId,
          comment: ownerResponse,
          accessToken: userInfo?.token,
        };
      } else {
        body = {
          userId: userInfo?.info?.userId,
          groupId: groupId?.groupId,
          locationId: reply?.locationId,
          comment: replyMessage,
          accessToken: userInfo?.token,
        };
      }
      const url = `${API_URL}/review/reply/${reply?.details?.review?.reviewId}`;
      const response = await httpservice.post(url, body);
      if (response.status === 200) {
        console.log(response?.data.data, "review", reviews);
        toast.success("Review Post successfully");
        setOpenModalReply(false);
        setReviews((prevState) =>
          prevState.map((item) =>
            item?.details?.review?.reviewId ===
            response?.data?.data.details?.review?.reviewId
              ? {
                  ...item,
                  ...response?.data?.data,
                }
              : item
          )
        );
        setReplyMessage("");

        // setReviews([]);

        // setPage(1);
        setIsUploading(false);
        if (userInfo?.info?.roleDetails?.roleId === 1) {
          handleReviewCountNotification(true);
        }

        // handleReview(startDate, endDate)
      }
      // console.log(response?.data?.data, 'review')
    } catch (error) {
      setReplyMessage("");
      setIsUploading(false);
      // console.log(error)
      setOpenModalReply(false);
      toast.error(error?.response?.data?.message);
    }
  };

  // console.log(selectedValues, 'selectedValues')

  const handleReviewDelete = async (reviewId, locationid) => {
    setIsUploading(true);
    try {
      // console.log(locationid, 'id')
      let body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        locationId: locationid,
      };
      const url = `${API_URL}/review/reply/delete/${reviewId}`;
      const response = await httpservice.post(url, body);
      if (response.status === 200) {
        // setPage(1);
        setIsUploading(false);
        // setReviews([]);
        setReviews((prevState) =>
          prevState.map((item) =>
            item?.details?.review?.reviewId ===
            response?.data?.data.details?.review?.reviewId
              ? {
                  ...item,
                  ...response?.data?.data,
                }
              : item
          )
        );
        if (userInfo?.info?.roleDetails?.roleId === 1) {
          handleReviewCountNotification(true);
        }
        // console.log(response, 'reponse')
        toast.success("Review deleted successfully");
      }
    } catch (error) {
      setIsUploading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const rangeRef1 = useRef();
  const rangeRef2 = useRef();
  const rangeRef3 = useRef();

  const [downloading, setDownloading] = useState(false);

  const handleDownload = async () => {
    try {
      setDownloading(true);
      const Apiurl = `${API_URL}/review/all/download`;
      const response = await httpservice.post(
        Apiurl,
        {
          userId: userInfo?.info?.userId,
          groupId: groupId?.groupId,
          reviewType: Number(reviewType),
          reviewResponseType: Number(responetype),
          sortBy: Number(sortBy),
          ratings: rating,
          startDate: startDate,
          endDate: endDate,
        },
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "reviews.xlsx");
      document.body.appendChild(link);
      link.click();
      setDownloading(false);
    } catch (error) {
      console.error("Failed to download Excel file:", error);
      setDownloading(false);
    }
  };

  return (
    <div className="p-2 pt-0 ">
      {load && <Loader />}
      <Calender Title={"Reviews"} />

      <div className="container md:px-4 py-2">
        <div className=" mt-2 mb-2 ">
          <div className="flex justify-around items-center shadow-filter1 rounded-2xl py-4  gap-2 bg-white">
            <RangeSlider
              title="Total"
              imageUrl="https://pro-manage.s3.ap-south-1.amazonaws.com/TotalReviews.svg"
              count={reviewCounter?.totalReviews}
              colorClass="bg-TotalColor"
              dialClass="dial"
              rangeRef={rangeRef1}
              percentage={reviewCounter?.totalReviews ? 100 : 0}
              // updateRangeSlider={updateRangeSlider}
            />
            <RangeSlider
              title="Replied"
              imageUrl="https://pro-manage.s3.ap-south-1.amazonaws.com/Replied.svg"
              count={reviewCounter?.repliedCount}
              colorClass="bg-listing"
              dialClass="dial1"
              rangeRef={rangeRef2}
              percentage={
                reviewCounter?.repliedCount
                  ? (reviewCounter?.repliedCount /
                      reviewCounter?.totalReviews) *
                    100
                  : 0
              }
            />
            <RangeSlider
              title="Not Replied"
              imageUrl="https://pro-manage.s3.ap-south-1.amazonaws.com/Rejected.svg"
              count={reviewCounter?.notRepliedCount}
              colorClass="bg-reviewNotReplied"
              dialClass="dial2"
              rangeRef={rangeRef3}
              percentage={
                reviewCounter?.notRepliedCount
                  ? (reviewCounter?.notRepliedCount /
                      reviewCounter?.totalReviews) *
                    100
                  : 0
              }
            />
          </div>
        </div>
      </div>
      <div className="container md:px-4 py-0 pt-3 pb-2">
        <div className="flex justify-between ">
          <div className="col-4 flex  gap-3 items-center ">
            <div
              className="flex items-center gap-2 justify-center   bg-white border border-gray-300 text-black text-md  p-2.5 w-28 rounded-lg cursor-pointer hover:bg-pink-200"
              onClick={() => setOpenModalReview(true)}
            >
              <img
                src="https://pro-manage.s3.ap-south-1.amazonaws.com/filter.svg"
                alt="filter"
                className="img-fluid"
              />
              Filter
            </div>
            <button
              onClick={handleDownload}
              disabled={downloading}
              className=" border-1 text-listing border-listing px-3 py-2 rounded-xl flex justify-between items-center"
            >
              <img
                src="https://pro-manage.s3.ap-south-1.amazonaws.com/downloadIconForReviews.svg"
                alt="images"
              />{" "}
              {downloading ? "Downloading..." : "Download Excel"}
            </button>
          </div>
          <div className="flex items-center justify-end gap-3 col-5">
            <div className=" col-md-4 col-6">
              <select
                id="pageSize"
                className="bg-white  border border-gray-300 text-black text-sm  rounded-lg block w-full p-2.5 cursor-pointer  dark:placeholder-gray-400 dark:text-white hover:bg-pink-200"
                onChange={(e) => {
                  setPageSize(e?.target?.value);
                  setPage(1);
                  setReviews("");
                }}
              >
                <option selected value="10">
                  Show 10 Entries
                </option>
                <option value="25">Show 25 Entries</option>
                <option value="50">Show 50 Entries</option>
                <option value="100">Show 100 Entries</option>
              </select>
            </div>
            <div className="col-1.5">
              <select
                id="countries"
                className="bg-white  border border-gray-300 text-black text-sm  rounded-lg block w-full p-2.5  dark:placeholder-gray-400 dark:text-white hover:bg-pink-200 cursor-pointer "
                onChange={(e) => {
                  setSortBy(e?.target?.value);
                  setPage(1);
                  setReviews("");
                }}
              >
                <option selected value="0">
                  Newest
                </option>
                <option value="1">Oldest</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="p-4 pt-2 ps-3 pe-3">
        <ReviewComponent
          reviews={reviews}
          handlereplyClick={handlereplyClick}
          handleReviewDelete={handleReviewDelete}
          timeZone={false}
          userRestriction={userInfo?.info?.accessTypeDetails?.accessTypeId}
          isUploading={isUploading}
        />

        {/* <p className="text-center mt-5 fw-semibold"> {
          !reviews && "No reviews found"
        }</p> */}
      </div>

      <ReviewReply
        openModalReply={openModalReply}
        setOpenModalReply={setOpenModalReply}
        reply={reply}
        ownerResponse={ownerResponse}
        setOwnerResponse={setOwnerResponse}
        replyMessage={replyMessage}
        setReplyMessage={setReplyMessage}
        handleReply={handleReply}
        isUploading={isUploading}
      />

      <ReviewFilter
        openModalReview={openModalReview}
        setOpenModalReview={setOpenModalReview}
        selectedValues={selectedValues}
        selectedOption={selectedOption}
        responseFilter={responseFilter}
        rating={rating}
        reviewType={reviewType}
        responetype={responetype}
        setResponse={setResponse}
        setSelectedOption={setSelectedOption}
        handleChange={handleChange}
        handleResponse={handleResponse}
        handleCheckboxChange={handleCheckboxChange}
        handleFilter={handleFilter}
        setSelectedValues={setSelectedValues}
      />

      <div className="">
        <h2
          className="mb-4 fw-semibold"
          ref={elementRef}
          style={{ textAlign: "center" }}
        >
          {totalPage >= page && hasMore && reviews?.length
            ? "Loading Items"
            : ""}
          {reviews?.length === 0 && (
            <div className="flex justify-center items-center">
              <img
                src="https://pro-manage.s3.ap-south-1.amazonaws.com/ReviewNotFound.svg"
                alt=""
              />
            </div>
          )}
        </h2>
      </div>
    </div>
  );
};

export default Review;
