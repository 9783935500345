import React, { useContext, useEffect, useState } from 'react'
import httpservice from '../Service/httpservice'
import { API_URL } from '../env/env'
import { AuthContext } from '../context/AuthContext'
import { Empty, Popover } from 'antd'
import moment from 'moment'
import NotificationTopBar from '../Components/NotificationTopBar/TopBar'
import { useLocation } from 'react-router-dom'
import Loader from '../loader/loader'

const LocationNotification = () => {
  const {
    userInfo,
    groupId,
    tokenInfo,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleQuestionCountNotification,
    handleReviewNotification,
    handleLocationNotification,
    locationNewNotification
  } = useContext(AuthContext)

  const [load, setload] = useState(true)

  const [isPending, setIsPending] = useState(false)

  const location = useLocation()

  useEffect(() => {
    tokenInfo()
  }, [])

  useEffect(() => {
    handleLocationCountNotification(true)
    handleReviewCountNotification(true)
    handleQuestionCountNotification(true)
    // handleReviewNotification(true)
    // handleLocationNotification(true)
    if (groupId) {
      handleNotification()
    }
  }, [groupId])

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)

  const [locationNotification, setLocationNotification] = useState([])
  // const [reviewNotification, setReviewNotification] = useState([])

  const indexOfLastItem = currentPage * pageSize
  const indexOfFirstItem = indexOfLastItem - pageSize
  const currentItems = locationNotification?.slice(
    indexOfFirstItem,
    indexOfLastItem
  )

  const totalPages = Math.ceil(locationNotification?.length / pageSize)

  const changePage = pageNumber => setCurrentPage(pageNumber)

  const changePageSize = size => {
    setPageSize(size)
    setCurrentPage(1)
  }

  const handleNotification = async () => {
    try {
      const currentDate = new Date()
      const startDate = new Date()
      startDate.setDate(startDate.getDate() - 20)
      const formattStartDate = startDate.toISOString().split('T')[0]
      const formattedEndDate = currentDate.toISOString().split('T')[0]
      let url = `${API_URL}/location/notification`
      let body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        startDate: formattStartDate,
        endDate: formattedEndDate,
        newNotification: 0
      }
      let response = await httpservice.post(url, body)

      if (response.status === 200) {
        // console.log(response?.data?.data, 'notification')
        setLocationNotification(response?.data?.data?.notification)
        setIsPending(true)
      } else {
        setLocationNotification([])
        setIsPending(true)
      }
    } catch {
      setIsPending(true)
      setLocationNotification([])
    }
  }

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  const days = [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY'
  ]

  function formatTime (hours, minutes) {
    const meridiem = hours >= 12 ? 'PM' : 'AM'
    const hour = hours % 12 === 0 ? 12 : hours % 12
    const minute =
      minutes !== undefined ? String(minutes).padStart(2, '0') : '00'

    return `${String(hour)}:${minute} ${meridiem}`
  }

  const content = (
    <div className=' text-sm text-gray-500 dark:text-gray-400'>
      <div
        className={`flex justify-start items-center ${
          description?.regularHours
            ? 'w-[20rem]'
            : description?.profile
            ? 'w-[30rem]'
            : 'w-[15rem]'
        } `}
      >
        <div className='px-3 py-2'>
          <p className='text-lg text-black  overflow-x-auto font-semibold '>
            {title}
          </p>
          {/* <h4 className='text-black text-lg font-semibold'>Edited Content</h4> */}
          <p className='text-[12px] text-businessProfile  overflow-x-auto'>
            {description?.title ? (
              description?.title
            ) : description?.storefrontAddress ? (
              <div>
                {description?.storefrontAddress?.addressLines?.map(
                  (item, index) => (
                    <p key={index}>{item}</p>
                  )
                )}
                <p>
                  {description?.storefrontAddress?.locality},{' '}
                  {description?.storefrontAddress?.administrativeArea},{' '}
                  {description?.storefrontAddress?.postalCode}
                </p>
              </div>
            ) : description?.phoneNumbers ? (
              <div>
                {description?.phoneNumbers?.primaryPhone}
                {description?.phoneNumbers?.additionalPhones?.map(item => (
                  <p className=''>{item}</p>
                ))}
              </div>
            ) : description?.profile ? (
              <div>{description?.profile?.description}</div>
            ) : description?.specialHours ? (
              description?.specialHours?.specialHourPeriods.map(item => (
                <>
                  <p>
                    {item?.startDate?.day}/{item?.startDate?.month}/
                    {item?.startDate?.year}
                  </p>
                  {/* <p>{item?.endDate?.day}/{item?.endDate?.month}/{item?.endDate?.year}</p> */}
                  <p>
                    {' '}
                    {item?.openTime &&
                      formatTime(item.openTime.hours, item.openTime.minutes)}
                    {item?.openTime ? ' - ' : ''}
                    {item?.closeTime &&
                      formatTime(item.closeTime.hours, item.closeTime.minutes)}
                  </p>
                </>
              ))
            ) : description?.categories ? (
              <div>
                <p>{description?.categories?.primaryCategory?.displayName}</p>
                {description?.categories?.additionalCategories.map(item => (
                  <p>{item?.displayName}</p>
                ))}
              </div>
            ) : description?.regularHours ? (
              // console.log('regularHours', description?.regularHours?.periods),
              days?.map((day, index) =>
                description?.regularHours?.periods?.map((item, i) => {
                  if (item?.closeDay === day) {
                    // console.log('BusinessEdit')
                    return (
                      <div key={i} className='text-justify flex pt-1'>
                        <p
                          className={`${
                            item?.openDay === 'SUNDAY'
                              ? 'text-listing'
                              : 'text-businessProfile'
                          }  pb-2`}
                        >
                          <span
                            className={`${
                              item?.openDay === null
                                ? ' text-white ps-4 ms-2'
                                : ''
                            }`}
                          >
                            {item?.openDay?.substring(0, 3)}{' '}
                          </span>
                        </p>
                        <p
                          className={`${
                            item?.openDay === 'SUNDAY'
                              ? 'text-listing'
                              : 'text-businessProfile'
                          } ps-2 pb-2`}
                        >
                          {moment({
                            hour: item?.openTime?.hours,
                            minute: item?.openTime?.minutes
                          }).format('hh:mm A')}{' '}
                          -{' '}
                          {moment({
                            hour: item?.closeTime?.hours,
                            minute: item?.closeTime?.minutes
                          }).format('hh:mm A')}
                        </p>
                      </div>
                    )
                  }
                  // If no match found, return null
                  return null
                })
              )
            ) : description?.websiteUri ? (
              <p>{description?.websiteUri}</p>
            ) : null}
          </p>
        </div>
      </div>
    </div>
  )

  useEffect(() => {
    if (isPending) {
      // // console.log(isPending, 'ispending', load)
      // debugger
      setload(false)
    }
  }, [isPending])

  const EmptyWithShadow = ({ condition }) => (
    <div
      className={`${condition ? '' : 'hidden'}`}
      style={{
        boxShadow: condition ? '0px 2px 5px rgba(0, 0, 0, 0.1)' : 'none',
        borderRadius: condition ? '0 0 10px 10px' : '0' // Adjust the border-radius values as needed
      }}
    >
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className='mt-0 pt-5' />
    </div>
  )
  return (
    <div>
      {/* {['/notification/review', '/notification/location'].includes(
        location.pathname
      ) && <NotificationTopBar />} */}
      {load && <Loader />}
      <div>
        <div className='relative overflow-x-auto rounded-t-none roundered-b-4 p-8 pt-1  bg-custom-background4 m-6 mt-0'>
          <div className='flex justify-end  items-center'>
            <div className=' pb-3 col-2 '>
              <select
                id='pageSize'
                className='bg-white border   border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                value={pageSize}
                onChange={e => changePageSize(e.target.value)}
              >
                {' '}
                {[25, 50, 100]?.map(option => (
                  <option key={option} value={option}>
                    Show {option} Entries
                  </option>
                ))}
              </select>
            </div>
          </div>
          <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
            <thead className='text-base text-center text-gray-700 capitalize bg-newTableBG dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                {/* <th scope='col' className='px-6 py-3 font-semibold rounded-s-lg'>
                Sl.no
              </th> */}
                <th
                  scope='col'
                  className='px-6 py-3 font-semibold  rounded-s-lg'
                >
                  User Name
                </th>
                <th scope='col' className='px-6 py-3 font-semibold'>
                  Business Profile
                </th>
                <th scope='col' className='px-6 py-3 font-semibold'>
                  Profile Edit
                </th>
                <th
                  scope='col'
                  className='px-6 py-3 font-semibold rounded-e-lg'
                >
                  Date & Time
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr
                  key={index}
                  className={`text-center rounded-b-2xl dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-600 ${
                    index % 2 === 0
                      ? 'bg-custom-background4'
                      : 'bg-custom-background5'
                  }`}
                >
                  {/* <td className='px-6 border-r py-4 text-notificationCount font-semibold whitespace-nowrap dark:text-white'>
                  {index + 1}
                </td> */}
                  <td className='px-6 py-4 border-r text-notificationCount font-semibold'>
                    {item?.editedBy?.name}
                  </td>
                  <td className='px-6 py-4 border-r text-notificationCount font-semibold'>
                    {item?.location?.name}
                    <p className='text-[10px] font-normal text-notificationCount mb-0'>
                      {item?.location?.address}
                    </p>
                  </td>
                  <td className='px-6 py-4 border-r text-notificationCount font-semibold cursor-pointer'>
                    <Popover
                      placement='leftTop'
                      content={content}
                      trigger='click'
                      className=''
                      onClick={() => {
                        setTitle(item?.updatedData?.section_name)
                        setDescription(item?.updatedData?.updated_detail)
                      }}
                    >
                      {item?.updatedData?.section_name}
                    </Popover>
                  </td>
                  {/* <td className='px-6 py-4 border-r font-medium text-notificationTime'>
                    {item?.updatedData?.updated_detail?.title}
                  </td> */}

                  <td className='px-6 py-4 font-medium text-ViewActivity'>
                    <p className='mb-0'>{item?.updatedDate} </p>
                    <p className='mb-0'>{item?.updatedTime}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <EmptyWithShadow condition={currentItems?.length === 0} />

          <div className='flex justify-end items-center mt-4'>
            <div className='me-2 font-medium text-notificationCount'>
              {/* Showing {currentItems?.length} out of {totalPages} */}
              Total Results {locationNotification?.length}
            </div>
            {currentPage !== 1 && (
              <div
                className='me-2 cursor-pointer'
                onClick={() => changePage(currentPage - 1)}
              >
                <img
                  src='https://pro-manage.s3.ap-south-1.amazonaws.com/PaginationNextArrow.svg'
                  alt='PaginationNextArrow'
                  className='transform rotate-180 w-8 h-8'
                />
              </div>
            )}
            <div className='gap-2 flex'>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i}
                  onClick={() => changePage(i + 1)}
                  className={`px-2.5 py-1  rounded-md ${
                    i + 1 === currentPage
                      ? 'bg-listing text-white font-semibold dark:bg-gray-800'
                      : 'border text-paginationColor font-semibold'
                  }`}
                >
                  {i + 1}
                </button>
              ))}
            </div>
            {totalPages > 1 && currentPage < totalPages && (
              <div
                className='ms-2 cursor-pointer'
                onClick={() => changePage(currentPage + 1)}
              >
                <img
                  src='https://pro-manage.s3.ap-south-1.amazonaws.com/PaginationNextArrow.svg'
                  alt='PaginationNextArrow'
                  className='w-8 h-8'
                />
              </div>
            )}
          </div>
        </div>
        {/* Pagination */}
      </div>
    </div>
  )
}

export default LocationNotification
