import React, { useContext, useEffect, useState } from 'react'
import PerformaneComponent from '../Components/Performance/PerformanceComponent'
import Loader from '../loader/loader'
import { AuthContext } from '../context/AuthContext'
const InsightPerformance = () => {
  const [load, setload] = useState(true)

  const {
    userInfo,
    tokenInfo,
    groupId,
    handleLocationCountNotification,
    handleReviewCountNotification,
    handleQuestionCountNotification,
    handleReviewNotification,
    handleLocationNotification
  } = useContext(AuthContext)

  useEffect(() => {
    tokenInfo()
  }, [])

  useEffect(() => {
    if (userInfo?.info?.roleDetails?.roleId === 1) {
      handleLocationCountNotification(true)
      handleReviewCountNotification(true)
      handleQuestionCountNotification(true)
      // handleReviewNotification(true)
      // handleLocationNotification(true)
    }
  }, [groupId, userInfo])

  return (
    <div className='bg-InsightBackGround'>
      {load && <Loader />}
      <div className='container  p-10 pt-0 '>
        <PerformaneComponent size={false} setload={setload} />
      </div>
    </div>
  )
}

export default InsightPerformance
