import axios from 'axios'
import { createContext, useEffect, useState } from 'react'
import { API_URL } from '../env/env'
import httpservice from '../Service/httpservice'
import { useNavigate } from 'react-router-dom'
import { UserInfo, setUserInfo } from '../Store/UserInfo'
import { AccountDetails } from '../Store/AccountInfo'
export const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
  // // console.log(currentUser, 'newUserInfo')

  const userInfo = UserInfo(state => state?.UserInfo)
  const groupId = AccountDetails(state => state.AccountDetails)
  const [currentUser, setCurrentUser] = useState(userInfo || null)
  // const tokenDetails = JSON.parse(localStorage.getItem('tmbUserData'))

  // console.log('currentUser', userInfo)
  const logout = async () => {
    let body = {
      userId: userInfo?.info?.userId
    }

    const response = await httpservice.post(`${API_URL}/auth/logout`, body)
    // console.log(response, 'LOGIN')
    if (response.status === 200) {
      window.location.href = '/login'
      localStorage.clear()
    }
    return response.data
  }

  // console.log('currentUser', currentUser)

  const tokenInfo = async () => {
    try {
      if (currentUser?.info) {
        let body = {
          userId: userInfo.info?.userId
        }
        const url = `${API_URL}/user/info`
        const response = await httpservice.post(url, body)
        if (response.status === 200) {
          // const updatedUser = { ...userInfo }
          // updatedUser['info'] = response?.data?.data.info
          // updatedUser['token']= response?.data?.data.token
          // updatedUser['userAccess'] = response?.data?.data.userAccess
          // // console.log('updatedUser', updatedUser)
          // localStorage.setItem('tmbUserData1',
          //  { state: { UserInfo: { updatedUser }} )

          setUserInfo(response?.data?.data)
        }
        // console.log('response', response)
      } else {
        window.location.href = '/login'
        localStorage.clear()
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        window.location.href = '/login'
        localStorage.clear()
      }
    }
  }

  const [locationCountNotification, setLocationCountNotification] = useState([])
  const [reviewCountNotification, setReviewCountNotification] = useState([])
  const [reviewNewNotification, setReviewNewNotification] = useState([])
  const [locationNewNotification, setLocationNewNotification] = useState([])
  const [questionNewNotification, setQuestionNewNotification] = useState([])
  const [questionCountNotification, setQuestionCountNotification] = useState([])

  const handleLocationCountNotification = async notificationClick => {
    try {
      const currentDate = new Date()
      const startDate = new Date()
      startDate.setDate(startDate.getDate() - 20)
      const formattStartDate = startDate.toISOString().split('T')[0]
      const formattedEndDate = currentDate.toISOString().split('T')[0]
      let url = `${API_URL}/location/notificationCount`
      let body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        startDate: formattStartDate,
        endDate: formattedEndDate
      }
      let response = await httpservice.post(url, body)

      if (response.status === 200) {
        // console.log(response?.data?.data, 'notification')
        setLocationCountNotification(response?.data?.data)
      }
    } catch {}
  }

  const handleReviewCountNotification = async notificationClick => {
    try {
      const currentDate = new Date()
      const startDate = new Date()
      startDate.setDate(startDate.getDate() - 20)
      const formattStartDate = startDate.toISOString().split('T')[0]
      const formattedEndDate = currentDate.toISOString().split('T')[0]
      let url = `${API_URL}/review/notificationCount`
      let body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        startDate: formattStartDate,
        endDate: formattedEndDate
      }
      let response = await httpservice.post(url, body)

      if (response.status === 200) {
        // console.log(response?.data?.data, 'notification')
        setReviewCountNotification(response?.data?.data)
      }
    } catch {}
  }

  const handleLocationNotification = async notificationClick => {
    try {
      const currentDate = new Date()
      const startDate = new Date()
      startDate.setDate(startDate.getDate() - 20)
      const formattStartDate = startDate.toISOString().split('T')[0]
      const formattedEndDate = currentDate.toISOString().split('T')[0]
      let url = `${API_URL}/location/notification`
      let body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        startDate: formattStartDate,
        endDate: formattedEndDate,
        newNotification: notificationClick ? 1 : 0
      }
      let response = await httpservice.post(url, body)

      if (response.status === 200) {
        // console.log(response?.data?.data, 'notification')
        setLocationNewNotification(response?.data?.data?.notification)
      } else {
        setLocationNewNotification([])
      }
    } catch {
      setLocationNewNotification([])
    }
  }

  const handleReviewNotification = async notificationClick => {
    try {
      const currentDate = new Date()
      const startDate = new Date()
      startDate.setDate(startDate.getDate() - 20)
      const formattStartDate = startDate.toISOString().split('T')[0]
      const formattedEndDate = currentDate.toISOString().split('T')[0]
      let url = `${API_URL}/review/notification`
      let body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        startDate: formattStartDate,
        endDate: formattedEndDate,
        newNotification: notificationClick ? 1 : 0
      }
      let response = await httpservice.post(url, body)

      if (response.status === 200) {
        // console.log(response?.data?.data, 'notification')
        setReviewNewNotification(response?.data?.data?.notification || [])
      } else {
        setReviewNewNotification([])
      }
    } catch {
      setReviewNewNotification([])
    }
  }

  const handeleQuestionNotification = async notificationClick => {
    try {
      const currentDate = new Date()
      const startDate = new Date()
      startDate.setDate(startDate.getDate() - 20)
      const formattStartDate = startDate.toISOString().split('T')[0]
      const formattedEndDate = currentDate.toISOString().split('T')[0]
      let url = `${API_URL}/qa/notification`
      let body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        startDate: formattStartDate,
        endDate: formattedEndDate,
        newNotification: notificationClick ? 1 : 0
      }
      let response = await httpservice.post(url, body)
      if (response.status === 200) {
        // console.log(response?.data?.data, 'notification')
        setQuestionNewNotification(response?.data?.data?.notification || [])
      } else {
        setQuestionNewNotification([])
      }
    } catch (error) {
      setQuestionNewNotification([])
    }
  }
  const handleQuestionCountNotification = async () => {
    try {
      const currentDate = new Date()
      const startDate = new Date()
      startDate.setDate(startDate.getDate() - 20)
      const formattStartDate = startDate.toISOString().split('T')[0]
      const formattedEndDate = currentDate.toISOString().split('T')[0]
      let url = `${API_URL}/qa/notificationCount`
      let body = {
        userId: userInfo?.info?.userId,
        groupId: groupId?.groupId,
        startDate: formattStartDate,
        endDate: formattedEndDate
        // newNotification: notificationClick ? 1 : 0
      }
      let response = await httpservice.post(url, body)
      if (response.status === 200) {
        // console.log(response?.data?.data, 'question notification')
        setQuestionCountNotification(response?.data?.data || [])
      } else {
        setQuestionCountNotification([])
      }
    } catch (error) {
      setQuestionCountNotification([])
    }
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        logout,
        tokenInfo,
        userInfo,
        groupId,
        handleLocationCountNotification,
        handleReviewCountNotification,
        handleReviewNotification,
        handleLocationNotification,
        handeleQuestionNotification,
        handleQuestionCountNotification,
        locationCountNotification,
        reviewCountNotification,
        reviewNewNotification,
        locationNewNotification,
        questionNewNotification,
        questionCountNotification
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
