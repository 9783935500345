import React from 'react'
import { Modal } from 'flowbite-react'
const ViewModal = ({ url, type, setOpenViewModal, openviewmodal }) => {
  return (
    <div>
      {/* <img src={url} alt='view' className='w-full h-full object-cover' /> */}
      <Modal
        show={openviewmodal}
        dismissible
        onClose={() => {
          setOpenViewModal(false)
        }}
        size='lg'
      >
        <div className='flex flex-col justify-center items-center p-3 bg-gray-100 rounded-lg relative'>
          {/* <div className='absolute top-2 right-4 z-50'>
            <img
              src='https://pro-manage.s3.ap-south-1.amazonaws.com/crossNewIcon.svg'
              alt='view'
              className='object-cover cursor-pointer'
              onClick={() => setOpenViewModal(false)}
            />
          </div> */}
          {type === 'Image' ? (
            <img src={url} alt='view' className='img-fluid' />
          ) : (
            <video
              src={url}
              alt='view'
              className='object-cover max-w-full h-auto'
              type='video/mp4'
              controls
            />
          )}
        </div>
      </Modal>
    </div>
  )
}

export default ViewModal
