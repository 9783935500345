import React, { useEffect } from 'react'

const RangeSlider = ({
  title,
  imageUrl,
  count,
  colorClass,
  dialClass,
  rangeRef,
  percentage
}) => {
  // console.log(percentage, "percentage")

  useEffect(() => {
    updateRangeSlider(rangeRef, percentage, dialClass)
  }, [percentage, rangeRef, dialClass])
  const updateRangeSlider = (rangeRef, percentage, dialClass) => {
    if (!rangeRef?.current) return

    const range = rangeRef.current
    const input = range.querySelector('input')
    const maxPoints = input.getAttribute('max')
    const dial = range.querySelector(`.${dialClass}`)

    // console.log(dial, "dial")

    if (!dial) return // Ensure the dial element is found

    const radius = range.clientWidth / 2
    // console.log(typeof radius, "radius", radius)
    const deg = Math.ceil((percentage / 100) * 360)
    // console.log(deg, "rad", typeof deg)
    const x =
      Math.ceil(
        (Number(radius) - 5) * Math.sin(Number(deg) * (Math.PI / 180))
      ) + Number(radius)
    const y =
      Math.ceil(
        (Number(radius) - 5) * -Math.cos(Number(deg) * (Math.PI / 180))
      ) + Number(radius)

    // console.log(x, "dailss")

    dial.style.transform = `translate(${x}px, ${y}px)`

    const rightBlocker = range.querySelector('.right .blocker')
    const leftBlocker = range.querySelector('.left .blocker')

    // console.log(rightBlocker, "rightBlocker", leftBlocker, "leftBlocker")

    if (!rightBlocker || !leftBlocker) return

    if (deg <= 180) {
      rightBlocker.style.transform = `rotate(${deg}deg)`
      leftBlocker.style.transform = 'rotate(0)'
    } else {
      rightBlocker.style.transform = 'rotate(180deg)'
      leftBlocker.style.transform = `rotate(${deg - 180}deg)`
    }
  }

  return (
    <div>
      <div className='flex gap-3'>
        <div className='range position-relative' ref={rangeRef}>
          <input
            type='range'
            name='points'
            min='0'
            max='100'
            value={count}
            className='count'
          />
          <div className={`slice ${colorClass} left`}>
            <div className='blocker'></div>
          </div>
          <div className={`slice ${colorClass} right`}>
            <div className='blocker'></div>
          </div>
          <span className='info flex justify-center items-center'>
            <div className='flex mx-auto my-auto'>
              <img
                src={imageUrl}
                alt={`${title} Img`}
                className='mx-auto my-auto'
              />
            </div>
          </span>
          <div className={`dial ${dialClass}`} tabIndex='10'></div>
        </div>
        <div className='my-auto'>
          <p className='font-medium text-lg'>{title}</p>
          <p className='text-listing fw-semibold text-lg mt-1'>
            {count ? count : 0}
          </p>
        </div>
      </div>
    </div>
  )
}

export default RangeSlider
