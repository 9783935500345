import { Modal } from 'flowbite-react'
import React from 'react'

const DeleteModal = ({
  isDeleteModal,
  setDeleteModal,
  deletePost,
  deletePostName,
  isUploading
}) => {
  return (
    <div>
      <Modal show={isDeleteModal} onClose={() => setDeleteModal(false)}>
        <Modal.Body>
          <div className='p-4 md:p-5 text-center flex justify-center items-center flex-col'>
            <img
              src='https://pro-manage.s3.ap-south-1.amazonaws.com/bin.svg'
              alt='deleteImg'
              className='h-52'
            />

            <h3 className='mb-5 text-lg font-normal text-gray-500 dark:text-gray-400'>
              Are you sure you want to delete this product?
            </h3>
            <div className='flex gap-4'>
              {/* <button data-modal-hide="popup-modal" type="button"
                                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center me-2"
                                onClick={(e) => deletePost(e, deletePostName)}
                                on>
                                Yes, I'm sure
                            </button> */}
              <button
                data-modal-hide='popup-modal'
                type='button'
                className='text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600'
                onClick={() => setDeleteModal(false)}
              >
                No, cancel
              </button>
              <button
                data-modal-hide='popup-modal'
                type='button'
                className='text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center me-2'
                onClick={e => deletePost(deletePostName)}
                disabled={isUploading}
              >
                Yes, I'm sure
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default DeleteModal
