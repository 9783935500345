import React from 'react'
import { Button, Modal } from 'flowbite-react'

const AnswerModel = ({
  openReplyQuestionModal,
  setOpenReplyQuestionModal,
  setReplyAnswer,
  replyAnswer,
  handleReplyAnswer,
  questionReplyJson,
  setSelectedLocation
}) => {
  // console.log(questionReplyJson, 'questionReplyJson')
  return (
    <div>
      <Modal
        show={openReplyQuestionModal}
        onClose={() => {
          setOpenReplyQuestionModal(false)
          setReplyAnswer('')
          setSelectedLocation('')
        }}
        // className="custom-modal"
      >
        <Modal.Header className='p-3 bg-reviewPopUp rounded-t-2xl    '>
          Question Reply
        </Modal.Header>
        <Modal.Body className=' bg-reviewPopUp'>
          <div className=''>
            <div className='d-flex gap-2 justify-content-start align-items-center'>
              <img
                className='w-14'
                src={
                  questionReplyJson?.details?.author?.type === 'MERCHANT'
                    ? 'https://pro-manage.s3.ap-south-1.amazonaws.com/DummyProfileManager.svg'
                    : questionReplyJson?.details?.author?.profilePhotoUri
                }
                alt='profileImg'
              />
              <p className='text-lg font-medium'>
                {questionReplyJson?.details?.author?.displayName}
              </p>
            </div>
            <div className='d-flex flex-col justify-content-start mt-3'>
              <p className='m-0 text-md font-medium text-black'>
                {' '}
                Business : {questionReplyJson?.title}
              </p>
              <p className='text-sm text-businessProfile'>
                {questionReplyJson?.address}
              </p>
              <h6 className='pt-2 text-lg font-medium text-black '>
                Question:
              </h6>
              <p>{questionReplyJson?.details?.text}</p>
            </div>
            <form className='mt-3'>
              <div className='relative'>
                <div className='form-floating relative questionEdit'>
                  <textarea
                    // ref={replyarea}
                    style={{ height: '250px' }}
                    className='form-control comment_flot rounded-2xl border-0 '
                    // placeholder='Leave a comment here'
                    id='floatingTextarea'
                    // pattern='/[<>{}~`"]/g'
                    value={replyAnswer}
                    // onChange={e =>
                    //   setReplyAnswer(e.target.value?.slice(0, 4000))
                    // }
                    onChange={e => {
                      const inputValue = e.target.value
                      const sanitizedInput = inputValue.replace(
                        /[<>{}~`"]/g,
                        ''
                      )
                      setReplyAnswer(sanitizedInput?.slice(0, 4000))
                    }}
                  ></textarea>
                  <label
                    for='floatingTextarea'
                    className='text-businessProfile mb-3'
                  >
                    Please Type Your Answer
                  </label>
                </div>
                <div className='absolute bottom-1 right-2 text-xs text-gray-500'>
                  {replyAnswer?.length}
                  /4000
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className='pt-3 justify-end '>
          <div className='flex justify-end gap-4 bg-white '>
            <button
              type='submit'
              className='px-4 py-2 text-md font-medium text-center text-white bg-listing rounded-lg hover:bg-listing focus:outline-none  '
              onClick={handleReplyAnswer}
            >
              Send
            </button>
            <button
              color='gray'
              onClick={() => {
                setOpenReplyQuestionModal(false)
                setReplyAnswer('')
                setSelectedLocation('')
              }}
              className='px-4 py-2 text-md font-medium text-center border-1 text-gray  rounded-lg focus:outline-none'
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AnswerModel
